import classNames from 'classnames';
import React from 'react';

import { ANON } from '!app/lib/constants';
import { ctaRedirect } from '!app/lib/urlUtils';
import { fireUserInteraction } from '!app/metrics';
import CTAButton from '!app/share/CTAButton';

const getAutomationIds = (index) => {
  const ctaPlacement = index === 0 ? 'primary' : 'secondary';
  return {
    preheadline: `masthead_preheadline_${ctaPlacement}`,
    headline: `masthead_headline_${ctaPlacement}`,
    subheadline: `masthead_subheadline_${ctaPlacement}`,
    primaryCopy: `masthead_message_${ctaPlacement}`,
    secondaryCopy: `masthead_secondary_message_${ctaPlacement}`,
    cta: `masthead_input_cta_${ctaPlacement}`,
    legal: `masthead_legal_${ctaPlacement}`,
  };
};

export const DualMastheadProduct = ({ cta, index, entitlement }) => {
  // entitlement check per CTA and cart abandonment to determine which values to render for each field

  const from = cta.programUrl;
  // checks the entitlementState, if it's null then we default to Anonymous user
  const entitlementState = entitlement?.entitlementState || ANON;
  const eyebrow = cta.eyebrowStates[entitlementState];
  const headline = cta?.headlineStates[entitlementState];
  const subheadline = cta?.subheadlineStates[entitlementState];
  const primaryCopy = cta?.primaryCopyStates[entitlementState];
  const secondaryCopy = cta?.secondaryCopyStates[entitlementState];
  const ctaCopy = cta?.ctaCopyStates[entitlementState];
  const ctaHref = cta?.ctaHrefStates[entitlementState];
  const ctaLegalCopy = cta?.ctaLegalCopyStates[entitlementState];
  const ctaButtonStyle = cta?.ctaButtonStyle;
  const ctaDownloadAppText = cta?.ctaDownloadApp;
  const getCtaText = () => {
    return ctaDownloadAppText || ctaCopy;
  };
  const onClick = () => {
    const elementSpecifier = `masthead_cta:position_${index + 1}`;

    fireUserInteraction(
      elementSpecifier,
      'driver_click',
      'click',
      true,
      ctaCopy
    );

    ctaRedirect({
      params: {},
      user: entitlement,
      componentUrl: ctaHref,
      from,
      passCustomFromToGoToUrl: true,
      ctaFields: {
        cartAbandonmentCopy: { href: cta.cartAbandonmentCopy.href },
      },
    });
  };

  const automationIds = getAutomationIds(index);

  return (
    <>
      <div style={{ gridRow: 1 }}>
        {eyebrow && (
          <div
            className="DualCtaMasthead__preheadline"
            data-automationid={automationIds.preheadline}
            dangerouslySetInnerHTML={{ __html: eyebrow }}
          />
        )}
      </div>
      <div style={{ gridRow: 2 }}>
        {headline && (
          <h1
            className="DualCtaMasthead__headline"
            id="regionMasthead"
            data-automationid={automationIds.headline}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        )}
      </div>
      <div style={{ gridRow: 3 }}>
        {subheadline && (
          <div
            className="DualCtaMasthead__subheadline"
            data-automationid={automationIds.subheadline}
            dangerouslySetInnerHTML={{ __html: subheadline }}
          />
        )}
      </div>
      <div style={{ gridRow: 4 }}>
        {primaryCopy && (
          <div
            className="DualCtaMasthead__primary-message"
            data-automationid={automationIds.primaryCopy}
            dangerouslySetInnerHTML={{ __html: primaryCopy }}
          />
        )}
      </div>
      <div style={{ gridRow: 5 }}>
        {secondaryCopy && (
          <div
            className="DualCtaMasthead__secondary-message"
            data-automationid={automationIds.secondaryCopy}
            dangerouslySetInnerHTML={{ __html: secondaryCopy }}
          />
        )}
      </div>
      <div className="DualCtaMasthead__input" style={{ gridRow: 6 }}>
        {ctaCopy && ctaHref && (
          <CTAButton
            useStyle={ctaButtonStyle}
            className={classNames('DualCtaMasthead__input-cta', {
              '--green': ctaButtonStyle === 'green',
            })}
            size="medium"
            onClick={onClick}
            data-automationid={automationIds.cta}
          >
            {getCtaText()}
          </CTAButton>
        )}
      </div>
      <div style={{ gridRow: 7 }}>
        {ctaLegalCopy && (
          <div
            className="DualCtaMasthead__legal section-disclaimer"
            data-automationid={automationIds.legal}
            dangerouslySetInnerHTML={{ __html: ctaLegalCopy }}
          />
        )}
      </div>
    </>
  );
};
