import Text from '@hulu/web-ui/Text';
import PropTypes, { shape } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import '../stylesheet/NetworkIcon.scss';

import messages from '../messages';

import { Translate } from '!app/i18n';

const NetworkIcon = (props) => {
  const { categoryIndex, index, channel, locale } = props;

  const translator = new Translate(locale);
  const translatedMessage = translator.translateAll(messages);
  const { on_demand, disclaimer_on_demand } = translatedMessage;

  const getBackgroundStyle = (chan) => {
    if (chan?.artwork?.path) {
      return {
        backgroundImage: `url(${chan?.artwork?.path})`,
      };
    }
    return {};
  };

  return (
    <div
      className="NetworkIcon"
      key={`${categoryIndex}.${index}`}
      role="listitem"
      aria-label={channel?.network_name}
    >
      <div className="NetworkIcon__frame">
        <span
          className={`NetworkIcon__logo ${
            channel?.artwork?.be_source ? 'NetworkIcon__logo-be' : ''
          }`}
          style={getBackgroundStyle(channel)}
        >
          <span
            className={
              channel?.artwork?.path
                ? `NetworkIcon__network-name-invisible`
                : ''
            }
          >
            {channel?.network_name}
          </span>
        </span>
      </div>
      {channel?.on_demand && (
        <div className="NetworkIcon__badge">
          <span className="HuluTooltip" data-tip data-for="sadFace">
            {on_demand}
          </span>
          <ReactTooltip id="sadFace" place="right" type="light" effect="solid">
            <div className="onDemandTooltip">
              <div>
                <Text variant="body12">{disclaimer_on_demand}</Text>
              </div>
            </div>
          </ReactTooltip>
        </div>
      )}
    </div>
  );
};

NetworkIcon.propTypes = {
  categoryIndex: PropTypes.number,
  index: PropTypes.number,
  channel: shape({}),
};
export default NetworkIcon;
