import PropTypes from 'prop-types';
import React from 'react';

import MastheadTileBackground from './MastheadTileBackground';

const MastheadTile = (props) => {
  const { title, artwork, mobileArtwork } = props;

  return (
    <div className="MastheadTile">
      <MastheadTileBackground
        className="MastheadTileBackground"
        artwork={artwork}
        mobileArtwork={mobileArtwork}
        title={title}
      />
    </div>
  );
};

MastheadTile.propTypes = {
  artwork: PropTypes.shape({}),
  mobileArtwork: PropTypes.shape({}),
  title: PropTypes.string,
};

export default MastheadTile;
