import Badge from '@hulu/web-ui/Badge';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ClockIconSVG from '../assets/ClockIconSVG';
import DetailButtonSVG from '../assets/DetailButtonSVG';

import { enterKeyHandler, preventFocus } from '!app/lib/accessibilityUtils';
import { getImageUrl } from '!app/lib/imageUtils';
import { handleTileOnClick } from '!app/lib/TealiumEventsUtils';
import { withTileInteraction } from '!app/metrics/hoc';
import TruncatedText from '!app/share/TruncatedText';

const WIDTH_RATIO = 16;
const HEIGHT_RATIO = 9;
const IMAGE_RATIO = HEIGHT_RATIO / WIDTH_RATIO;
const IMAGE_WIDTH = 600;
const RETINA_IMAGE_WIDTH = 1200;
const IMAGE_HEIGHT = Math.round(IMAGE_WIDTH * IMAGE_RATIO);
const RETINA_IMAGE_HEIGHT = 676;

const createImageUrl = (url, isRetina) => {
  const retinaImageUrlOptions = {
    width: RETINA_IMAGE_WIDTH,
    height: RETINA_IMAGE_HEIGHT,
  };
  const imageUrlOptions = {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  };

  return isRetina
    ? getImageUrl(url, retinaImageUrlOptions)
    : getImageUrl(url, imageUrlOptions);
};

const BRAND_WIDTH = 100;
const BRAND_HEIGHT = 100;
const BRAND_FORMAT = 'webp';
const createBrandUrl = (url) =>
  getImageUrl(url, {
    width: BRAND_WIDTH,
    height: BRAND_HEIGHT,
    format: BRAND_FORMAT,
  });
const generateBadgeClassNames = (modifiers) =>
  `Tile__badge ${modifiers.map((m) => `Tile__badge--${m}`).join(' ')}`;

const TileThumbnail = (props) => {
  const {
    assetUrl,
    brandWatermark,
    collectionId,
    collectionIndex,
    disableTileLink,
    enableSignupModal,
    entityId,
    hideControlIcon,
    index,
    isRetina,
    isUpcoming,
    onClick,
    showSignupModal,
    thumbnail,
    thumbnailHover,
    title,
    type,
    videoData,
  } = props;
  const enableTileLink = !disableTileLink;
  // If we're showing signup modal, add watchUrl to href for google to crawl
  const watchUrl = showSignupModal ? `/watch/${entityId}` : undefined;

  const Thumbnail = withTileInteraction('a', {
    element_specifier: 'tile_thumbnail',
    action_specifier: disableTileLink ? 'static' : 'link',
    conditional_properties: ['collection', 'entity'],
    collection_id: collectionId,
    collection_source: 'heimdall',
    collection_item_index: index,
    collection_index: collectionIndex,
    entity_id: entityId,
    entity_action_id: entityId,
    entity_type: type,
    entity_action_type: 'browse',
  });

  const StatusBadgeElement = () => {
    const { statusBadge } = props;
    if (!statusBadge) return null;

    const { text } = statusBadge;
    const className = generateBadgeClassNames([
      'status',
      ...(statusBadge?.classNames || []),
    ]);

    return (
      <Badge size="small" variant="badge2" className={className}>
        {text}
      </Badge>
    );
  };

  const TimeBadgeElement = () => {
    const { timeBadge } = props;
    if (!timeBadge) return null;

    const { text } = timeBadge;
    const className = generateBadgeClassNames([
      'time',
      ...(timeBadge?.classNames || []),
    ]);

    return (
      <Badge size="small" variant="badge1" className={className}>
        {text}
      </Badge>
    );
  };

  const UpcomingBadgeElement = () => {
    if (!isUpcoming) return null;
    return (
      <Badge
        className="Tile__upcoming"
        size="small"
        variant="badge1"
        startIcon={<ClockIconSVG />}
      >
        Upcoming
      </Badge>
    );
  };

  const thumbnailContent = (
    <div>
      <div className="Tile__spacer" />
      {thumbnail ? (
        <img
          className="Tile__image lazyload"
          data-src={createImageUrl(thumbnail, isRetina)}
          role="presentation"
          itemProp="image"
        />
      ) : (
        <div className="Tile__placeholder-image" />
      )}
      {thumbnail ? (
        <div className="Tile__scrim Tile__scrim--thumbnail" />
      ) : (
        <div className="Tile__scrim">
          <div className="Tile__scrim-text">
            <TruncatedText variant="title18">{title}</TruncatedText>
          </div>
        </div>
      )}
      <div className="Tile__rollover">
        {thumbnailHover ? (
          <img
            className="Tile__rollover-image"
            src={createImageUrl(thumbnailHover, isRetina)}
            role="presentation"
          />
        ) : null}
        <div className="Tile__controls">
          {!hideControlIcon && (
            <span
              className={classnames('Tile__icon', {
                'Tile__icon--retina': isRetina,
              })}
            >
              <DetailButtonSVG />
            </span>
          )}
        </div>
      </div>
      <StatusBadgeElement />
      <TimeBadgeElement />
      <UpcomingBadgeElement />
      {brandWatermark ? (
        <img
          className="Tile__brand"
          src={createBrandUrl(brandWatermark)}
          role="presentation"
        />
      ) : null}
    </div>
  );

  if (enableTileLink) {
    return (
      <Thumbnail
        className="Tile__thumbnail Tile__thumbnail--with-hover"
        href={assetUrl}
        onClick={() => handleTileOnClick(false, title, onClick)}
        onKeyDown={enterKeyHandler(onClick)}
        onMouseDown={preventFocus}
        videoData={videoData}
        role="button"
        aria-label={`Play ${title}`}
        tabIndex="0"
      >
        {thumbnailContent}
      </Thumbnail>
    );
  }

  // when enableSignupModal is not set on Hookup for non editorial collection's,
  // it would get defaulted to null which could lead to the modal not showing up
  let shouldEnableSignupModal = enableSignupModal;
  if (shouldEnableSignupModal === null) {
    shouldEnableSignupModal = true;
  }
  const shouldDisableSignupModal = !shouldEnableSignupModal;

  if (shouldDisableSignupModal) {
    return (
      <Thumbnail
        className="Tile__thumbnail Tile__thumbnail--no-hover"
        role="button"
      >
        {thumbnailContent}
      </Thumbnail>
    );
  }

  return (
    <Thumbnail
      className="Tile__thumbnail Tile__thumbnail--with-hover"
      onClick={(event) =>
        handleTileOnClick(false, title, null, showSignupModal, event)
      }
      onKeyDown={enterKeyHandler(() => showSignupModal && showSignupModal())}
      onMouseDown={preventFocus}
      role="button"
      aria-label={`Play ${title}`}
      tabIndex="0"
      href={watchUrl}
    >
      {thumbnailContent}
    </Thumbnail>
  );
};

const badgeShape = PropTypes.shape({
  classNames: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
});

TileThumbnail.defaultProps = {
  hideControlIcon: false,
  isRetina: false,
  disableTileLink: false,
  enableSignupModal: true,
};

TileThumbnail.propTypes = {
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  thumbnailHover: PropTypes.string,
  brandWatermark: PropTypes.string,
  timeBadge: badgeShape,
  statusBadge: badgeShape,
  availability: PropTypes.shape({
    isLive: PropTypes.boolean,
    progress: PropTypes.number,
  }),
  assetUrl: PropTypes.string,
  hideControlIcon: PropTypes.bool, // hides the play and arrow icon (used on details page)
  disableTileLink: PropTypes.bool, // previously isReadOnly. Used to disable toggle state
  onClick: PropTypes.func,
  isRetina: PropTypes.bool,
  videoData: PropTypes.shape({}),
  showSignupModal: PropTypes.func,
  collectionId: PropTypes.string,
  collectionIndex: PropTypes.number,
  index: PropTypes.number,
  entityId: PropTypes.string,
  type: PropTypes.string,
  enableSignupModal: PropTypes.bool,
  isUpcoming: PropTypes.bool,
};

export default TileThumbnail;
