import classNames from 'classnames';
import React from 'react';

import { ExpMastheadDualCTAModelSchema } from '../model/schema';

import ImageBackground from './ImageBackground';
import VideoBackground from './VideoBackground';

import { goToUrl } from '!app/lib/urlUtils';
import { fireUserInteraction } from '!app/metrics';
import { WithEvents } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';
import '../stylesheet/ExpMasthead.scss';

export const MastheadDualCTA = (props) => {
  const {
    model: {
      backgroundImage,
      backgroundVideos,
      eyebrow,
      headline,
      subheadline,
      primaryCopy,
      secondaryCopy,
      leftCta,
      rightCta,
      legal,
      style,
      leftAlign,
    },
  } = props;

  return (
    <div
      id="masthead"
      className={classNames('ExpMasthead', 'cu-masthead', style)}
      role="region"
      aria-labelledby="regionMasthead"
      data-automationid="masthead"
    >
      <ImageBackground
        image={backgroundImage}
        identifier={0}
        mobileFirst={false}
      />
      <VideoBackground videos={backgroundVideos} />
      <div
        className={classNames('ExpMasthead__container', {
          'left-align': leftAlign,
        })}
      >
        {eyebrow && (
          <WithEvents.div
            className="ExpMasthead__preheadline"
            dangerouslySetInnerHTML={{ __html: eyebrow }}
          />
        )}
        {headline && (
          <WithEvents.h1
            className="ExpMasthead__headline"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        )}
        {subheadline && (
          <WithEvents.div
            className="ExpMasthead__subheadline"
            dangerouslySetInnerHTML={{ __html: subheadline }}
          />
        )}
        {primaryCopy && (
          <WithEvents.div
            className="ExpMasthead__primary-message"
            dangerouslySetInnerHTML={{ __html: primaryCopy }}
          />
        )}
        {secondaryCopy && (
          <WithEvents.div
            className="ExpMasthead__secondary-message"
            dangerouslySetInnerHTML={{ __html: secondaryCopy }}
          />
        )}
        <div className="ExpMasthead__cta-container">
          {[leftCta, rightCta].map((cta) => (
            <React.Fragment key={cta?.ctaCopy}>
              {cta?.eyebrow && (
                <WithEvents.div
                  className="ExpMasthead__preheadline"
                  dangerouslySetInnerHTML={{ __html: cta?.eyebrow }}
                  style={{ gridRow: 1 }}
                />
              )}
              {cta?.headline && (
                <WithEvents.h1
                  className="ExpMasthead__headline"
                  id="regionMasthead"
                  dataAutomationId="masthead_headline"
                  dangerouslySetInnerHTML={{ __html: cta?.headline }}
                  style={{ gridRow: 2 }}
                />
              )}
              {cta?.subheadline && (
                <WithEvents.div
                  className="ExpMasthead__subheadline"
                  dataAutomationId="masthead_subheadline"
                  dangerouslySetInnerHTML={{ __html: cta?.subheadline }}
                  style={{ gridRow: 3 }}
                />
              )}
              {cta?.ctaCopy &&
                cta?.ctaHref &&
                cta?.elementSpecifier &&
                cta?.targetDisplayName && (
                  <div className="ExpMasthead__input" style={{ gridRow: 4 }}>
                    <CTAButton
                      useStyle="white"
                      className="ExpMasthead__input-cta"
                      color="#1ce783"
                      size="medium"
                      onClick={() => {
                        fireUserInteraction(
                          cta?.elementSpecifier,
                          'driver_click',
                          'click',
                          true,
                          cta?.targetDisplayName
                        );
                        goToUrl(cta?.ctaHref);
                      }}
                    >
                      {cta?.ctaCopy}
                    </CTAButton>
                  </div>
                )}
            </React.Fragment>
          ))}
        </div>
        <WithEvents.div
          className="ExpMasthead__legal section-disclaimer"
          dataAutomationId="masthead_legal"
          dangerouslySetInnerHTML={{ __html: legal }}
        />
      </div>
    </div>
  );
};

MastheadDualCTA.propTypes = {
  model: ExpMastheadDualCTAModelSchema,
};
