import React from 'react';

import useSeasonCollection from '../useSeasonCollection';

import { EpisodeTile } from '!app/components/Tile';

const SeasonCollection = (props) => {
  const {
    seasonNum,
    seriesId,
    disableTileLink,
    collectionIndex,
    partOfSeries,
  } = props;
  const { data, isFetching } = useSeasonCollection(props);
  const { items } = data ?? {};

  return (
    !isFetching && (
      <div className="EpisodeCollection__items">
        {items.map((item, index) => (
          <div className="EpisodeCollection__item" key={item.id}>
            <EpisodeTile
              asset={item}
              index={index}
              hideControlIcon
              disableTileLink={disableTileLink}
              collectionIndex={collectionIndex}
              collectionId={seriesId}
              partOfSeries={partOfSeries || null}
              seasonNumber={seasonNum}
            />
          </div>
        ))}
      </div>
    )
  );
};

export default SeasonCollection;
