import Text from '@hulu/web-ui/Text';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

import CloseButtonSVG from '../assets/CloseButtonSVG';

import { preventFocus } from '!app/lib/accessibilityUtils';
import { WithEvents } from '!app/metrics/hoc';
import '../stylesheet/ModalStyle.scss';

const Modalv2 = ({
  children,
  body,
  title,
  className,
  close,
  arePostersAutomated,
}) => {
  const modalClose = useRef(null);

  useEffect(() => {
    modalClose.current.focus();
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        close();
      }
    };
    document.body.classList.add('modal-open');
    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.body.classList.remove('modal-open');
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, []);

  const bodyStyle = classnames('modal--body', {
    'modal--body-with-title': title,
    'modal--body--with-automated-collection-image': arePostersAutomated,
  });
  const modalStyle = classnames(className, 'modal-dialog modal', {
    'modal-dialog--with-automated-collection-image': arePostersAutomated,
  });

  return (
    <>
      <div
        className="modal-wrapper modal-show cu-modal"
        role="dialog"
        aria-label="Modal has opened"
      >
        <div className="modal-backdrop" onClick={close} />
        <div className={modalStyle}>
          <div className="modal_title--header">
            {title ? (
              <Text
                as="div"
                variant="body14"
                className="modal_title--header__text"
              >
                {title}
              </Text>
            ) : null}
            <button
              className="modal--close"
              onClick={close}
              onMouseDown={preventFocus}
              aria-label="Close modal"
              ref={modalClose}
            >
              <CloseButtonSVG />
            </button>
          </div>
          {body ? (
            <Text as="div" variant="body16">
              <WithEvents.div
                className={bodyStyle}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </Text>
          ) : (
            <Text as="div" variant="body16" className={bodyStyle}>
              {children}
            </Text>
          )}
          <div className="modal--footer" />
        </div>
      </div>
    </>
  );
};

export default Modalv2;
