import PropTypes from 'prop-types';
import React from 'react';

import CollectionTabs from './view/CollectionTabs';
import CollectionTabsWithHeroSlider from './view/CollectionTabsWithHeroSlider';

const view = (props) => {
  const {
    model: { includeHeroSlider },
  } = props;
  if (includeHeroSlider) {
    return <CollectionTabsWithHeroSlider {...props} />;
  }
  return <CollectionTabs {...props} />;
};

view.propTypes = {
  model: PropTypes.shape({ includeHeroSlider: PropTypes.boolean }),
};

export { view };
