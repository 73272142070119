import PropTypes from 'prop-types';

export const FAQModelSchema = PropTypes.shape({
  question: PropTypes.string,
  answer: PropTypes.string,
  index: PropTypes.number,
});

export const FAQsModelSchema = PropTypes.shape({
  headline: PropTypes.string,
  description: PropTypes.string,
  faqs: PropTypes.arrayOf(FAQModelSchema),
});
