// eslint-disable-next-line
import {
  HuluLogin,
  LoginModal as SharedLoginModal,
} from '@hulu/login-modal';
import { connect } from 'react-redux';

import LoginModal from './view/LoginModal';

// TODO: Legacy login remove #HUWEB-30424
const {
  stateKey: HULU_LOGIN,
  actionHandlers: huluLoginActionHandlers,
} = HuluLogin;
const {
  stateKey: LOGIN_MODAL,
  actionHandlers: loginModalActionHandlers,
  actions: { closeLogin },
} = SharedLoginModal;

const reducerMap = {
  [HULU_LOGIN]: huluLoginActionHandlers,
  [LOGIN_MODAL]: loginModalActionHandlers,
};

const view = connect(
  (state) => {
    const shown =
      state?.modal?.['login-modal'] &&
      Boolean(state?.modal?.['login-modal'].shown);
    return { shown };
  },
  (dispatch) => ({
    closeLoginModal: () => dispatch(closeLogin()),
  })
)(LoginModal);

export { view, reducerMap };
