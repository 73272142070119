import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './DetailEntityMetadata.scss';
import { AvFeatures, Credits, EntityHub, Genres } from './index';

import { SEPARATOR } from '!app/lib/constants';

export const METADATA_FONT_SIZES = { xs: 'body12', lg: 'body16' };

const DetailEntityMetadata = (props) => {
  const {
    avFeatures,
    credits,
    className,
    genres,
    rating,
    tags,
    entityType,
    showAvInline,
    truncateCredits,
  } = props;

  return (
    <div className={classNames('DetailEntityMetadata', className)}>
      <Credits credits={credits} shouldTruncate={truncateCredits} />
      <div
        className={classNames('DetailEntityMetadata__tag-wrapper', {
          'DetailEntityMetadata__has-inline-av-features': showAvInline,
        })}
      >
        <div className="DetailEntityMetadata__tag-group">
          {rating && (
            <Text
              breakpoints={METADATA_FONT_SIZES}
              className="DetailEntityMetadata__rating"
            >
              {rating}
            </Text>
          )}
          <Genres genres={genres} />
          <EntityHub entityType={entityType} tags={tags} />
          {tags && (
            <Text
              breakpoints={METADATA_FONT_SIZES}
              className="DetailEntityMetadata__tags"
            >
              {tags.join(SEPARATOR)}
            </Text>
          )}
        </div>
        <AvFeatures items={avFeatures?.items} isInline={showAvInline} />
      </div>
    </div>
  );
};

DetailEntityMetadata.propTypes = {
  credits: PropTypes.shape({}),
  avFeatures: PropTypes.shape({}),
  genres: PropTypes.shape({}),
  rating: PropTypes.shape({}),
  tags: PropTypes.shape({}),
  entityType: PropTypes.string,
};

export default DetailEntityMetadata;
