import { shape, func, number, array, string } from 'prop-types';

import { RibbonModelSchema } from '!app/components/Ribbon/model/schema';
import { DetailEntityStoreSchema } from '!app/share/schema';

const SingleImagePropType = shape({
  path: string,
  accent: shape({
    hue: number,
  }),
});

const AvFeaturesPropTypes = shape({
  items: [string],
  truncatedItems: [string],
});

const CreditsPropTypes = shape({
  items: [string],
  truncatedItems: [string],
  prefix: string,
  seoSchemaProp: string,
});

export const DetailEntitySchema = {
  avFeatures: [AvFeaturesPropTypes],
  brandArtwork: SingleImagePropType,
  description: string,
  credits: [CreditsPropTypes],
  entityId: string,
  entityType: string,
  headline: array,
  play: func,
  tags: array,
  title: string.isRequired,
  titleArtwork: SingleImagePropType,
  updateDetailEntity: func,
  verticalTileArtwork: SingleImagePropType,
  ...DetailEntityStoreSchema,
};

export const MastheadModelSchema = shape({
  ...DetailEntitySchema,
  backgroundArtwork: SingleImagePropType,
  brandArtwork: SingleImagePropType,
  ribbon: RibbonModelSchema,
});
