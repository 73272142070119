import PropTypes from 'prop-types';

import { RibbonModelSchema } from '!app/components/Ribbon/model/schema';
import { StatefulPropType } from '!app/share/schema';

const MastheadVideo = PropTypes.shape({
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const SingleImagePropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
});

export const MastheadModelSchema = PropTypes.shape({
  anchorHash: PropTypes.string,
  style: PropTypes.string,
  ctaFormat: PropTypes.string,
  ctaButtonStyle: PropTypes.string,
  anchorCopy: PropTypes.string,
  anchorButton: PropTypes.boolean,
  backgroundImage: PropTypes.shape({
    desktop: SingleImagePropType,
    mobile: SingleImagePropType,
  }),
  backgroundVideos: PropTypes.arrayOf(MastheadVideo),
  isFullscreen: PropTypes.bool,
  preheadlineStates: StatefulPropType,
  headlineStates: StatefulPropType,
  subheadlineStates: StatefulPropType,
  supportingCopyStates: StatefulPropType,
  secondaryCopyStates: StatefulPropType,
  legalStates: StatefulPropType,
  mobileFirst: PropTypes.bool,
  programType: PropTypes.string,
  programPartner: PropTypes.string,
  programSubpartner: PropTypes.string,
  requireDeviceCode: PropTypes.bool,
  enableBrandBorder: PropTypes.bool,
  ctaDownloadAppText: PropTypes.string,
  ribbon: RibbonModelSchema,
  ctaSecondary: PropTypes.shape({}),
  cartAbandonmentCopy: PropTypes.shape({}),
  ctaElementSpecifierOverride: PropTypes.string,
});
