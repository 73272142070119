import Text from '@hulu/web-ui/Text';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CloseButtonSVG from '../assets/CloseButtonSVG';
import { ModalModelSchema } from '../model/schema';

import { preventFocus } from '!app/lib/accessibilityUtils';
import { REGISTER_MODALS_EVENT } from '!app/lib/constants';
import { fireUserInteraction, fireUtagLink } from '!app/metrics/fireEvent';
import { WithEvents } from '!app/metrics/hoc';
import '../stylesheet/ModalStyle.scss';

const ModalCloseBtn = (props) => (
  <button className="modal--close" data-automationid="modal_close" {...props}>
    <CloseButtonSVG />
  </button>
);

// Provides a way for other components to more reliably toggle the modal by adding a visually hidden
// button to the modal that invokes the modal toggle action directly
const ModalToggleBtn = (props) => (
  <button
    className="modal--toggle visually-hidden"
    data-automationid="modal_toggle"
    {...props}
  />
);

const fireToggleEvents = (isOpen, title) => {
  const eventName = isOpen ? 'modal_open' : 'modal_close';
  const modalUtagProperties = {
    event_name: eventName,
    modal_name: title,
  };
  fireUtagLink(modalUtagProperties);
  fireUserInteraction(eventName, 'click', 'click');
};

class Modal extends Component {
  constructor(props) {
    super(props);

    this.modal = null;
    this.focusableEls = [];

    this.toggleModal = this.toggleModal.bind(this);
    this.registerModals = this.registerModals.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setModalRef = this.setModalRef.bind(this);
    this.setFocusableEls = this.setFocusableEls.bind(this);
    this.state = {
      atTop: true,
      closing: false,
      isOpen: false,
      returnFocus: '',
    };
  }

  /**
   * Add keydown escape to close modal
   */
  UNSAFE_componentWillMount() {
    if (global.window) {
      global.window.addEventListener('keydown', this.handleKeyDown, true);
    }
  }

  setModalRef(element) {
    this.modal = element;
  }

  handleKeyDown(e) {
    const { shown } = this.props;
    const TAB_CODE = 9;
    const ESCAPE_CODE = 27;
    if (typeof e === 'undefined' || !e.keyCode || !shown) {
      return;
    }
    switch (e.keyCode) {
      // Tab
      case TAB_CODE:
        if (this.focusableEls.length === 1) {
          this.focusableEls[0].focus();
          e.preventDefault();
          break;
        }
        if (e.shiftKey) {
          if (document.activeElement === this.focusableEls[0]) {
            e.preventDefault();
            this.focusableEls[this.focusableEls.length - 1].focus();
          }
        } else if (
          document.activeElement ===
          this.focusableEls[this.focusableEls.length - 1]
        ) {
          e.preventDefault();
          this.focusableEls[0].focus();
        }
        break;
      // Escape
      case ESCAPE_CODE:
        this.closeModal(e);
        break;
      default:
        break;
    }
  }

  closeModal(e) {
    const { returnFocus } = this.state;
    const { onModalClosed } = this.props;
    this.setState({ closing: true });
    if (returnFocus) {
      returnFocus.focus();
    }

    this.setState({ closing: false });
    this.toggleModal();

    if (onModalClosed) {
      onModalClosed();
    }

    // If there is a video element in the modal, force it to stop playing when
    // the modal is closed.
    const video = this.modal && this.modal.querySelector('video');
    if (video) video.pause();

    e.stopPropagation();
  }

  toggleModal(e) {
    const {
      toggleModal,
      model: { id },
    } = this.props;
    const { isOpen } = this.state;
    toggleModal(id);
    this.setState({
      isOpen: !isOpen,
      returnFocus: e && e.target ? e.target : '',
    });
    // Set the focus to the first element.
    if (this.focusableEls.length >= 1) {
      this.focusableEls[0].focus();
    }

    if (this.modal) {
      this.modal.scrollTop = 0;
    }
  }

  registerModals() {
    // eslint-disable-next-line
    const doc = document || window.document || win.document;

    if (doc) {
      const {
        model: { id },
      } = this.props;
      const array = doc.querySelectorAll(
        `a[data-target="#${id}"], button[data-target="#${id}"]`
      );
      array.forEach((dom) => {
        if (dom.getAttribute('data-listener') !== 'true') {
          // eslint-disable-next-line
          dom.addEventListener('click', this.toggleModal);
          dom.setAttribute('data-listener', 'true');
        }
      });
    }
  }

  setFocusableEls() {
    if (this.modal) {
      this.focusableEls = Array.from(
        this.modal.querySelectorAll(
          'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
        )
      );
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { shown, onModalOpen, title } = this.props;
    if (shown !== next.shown) {
      if (next.shown) {
        if (onModalOpen) {
          onModalOpen();
        }
        document.body.classList.add('modal-open');

        fireToggleEvents(true, title);
        // Continue playing of video on reopen
        const video = this.modal && this.modal.querySelector('video');
        if (video) video.play();
      } else {
        fireToggleEvents(false, title);
        document.body.classList.remove('modal-open');
      }
    }
  }

  componentDidMount() {
    this.setFocusableEls();

    // eslint-disable-next-line
    const doc = document || window.document || win.document;
    if (doc) {
      const {
        model: { id },
        deepLinkAnchor,
      } = this.props;
      const deepLink = deepLinkAnchor ? `#${deepLinkAnchor}` : `#${id}`;

      doc.addEventListener(REGISTER_MODALS_EVENT, () => {
        this.registerModals();
      });

      this.registerModals();

      if (window.location.hash === deepLink) {
        this.toggleModal();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { shown } = this.props;
    this.setFocusableEls();
    // if modal open provide focus for scrolling
    if (prevProps.shown !== shown && shown) {
      this.modal.focus();
    }
  }

  componentWillUnmount() {
    if (global.window) {
      global.window.removeEventListener('keydown', this.handleKeyDown, true);
    }
  }

  render() {
    const {
      shown,
      className,
      model: { id, body, modalTitle },
      children,
    } = this.props;
    const { closing } = this.state;

    const wrapperStyle = classnames('modal-wrapper cu-modal', {
      'modal-show': shown,
      'modal-hide': !shown,
      'modal-closing': closing,
    });

    const modalStyle = classnames(className, 'modal-dialog modal', {
      in: shown,
    });

    return (
      <>
        <div
          className={wrapperStyle}
          id={id}
          ref={this.setModalRef}
          role="dialog"
          tabIndex="-1"
          aria-label="Modal has opened"
          aria-hidden={!shown}
        >
          <div
            className={`modal-backdrop ${shown ? 'in' : ''}`}
            onClick={this.closeModal}
          />
          <div className={modalStyle} ref={(el) => (this.modalDialog = el)}>
            <div className="modal--header">
              <ModalCloseBtn
                onClick={this.closeModal}
                onMouseDown={preventFocus}
                aria-label="Close modal"
              />
              <ModalToggleBtn
                onClick={this.toggleModal}
                onMouseDown={preventFocus}
                aria-label="Toggle modal"
                tabIndex="-1"
              />
            </div>
            {modalTitle && (
              <Text as="h2" variant="title24" className="modal-dialog__title">
                {modalTitle}
              </Text>
            )}
            {body ? (
              <WithEvents.div
                className="modal--body"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            ) : (
              <div className="modal--body">{children}</div>
            )}
            <div className="modal--footer" />
          </div>
        </div>
      </>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  model: ModalModelSchema.isRequired,
  shown: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onModalClosed: PropTypes.func,
  onModalOpen: PropTypes.func,
  deepLinkAnchor: PropTypes.string,
};

export default Modal;
