import PropTypes from 'prop-types';

export const LogoIconModelSchema = PropTypes.shape({
  icon: PropTypes.string.isRequired,
  name: PropTypes.string,
});

export const LogoWallModelSchema = PropTypes.shape({
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  zipCodeModalText: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(LogoIconModelSchema).isRequired,
});
