import NetworksSearchModal from './view/NetworksSearchModal';

import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';

const view = NetworksSearchModal;

const reducerMap = {
  [MODAL]: modalActionHandler,
};

export { view, reducerMap };
