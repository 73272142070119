import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React from 'react';

import '../stylesheet/HubNav.scss';

const HubNav = (props) => {
  const {
    model: { title },
  } = props;

  return (
    <div className="hub-nav cu-hubspan">
      <Text as="h1" variant="title48" className="hub-nav__title">
        {title}
      </Text>
    </div>
  );
};

HubNav.propTypes = {
  model: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default HubNav;
