import React from 'react';

import AdaptiveCollectionModelSchema from '../model/schema';

import SimpleCollectionShared from '!app/components/Collections/SimpleCollection/views/SimpleCollectionShared';
import { view as PortraitSlider } from '!app/components/Slider/PortraitSlider';

import '../stylesheets/AdaptiveCollection.scss';

const AdaptiveCollection = (props) => {
  const {
    model: { collection, title, description },
  } = props;

  if (!collection.items.length) return null;

  const slider = <PortraitSlider collection={collection} horizontal />;

  return (
    <div className="AdaptiveCollection cu-adaptive-collection">
      <SimpleCollectionShared
        name={title}
        description={description}
        slider={slider}
      />
    </div>
  );
};

AdaptiveCollection.propTypes = {
  model: AdaptiveCollectionModelSchema,
};

export default AdaptiveCollection;
