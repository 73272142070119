import PropTypes from 'prop-types';
import React from 'react';

import { getDisplayName } from '!app/lib/hoc';
import { trackEvent, getMetricsDeviceType } from '!app/metrics';

/**
 * A Higher-Order-Component (HOC) that wraps any targed component and returns a new Component that
 * will also fire off `user_interaction` events to the metrics platform.
 *
 * @param {React.Component} Component The component that we're going to wrap for sending user_interactions.
 * @param {string} element The element target name to send to a component.
 * @param {string} action The action name on the element to send to a component.
 * @param {string} interactionType The type of action. Accepts 'click', 'pinch', 'swipe', 'tap,' and 'hover'. Will default to 'click'.
 * @returns {React.Component}
 */
export default function withTileInteraction(Component, metricsProps) {
  const {
    action_specifier,
    element_specifier,
    interaction_type,
    collection_id,
    collection_item_index,
    collection_index,
    entity_id,
    entity_action_id,
    conditional_properties,
    entity_type,
    entity_action_type,
  } = metricsProps;

  const render = (props) => {
    // TODO: accept different functions for different interactions or spin off multiple HOCs for each event type.
    const deviceType = getMetricsDeviceType();

    const onClick = (...args) => {
      trackEvent('user_interaction', {
        hit_version: '2.4.0',
        interaction_type: interaction_type || 'click',
        element_specifier,
        action_specifier: `${action_specifier}:${deviceType}`,
        conditional_properties,
        collection_id,
        collection_source: 'heimdall',
        collection_item_index,
        collection_index,
        entity_id,
        entity_action_id,
        entity_type,
        entity_action_type,
      });

      // TODO (drew.hays November 15, 2017): trackEvent returns a promise,
      // so should we only call onClick after that? (Otherwise, if we can't
      // use navigator.sendBeacon, we may fail to send the event, because
      // the Document cancels the XHR before a page change.)
      //
      // Currently, if we only call `onClick` after the promise returns, unit
      // tests will fail because the test ends before the promise can be
      // resolved.  (Probably need to do some magic async stuff in jest.)
      if (props.onClick) {
        props.onClick.call(null, ...args);
      }
    };

    const newProps = { ...props, onClick };
    return <Component {...newProps} />;
  };

  render.displayName = getDisplayName('withTileInteraction', Component);
  render.propTypes = {
    onClick: PropTypes.func,
  };

  return render;
}
