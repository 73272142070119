import NetworksSearchModalWithGeo from './view/NetworksSearchModalWithGeo';

import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';

const view = NetworksSearchModalWithGeo;

const reducerMap = {
  [MODAL]: modalActionHandler,
};

export { view, reducerMap };
