import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { shape, number } from 'prop-types';
import React from 'react';

import '../stylesheet/TabButtons.scss';

const TabButtons = (props) => {
  const {
    items,
    tabArray,
    tabOffset,
    activeBarStyles,
    activeTab,
    onClick,
  } = props;

  return (
    <div
      className="TabButtons"
      style={{
        transform: `translateX(calc(-${tabOffset}px))`,
      }}
    >
      {items.map((item, index) => {
        const { eyebrow } = item;
        const idName = `tab${index}`;
        const isActive = activeTab === index;
        return (
          <button
            aria-label={`${eyebrow} tab`}
            key={idName}
            id={idName}
            ref={tabArray[index]}
            className={classNames('TabButtons__tab', {
              '--active': isActive,
            })}
            onClick={() => onClick(index)}
          >
            <Text variant="navigation12" style={{ fontWeight: 'bold' }}>
              {eyebrow}
            </Text>
            {index === 0 && (
              <div className="TabButtons__activeBar" style={activeBarStyles} />
            )}
          </button>
        );
      })}
    </div>
  );
};

TabButtons.propTypes = {
  items: shape({}),
  tabArray: shape({}),
  tabOffset: number,
  activeBarStyles: shape({}),
  activeTab: number,
  onClick: shape({}),
};

export default TabButtons;
