import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import '../stylesheet/ToasterStyle.scss';

const Toaster = ({ className, active, mobileOnly, children }) => {
  const style = classNames('toaster', className, {
    'toaster--active': active,
    'toaster--mobile-only': mobileOnly,
  });
  return <div className={style}>{children}</div>;
};

Toaster.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  children: PropTypes.element,
};

export default Toaster;
