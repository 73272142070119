export const CollectionsModalEntityFieldsFragment = `fragment collectionsModalEntityFields on Entity {
  genres
  href
  name
  premiereDate
  rating
  type
  artwork {
    horizontalProgramTile {
      path
    }
    watermark {
      path
    }
  }
}`;

export const DetailEntityFieldsFragment = `fragment detailEntityFields on DetailEntity {
  type
  name
  availableSeasons
  episodeCount
  network
  accentHue
  isOriginalContent
  premiereDate
  requirePremium
}`;

export const EntityFieldsFragment = `fragment entityFields on Entity {
  id
  href
  eabId
  type
  name
  description
  rating
  genres
  premiereDate
  season
  number
  duration
  seriesName
  artwork {
    horizontalHero {
      path
      hue
    }
    horizontalProgramTile {
      path
      hue
    }
    verticalHero {
      path
      hue
    }
    networkTile {
      path
    }
    watermark {
      path
    }
    titleArtwork {
      path
    }
  }
}`;
