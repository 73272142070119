import PropTypes from 'prop-types';
import React from 'react';

import { WithEvents } from '../../../metrics/hoc';

import '../stylesheet/StaticHtml.scss';

const StaticHtml = (props) => {
  const {
    model: { html_code },
  } = props;

  return (
    <WithEvents.div
      className="html-module"
      dangerouslySetInnerHTML={{ __html: html_code }}
    />
  );
};

StaticHtml.propTypes = {
  model: PropTypes.shape({
    html_code: PropTypes.string,
  }),
};

export default StaticHtml;
