import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { view as Dropdown } from '../../../Dropdown';
import EpisodeCollectionSchema from '../model/schema';
import SeasonCollection from '../SeasonCollection/views/SeasonCollection';

import '../stylesheets/EpisodeCollection.scss';

const checkSeasonAvailability = (seasonIndex, latestSeason) => {
  const {
    hasUpcomingSeason,
    hasContentAvailable,
    hasLastEpPremiered,
    season,
  } = latestSeason;
  const isLatestSeason = seasonIndex === Number(season);
  const isSeasonUpcoming =
    hasUpcomingSeason && isLatestSeason && !hasLastEpPremiered;
  const isSeasonNew = !hasLastEpPremiered && isLatestSeason;
  const upcomingUnavailable = isSeasonUpcoming && !hasContentAvailable;
  return {
    isSeasonUpcoming,
    isSeasonNew,
    upcomingUnavailable,
  };
};

const getSeasonAppendCopy = (isSeasonNew, isSeasonUpcoming, seasonName) => {
  if (isSeasonUpcoming) {
    return `${seasonName} (Upcoming)`;
  }
  if (isSeasonNew) {
    return `${seasonName} (New)`;
  }
  return seasonName;
};

const EpisodeCollection = (props) => {
  const {
    latestSeason,
    model: {
      collection: { enabledTileLinks, seasons },
      upsell,
    },
    collectionIndex,
    query,
  } = props;

  const seasonParam = query?.season;
  const seasonNumbers = seasons.map((s) => s.number);

  const [currentIndex, updateActiveIndex] = useState(seasonNumbers[0]);

  if (seasonNumbers.includes(seasonParam)) {
    updateActiveIndex(seasonParam);
  }

  const seasonItems = seasons.map((season) => {
    const seasonIndex = season.number;
    const seasonName = season.name;
    const {
      isSeasonNew,
      upcomingUnavailable,
      isSeasonUpcoming,
    } = checkSeasonAvailability(seasonIndex, latestSeason);
    const seasonCopy = getSeasonAppendCopy(
      isSeasonNew,
      isSeasonUpcoming,
      seasonName
    );

    return {
      number: seasonIndex,
      name: upcomingUnavailable ? `${season.name} (Upcoming)` : seasonCopy,
    };
  });

  return (
    <div className="EpisodeCollection">
      <div className="EpisodeCollection__seasons">
        <Dropdown
          items={seasonItems}
          label={seasonItems[0].name}
          onSelect={updateActiveIndex}
          activeIndex={currentIndex}
        />
      </div>
      <SeasonCollection
        seriesId={query?.id}
        disableTileLink={!enabledTileLinks}
        seasonNum={currentIndex}
        collectionIndex={collectionIndex}
        partOfSeries={upsell?.name}
      />
    </div>
  );
};

EpisodeCollection.propTypes = {
  model: EpisodeCollectionSchema,
  collectionIndex: PropTypes.number,
  latestSeason: PropTypes.shape({
    season: PropTypes.string,
    previousSeason: PropTypes.string,
    hasUpcomingSeason: PropTypes.boolean,
    hasLastEpPremiered: PropTypes.boolean,
    firstEpPremiereDate: PropTypes.string,
    lastEpPremiereDate: PropTypes.string,
    hasContentAvailable: PropTypes.boolean,
    seasonPremiereDates: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default EpisodeCollection;
