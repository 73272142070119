import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';

import '../stylesheet/ExpPlanComparisonHeaderV2.scss';

const ExpPlanComparisonHeader = (props) => {
  const { headline, description } = props;

  return (
    <div className="header">
      <Text
        as="h2"
        breakpoints={{ xs: 'title32', md: 'title48' }}
        className="header__headline"
      >
        {headline}
      </Text>
      <div
        className="header__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

ExpPlanComparisonHeader.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
};
ExpPlanComparisonHeader.defaultProps = {
  headline: '',
  description: '',
};

export default ExpPlanComparisonHeader;
