import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React from 'react';

const LINE_HEIGHT_EM = 1.18;
const TruncatedText = ({
  children,
  className,
  lineHeightEm = LINE_HEIGHT_EM,
  maxLines = 2,
  dataAutomationId,
  ...props
}) => {
  const multiLineStyles = {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: `${maxLines}`,
    display: '-webkit-box',
    overflow: 'hidden',
    lineHeight: `${lineHeightEm}em`, // Other browsers fallback to just truncate the text
    maxHeight: `${maxLines * lineHeightEm}em`,
    whiteSpace: 'normal',
  };

  return (
    <Text
      {...props}
      className={className}
      style={multiLineStyles}
      data-automationid={dataAutomationId}
    >
      {children}
    </Text>
  );
};

TruncatedText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  lineHeightEm: PropTypes.number,
  maxLines: PropTypes.number,
};

export default TruncatedText;
