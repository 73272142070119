import PropTypes from 'prop-types';
import React from 'react';

import { fireUtagLink } from '..';

import { getDisplayName } from '!app/lib/hoc';

/**
 * An HOC that wraps a component and returns a new Component that will fire a Tealium (utag) link event.
 *
 * @param {Object} dataObject A data object containing the attributes associated with this specific tracking call.
 * @param {React.Component} Component The component to wrap.
 * @returns {React.Component}
 */
export default function withUtagLink(dataObject, Component, options) {
  const render = (props) => {
    const onClick = (...args) => {
      fireUtagLink(dataObject, options);

      if (props.onClick) {
        props.onClick.call(null, ...args);
      }
    };

    const newProps = { ...props, onClick };

    return <Component {...newProps} />;
  };

  render.displayName = getDisplayName('withUtagLink', Component);
  render.propTypes = {
    onClick: PropTypes.func,
  };

  return render;
}
