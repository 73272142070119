import React from 'react';

import { MediaContainerSchema } from '../model/schema';

import '../stylesheet/MediaContainer.scss';

/**
 * Renders a static image for now with other types of media supportable in the future.
 * Copies over the CSS from the original skittles image from /welcome.
 */
const MediaContainer = (props) => {
  const {
    model: {
      image: { desktop, tablet, mobile },
    },
  } = props;

  return (
    <div className="media-container cu-media-container">
      <div
        className="media-container__wrapper"
        role="img"
        aria-label={desktop.alt}
      >
        <picture>
          <source media="(min-width: 769px)" data-srcset={desktop.url} />
          <source media="(min-width: 376px)" data-srcset={tablet.url} />
          <source media="(min-width: 0px)" data-srcset={mobile.url} />
          <img
            className="resp-img lazyload"
            data-src={desktop.url}
            alt={desktop.alt}
          />
        </picture>
      </div>
    </div>
  );
};

MediaContainer.propTypes = {
  model: MediaContainerSchema,
};

export default MediaContainer;
