import PropTypes from 'prop-types';

export const RibbonModelSchema = {
  ribbonImage: PropTypes.string,
  eyebrow: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonText: PropTypes.string,
  legalModalId: PropTypes.string,
  legalLink: PropTypes.string,
  legalText: PropTypes.string,
  mainTextDt: PropTypes.string,
  mainTextMobile: PropTypes.string,
  learnMore: PropTypes.string,
  learnText: PropTypes.string,
  enableRoundedCorners: PropTypes.bool,
};
