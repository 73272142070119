import { ExpGridRowModelSchema } from '../model/schema';

import ExpGridColumn from './ExpGridColumn';

const ExpGridRow = (props) => {
  const {
    model: { columns, verticalAlign },
  } = props;
  // TODO: Make this editor configurable
  const columnsPerRow = columns.length;
  // TODO: Allow for other flex configurations like flex direction and text align
  // TODO: Allow for custom styles
  const rowStyles = {
    'align-items': verticalAlign || 'normal',
  };

  return (
    <div className="exp-grid-row" style={rowStyles}>
      {columns?.map((column, index) => (
        <ExpGridColumn key={index} model={{ columnsPerRow, ...column }} />
      ))}
    </div>
  );
};

ExpGridRow.propTypes = {
  model: ExpGridRowModelSchema,
};

export default ExpGridRow;
