import PropTypes from 'prop-types';

export const EmailUnsubscribeSchema = PropTypes.shape({
  unsubHeadline: PropTypes.string,
  unsubDescription: PropTypes.string,
  resubHeadline: PropTypes.string,
  resubDescription: PropTypes.string,
  errorHeadline: PropTypes.string,
  errorDescription: PropTypes.string,
  resubscribeCtaText: PropTypes.string,
  backCtaText: PropTypes.string,
});
