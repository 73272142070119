import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import React from 'react';

import { RibbonModelSchema } from '../model/schema';

import { preventFocus } from '!app/lib/accessibilityUtils';
import { withUtagLink, withUserInteraction } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';
import '../stylesheet/Ribbon.scss';

const LegalLink = withUtagLink(
  {
    event_name: 'ribbon_terms',
    component_id: 'ribbon',
  },
  withUserInteraction('a', 'ribbon', 'link')
);

const MoreDetailsLink = withUtagLink(
  {
    event_name: 'ribbon_more_details',
    component_id: 'ribbon',
  },
  withUserInteraction('a', 'ribbon', 'modal')
);

const onSubmit = (link) => {
  // TODO Integrate CTA logic
  window.location.assign(link);
};

const Ribbon = (props) => {
  const {
    model: {
      ribbonImage,
      eyebrow,
      mainTextDt,
      mainTextMobile,
      learnMore,
      learnText,
      buttonLink,
      buttonStyle,
      buttonText,
      legalModalId,
      legalLink,
      legalText,
      backgroundStyle,
      enableRoundedCorners,
    },
  } = props;

  if (!ribbonImage) {
    return null;
  }

  const LinkButton = withUtagLink(
    {
      event_name: 'ribbon_link',
      component_id: 'ribbon',
    },
    withUserInteraction(
      CTAButton,
      'ribbon_link',
      'driver_click',
      'click',
      true,
      buttonText
    )
  );

  const moreDetailsHref = `#${learnMore}`;
  const componentClasses = classNames('Ribbon', {
    'Ribbon--black-transparent': backgroundStyle === 'blackTransparent',
    'Ribbon--rounded-corners': enableRoundedCorners,
  });

  return (
    <div className={componentClasses}>
      <div className="row">
        <div className="Ribbon__hide-xs col-xs-0 col-md-4 col-lg-3">
          <img
            className="Ribbon__brand-img"
            src={ribbonImage}
            role="presentation"
            data-automationid="Ribbon_brand_img"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7 Ribbon__info">
          {eyebrow && (
            <Text
              as="div"
              variant="eyebrow12"
              className="Ribbon__eyebrow"
              data-automationid="Ribbon_eyebrow"
              dangerouslySetInnerHTML={{ __html: eyebrow }}
            />
          )}
          <img
            className="Ribbon__brand-img-mobile"
            src={ribbonImage}
            role="presentation"
          />
          <div className="Ribbon__details-container">
            {mainTextDt && (
              <Text
                as="span"
                variant="body24"
                className="Ribbon--showdt"
                data-automationid="Ribbon_showdt"
                dangerouslySetInnerHTML={{ __html: mainTextDt }}
              />
            )}
            {mainTextMobile && (
              <Text
                as="span"
                variant="body14"
                className="Ribbon--showmobile"
                data-automationid="Ribbon_showmobile"
                dangerouslySetInnerHTML={{ __html: mainTextMobile }}
              />
            )}
            {learnMore && (
              <div className="Ribbon__learn">
                <MoreDetailsLink
                  href={moreDetailsHref}
                  name="view-ribbon-more-details"
                  data-toggle="modal"
                  data-target={`#${learnMore}`}
                  onMouseDown={preventFocus}
                  aria-label="Additional Details about promotion"
                  dangerouslySetInnerHTML={{ __html: learnText }}
                  data-automationid="Ribbon_learn_link"
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 Ribbon__button">
          <div className="Ribbon__button-container">
            {buttonLink && (
              <div className="Ribbon__button-item">
                <LinkButton
                  className="Ribbon__cta-button"
                  useStyle={buttonStyle}
                  href={buttonLink}
                  onMouseDown={preventFocus}
                  onClick={() => onSubmit(buttonLink)}
                  aria-label="Purchase the current promotion"
                  data-automationid="Ribbon_cta_button"
                >
                  {buttonText}
                </LinkButton>
              </div>
            )}
            {!legalModalId && legalLink && (
              <div className="Ribbon__button-terms">
                <LegalLink
                  className="Ribbon__legal_modal"
                  name="view-ribbon-terms"
                  href={legalLink}
                  dangerouslySetInnerHTML={{ __html: legalText }}
                  aria-label="Legal Terms that apply to the promotion"
                  aria-describedby="new-window-activate"
                  target="_blank"
                  data-automationid="Ribbon_button_terms_link"
                />
                <div className="hidden">
                  <span id="new-window-activate">Opens in a new window</span>
                </div>
              </div>
            )}
            {legalModalId && (
              <div className="Ribbon__button-terms">
                <LegalLink
                  className="Ribbon__legal_modal"
                  name="view-ribbon-terms"
                  data-toggle="modal"
                  data-target={`#${legalModalId}`}
                  onMouseDown={preventFocus}
                  dangerouslySetInnerHTML={{ __html: legalText }}
                  aria-label="Legal Terms that apply to the promotion"
                  data-automationid="Ribbon_button_terms_link"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Ribbon.propTypes = RibbonModelSchema;

export default Ribbon;
