import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { MastheadCarouselModelSchema } from '../model/schema';

import { view as Masthead } from '!app/components/Masthead';
import { fireUserInteraction, fireUtagLink } from '!app/metrics';

import '../stylesheet/MastheadCarousel.scss';

const INTERVAL_TIME_MS = 6000;

class MastheadCarousel extends Component {
  constructor(props) {
    super(props);
    const {
      model: { carousel_masthead },
    } = this.props;
    this.state = {
      curPane: 0,
      noPanes: carousel_masthead.length,
      fadeInContent: 'mhcarousel--fade-in',
      hideContent: '',
      inFlux: false,
      intervalAuto: false,
      panes: new Array(carousel_masthead.length).fill({
        tease: '',
        animate: '',
        freeze: '',
        fade: '',
      }),
      navButtons: new Array(carousel_masthead.length).fill({
        haloClass: '',
        moveClass: '',
      }),
    };

    this.mousePrevEnter = ::this.mousePrevEnter;
    this.mousePrevLeave = ::this.mousePrevLeave;
    this.mouseNextEnter = ::this.mouseNextEnter;
    this.mouseNextLeave = ::this.mouseNextLeave;
    this.gotoSlide = ::this.gotoSlide;

    /**
     * This animation timeout corresponds to the scss transition time
     * It will reset the slider after the scss animations have finished
     */
    this.animationTimout = 400;
  }

  componentDidMount() {
    this.intervalStart();
  }

  componentWillUnmount() {
    this.intervalStop();
  }

  /**
   * Interval callback to move to the next Pane.
   */
  intervalNext() {
    this.setState({ intervalAuto: true });
    // Firefox issue with this transition not sliding all the time.
    // this.gotoNextPane();
    this.navButtonFade(this.getNextPane());
  }

  /**
   * Interval start in case we want to restart it.
   * This interval will goto the next pane every 6 seconds
   */
  intervalStart() {
    this.intervalId = setInterval(() => this.intervalNext(), INTERVAL_TIME_MS);
  }

  /**
   * Interval stop and set the id to 0
   */
  intervalStop() {
    if (this.intervalId > 0) {
      clearInterval(this.intervalId);
      this.intervalId = 0;
    }
  }

  /**
   * When the previous arrow is hovered over the previous pane
   * will peek out. This will also animate the navigation dots
   */
  mousePrevEnter() {
    const {
      inFlux,
      panes: carouselPanes,
      navButtons: carouselNavButtons,
      curPane,
      noPanes,
    } = this.state;
    if (inFlux) {
      return false;
    }
    const previousPane = this.getPrevPane();
    const panes = [...carouselPanes];
    const navButtons = [...carouselNavButtons];
    panes[previousPane] = {
      ...panes[previousPane],
      tease: 'mhcarousel--tease-prev',
    };
    navButtons[previousPane] = {
      ...navButtons[previousPane],
      haloClass: 'mhcarousel--halo',
    };
    navButtons[curPane] = {
      haloClass: '',
      moveClass:
        curPane !== 0
          ? 'mhcarousel--move-prev'
          : `mhcarousel--move-last-${noPanes}`,
    };
    this.setState({
      panes,
      navButtons,
    });

    return undefined;
  }

  /**
   * When the mouse leaves hover over the arrow this will reset
   * the previous pane make to normal. This will also reset the
   * navigation dots.
   */
  mousePrevLeave() {
    const {
      inFlux,
      panes: carouselPanes,
      navButtons: carouselNavButtons,
    } = this.state;
    if (inFlux) {
      return false;
    }
    const previousPane = this.getPrevPane();
    const panes = [...carouselPanes];
    const navButtons = [...carouselNavButtons];
    panes[previousPane] = { ...panes[previousPane], tease: '' };
    navButtons.fill({ haloClass: '', moveClass: '' });
    this.setState({
      panes,
      navButtons,
    });

    return undefined;
  }

  /**
   * When the previous arrow is clicked we will stop the auto increasing
   * Then slide the previous pane into place
   */
  gotoPrevPane() {
    const { inFlux, panes: carouselPanes } = this.state;
    if (inFlux) {
      return false;
    }
    const previousPane = this.getPrevPane();
    this.intervalStop();
    const panes = [...carouselPanes];
    panes[previousPane] = {
      ...panes[previousPane],
      animate: 'mhcarousel--animate-prev',
      tease: '',
    };
    this.setState({
      fadeInContent: '',
      inFlux: true,
      panes,
    });

    setTimeout(this.gotoSlide, this.animationTimout, previousPane);

    return undefined;
  }

  /**
   * Helper function to calculate what the previous pane number is
   */
  getPrevPane() {
    const { curPane, noPanes } = this.state;
    return curPane - 1 < 0 ? noPanes - 1 : curPane - 1;
  }

  /**
   * When the next arrow is hovered over the next pane
   * will peek out. This will also animate the navigation dots
   */
  mouseNextEnter() {
    const {
      inFlux,
      noPanes,
      panes: carouselPanes,
      navButtons: carouselNavButtons,
      curPane,
    } = this.state;

    if (inFlux) {
      return false;
    }
    const nextPane = this.getNextPane();
    const panes = [...carouselPanes];
    const navButtons = [...carouselNavButtons];
    panes[nextPane] = { ...panes[nextPane], tease: 'mhcarousel--tease-next' };
    navButtons[nextPane] = {
      ...navButtons[nextPane],
      haloClass: 'mhcarousel--halo',
    };
    navButtons[curPane] = {
      haloClass: '',
      moveClass:
        curPane !== noPanes - 1
          ? 'mhcarousel--move-next'
          : `mhcarousel--move-first-${noPanes}`,
    };
    this.setState({
      panes,
      navButtons,
    });

    return undefined;
  }

  /**
   * When the mouse leaves hover over the next arrow this will reset
   * the next pane make to normal. This will also reset the
   * navigation dots.
   */
  mouseNextLeave() {
    const {
      inFlux,
      panes: carouselPanes,
      navButtons: carouselNavButtons,
    } = this.state;
    if (inFlux) {
      return false;
    }
    const nextPane = this.getNextPane();
    const panes = [...carouselPanes];
    const navButtons = [...carouselNavButtons];
    panes[nextPane] = { ...panes[nextPane], tease: '' };
    navButtons.fill({ haloClass: '', moveClass: '' });
    this.setState({
      panes,
      navButtons,
    });

    return undefined;
  }

  /**
   * When the next arrow is clicked we will stop the auto increasing
   * Then slide the next pane into place
   */
  gotoNextPane() {
    const { inFlux, panes: carouselPanes, intervalAuto } = this.state;
    if (inFlux) {
      return false;
    }

    const nextPane = this.getNextPane();
    const panes = [...carouselPanes];
    if (!intervalAuto) this.intervalStop();
    panes[nextPane] = {
      ...panes[nextPane],
      animate: 'mhcarousel--animate-next',
      tease: '',
    };
    this.setState({
      fadeInContent: '',
      inFlux: true,
      panes,
    });
    setTimeout(this.gotoSlide, this.animationTimout, nextPane);

    return undefined;
  }

  /**
   * Helper function to calculate what the next pane number is
   */
  getNextPane() {
    const { curPane, noPanes } = this.state;
    return curPane + 1 === noPanes ? 0 : curPane + 1;
  }

  /**
   * This will simply reset the carousel back to normal after the
   * animations are completed.
   * @param {number} i
   */
  gotoSlide(i) {
    const { panes: carouselPanes, navButtons: carouselNavButtons } = this.state;
    const panes = [...carouselPanes];
    const navButtons = [...carouselNavButtons];
    panes.fill({ tease: '', animate: '', freeze: '', fade: '' });
    navButtons.fill({ haloClass: '', moveClass: '' });
    this.setState({
      fadeInContent: 'mhcarousel--fade-in',
      curPane: i,
      inFlux: false,
      intervalAuto: false,
      panes,
      navButtons,
    });
  }

  /**
   * When a navigation button is clicked the carousel will jump to chosen pane.
   * This will fade the next pane over the current one.
   * @param {number} i
   */
  navButtonFade(i) {
    const {
      inFlux,
      curPane,
      noPanes,
      panes: carouselPanes,
      intervalAuto,
    } = this.state;
    const panes = [...carouselPanes];

    if (Boolean(inFlux) || i === curPane) {
      return false;
    }
    if (!intervalAuto) this.intervalStop();

    if (curPane < i) {
      if (curPane === 0 && i === noPanes - 1) {
        panes[i] = { ...panes[i], fade: 'mhcarousel--fade-in-next-pane' };
        panes[curPane] = {
          ...panes[curPane],
          freeze: 'mhcarousel--freeze-next-pane',
        };
      } else {
        panes[i] = { ...panes[i], fade: 'mhcarousel--fade-in-prev-pane' };
        panes[curPane] = {
          ...panes[curPane],
          freeze: 'mhcarousel--freeze-prev-pane',
        };
      }
    } else if (curPane - 1 === i) {
      panes[i] = { ...panes[i], fade: 'mhcarousel--fade-in-next-pane' };
      panes[curPane] = {
        ...panes[curPane],
        freeze: 'mhcarousel--freeze-next-pane',
      };
    } else {
      panes[i] = { ...panes[i], fade: 'mhcarousel--fade-in-prev-pane' };
      panes[curPane] = {
        ...panes[curPane],
        freeze: 'mhcarousel--freeze-prev-pane',
      };
    }

    this.setState({
      fadeInContent: '',
      inFlux: true,
      panes,
    });

    setTimeout(this.gotoSlide, this.animationTimout, i);

    return undefined;
  }

  /**
   * This is a helper function that when looping through the panes the
   * previous/current/next will get the correct order number. They are
   * increased by 2 so the carousel can move a pane between them for animations
   * @param {number} i
   */
  orderNumber(i) {
    const { curPane: currentPane, noPanes } = this.state;
    const LAST_ORDER = 100;
    if (currentPane === i) {
      return 2;
    }
    if (currentPane + 1 === i || (i === 0 && currentPane + 1 === noPanes)) {
      return 4;
    }
    if (currentPane - 1 === i || (i === noPanes - 1 && currentPane - 1 < 0)) {
      return 0;
    }
    return LAST_ORDER;
  }

  render() {
    const {
      model: { carousel_masthead, style },
      user,
    } = this.props;
    const { panes, navButtons, curPane, fadeInContent } = this.state;
    const WIDTH_PERCENTAGE = 100;
    const contentSize = {
      width: `${carousel_masthead.length * WIDTH_PERCENTAGE}%`,
    };
    const paneClasses = {
      0: 'mhcarousel--content-pane-prev',
      2: 'mhcarousel--content-pane-cur',
      4: 'mhcarousel--content-pane-next',
    };
    const nav = navButtons.map((nb, i) => {
      const buttonClasses = classNames(
        'mhcarousel__navButton',
        nb.moveClass,
        nb.haloClass,
        i === curPane && 'mhcarousel--navButton-active'
      );
      return (
        <span className={buttonClasses} key={i}>
          <button
            onClick={() => {
              this.navButtonFade(i);
              fireUtagLink({ event_name: 'masthead_carousel_nav' });
              fireUserInteraction('masthead_carousel', 'nav_button_click');
            }}
          />
        </span>
      );
    });

    return (
      <div
        className={`mhcarousel mhcarousel--hide-content ${fadeInContent} cu-masthead`}
      >
        <div className="mhcarousel__container">
          <div className="mhcarousel__arrows">
            <button
              className="mhcarousel__arrow mhcarousel--arrows-left"
              onClick={() => {
                this.gotoPrevPane();
                fireUtagLink({ event_name: 'masthead_carousel_arrow_prev' });
                fireUserInteraction('masthead_carousel', 'arrow_prev');
              }}
              onMouseEnter={() => this.mousePrevEnter()}
              onMouseLeave={() => this.mousePrevLeave()}
            />
            <button
              className="mhcarousel__arrow mhcarousel--arrows-right"
              onClick={() => {
                this.gotoNextPane();
                fireUtagLink({ event_name: 'masthead_carousel_arrow_next' });
                fireUserInteraction('masthead_carousel', 'arrow_next');
              }}
              onMouseEnter={() => this.mouseNextEnter()}
              onMouseLeave={() => this.mouseNextLeave()}
            />
          </div>
          <div className={`mhcarousel__nav ${style}`}>{nav}</div>
          <div className="mhcarousel__content-wrapper">
            <div
              className={`mhcarousel__content-panes ${style}`}
              style={contentSize}
            >
              {carousel_masthead &&
                carousel_masthead.map((item, index) => {
                  const orderNo = this.orderNumber(index);
                  const paneClass = classNames(
                    'mhcarousel__content-pane',
                    paneClasses[orderNo],
                    panes[index].tease,
                    panes[index].animate,
                    panes[index].fade,
                    panes[index].freeze
                  );
                  return (
                    <div
                      key={index}
                      className={paneClass}
                      style={{ order: `${orderNo}` }}
                    >
                      <Masthead idx={index} model={item} user={user} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MastheadCarousel.propTypes = {
  model: MastheadCarouselModelSchema,
  user: PropTypes.shape({}),
};
export default MastheadCarousel;
