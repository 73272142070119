import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { TRANSITION_TYPE_FADE, TRANSITION_TYPE_SLIDE } from './Tabs';
import '../stylesheet/TabStyle.scss';

const Tab = (props) => {
  const { children, active, className, transitionType, ...otherProps } = props;
  return (
    <div
      className={classnames(
        'tab',
        {
          'tab--hidden': transitionType === TRANSITION_TYPE_FADE,
          'tab--active': active,
        },
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};

Tab.displayName = 'Tab';

Tab.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  transitionType: PropTypes.oneOf([
    TRANSITION_TYPE_FADE,
    TRANSITION_TYPE_SLIDE,
  ]),
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Tab;
