import Text from '@hulu/web-ui/Text';
import React from 'react';

import { DeviceTypeModelSchema } from '../model/schema';

import '../stylesheet/DevicesTypeStyle.scss';

const DevicesType = (props) => {
  const {
    model: { description, icon, name },
  } = props;

  return (
    <div>
      <img className="lazyload" data-src={icon.url} role="presentation" />
      <Text as="p" variant="body16" className="supported-devices--device-title">
        {name}
      </Text>
      <Text
        as="p"
        variant="body12"
        className="supported-devices--device-subtitle"
      >
        {description}
      </Text>
    </div>
  );
};

DevicesType.propTypes = {
  model: DeviceTypeModelSchema,
};

export default DevicesType;
