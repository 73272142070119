import Text from '@hulu/web-ui/Text';
import React from 'react';

import { METADATA_FONT_SIZES } from './DetailEntityMetadata';

import { GenreLink } from '!app/share/GenreLink';

const Genres = ({ genres }) => {
  if (!genres || genres?.length <= 0) return null;

  const genreElements = genres.map((genre) => {
    const { name, hubPath } = genre;

    if (!hubPath) {
      return (
        <Text
          breakpoints={METADATA_FONT_SIZES}
          className="DetailEntityMetadata__genre"
        >
          {name}
        </Text>
      );
    }

    const aria = { 'aria-label': `View ${name} hub` };
    const genreLink = (
      <GenreLink
        className="DetailEntityMetadata__genre DetailEntityMetadata__genre--link"
        name="view-genre-hub"
        href={hubPath}
        dangerouslySetInnerHTML={{ __html: name }}
        {...aria}
      />
    );

    return (
      <Text
        key={name}
        breakpoints={METADATA_FONT_SIZES}
        className="DetailEntityMetadata__genre"
      >
        {genreLink}
      </Text>
    );
  });

  return <span className="DetailEntityMetadata__genres">{genreElements}</span>;
};

export default Genres;
