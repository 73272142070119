import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ButtonLeftArrowSVG from '../assets/ButtonLeftArrowSVG';
import ButtonRightArrowSVG from '../assets/ButtonRightArrowSVG';

import { preventFocus } from '!app/lib/accessibilityUtils';
import { fireUtagOnSliderClick } from '!app/lib/TealiumEventsUtils';
import { withTileInteraction } from '!app/metrics/hoc';
import Swipe from '!app/share/EasySwipe';
import '../stylesheet/Slider.scss';

const MODE_SLIDE = 'slide';
// SWIPE and STATIC modes are used for mobile breakpoints
// SWIPE and STATIC modes are mutually exclusive
const MODE_SWIPE = 'swipe'; //
const MODE_STATIC = 'static';
const MODE_STATIC_ITEMS = 4;
const LEFT_INDEX = -1;
const INACTIVE_INDEX = -1;
const RESIZE_DELAY_MS = 100;
const CLEAR_LAST_PAGE_DELAY_MS = 500;

class Slider extends Component {
  state = {
    mode: MODE_SLIDE,
    expanded: false,
    swipePosition: 0,
    page: 0,
    lastPage: null, // the previously visible page, for animation
    itemsPerPage: 5,
    itemWidth: 25,
    itemWidthPx: 0,
  };

  SeeMoreButton = withTileInteraction('button', {
    action_specifier: 'see_more',
    ...this.commonMetricsContext,
  });

  SliderLeftButton = withTileInteraction('button', {
    action_specifier: 'arrow_previous',
    ...this.commonMetricsContext,
  });

  SliderRightButton = withTileInteraction('button', {
    action_specifier: 'arrow_next',
    ...this.commonMetricsContext,
  });

  constructor(props) {
    super(props);
    this.slider = null;
    this.setSliderRef = ::this.setSliderRef;
    this.setItemsAccessibility = ::this.setItemsAccessibility;
    const { collectionIndex } = this.props;
    this.commonMetricsContext = {
      element_specifier: get(this.props, 'collectionType', 'collection'),
      collection_id: get(this.props, 'collection.id'),
      collection_index: collectionIndex,
      conditional_properties: ['collection'],
    };
  }

  getIndex(page) {
    const { items } = this.props;
    const { itemsPerPage } = this.state;

    const carry = items.length % itemsPerPage;
    const adjust =
      this.hasPreviousPage() && !this.hasNextPage() && carry > 0
        ? itemsPerPage - carry
        : 0;

    return page * itemsPerPage - adjust;
  }

  getItemsStyle() {
    const { page, itemsPerPage, itemWidth, mode, swipePosition } = this.state;
    if (mode === MODE_STATIC) {
      return {};
    }

    if (mode === MODE_SWIPE) {
      // Swiping is still happening, we only transition whatevery pixels user swiped
      if (this.swipeAction) {
        return {
          transform: `translateX(${swipePosition}px)`,
        };
      }
      // this is to handle flick swipes
      // The user has finished swiping...trigger snapping to a page
      return {
        transition: 'transform 0.3s ease-out',
        transform: `translateX(${swipePosition}px)`,
      };
    }

    const index = this.getIndex(page);
    const position = -itemWidth * itemsPerPage * (index / itemsPerPage);

    return {
      transition: 'transform 0.5s ease-out',
      transform: `translateX(${position}%)`,
    };
  }

  isVisible(key, index) {
    const { itemsPerPage } = this.state;
    return key >= index && key < index + itemsPerPage;
  }

  updateExpanded = () => {
    this.setState({ expanded: true });
  };

  setSliderRef(element) {
    this.slider = element;
  }

  renderItems() {
    const { items } = this.props;
    const { page, mode, expanded } = this.state;
    const index = this.getIndex(page);
    const toggeVisible = mode === MODE_SLIDE;
    const expandedMode = mode === MODE_STATIC && expanded;

    return (
      <div className="Slider__wrapper">
        <div
          className={classNames('Slider__items', {
            'Slider__items--expanded': expandedMode,
          })}
          style={this.getItemsStyle()}
        >
          {items.map((item, key) => (
            <div
              className={classNames('Slider__item', {
                'Slider__item--visible':
                  toggeVisible && this.isVisible(key, index),
              })}
              ref={(itemElement) => (this.itemElement = itemElement)}
              key={key}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderSliderMode() {
    const { previousAltText, nextAltText } = this.props;
    const { mode } = this.state;

    let items = this.renderItems();
    if (mode === MODE_SWIPE) {
      items = (
        <Swipe
          onSwipeStart={this.handleSwipeStart}
          onSwipeMove={this.handleSwipeMove}
          onSwipeEnd={this.handleSwipeEnd}
          onSwipeLeft={this.handleSwipeLeft}
          onSwipeRight={this.handleSwipeRight}
          tolerance={80}
        >
          {this.renderItems()}
        </Swipe>
      );
    }

    return (
      <>
        <this.SliderLeftButton
          className="Slider__button Slider__button--previous"
          disabled={!this.hasPreviousPage()}
          onMouseDown={preventFocus}
          onClick={() => this.changePage(LEFT_INDEX)}
          aria-label="Previous slide"
          data-automationid="slider_button_previous"
        >
          <ButtonLeftArrowSVG title={previousAltText} />
        </this.SliderLeftButton>

        {items}

        <this.SliderRightButton
          className="Slider__button Slider__button--next"
          disabled={!this.hasNextPage()}
          onMouseDown={preventFocus}
          onClick={() => this.changePage(1)}
          aria-label="Next slide"
          data-automationid="slider_button_next"
        >
          <ButtonRightArrowSVG title={nextAltText} />
        </this.SliderRightButton>
      </>
    );
  }

  renderStaticMode() {
    const { items } = this.props;
    const { expanded } = this.state;
    const moreItemCount = items.length - MODE_STATIC_ITEMS;
    const hasMoreItems = moreItemCount > 0;

    return (
      <>
        {this.renderItems()}
        {!expanded && hasMoreItems && (
          <this.SeeMoreButton
            className="Slider__see-more-button"
            onClick={this.updateExpanded}
          >
            {`SEE MORE (${moreItemCount})`}
          </this.SeeMoreButton>
        )}
      </>
    );
  }

  render() {
    const { className } = this.props;
    const { mode } = this.state;
    const isStaticMode = mode === MODE_STATIC;

    const sliderClassNames = classNames('Slider', className, {
      'Slider--static': isStaticMode,
    });

    return (
      <div className={sliderClassNames} ref={this.setSliderRef}>
        {!isStaticMode && this.renderSliderMode()}
        {isStaticMode && this.renderStaticMode()}
      </div>
    );
  }

  hasPreviousPage() {
    const { page } = this.state;

    return page > 0;
  }

  hasNextPage() {
    const { items } = this.props;
    const { page, itemsPerPage } = this.state;

    return page < items.length / itemsPerPage - 1;
  }

  nextPage = () => {
    if (this.hasNextPage()) this.changePage(1);
  };

  prevPage = () => {
    if (this.hasPreviousPage()) this.changePage(LEFT_INDEX);
  };

  changePage(by) {
    const { page, itemsPerPage } = this.state;
    const { collection } = this.props;
    const moveToSlide = itemsPerPage * (page + by);
    const direction = by > 0 ? 'next' : 'prev';

    // fire Utags for both left and right slider clicks
    fireUtagOnSliderClick(moveToSlide, direction, collection.name);

    // We need the previous content to be visible
    // for the duration of the animation, then remove it
    clearTimeout(this.clearLastPage);
    this.clearLastPage = setTimeout(() => {
      this.setState({ lastPage: null });
    }, CLEAR_LAST_PAGE_DELAY_MS);

    this.setState({
      lastPage: page,
      page: page + by,
      swipePosition: this.getSwipePositionForPage(page + by),
    });
  }

  setItemsAccessibility() {
    const { page } = this.state;
    if (!this.slider) {
      return;
    }

    const pageIndex = this.getIndex(page);

    // Get all the items for the slider and its focusable elements
    const sliderItems = Array.from(
      this.slider.querySelectorAll('.Slider__item')
    );

    sliderItems.map((sliderItem, key) => {
      const focusableElements = Array.from(
        sliderItem.querySelectorAll('button, a[href]')
      );

      // If it doesn't contain visible class, set focusable elements to tabIndex = -1
      const tabIndex = this.isVisible(key, pageIndex) ? 0 : INACTIVE_INDEX;
      focusableElements.map((focusableElement) => {
        /* eslint-disable no-param-reassign */
        focusableElement.tabIndex = tabIndex;
      });
    });
  }

  onResize = debounce(() => this.handleResize(), RESIZE_DELAY_MS);

  getSwipePositionForPage(page) {
    const { itemWidthPx, itemsPerPage } = this.state;
    return -itemWidthPx * itemsPerPage * page;
  }

  handleSwipeMove = (delta) => {
    const { itemWidthPx, itemsPerPage } = this.state;
    const { items } = this.props;

    const maxPosition = (itemsPerPage - items.length) * itemWidthPx;

    this.setState({
      swipePosition: Math.min(
        0,
        Math.max(this.swipeStartIndex + delta.x, maxPosition)
      ),
    });

    if (delta.x > 5) {
      return true;
    }

    return false;
  };

  handleSwipeStart = () => {
    const { swipePosition } = this.state;

    this.swipeStartIndex = swipePosition;
    this.swipeStart = true;
  };

  handleSwipeEnd = () => {
    this.swipeStart = false;
  };

  handleSwipeLeft = (delta) => {
    if (this.shouldSwipeSnap(delta.x)) {
      this.nextPage();
    } else if (this.hasNextPage())
      this.setState((prev) => ({ page: prev.page + 1 }));
  };

  handleSwipeRight = (delta) => {
    if (this.shouldSwipeSnap(delta.x)) {
      this.prevPage();
    } else if (this.hasPreviousPage())
      this.setState((prev) => ({ page: prev.page - 1 }));
  };

  shouldSwipeSnap(distance) {
    const { itemWidthPx, itemsPerPage } = this.state;
    const pageWidth = itemWidthPx * itemsPerPage;

    return Math.abs(distance) < pageWidth;
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.handleResize();
    this.setItemsAccessibility();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const { itemsPerPage, page } = this.state;

    // If items per page and the page number changes for the slider, we set the accessibility for the items again
    if (prevState.itemsPerPage !== itemsPerPage || prevState.page !== page) {
      this.setItemsAccessibility();
    }
  }

  handleResize() {
    const { items, mobileModeTrigger, mobileMode } = this.props;
    const { page } = this.state;

    // HUWEB-5395: Bail out early if there are no items
    if (!items.length || !this.itemElement) return;
    const itemStyle = window.getComputedStyle(this.itemElement);
    const itemWidth = parseFloat(itemStyle.flexBasis);
    const ITEMS_WIDTH = 100;

    // keep track of item width in px for max swiping
    const itemWidthPx = parseFloat(itemStyle.width);
    let itemsPerPage = ITEMS_WIDTH / itemWidth;
    const PEEKING_RATIO = 0.98;

    // we cannot use on Math.round because Math.round(2.5) => 3
    // we need to use Math.floor when we have half a peaking tile in the Portrait slider
    if (itemsPerPage % 1 >= PEEKING_RATIO) {
      itemsPerPage = Math.ceil(itemsPerPage);
    } else {
      itemsPerPage = Math.floor(itemsPerPage);
    }

    const maxPage = Math.floor(items.length / itemsPerPage) - 1;

    let mode = MODE_SLIDE;
    if (mobileModeTrigger && itemsPerPage <= mobileModeTrigger) {
      mode = mobileMode;
    }

    this.setState((prevState) => {
      // RESET scroll position on mode change to address conflicts between page-slide vs swipeedge cases
      const isModeUpdated = prevState.mode !== mode;
      return {
        mode,
        itemsPerPage,
        itemWidth,
        itemWidthPx,
        swipePosition: isModeUpdated ? 0 : prevState.swipePosition,
        page: isModeUpdated ? 0 : Math.max(0, Math.min(page, maxPage)),
      };
    });
  }
}

Slider.defaultProps = {
  mobileModeTrigger: 0,
};

Slider.propTypes = {
  items: PropTypes.node,
  className: PropTypes.string,
  previousAltText: PropTypes.node,
  nextAltText: PropTypes.node,
  collectionIndex: PropTypes.number,
  collection: PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mobileMode: PropTypes.oneOf([MODE_STATIC, MODE_SWIPE]),
  mobileModeTrigger: PropTypes.number, // the number of visible tiles that triggers the mobile mode
};

export default Slider;
