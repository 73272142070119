import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { PlanComparisonPricingRowModelSchema } from '../model/schema';

const PlanComparisonPricingRow = (props) => {
  const {
    isToggleOn,
    pricingRow: { slug, headline, prices },
    numPlans,
    highlightedColumnIndex,
    toggleHighlightedColumnIndex,
  } = props;

  const pricesForCurrentBundle = isToggleOn
    ? prices.slice(prices.length - numPlans)
    : prices.slice(0, numPlans);

  return (
    <div className="plan-feature-item">
      <div className="col-xs-6 col-lg-5 plan-feature__title">
        <Text variant="body14">{headline}</Text>
      </div>

      {pricesForCurrentBundle.map((priceMap, i) => {
        const { priceNote, price, originalPriceOverride } = priceMap;
        const displayPrice = priceNote !== '' ? `${price}${priceNote}` : price;
        const isHighlighted =
          (i === highlightedColumnIndex - 1 && !isToggleOn) ||
          (i === toggleHighlightedColumnIndex - 1 && isToggleOn);

        return (
          <Fragment key={i}>
            <div
              className={`col-xs-${
                12 / numPlans
              } plan-feature__check plan-feature__check-${
                isHighlighted ? 'highlighted' : 'not-highlighted'
              }`}
              key={`${slug}_${i}`}
            >
              <div className="plan-feature__bullet">
                {originalPriceOverride && (
                  <Text
                    variant="body12"
                    className="plan-feature__bullet_text--strikethrough"
                  >
                    {originalPriceOverride}
                  </Text>
                )}
                <Text
                  breakpoints={{
                    xs: 'body14',
                    md: 'body16',
                  }}
                  className="plan-feature__bullet_text"
                >
                  {displayPrice}
                </Text>
              </div>
            </div>
            <div className="plan-feature__separator" />
          </Fragment>
        );
      })}
    </div>
  );
};

PlanComparisonPricingRow.propTypes = {
  pricingRow: PlanComparisonPricingRowModelSchema,
  numPlans: PropTypes.number,
};

export default PlanComparisonPricingRow;
