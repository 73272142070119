import React, { useState, useEffect, useRef } from 'react';

import { SpotlightTabsModelSchema } from '../model/schema';

import TabButtons from './TabButtons';
import TabContent from './TabContent';

import { BREAKPOINTS } from '!app/lib/constants';
import { useWindowResize } from '!app/lib/hooks/useWindowResize';

import '../stylesheet/SpotlightTabs.scss';

const SpotlightTabs = (props) => {
  const {
    model: { spotTabs },
  } = props;
  const {
    windowSize: { width },
  } = useWindowResize();
  const isMobileView = width ? width < BREAKPOINTS.LARGE : null;

  const [activeTab, setActiveTab] = useState(0);
  const [activeBarWidth, setActiveBarWidth] = useState(0);
  const [activeBarLeft, setActiveBarLeft] = useState(0);
  const [tabOffset, setTabOffset] = useState(0);

  const tabArray = spotTabs.map(() => useRef(null));
  const activeBarStyles = {
    width: `${activeBarWidth}px`,
    transform: `translateX(${activeBarLeft}px)`,
  };

  const backgroundImg = isMobileView
    ? `linear-gradient(180deg, rgba(0, 0, 0, 0.8) -39.59%, rgba(0, 0, 0, 0) 117.14%), url(${spotTabs[activeTab]?.mobileBackgroundImage.url})`
    : `linear-gradient(90deg, rgba(0, 0, 0, 0.8) -57.5%, rgba(0, 0, 0, 0) 98.72%), url(${spotTabs[activeTab].desktopBackgroundImage.url})`;

  function tabClick(tabNum) {
    setActiveTab(tabNum);
    setActiveBarLeft(
      tabArray[tabNum].current.offsetLeft - tabArray[0].current.offsetLeft
    );
    tabSlide(tabNum);
  }

  function tabSlide(selected) {
    if (!isMobileView) {
      setTabOffset(0);
    } else if (window.innerWidth) {
      const windowInnerWidth = window.innerWidth;
      const firstTab = tabArray[0].current;
      const lastTab = tabArray[tabArray.length - 1].current;
      // Check if the nav items are wider than the window
      const navItemsWidth =
        lastTab.offsetLeft + lastTab.offsetWidth - firstTab.offsetLeft;
      if (windowInnerWidth < navItemsWidth) {
        let tabOff =
          tabArray[selected].current.offsetLeft - firstTab.offsetLeft;
        // Check if the selected tab would hit the end of the window so keep it from moving
        if (navItemsWidth - tabOff < windowInnerWidth) {
          tabOff = navItemsWidth - windowInnerWidth + 2 * firstTab.offsetLeft;
        }
        setTabOffset(tabOff);
      }
    }
  }

  useEffect(() => {
    setActiveBarWidth(tabArray[activeTab].current.offsetWidth);
  }, [activeTab]);

  useEffect(() => {
    if (isMobileView !== null) {
      spotTabs.forEach((tab) => {
        const imgPreload = new Image();
        imgPreload.src = isMobileView
          ? tab.mobileBackgroundImage.url
          : tab.desktopBackgroundImage.url;
      });
      setActiveBarLeft(
        tabArray[activeTab].current.offsetLeft - tabArray[0].current.offsetLeft
      );
      tabSlide(activeTab);
    }
  }, [isMobileView]);

  return (
    <div
      id="SpotlightTabs"
      className="SpotlightTabs"
      style={{
        backgroundImage: backgroundImg,
      }}
    >
      <div className="SpotlightTabs__outerContainer">
        <div className="SpotlightTabs__innerContainer">
          <TabButtons
            items={spotTabs}
            tabArray={tabArray}
            tabOffset={tabOffset}
            activeBarStyles={activeBarStyles}
            activeTab={activeTab}
            onClick={tabClick}
          />
          <TabContent items={spotTabs} activeTab={activeTab} />
        </div>
      </div>
    </div>
  );
};

SpotlightTabs.propTypes = {
  model: SpotlightTabsModelSchema.isRequired,
};

export default SpotlightTabs;
