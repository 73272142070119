import Text from '@hulu/web-ui/Text';
import React from 'react';

import EditorialCollectionModelSchema from '../model/schema';

import { getComponent } from '!app/components';
import '../stylesheets/EditorialCollection.scss';
import { getAutomationIdFromString } from '!app/lib/utils';

const EditorialCollection = (props) => {
  const { model } = props;
  const { editorialHeadline, editorialDescription, collection, title } = model;

  if (!collection?.items?.length) return null;

  const CollectionComponent = getComponent(collection.theme);

  return (
    <div
      className="NonSubEditorialCollection"
      data-automationid={`${getAutomationIdFromString(
        title
      )}_editorial_collection`}
    >
      <div
        className={editorialHeadline ? 'NonSubEditorialCollection__header' : ''}
      >
        {editorialHeadline && (
          <Text
            as="h2"
            breakpoints={{ xs: 'title32', lg: 'title48' }}
            className="section-headline NonSubEditorialCollection__header__headline"
          >
            {editorialHeadline}
          </Text>
        )}
        {editorialDescription && (
          <Text
            as="p"
            variant="body16"
            className="NonSubEditorialCollection__header__description"
          >
            {editorialDescription}
          </Text>
        )}
      </div>
      {CollectionComponent && (
        <CollectionComponent key={collection.id} model={{ ...model }} />
      )}
    </div>
  );
};

EditorialCollection.propTypes = {
  model: EditorialCollectionModelSchema,
};

export default EditorialCollection;
