import withCustomInteraction from './withCustomInteraction';
import withEntityInteraction from './withEntityInteraction';
import withInnerHtmlEvents from './withInnerHtmlEvents';
import withTileInteraction from './withTileInteraction';
import withUserInteraction from './withUserInteraction';
import withUtagLink from './withUtagLink';

const WithEvents = {
  p: withInnerHtmlEvents('p'),
  div: withInnerHtmlEvents('div'),
  h1: withInnerHtmlEvents('h1'),
  h2: withInnerHtmlEvents('h2'),
  span: withInnerHtmlEvents('span'),
  a: withInnerHtmlEvents('a'),
};

export {
  withCustomInteraction,
  withInnerHtmlEvents,
  withUtagLink,
  withUserInteraction,
  withTileInteraction,
  withEntityInteraction,
  WithEvents,
};
