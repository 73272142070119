import PropTypes from 'prop-types';

export default PropTypes.shape({
  collection: {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    enabledTileLinks: PropTypes.bool,
    seasons: PropTypes.arrayOf(PropTypes.shape({})),
  },
});
