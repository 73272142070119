import { ENTER_KEY } from '!app/lib/constants';

export const preventFocus = (event) => {
  event.preventDefault();
};

/**
 * Curried function that takes a callback and returns
 * a function that takes an event to call that callback with
 * if the enter key has been pressed on a focused element. Useful
 * for simulating an onclick.
 *
 * @param {function} callback function to be called once given event object
 * @param {Object} e event to be supplied by event listener
 * @returns curried function. (function: function -> function: object -> void)
 */
export const enterKeyHandler = (callback) => (e) => {
  if (e.keyCode === ENTER_KEY) callback(e);
};
