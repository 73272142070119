import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';

const EmailCaptureField = (props) => {
  const {
    email,
    handleChangeEmail,
    isExistingEmail,
    isInvalidEmail,
    emailCapturePrompt,
    invalidEmailErrorText,
    existingEmailErrorText,
  } = props;
  const isError = isExistingEmail || isInvalidEmail;
  return (
    <>
      <input
        type="text"
        className={`Masthead__email-capture-input${isError ? '__error' : ''}`}
        value={email}
        placeholder="Email"
        onChange={handleChangeEmail}
        aria-invalid={isError ? 'true' : 'false'}
        aria-label={emailCapturePrompt}
      />
      {isError && (
        <Text
          as="span"
          variant="body12"
          className="Masthead__email-capture-input-error-text"
        >
          {isExistingEmail && (
            <div dangerouslySetInnerHTML={{ __html: existingEmailErrorText }} />
          )}
          {isInvalidEmail && invalidEmailErrorText}
        </Text>
      )}
    </>
  );
};

EmailCaptureField.propTypes = {
  email: PropTypes.string,
  handleChangeEmail: PropTypes.func,
  isExistingEmail: PropTypes.bool,
  isInvalidEmail: PropTypes.bool,
  emailCapturePrompt: PropTypes.string,
  invalidEmailErrorText: PropTypes.string,
  existingEmailErrorText: PropTypes.string,
};

export default EmailCaptureField;
