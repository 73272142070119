import Text from '@hulu/web-ui/Text';
import isEmpty from 'lodash/isEmpty';
import { shape, string, arrayOf, boolean } from 'prop-types';

import ExpPlanComparisonBundleHeaderModal from './ExpPlanComparisonBundleHeaderModal';

import { useExpPlanComparisonChartContext } from '!app/experimental/components/ExpPlanComparisonChart/view/ExpPlanComparisonChartContext';
import { useWindowResize } from '!app/lib/hooks/useWindowResize';
import { withUtagLink, withUserInteraction } from '!app/metrics/hoc';
import ToggleSwitch from '!app/share/ToggleSwitch';

import '../stylesheet/ExpPlanComparisonBundleHeader.scss';

const ExpPlanComparisonBundleHeader = (props) => {
  const {
    bundle: { headline, description, modal },
    isDark,
  } = props;
  const hasModal = !isEmpty(modal);
  const { isBundle, setIsBundle } = useExpPlanComparisonChartContext();
  const {
    windowSize: { width },
  } = useWindowResize();
  const LARGE_WIDTH = 1024;
  const isDesktop = width >= LARGE_WIDTH;
  const eventName = `bundle_toggle_${isBundle ? 'on' : 'off'}`;
  const Toggle = withUtagLink(
    {
      event_name: eventName,
      cta_placement: 'comparison_chart',
    },
    withUserInteraction(ToggleSwitch, eventName)
  );

  return (
    <div className="bundle-header">
      <div className="bundle-header__headline">
        <Text breakpoints={{ xs: 'title14', md: 'title32' }}>{headline}</Text>
        {hasModal && (
          <span>
            <a data-toggle="modal" data-target={`#${modal.id}`} role="button">
              <img
                src={
                  isDark
                    ? '/static/hitch/static/icons/Info_Icon_White.svg'
                    : '/static/hitch/static/icons/Info_Icon_Dark_Grey.svg'
                }
                role="presentation"
                alt={headline}
              />
            </a>
          </span>
        )}
      </div>
      <div className="bundle-header__toggle-container">
        <img
          src={
            isDark
              ? '/static/hitch/static/logos/bundles-dark.svg'
              : '/static/hitch/static/logos/bundles.svg'
          }
          role="presentation"
          alt="logos"
        />
        {!isDesktop && (
          <Toggle
            isOn={isBundle}
            onColor={isDark ? '#1CE783' : '#040405'}
            handleToggle={() => setIsBundle(!isBundle)}
          />
        )}
      </div>
      <div className="bundle-header__description">
        <Text variant="body14">{description}</Text>
      </div>
      {isDesktop && (
        <div className="bundle-header__toggle-container--desktop">
          <Toggle
            isOn={isBundle}
            onColor={isDark ? '#1CE783' : '#040405'}
            handleToggle={() => setIsBundle(!isBundle)}
          />
        </div>
      )}
      {hasModal && <ExpPlanComparisonBundleHeaderModal modal={modal} />}
    </div>
  );
};

ExpPlanComparisonBundleHeader.propTypes = {
  bundle: shape({
    headline: string,
    description: string,
    modal: shape({
      id: string,
      header: string,
      disclaimer: string,
      sections: arrayOf(
        shape({
          title: string,
          items: arrayOf(
            shape({
              text: string,
              displayBullet: boolean,
            })
          ),
        })
      ),
    }),
  }),
};

export default ExpPlanComparisonBundleHeader;
