import Text from '@hulu/web-ui/Text';
import React, { useState } from 'react';

import { FAQModelSchema } from '../model/schema';

import { enterKeyHandler, preventFocus } from '!app/lib/accessibilityUtils';
import { fireUtagLink, fireUserInteraction } from '!app/metrics';
import { WithEvents } from '!app/metrics/hoc';

export const FAQ = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    model: { index, question, answer },
  } = props;

  const onHeaderClicked = () => {
    setIsOpen(!isOpen);

    if (isOpen === false) {
      fireUserInteraction(`view_faq_${index + 1}`, 'click', 'click');
      fireUtagLink({ event_name: 'view_faq', faq_number: `${index + 1}` });
    }
  };

  return (
    <li>
      <div className={`accordion__item ${isOpen ? 'open' : ''}`}>
        <a
          className="accordion__link"
          role="button"
          aria-label={`${question} Learn more`}
          tabIndex="0"
          onClick={onHeaderClicked}
          onKeyDown={enterKeyHandler(onHeaderClicked)}
          onMouseDown={preventFocus}
        >
          <Text as="h3" variant="body18" className="accordion__question">
            {question}
          </Text>
          <div className="accordion__icon" />
        </a>
        <WithEvents.div
          className="section-body"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </li>
  );
};

FAQ.propTypes = {
  model: FAQModelSchema,
};

export default FAQ;
