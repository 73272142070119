import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { DetailEntitySchema } from '../model/schema';

import TitleArt from './TitleArt';
import Toaster from './Toaster';

import { view as Modal } from '!app/components/Modal';
import { view as Ribbon } from '!app/components/Ribbon';
import { enterKeyHandler, preventFocus } from '!app/lib/accessibilityUtils';
import { TITLE_ART_SIZES } from '!app/lib/constants';
import { mobileDetect } from '!app/lib/environment';
import { isPremiumNetwork } from '!app/lib/heimdall';
import { getImageUrls } from '!app/lib/imageUtils';
import {
  getCtaText,
  getPremiumNetworkFromParameter,
} from '!app/lib/signupUtils';
import { getEventNameFromEntitlement } from '!app/lib/TealiumEventsUtils';
import { ctaRedirect, getDownloadAppLink } from '!app/lib/urlUtils';
import { toPremiereDateFormat, isDateWithin90Days } from '!app/lib/utils';
import { withUtagLink, withUserInteraction } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';
import DetailEntityMetadata from '!app/share/DetailEntityMetadata';
import Logo from '!app/share/Logo';

import '../stylesheets/MastheadModal.scss';

const DESCRIPTION_MOBILE_LENGTH = 65;

const DetailEntity = (props) => {
  const {
    avFeatures,
    brandArtwork,
    contentOverrides,
    credits,
    ctaDownloadAppText,
    ctaFields,
    ctas,
    description,
    disclaimer,
    entityType,
    genres,
    headline,
    isHuluOriginal,
    isHuluOriginalContent,
    isOriginalContent,
    legalText,
    locale,
    network,
    premiereDate,
    rating,
    requirePremium,
    ribbon,
    tags,
    title,
    titleArtwork,
    user,
  } = props;

  const descriptionRef = React.createRef();
  const ctaRef = React.createRef();

  const [toasterActive, setToasterActive] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const top = ctaRef.current?.getBoundingClientRect().top;
      setToasterActive(top < 0 || top > window.innerHeight);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const isMobile = mobileDetect().mobile();

  const getDescriptionMobile = () => {
    const descriptionText =
      contentOverrides?.overrideDescription || description;

    return descriptionText?.length > DESCRIPTION_MOBILE_LENGTH ? (
      <>
        {descriptionText.substring(0, DESCRIPTION_MOBILE_LENGTH)}...
        <a
          type="button"
          role="button"
          tabIndex="0"
          className="DetailEntityMasthead__description-read-more"
          ref={descriptionRef}
          onKeyDown={enterKeyHandler(() => descriptionRef.current?.click())}
          onMouseDown={preventFocus}
          name="view-description"
          data-toggle="modal"
          data-target="#description-modal"
          data-events="utag"
          data-utag-object="event_name:detail_entity_read_more"
        >
          More
        </a>
      </>
    ) : (
      <>{descriptionText}</>
    );
  };

  // determines whether or not to show download app link
  const shouldShowDownloadAppLink =
    user.isHuluUser && isMobile && ctaDownloadAppText;

  // Function that renders appropriate CTAs for masthead
  const renderCTAs = () => {
    // HOC that adds HITs tracking to CTAs
    const CTAWithTracking = ({
      elementSpecifier,
      targetDisplayName,
      ...ctaProps
    }) => {
      return withUtagLink(
        {
          event_name: getEventNameFromEntitlement(
            user,
            shouldShowDownloadAppLink
          ),
          cta_placement: 'detailEntity',
        },
        withUserInteraction(
          CTAButton,
          elementSpecifier,
          'driver_click',
          'click',
          true,
          targetDisplayName
        )
      )(ctaProps);
    };
    // Determine which masthead CTAs to render
    // if shouldShowDownloadAppLink = true, we only render one CTA with the download link and text
    // else, we render each of the CTAs in the ctas array
    if (shouldShowDownloadAppLink) {
      return (
        <CTAWithTracking
          className="DetailEntityMasthead__cta"
          useStyle="white"
          onClick={() => window.location.assign(getDownloadAppLink())}
          elementSpecifier="cta_detailEntity"
          targetDisplayName={ctaDownloadAppText}
        >
          {ctaDownloadAppText}
        </CTAWithTracking>
      );
    }
    return (
      ctas &&
      ctas.map(({ url, text, hypeProgram }, index) => {
        const ctaCopy = getCtaText({
          user,
          componentNonSubCta: text,
          locale,
          ctaFields,
        });
        return (
          <CTAWithTracking
            key={`DetailEntityCTA-${url}`}
            className="DetailEntityMasthead__cta"
            useStyle="white"
            onClick={() => {
              ctaRedirect({
                user,
                componentUrl: url,
                ctaFields,
                // the "from" value will either come from CMS, fallback to premium network name if a premium title, or fallback to undefined if not premium
                from:
                  hypeProgram ||
                  getPremiumNetworkFromParameter(requirePremium, network),
                // for Detail Entity CTAs, we do not want the value passed to the "from" parameter to fallback to the page URL
                passUrlAsProgram: false,
              });
            }}
            elementSpecifier={
              ctas.length > 1
                ? `masthead_cta:position_${index + 1}`
                : `cta_detailEntity`
            }
            targetDisplayName={ctaCopy}
            data-automationid={
              index === 0
                ? 'masthead_input_cta'
                : 'masthead_input_cta_secondary'
            }
          >
            {ctaCopy}
          </CTAWithTracking>
        );
      })
    );
  };

  const MastheadCTAs = renderCTAs();

  const getTitleText = () => {
    const separator = ' • ';

    const premiereCopy = `Coming ${toPremiereDateFormat(premiereDate)}`;
    let upcomingTitle = premiereCopy;
    if (isHuluOriginalContent) {
      upcomingTitle = `Original ${separator} ${upcomingTitle}`;
    }

    const existingHeadline = headline.join(separator);

    return isDateWithin90Days(premiereDate) && isOriginalContent
      ? upcomingTitle
      : existingHeadline;
  };

  const getHeadline = () => {
    const headlineText = getTitleText();
    if (!headlineText) return null;

    return (
      <div className="DetailEntityMasthead__headline">
        {isHuluOriginal && <Logo />}
        <Text breakpoints={{ xs: 'subtitle10Caps', md: 'subtitle12Caps' }}>
          {headlineText}
        </Text>
      </div>
    );
  };

  const descriptionElement = (
    <>
      <Text
        breakpoints={{ xs: 'body12', lg: 'body14' }}
        className="DetailEntityMasthead__description-truncated"
      >
        {contentOverrides?.overrideDescription || description}
      </Text>
      <span>
        <a
          type="button"
          role="button"
          tabIndex="0"
          className="DetailEntityMasthead__description-read-more"
          ref={descriptionRef}
          onKeyDown={enterKeyHandler(() => descriptionRef.current?.click())}
          onMouseDown={preventFocus}
          name="view-description"
          data-toggle="modal"
          data-target="#description-modal"
          data-events="utag"
          data-utag-object="event_name:detail_entity_read_more"
        >
          more
        </a>
      </span>
    </>
  );

  return (
    <div>
      <div className="DetailEntityMasthead__entity">
        <div className="DetailEntityMasthead__meta">
          {getHeadline()}
          <TitleArt
            imageUrls={
              titleArtwork
                ? getImageUrls(titleArtwork.path, TITLE_ART_SIZES, 'webp', true)
                : undefined
            }
            title={contentOverrides?.overrideName || title}
            brandArtwork={brandArtwork}
          />
          <Text
            as="p"
            breakpoints={{ xs: 'body12', lg: 'body14' }}
            className="DetailEntityMasthead__description"
          >
            {descriptionElement}
          </Text>
          <Text
            as="p"
            breakpoints={{ xs: 'body14' }}
            className="DetailEntityMasthead__description-mobile"
          >
            {getDescriptionMobile(
              contentOverrides?.overrideDescription || description
            )}
          </Text>
          <DetailEntityMetadata
            avFeatures={avFeatures}
            className="DetailEntityMasthead__tags"
            credits={credits}
            entityType={entityType}
            genres={genres}
            rating={rating}
            tags={tags}
            showAvInline
            truncateCredits
          />
          <p
            className={classNames(`DetailEntityMasthead__disclaimer`, {
              'DetailEntityMasthead__disclaimer--premium': isPremiumNetwork(
                network
              ),
            })}
            dangerouslySetInnerHTML={{ __html: disclaimer }}
          />
          <div className="DetailEntityMasthead__buttonSection" ref={ctaRef}>
            {MastheadCTAs}
          </div>
          <p
            className="DetailEntityMasthead__legalText"
            dangerouslySetInnerHTML={{ __html: legalText }}
          />
        </div>
        <Modal
          model={{ id: 'description-modal' }}
          className="description-modal"
        >
          <div className="DetailEntityModal">
            <Text
              as="h2"
              breakpoints={{ xs: 'title14', md: 'title26', lg: 'title24' }}
              className="DetailEntityModal__title"
            >
              {contentOverrides?.overrideName || title}
            </Text>
            <Text
              as="p"
              breakpoints={{ xs: 'body12', md: 'body14', lg: 'body16' }}
              className="DetailEntityModal__description"
            >
              {contentOverrides?.overrideDescription || description}
            </Text>
            <DetailEntityMetadata
              avFeatures={avFeatures}
              className="DetailEntityModal__tags"
              credits={credits}
              entityType={entityType}
              genres={genres}
              rating={rating}
              tags={tags}
            />
          </div>
        </Modal>
      </div>
      <Ribbon model={ribbon} />
      <Toaster {...props} isActive={toasterActive} />
    </div>
  );
};

DetailEntity.propTypes = {
  user: PropTypes.shape({}),
  ctaFields: PropTypes.shape({}),
  locale: PropTypes.string,
  ...DetailEntitySchema,
};

export default DetailEntity;
