import PropTypes from 'prop-types';
import React from 'react';

/* istanbul ignore next */
const ButtonRightArrowSVG = ({ title }) => (
  <svg viewBox="0 0 64 64">
    <title>{title}</title>
    <g transform="rotate(-90 32 32)" fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx="32" cy="32" r="32" />
      <path d="M40.8 27.92l-9.2 9.2-9.2-9.2" strokeWidth="2" stroke="#868D9B" />
    </g>
  </svg>
);

ButtonRightArrowSVG.propTypes = {
  title: PropTypes.node,
};

export default ButtonRightArrowSVG;
