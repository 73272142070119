import ContentWithAnchor from './view/ContentWithAnchor';

import { getReducerMap } from '!app/components';

const view = ContentWithAnchor;

const reducerMap = (model) => {
  if (!model || !model.content) {
    return null;
  }
  return getReducerMap(model.content.type, model.content);
};

export { view, reducerMap };
