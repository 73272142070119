import { ExpNoFeesMinimalistModelSchema } from '../model/schema';

import ExpNoFeesMinimalistColumn from './ExpNoFeesMinimalistColumn';

const ExpNoFeesMinimalistRow = (props) => {
  const { model: columns, className, isLastRow } = props;

  return (
    <div className={className}>
      {columns?.map((column, index) => (
        <ExpNoFeesMinimalistColumn
          key={index}
          model={column}
          index={index}
          isLastRow={isLastRow}
          className={
            className.includes('header')
              ? 'exp-no-fees-minimalist__column--header'
              : 'exp-no-fees-minimalist__column'
          }
        />
      ))}
    </div>
  );
};

ExpNoFeesMinimalistRow.propTypes = {
  model: ExpNoFeesMinimalistModelSchema.rows,
};

export default ExpNoFeesMinimalistRow;
