import React, { Component } from 'react';

import { ContentTabsModelSchema } from '../model/schema';

import { view as MediaWithText } from '!app/components/MediaWithText';
import { view as ValueProps } from '!app/components/ValueProps';
import { fireUserInteraction, fireUtagLink } from '!app/metrics/fireEvent';
import { withUserInteraction, WithEvents } from '!app/metrics/hoc';

import '../stylesheet/ContentTabsStyle.scss';

/**
 * Renders content tabs.
 */
class ContentTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBarWidth: 0,
      activeBarLeft: 0,
      mobileLeft: 0,
      selected: 0,
    };
    this.switchTab = ::this.switchTab;
    this.handleStartTouch = ::this.handleStartTouch;
    this.handleEndTouch = ::this.handleEndTouch;
    this.startX = 0;
    this.startY = 0;
    this.distX = 0;
    this.distY = 0;
    this.threshold = 50; // required min distance traveled to be considered swipe
    this.restraint = 25; // maximum distance allowed at the same time in perpendicular direction
    this.allowedTime = 300; // maximum time allowed to travel that distance
    this.elapsedTime = 0;
    this.startTime = 0;
  }

  componentDidMount() {
    this.switchTab(0);
  }

  /**
   * Callback when the list of tabs begin to be touched to start tracking a swipe.
   *
   * @param {Event} e
   */
  handleStartTouch(e) {
    const touchobj = e.changedTouches[0];
    this.dist = 0;
    this.startX = touchobj.pageX;
    this.startY = touchobj.pageY;
    this.startTime = new Date().getTime();
  }

  /**
   * When the touch finally ends we will compare with when the touch was started.
   * This will determine if the move was prominant enough and if it left or right.
   *
   * @param {Event} e
   */
  handleEndTouch(e) {
    const { selected } = this.state;
    const { model } = this.props;
    const touchobj = e.changedTouches[0];
    this.distX = touchobj.pageX - this.startX;
    this.distY = touchobj.pageY - this.startY;
    this.elapsedTime = new Date().getTime() - this.startTime;
    if (this.elapsedTime <= this.allowedTime) {
      if (
        Math.abs(this.distX) >= this.threshold &&
        Math.abs(this.distY) <= this.restraint
      ) {
        const nextTab = selected + 1;
        const prevTab = selected - 1;
        if (this.distX < 0) {
          if (model.tabs.length - 1 !== selected) {
            fireUserInteraction('change_tab', 'change_tab', 'swipe');
            this.switchTab(nextTab, 'swipe');
          }
        } else if (selected > 0) {
          fireUserInteraction('change_tab', 'change_tab', 'swipe');
          this.switchTab(prevTab, 'swipe');
        }
      }
    }
  }

  /**
   * This will switch the tab. Because there is a mobile and desktop version
   * They will need to be synced so that it will be responsive. This will also
   * track if it is a swipe or a click.
   */
  switchTab(tabidx, interactionType = 'click') {
    if (this.contentTabs) {
      const currentSelectedTab = this.contentTabs.getElementsByTagName('span')[
        tabidx
      ];
      const activeBarLeft = currentSelectedTab.offsetLeft;
      this.setState({
        activeBarWidth: currentSelectedTab.offsetWidth,
        activeBarLeft,
      });

      this.resetTabs(tabidx, interactionType);
    }
  }

  /**
   * Tracks the change of a tab and sets the what the new tab pane should be.
   */
  resetTabs(tabidx, interactionType = 'click') {
    // Need to do this manually for now because of swipe
    const target = `tabs_${interactionType}`;
    fireUtagLink({
      event_name: target,
      tab_number: tabidx + 1,
    });
    this.setState({
      selected: tabidx,
    });
  }

  render() {
    const {
      model: { tabs, tabTitle, tabDescription },
    } = this.props;
    const { selected, activeBarWidth, activeBarLeft } = this.state;
    const PERCENT_RATIO = 100;
    const contentSize = {
      width: `${tabs.length * PERCENT_RATIO}%`,
    };
    const activeBarStyles = {
      width: `${activeBarWidth}px`,
      transform: `translateX(${activeBarLeft}px)`,
    };
    return (
      <div className="content-tabs cu-content-tabs" id="tabs">
        <div className="content-tabs--info">
          <h2 className="container-width section-headline">{tabTitle}</h2>
          {Boolean(tabDescription) && (
            <WithEvents.div
              className="content-tabs--description"
              dangerouslySetInnerHTML={{ __html: tabDescription }}
            />
          )}
        </div>
        <div className="content-tabs--tabs-wrapper">
          <div className="content-tabs--scroll">
            <div className="content-tabs--outer-container">
              <div
                className="content-tabs--active-bar"
                style={activeBarStyles}
              />
              <div
                className="content-tabs--inner-container"
                ref={(el) => (this.contentTabs = el)}
              >
                {tabs &&
                  tabs.map((item, index) => {
                    const UISpan = withUserInteraction(
                      'span',
                      `tabs_${index + 1}`,
                      'change_tab'
                    );
                    return (
                      <div
                        key={index}
                        className={`content-tabs--tab ${
                          selected === index ? 'active' : ''
                        }`}
                      >
                        <UISpan onClick={() => this.switchTab(index)}>
                          {item.name}
                        </UISpan>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="content-tabs--content-wrapper">
          <div
            className="content-tabs--content-panes"
            style={contentSize}
            onTouchStart={(e) => this.handleStartTouch(e)}
            onTouchEnd={(e) => this.handleEndTouch(e)}
          >
            {tabs &&
              tabs.map((item, index) => (
                <div
                  key={index}
                  className={`content-tabs--content-pane ${
                    selected === index ? 'active' : ''
                  }`}
                >
                  {item.tab && (
                    <MediaWithText model={item.tab} should_preload />
                  )}
                  {item.tabValueProps && (
                    <ValueProps model={item.tabValueProps} />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

ContentTabs.propTypes = {
  model: ContentTabsModelSchema,
};

export default ContentTabs;
