import { string, shape, number } from 'prop-types';

const SignupModalModelSchema = shape({
  name: string.isRequired,
  availableSeasons: number,
  episodeCount: number,
  ctaText: string,
  ctaUrl: string,
  ctaDownloadAppText: string,
  ctaHypeProgram: string,
  legalText: string,
});

export default SignupModalModelSchema;
