import classNames from 'classnames';
import React from 'react';

import { BillboardModelSchema } from '../model/schema';

import { enterKeyHandler } from '!app/lib/accessibilityUtils';
import { fireUtagLink } from '!app/metrics/fireEvent';
import { scrollToComponent } from '!app/welcome/utils/welcomeVariationUtils';

import '!app/components/Billboard/stylesheet/Billboard.scss';

const Billboard = (props) => {
  const { model } = props;

  const onDownArrowClick = () => {
    scrollToComponent(model?.componentToScrollToId);
  };

  return (
    <div className="Billboard">
      <div className={classNames({ Billboard__border: model?.hasBorder })}>
        <div className="Billboard__container">
          <div
            className="Billboard__eyebrow"
            dangerouslySetInnerHTML={{ __html: model?.eyebrow }}
          />
          <h2
            className="Billboard__headline"
            dangerouslySetInnerHTML={{ __html: model?.headline }}
          />
          <h3
            className="Billboard__subheadline"
            dangerouslySetInnerHTML={{ __html: model?.subheadline }}
          />
          <p
            className="Billboard__disclaimer section-disclaimer"
            dangerouslySetInnerHTML={{
              __html: model?.disclaimer,
            }}
          />
          {model?.modalLinkText && (
            <button
              aria-label="Open Zip Code Module"
              onClick={() => fireUtagLink({ event_name: 'zip_module' })}
              onKeyDown={enterKeyHandler(() =>
                fireUtagLink({ event_name: 'zip_module' })
              )}
              className="Billboard__modalLink"
              data-toggle="modal"
              data-target="#zip-modal"
            >
              {model?.modalLinkText}
            </button>
          )}
          <button
            aria-label="Scroll to next section"
            onClick={onDownArrowClick}
            className="Billboard__downArrow"
          />
        </div>
      </div>
    </div>
  );
};

Billboard.propTypes = {
  model: BillboardModelSchema.isRequired,
};

export default Billboard;
