import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DetailButtonSVG from '../../assets/DetailButtonSVG';

import PortraitBackground from './PortraitBackground';
import PortraitTitleArt from './PortraitTitleArt';

import { TITLE_ART_SIZES } from '!app/lib/constants';
import { getImageUrl, getImageUrls } from '!app/lib/imageUtils';
import { handleTileOnClick } from '!app/lib/TealiumEventsUtils';
import { trackEvent } from '!app/metrics/metricsTracker';
import TruncatedText from '!app/share/TruncatedText';

import '../../stylesheets/PortraitTile.scss';

const BRAND_WIDTH_LARGE = 500;
const BRAND_HEIGHT_LARGE = 500;
const BRAND_WIDTH = 100;
const BRAND_HEIGHT = 100;
const BRAND_FORMAT = 'webp';

export const createBrandUrl = (url) =>
  getImageUrl(url, {
    width: BRAND_WIDTH,
    height: BRAND_HEIGHT,
    format: BRAND_FORMAT,
  });
export const createBrandUrlLarge = (url) =>
  getImageUrl(url, {
    width: BRAND_WIDTH_LARGE,
    height: BRAND_HEIGHT_LARGE,
    format: BRAND_FORMAT,
  });

const getBackgroundPlaceholderStyle = () => ({
  backgroundImage: `linear-gradient(225deg, #306e6b 0%, #334272 45%, #231338 100%)`,
});

const PortraitTile = (props) => {
  const {
    altText,
    artwork,
    brandName,
    brandWatermark,
    collectionId,
    collectionIndex,
    customBackground,
    description,
    detailsAltText,
    detailsUrl,
    disableTileLink,
    entityId,
    horizontal,
    index,
    joinedTags,
    large,
    programArtwork,
    small,
    title,
    titleArtwork,
    type,
  } = props;

  const allowOnClick = !disableTileLink && detailsUrl;
  const portraitTileClasses = classnames('PortraitTile', {
    'PortraitTile--horizontal': horizontal,
    'PortraitTile--small': small,
    'PortraitTile--large': large,
    PortraitTile__clickable: allowOnClick,
  });

  const BackgroundElement = () => {
    if (artwork && customBackground) {
      return customBackground;
    }
    if (artwork) {
      return (
        <PortraitBackground
          className="PortraitTile"
          artwork={artwork}
          mobileArtwork={programArtwork}
          altText={altText}
          horizontal={horizontal}
        />
      );
    }
    return (
      <div className="PortraitTile__wrapper">
        <div
          className="PortraitTile__base"
          style={getBackgroundPlaceholderStyle()}
        >
          <span role="img" aria-label={altText} />
        </div>
      </div>
    );
  };

  const ActionButtons = () => {
    const portraitTitleClassName = classnames(
      'PortraitTile__btns',
      'PortraitTile__btns--hover'
    );

    return (
      <div className={portraitTitleClassName}>
        {/* TODO Remove this after HUWEB-5549 */}
        <a
          href={detailsUrl}
          onClick={() =>
            handleTileClickRedirect({ title, redirectUrl: detailsUrl })
          }
          aria-label={`link to ${title}`}
        >
          <div className="PortraitTile__sideDetailsBtn">
            <DetailButtonSVG
              height="40px"
              width="40px"
              title={detailsAltText}
            />
          </div>
        </a>
      </div>
    );
  };

  const getTitleArtUrls = () => {
    if (!titleArtwork) return undefined;
    return getImageUrls(titleArtwork, TITLE_ART_SIZES, 'webp', true);
  };

  const TitleElement = () => {
    const portraitTitleClassName = classnames(
      'PortraitTile__title PortraitTile__title--clickable',
      { 'PortraitTile__btns--hover': allowOnClick }
    );
    const titleArtUrls = getTitleArtUrls();
    return detailsUrl ? (
      <div
        className={portraitTitleClassName}
        aria-label={`link to ${title}`}
        onClick={() =>
          handleTileClickRedirect({
            isLink: true,
            title,
            redirectUrl: detailsUrl,
            isTitleClicked: true,
          })
        }
      >
        <PortraitTitleArt title={title} imageUrls={titleArtUrls} />
      </div>
    ) : (
      <h3 className="PortraitTile__title">
        <PortraitTitleArt title={title} imageUrls={titleArtUrls} />
      </h3>
    );
  };

  const fireUserInteractionEvent = (isTitleClicked) => {
    trackEvent('user_interaction', {
      element_specifier: isTitleClicked
        ? 'portrait_tile_title'
        : 'portrait_tile_thumbnail',
      action_specifier: disableTileLink ? 'static' : 'link',
      conditional_properties: ['collection', 'entity'],
      collection_id: collectionId,
      collection_source: 'heimdall',
      collection_item_index: index,
      collection_index: collectionIndex,
      entity_id: entityId,
      entity_action_id: entityId,
      entity_type: type,
      entity_action_type: 'browse',
      hit_version: '2.4.0',
    });
  };

  const handleTileClickRedirect = ({
    isLink = false,
    titleText = '',
    redirectUrl = '',
    isTitleClicked = false,
  }) => {
    if (disableTileLink) {
      return;
    }
    // fire utag link on portrait tile click
    handleTileOnClick(isLink, titleText);
    // fire interaction event
    fireUserInteractionEvent(isTitleClicked);
    // redirect user
    window.location.assign(redirectUrl);
  };

  const BrandWatermark = () => {
    const brandUrl = large
      ? createBrandUrlLarge(brandWatermark)
      : createBrandUrl(brandWatermark);
    return (
      <img
        className="PortraitTile__brand"
        src={brandUrl}
        role="presentation"
        alt={brandName}
      />
    );
  };

  const Description = () => {
    // make the content fit when hovering
    let maxLines = 3;
    if (horizontal) {
      maxLines = large ? 3 : 1;
    }
    return (
      <TruncatedText
        maxLines={maxLines}
        lineHeightEm={1.15}
        className="PortraitTile__description"
      >
        {description}
      </TruncatedText>
    );
  };

  const JoinedTags = () => {
    return (
      <TruncatedText
        lineHeightEm={1.5}
        maxLines={1}
        className="PortraitTile__joined-tags"
      >
        {joinedTags}
      </TruncatedText>
    );
  };

  return (
    <div className={portraitTileClasses}>
      <div
        className="PortraitTile__thumbnail"
        onClick={() =>
          handleTileClickRedirect({
            title,
            redirectUrl: detailsUrl,
          })
        }
      >
        <div className="PortraitTile__spacer" />
        <BackgroundElement />
        {brandWatermark && <BrandWatermark />}
        <div className="PortraitTile__metadataLayer">
          <div className="PortraitTile__metadata">
            <TitleElement />
            <div className="PortraitTile__hoverData">
              {description && description?.length && <Description />}
            </div>
            {joinedTags && joinedTags?.length && <JoinedTags />}
          </div>
          <div className="PortraitTile__actionBar">
            {allowOnClick && <ActionButtons />}
          </div>
        </div>
      </div>
    </div>
  );
};

PortraitTile.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  artwork: PropTypes.shape({}),
  titleArtwork: PropTypes.string,
  programArtwork: PropTypes.shape({}),
  customBackground: PropTypes.shape({}),
  detailsUrl: PropTypes.string,
  detailsAltText: PropTypes.node,
  brandWatermark: PropTypes.string,
  brandName: PropTypes.string,
  altText: PropTypes.string,
  small: PropTypes.bool,
  horizontal: PropTypes.bool,
  large: PropTypes.bool,
  joinedTags: PropTypes.string,
  disableTileLink: PropTypes.bool,
  collectionId: PropTypes.string,
  index: PropTypes.number,
  collectionIndex: PropTypes.number,
  entityId: PropTypes.string,
  type: PropTypes.string,
};

export default PortraitTile;
