import get from 'lodash/get';

import {
  PROGRAMS_WITH_CODE_EXCLUDE_DEVICE,
  PROGRAM_TYPE_DEVICE_CODE,
  HOTH_PREFILL_CODE_PARAM,
  PREFILL_CODE_PARAM,
} from '!app/lib/constants';

export const CODE_TYPE_DEVICE = 'device';
export const CODE_TYPE_PROMOTION = 'promotion';

export const getCodeType = (program) => {
  if (!program) {
    return null;
  }
  if (PROGRAMS_WITH_CODE_EXCLUDE_DEVICE.includes(program.type)) {
    return CODE_TYPE_PROMOTION;
  }
  if (program.type === PROGRAM_TYPE_DEVICE_CODE) {
    return CODE_TYPE_DEVICE;
  }
  return null;
};

/**
 * Grabs the prefill code by established precedence.
 * @param {Object} queryParams consistening of key, value
 */
export const getPrefillCode = (queryParams) =>
  get(queryParams, PREFILL_CODE_PARAM, false) ||
  get(queryParams, HOTH_PREFILL_CODE_PARAM, '');
