import { shape, string, arrayOf, boolean } from 'prop-types';

const ExpPlanComparisonFeaturePlanModelSchema = shape({
  slug: string,
  isApplicable: boolean,
});

const ExpPlanComparisonFeatureDescriptionModelSchema = shape({
  richText: string,
  modalId: string,
});

export const ExpPlanComparisonFeatureDisclaimerModelSchema = shape({
  id: string,
  richText: string,
  modalLinkText: string,
  modalId: string,
});

export const ExpPlanComparisonFeatureModelSchema = shape({
  slug: string,
  headline: string.isRequired,
  description: ExpPlanComparisonFeatureDescriptionModelSchema,
  disclaimer: ExpPlanComparisonFeatureDisclaimerModelSchema,
  plans: arrayOf(ExpPlanComparisonFeaturePlanModelSchema),
});

export const ExpPlanComparisonPricingRowModelSchema = shape({
  slug: string,
  headline: string.isRequired,
  bundleHeadline: string.isRequired,
  prices: arrayOf(shape({ text: string })),
});

export const ExpPlanComparisonPlanModelSchema = shape({
  slug: string,
  headline: string,
  eyebrow: string,
  badge: string,
  ctaText: string,
  mobileCtaText: string,
  ctaAction: string,
  ctaBtnStyle: string,
  program: string,
});

const FeatureDisclaimerSchema = shape({
  richText: string,
  modalLinkText: string,
  modalContent: string,
  isBundle: boolean,
});

export const ExpPlanComparisonChartWithToggleModelSchema = shape({
  headline: string.isRequired,
  description: string,
  addonsHeadline: string,
  addonsDescription: string,
  bundle: shape({
    leftHeadline: string,
    rightHeadline: string,
    modal: shape({
      id: string,
      header: string,
      disclaimer: string,
      sections: arrayOf(
        shape({
          title: string,
          items: arrayOf(
            shape({
              text: string,
              displayBullet: boolean,
            })
          ),
        })
      ),
    }),
  }),
  plans: arrayOf(ExpPlanComparisonPlanModelSchema).isRequired,
  bundlePlans: arrayOf(ExpPlanComparisonPlanModelSchema).isRequired,
  pricingRows: arrayOf(ExpPlanComparisonPricingRowModelSchema),
  features: arrayOf(ExpPlanComparisonFeatureModelSchema),
  bundleFeatures: arrayOf(ExpPlanComparisonFeatureModelSchema),
  featureDisclaimers: arrayOf(FeatureDisclaimerSchema),
  bundleFeatureDisclaimers: arrayOf(FeatureDisclaimerSchema),
  addons: arrayOf(ExpPlanComparisonFeatureModelSchema),
  bundleAddons: arrayOf(ExpPlanComparisonFeatureModelSchema),
  addonDisclaimers: arrayOf(FeatureDisclaimerSchema),
  bundleAddonDisclaimers: arrayOf(FeatureDisclaimerSchema),
});
