/** @jsx jsx */
import { jsx, css, useTheme, ThemeProvider } from '@emotion/react';
import { ChevronDownIcon, ChevronUpIcon } from '@hulu/web-ui-icons';
import { oneHuluLightTheme } from '@hulu/web-ui/style/createTheme';
import classnames from 'classnames';
import { useReducer } from 'react';

import '../stylesheets/Dropdown.scss';

const Dropdown = (props) => {
  const { items, label, onSelect, activeIndex } = props;
  const theme = useTheme();
  const typography = theme.typography.css;

  if (!items) {
    return null;
  }

  const initialState = {
    showMenu: false,
    menuLabel: label,
    activeIndex,
  };

  const menuReducer = (state, action) => {
    if (action.type === 'toggleOnly') {
      return {
        showMenu: !state.showMenu,
        menuLabel: state.menuLabel,
        activeIndex: state.activeIndex,
      };
    }
    return {
      showMenu: false,
      menuLabel: action.menuItem.name,
      activeIndex: action.menuItem.number,
    };
  };

  const [state, dispatch] = useReducer(menuReducer, initialState);

  const buttonStyles = css(typography.body14.styles);

  const onItemClick = (item) => {
    onSelect(item.number);
    dispatch({ type: 'updateActive', menuItem: item });
  };

  return (
    <div className="Dropdown">
      <button
        tabIndex="0"
        className={classnames('Dropdown__button', state.showMenu && 'isOpen')}
        css={buttonStyles}
        onClick={() => dispatch({ type: 'toggleOnly' })}
      >
        {state.menuLabel}
        {state.showMenu ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </button>
      {state.showMenu && (
        <ul className="Dropdown__menu">
          {items.map((item) => (
            <li
              className={classnames(
                'Dropdown__menu-item',
                activeIndex === item.number && 'active'
              )}
            >
              <button
                tabIndex="0"
                css={buttonStyles}
                onClick={() => onItemClick(item)}
                value={item.number}
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const ThemedDropdown = (props) => (
  <ThemeProvider theme={oneHuluLightTheme}>
    <Dropdown {...props} />
  </ThemeProvider>
);

export default ThemedDropdown;
