export const CollectionQuery = `... on CollectionComponent {
  model {
    collections {
      ...collectionFields
    }
  }
}`;

export const CollectionFieldsFragment = `fragment collectionFields on Collection {
  id
  href
  name
  theme
  enabledTileLinks
  enableSignupModal
  collectionHubPath
  seasons {
    name
    number
  }
  items {
    ...entityFields
  }
}`;

export const CollectionsModalCollectionFieldsFragment = `fragment collectionsModalCollectionFields on Collection {
  id
  href
  name
  theme
  enabledTileLinks
  enableSignupModal
  items {
    ...collectionsModalEntityFields
  }
}`;
