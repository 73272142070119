import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { string, bool } from 'prop-types';
import React, { Component } from 'react';
import './MastheadVideo.scss';

const MOBILE_NAVBAR_ADJUSTMENT = 200; // Adjustment for navbar that shows up on actual mobile device
const RESIZE_DELAY_MS = 500;
const WIDTH_RATIO = 16;
const RATIO_9x16 = 9 / WIDTH_RATIO;
const RATIO_16x9 = WIDTH_RATIO / 9;

class MastheadVideo extends Component {
  state = {
    isLandscape: true,
    videoDimensions: null,
  };

  constructor(props) {
    super(props);
    this.checkResize = ::this.checkResize;
  }

  componentDidMount() {
    this.checkResize();
    if (window)
      window.addEventListener(
        'resize',
        throttle(this.checkResize, RESIZE_DELAY_MS)
      );
  }

  componentWillUnmount() {
    if (window) window.removeEventListener('resize', this.checkResize);
  }

  getVideoDimensions() {
    const { innerWidth, innerHeight } = window;

    const aspectRatio = RATIO_16x9;
    let aspectWidth = innerHeight * aspectRatio + MOBILE_NAVBAR_ADJUSTMENT;
    let aspectHeight = innerHeight + MOBILE_NAVBAR_ADJUSTMENT;
    if (innerWidth / innerHeight > aspectRatio) {
      aspectWidth = innerWidth;
      aspectHeight = innerWidth / aspectRatio;
    }

    return {
      width: aspectWidth,
      height: aspectHeight,
      opacity: 1,
    };
  }

  checkResize() {
    const { inModal } = this.props;
    const currentAspect = window && window.innerWidth / window.innerHeight;
    const MEDIUM_WIDTH = 768;
    const isLandscape = inModal
      ? window && window.innerWidth >= MEDIUM_WIDTH
      : RATIO_9x16 < currentAspect;
    const videoDimensions = !inModal ? this.getVideoDimensions() : null;

    this.setState({
      isLandscape,
      videoDimensions,
    });
  }

  useVideo916() {
    const currentAspect = window && window.innerWidth / window.innerHeight;
    return 9 / WIDTH_RATIO >= currentAspect;
  }

  render() {
    const { landscapeVideo, portraitVideo, inModal } = this.props;
    const { isLandscape, videoDimensions } = this.state;
    const video = isLandscape ? landscapeVideo : portraitVideo;
    const videoClass = classNames('MastheadVideo__video', {
      'MastheadVideo__video--landscape': isLandscape,
      'MastheadVideo__video--portrait': !isLandscape,
      'lazyload lazypreload': inModal,
    });

    return (
      <div className="MastheadVideo">
        {video && (
          <iframe
            src={`${video}?background=1&autoplay=1&loop=1&byline=0&title=0`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            className={videoClass}
            style={videoDimensions}
            key={video}
          />
        )}
      </div>
    );
  }
}

MastheadVideo.propTypes = {
  landscapeVideo: string,
  portraitVideo: string,
  inModal: bool,
};

export default MastheadVideo;
