import classNames from 'classnames';

import { ExpGridModelSchema } from '../model/schema';

import ExpGridRow from './ExpGridRow';

import '../stylesheet/ExpGridStyle.scss';

const ExpGrid = (props) => {
  const {
    model: { isSection, rows, verticalAlign },
  } = props;
  // Used to apply standard Hulu Section styles to a grid container
  const isSectionClass = { 'is-section': isSection };

  return (
    <div className={classNames('exp-grid', isSectionClass)}>
      {rows?.map((row, index) => (
        <ExpGridRow key={index} model={{ verticalAlign, ...row }} />
      ))}
    </div>
  );
};

ExpGrid.propTypes = {
  model: ExpGridModelSchema,
};

export default ExpGrid;
