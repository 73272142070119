import { connect } from 'react-redux';

import CallToAction from './view/CallToAction';

import { KEY as CTAFIELDS } from '!app/redux/ctaFields';
import ctaFieldsActionHandler from '!app/redux/ctaFields/action-handlers';
import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

const view = connect((state) => ({
  user: state[USER],
  ctaFields: state[CTAFIELDS],
}))(CallToAction);

const reducerMap = {
  [USER]: userActionHandler,
  [CTAFIELDS]: ctaFieldsActionHandler,
};

export { view, reducerMap };
