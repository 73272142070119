import { HuluLogin, LoginModal } from '@hulu/login-modal';
import { connect } from 'react-redux';

import LoginForm from './view/LoginForm';

import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';
import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

// TODO: Legacy login remove #HUWEB-30424
const {
  stateKey: HULU_LOGIN,
  actionHandlers: huluLoginActionHandlers,
} = HuluLogin;

const {
  stateKey: LOGIN_MODAL,
  actionHandlers: loginModalActionHandlers,
} = LoginModal;

const view = connect((state) => {
  return {
    user: state[USER],
  };
})(LoginForm);

const reducerMap = {
  [USER]: userActionHandler,
  [HULU_LOGIN]: huluLoginActionHandlers,
  [LOGIN_MODAL]: loginModalActionHandlers,
  [MODAL]: modalActionHandler,
};

export { view, reducerMap };
