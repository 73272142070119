import { useEffect, useState, useRef } from 'react';

import { IMAGE_STATUSES } from '!app/lib/constants';

const DEFAULT_RETRIES = 3;
const DEFAULT_TIMEOUT = 1000; // 1 second

const DEFAULT_IMAGE_LOAD_CONFIG = {
  retries: DEFAULT_RETRIES,
  timeout: DEFAULT_TIMEOUT,
};

const useImageLoadStatus = (
  imageRef,
  imageLoadConfig = DEFAULT_IMAGE_LOAD_CONFIG
) => {
  const [imageStatus, setImageStatus] = useState(IMAGE_STATUSES.LOADING);
  const retries = useRef(0);

  const isRetrying = imageStatus === IMAGE_STATUSES.RETRYING;
  const isLoaded = imageStatus === IMAGE_STATUSES.LOADED;
  const isLoading =
    imageStatus === IMAGE_STATUSES.LOADING ||
    imageStatus === IMAGE_STATUSES.RETRYING;
  const hasError = imageStatus === IMAGE_STATUSES.ERROR;

  // Override defaults if config is set
  const thisConfig = { ...DEFAULT_IMAGE_LOAD_CONFIG, ...imageLoadConfig };

  useEffect(() => {
    // Handle image error if it exists
    const handleImageError = () => {
      // Exit if no more retries can be done
      if (retries.current >= thisConfig.retries) {
        setImageStatus(IMAGE_STATUSES.ERROR);
        return;
      }

      // Attempt an image load retry
      setImageStatus(IMAGE_STATUSES.RETRYING);

      retries.current++;

      const timerId = setTimeout(() => {
        // Forces the image to reload/retry. Resetting the src value triggers the image to be reloaded with the given source
        const imgSrc = image.src;
        image.src = imgSrc;

        // Removes itself from the list of timerIds
        timerIds.splice(timerIds.indexOf(timerId), 1);
      }, thisConfig.timeout);
      timerIds.push(timerId);
    };

    const handleImageLoaded = () => {
      // This should only run once per image
      if (!isLoaded) {
        setImageStatus(IMAGE_STATUSES.LOADED);
      }
    };

    // Exit early if no reference exists or detected load complete
    if (!imageRef?.current || isLoaded) {
      return undefined;
    }

    // Keep a stable reference to the current image
    const image = imageRef.current;
    const timerIds = [];

    image.addEventListener('error', handleImageError);
    image.addEventListener('load', handleImageLoaded, { once: true });

    // This is needed because we server side render the html. Image requests are made before the react app can hydrate.
    // It can sometimes work depending on how long the request takes but the race condition possiblity requires this.
    // In this case check the image properties to determine if loading has completed and no reload attempts exist.
    if (
      image &&
      image.complete &&
      timerIds.length === 0 &&
      (isLoading || isRetrying)
    ) {
      // If the uncaught image load failed the natural width will be 0
      if (image.naturalWidth === 0) {
        // Assume an error happened on the initial load and try again
        handleImageError();
        return undefined;
      }

      // Image loaded fine before detection so update status
      handleImageLoaded();
    }

    return () => {
      image.removeEventListener('error', handleImageError);
      image.removeEventListener('load', handleImageLoaded);
      // Cleanup pending setTimeout's. We use `splice(0)` to clear the list.
      for (const timerId of timerIds.splice(0)) {
        clearTimeout(timerId);
      }
    };
  }, [imageRef, retries]);

  // Handle functions included for cases where they need to be manually envoked
  return {
    isLoaded,
    isLoading,
    isRetrying,
    hasError,
  };
};

export { useImageLoadStatus };
