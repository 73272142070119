import { PropTypes, string, shape, arrayOf } from 'prop-types';

export const SupportedDevicesSchema = PropTypes.shape({
  model: shape({
    description: PropTypes.string,
    headline: PropTypes.string,
    disclaimerDesktop: PropTypes.string,
    disclaimerMobile: PropTypes.string,
    styleMode: PropTypes.string,
    deviceTabs: arrayOf(
      shape({
        title: string,
        devices: arrayOf(
          shape({
            icon: string,
            label: string,
          })
        ),
      })
    ),
  }),
});
