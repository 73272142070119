import Text from '@hulu/web-ui/Text';
import isEmpty from 'lodash/isEmpty';
import { shape, string, arrayOf, boolean } from 'prop-types';

import ExpPlanComparisonBundleHeaderModal from '../../ExpPlanComparisonChart/view/ExpPlanComparisonBundleHeaderModal';

import { useExpPlanComparisonChartContext } from '!app/experimental/components/ExpPlanComparisonChart/view/ExpPlanComparisonChartContext';
import { REGISTER_MODALS_EVENT } from '!app/lib/constants';
import { fireUserInteraction } from '!app/metrics';
import ToggleSwitch from '!app/share/ToggleSwitch';

import '../stylesheet/ExpPlanComparisonBundleHeader.scss';

const BUNDLE_CHANGE_DELAY_TIME = 100;

const ExpPlanComparisonBundleHeader = (props) => {
  const {
    bundle: { leftHeadline, rightHeadline, modal },
    isDark,
  } = props;
  const hasModal = !isEmpty(modal);
  const { isBundle, setIsBundle } = useExpPlanComparisonChartContext();
  const eventName = `comparison_chart:${
    isBundle ? 'base_toggle' : 'bundle_toggle'
  }`;

  const handleBundleChange = () => {
    setIsBundle(!isBundle);
    fireUserInteraction(
      eventName,
      'driver_click',
      'click',
      true,
      isBundle ? 'Base Plans' : 'Bundle Save'
    );
    const event = new CustomEvent(REGISTER_MODALS_EVENT);
    setTimeout(() => {
      document.dispatchEvent(event);
    }, BUNDLE_CHANGE_DELAY_TIME);
  };

  return (
    <div className="bundle-header-with-new-toggle">
      <div className="bundle-header-with-new-toggle__left-headline">
        <div className="bundle-header-with-new-toggle__left-top-headline">
          <Text variant="subtitle14">{leftHeadline}</Text>
        </div>
        <div className="bundle-header-with-new-toggle__left-bottom-headline">
          <img
            src="/static/hitch/static/logos/hulu-logo.svg"
            role="presentation"
            alt="hulu-logo"
          />
        </div>
      </div>

      <div className="bundle-header-with-new-toggle__toggle-container">
        <ToggleSwitch
          isOn={isBundle}
          onColor={isDark ? '#1CE783' : '#040405'}
          handleToggle={handleBundleChange}
        />
      </div>

      <div className="bundle-header-with-new-toggle__right-headline">
        <div className="bundle-header-with-new-toggle__right-top-headline">
          <Text variant="subtitle14">{rightHeadline}</Text>
          {hasModal && (
            <span>
              <a data-toggle="modal" data-target={`#${modal.id}`} role="button">
                <img
                  src={
                    isDark
                      ? '/static/hitch/static/icons/Info_Icon_White.svg'
                      : '/static/hitch/static/icons/Info_Icon_Dark_Grey.svg'
                  }
                  role="presentation"
                  alt={rightHeadline}
                />
              </a>
            </span>
          )}
        </div>
        <div className="bundle-header-with-new-toggle__right-bottom-headline">
          <img
            src={
              isDark
                ? '/static/hitch/static/logos/bundles-dark.svg'
                : '/static/hitch/static/logos/bundles.svg'
            }
            role="presentation"
            alt="logos"
          />
        </div>
      </div>
      {hasModal && <ExpPlanComparisonBundleHeaderModal modal={modal} />}
    </div>
  );
};

ExpPlanComparisonBundleHeader.propTypes = {
  bundle: shape({
    headline: string,
    description: string,
    modal: shape({
      id: string,
      header: string,
      disclaimer: string,
      sections: arrayOf(
        shape({
          title: string,
          items: arrayOf(
            shape({
              text: string,
              displayBullet: boolean,
            })
          ),
        })
      ),
    }),
  }),
};

export default ExpPlanComparisonBundleHeader;
