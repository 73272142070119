import React, { Component } from 'react';

import { ValuePropsModelSchema } from '../model/schema';

import ValueProp from './ValueProp';

import '../stylesheet/ValuePropsStyle.scss';

class ValueProps extends Component {
  render() {
    const {
      model: { items, style },
    } = this.props;
    return (
      <div
        id="value-props"
        className="value-props--wrapper cu-value-props"
        style={style}
        data-automationid="value_props"
      >
        <div className="content container-width">
          {items &&
            items.map((item, index) => (
              <ValueProp key={index} model={item} uniqueKey={index} />
            ))}
        </div>
      </div>
    );
  }
}

ValueProps.propTypes = {
  model: ValuePropsModelSchema.isRequired,
};

export default ValueProps;
