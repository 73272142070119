import get from 'lodash/get';

import Entity from './Entity';

class Episode extends Entity {
  constructor(entity) {
    super(entity);
  }

  getData() {
    const common = super.getData();
    const data = {
      ...common,
      thumbnail: get(this._entity, 'artwork.horizontalHero.path', null),
      episode: get(this._entity, 'number'),
    };

    return data;
  }
}

export default Episode;
