import Badge from '@hulu/web-ui/Badge';
import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import 'isomorphic-fetch';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';

import { view as Modal } from '!app/components/Modal';
import { Translate } from '!app/i18n';
import { getHost } from '!app/lib/config';
import { getDevice } from '!app/lib/deviceUtils';
import { withCustomInteraction, withUtagLink } from '!app/metrics/hoc';

import '../stylesheet/NetworksSearchModalStyle.scss';

const NetworksSearchModal = (props) => {
  const {
    locale,
    modalId,
    sharedZipCode,
    showResultsOnOpen,
    updateParent,
  } = props;
  const [zipcode, setZipcode] = useState(sharedZipCode || '');
  const [results, setResults] = useState([]);
  const [showDetailedInstructions, setShowDetailedInstructions] = useState(
    true
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isModalJustOpened, setIsModalJustOpened] = useState(false);
  const [isInvalidZipcode, setIsInvalidZipcode] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const translator = new Translate(locale);
  const translatedMessage = translator.translateAll(messages);
  const {
    channels_in_area_lower,
    enter_your_home,
    placeholder,
    button,
    disclaimer,
    disclaimer_detailed,
    disclaimer_ie,
    on_demand,
    disclaimer_on_demand,
    error_invalid,
    error_try_later,
  } = translatedMessage;
  const isIE = ['Netscape', 'Microsoft Internet Explorer'].includes(
    getDevice().browser
  );
  const resultsRef = useRef(null);
  const zipCodeInputRef = useRef(null);

  const handleChangeZipCode = (e) => {
    setZipcode(e.target.value);
    setIsInvalidZipcode(false);
  };

  const resetForm = () => {
    // Call updateParent callback function only if defined
    if (updateParent && zipcode) {
      updateParent(zipcode, isInvalidZipcode);
    }
    setResults([]);
    clearZip();
  };

  const initializeForm = () => {
    // In some instances we want to show network results as soon as the modal is shown as long as we have a shared zipcode
    if (showResultsOnOpen && sharedZipCode) {
      setIsModalJustOpened(true);
      setZipcode(sharedZipCode);
    }
  };

  const clearZip = () => {
    setZipcode('');
    setShowDetailedInstructions(true);
    setIsLoading(false);
    setIsInvalidZipcode(false);
    setIsServerError(false);
  };

  const setInvalidZipcodeError = () => {
    setIsInvalidZipcode(true);
    setIsLoading(false);
  };

  const setServerError = () => {
    setIsServerError(true);
    setIsLoading(false);
    zipCodeInputRef?.current?.blur();
  };

  const clearResults = () => {
    setResults([]);
    setIsServerError(false);
    setIsInvalidZipcode(false);
  };

  const updateResults = (resultsArray) => {
    setResults(resultsArray);
    setIsLoading(false);
    zipCodeInputRef?.current?.blur();
  };

  const submitSearch = (e) => {
    if (e) e?.preventDefault();

    const { filterChannel } = props;
    clearResults();

    if (!isValidZipcode(zipcode)) {
      setInvalidZipcodeError();
      return;
    }

    setIsLoading(true);
    setShowDetailedInstructions(false);

    const site = getHost('site').replace(
      /^http(s)?:/i,
      window.location.protocol
    );

    fetch(
      `${site}/api/3.0/channels/local/${zipcode}?group_by=category&be_localsonly=1`
    )
      .then((res) => res.json())
      .then(
        (data) => {
          if (data && data.length > 0) {
            // We can pass along promoted channel types dynamically for the welcome experiments through the JSON
            if (filterChannel) {
              filterPromotedChannelResults(data, filterChannel);
            } else {
              const { model: { promoted_channel } = {} } = props;
              filterPromotedChannelResults(data, promoted_channel);
            }
          } else {
            setInvalidZipcodeError();
          }
        },
        () => {
          setServerError();
        }
      )
      .then(() => {
        resultsRef?.current?.focus();
      });
  };

  const filterPromotedChannelResults = (data, promoted_channel) => {
    const found = data.findIndex(
      (channel) => channel.name === promoted_channel
    );
    const NOT_FOUND_INDEX = -1;
    const channels =
      found !== NOT_FOUND_INDEX ? [...data.splice(found, 1), ...data] : data;
    updateResults(channels);
  };

  /*
   * Validates if the zip code is numeric and has a length of 5 characters
   */
  const isValidZipcode = (value) => {
    return value.match(/\b\d{5}\b/);
  };

  const getBackgroundStyle = (channel) => {
    if (channel.artwork.path) {
      return {
        backgroundImage: `url(${channel.artwork.path})`,
      };
    }
    return {};
  };

  const getChannelsMapping = (group) => {
    const map = new Map([
      ['Sports Channels', 'channels_sports'],
      ['Live Local Channels', 'channels_local'],
      ['Entertainment & Lifestyle Channels', 'channels_entertainment'],
      ['Family & Kids Channels', 'channels_family'],
      ['Movies Channels', 'channels_movies'],
      ['News Channels', 'channels_news'],
      ['Add-on Premium Channels', 'channels_premium'],
      ['Entertainment Add-on', 'entertainment_addon'],
      ['Español Add-on', 'espanol_addon'],
      ['Sports Add-on', 'sports_addon'],
    ]);
    return map.get(group);
  };

  const SearchButton = withUtagLink(
    {
      event_name: 'zip_module_search',
      zip_code: zipCodeInputRef?.current?.value
        ? isValidZipcode(zipCodeInputRef.current.value)
          ? zipCodeInputRef.current.value
          : null
        : null,
    },
    withCustomInteraction('button', 'home_zip_code_check', {
      zip_code: zipCodeInputRef?.current?.value
        ? isValidZipcode(zipCodeInputRef.current.value)
          ? zipCodeInputRef.current.value
          : null
        : null,
    })
  );

  // Ensures that this is checked every time the modal is opened
  useEffect(() => {
    // Requires the showResultsOnOpen flag to be set, a sharedZipCode to exist and the modal to have just opened to perform
    // a show or results on modal open
    if (showResultsOnOpen && sharedZipCode && isModalJustOpened) {
      // Initialize form on open to shared zipcode if one is provided
      submitSearch(null);
      // Legal has decided that the zipcode input field should NOT display the automatically detected zip code to the user
      // This will clear the automatically detected zipcode value out
      setIsModalJustOpened(false);
      clearZip();
    }
  }, [zipcode, isModalJustOpened]);

  return (
    <Modal
      model={{ id: modalId || 'zip-modal' }}
      onModalOpen={initializeForm}
      onModalClosed={resetForm}
      deepLinkAnchor="allchannels"
    >
      <div className="networks">
        <Text as="h2" variant="title-32" className="networks-title">
          {channels_in_area_lower}
        </Text>
        <label htmlFor="zipcode" className="networks-subtitle body">
          <Text variant="body16">{enter_your_home}</Text>
        </label>

        <form onSubmit={submitSearch}>
          <div className="networks-form">
            <span>
              <input
                type="tel"
                id="zipcode-input"
                className={`zipcode-input
                  ${isInvalidZipcode ? 'error' : null}`}
                value={zipcode}
                maxLength="5"
                placeholder={placeholder}
                onChange={handleChangeZipCode}
                aria-invalid={isInvalidZipcode ? 'true' : 'false'}
                ref={zipCodeInputRef}
              />

              {!isLoading && (
                <SearchButton
                  className="submit-button button--black"
                  type="submit"
                >
                  {button}
                </SearchButton>
              )}

              {isLoading && <span className="icon loading-icon" />}
            </span>
            {isInvalidZipcode && (
              <span className="invalid-zip-code">{error_invalid}</span>
            )}
          </div>
        </form>

        <Text as="p" variant="body-12" className="legal-advice">
          {showDetailedInstructions ? disclaimer : disclaimer_detailed}
          {isIE && (
            <span>
              <br />
              <br />
              {disclaimer_ie}
            </span>
          )}
        </Text>

        <div ref={resultsRef}>
          {isServerError && (
            <Text as="p" variant="body-14" className="server-error">
              {error_try_later}
            </Text>
          )}

          {results && results.length > 0 && (
            <div className="channels-container">
              {results.map((category, categoryIndex) => (
                <div key={categoryIndex} className="channel-category">
                  <Text
                    as="h3"
                    variant="subtitle-18"
                    className="channel-category-title"
                  >
                    {translator.translate(
                      messages[getChannelsMapping(category.name)]
                    )}
                  </Text>
                  <div
                    className="network-icons-group"
                    role="list"
                    aria-label={`List of ${translator.translate(
                      messages[getChannelsMapping(category.name)]
                    )}`}
                  >
                    {category.channels.map((channel, index) => (
                      <div
                        className="NetworkIcon"
                        key={`${categoryIndex}.${index}`}
                        role="listitem"
                        aria-label={channel.network_name}
                      >
                        <div className="NetworkIcon__frame">
                          <span
                            className={`NetworkIcon__logo ${
                              channel.artwork.be_source
                                ? 'NetworkIcon__logo-be'
                                : ''
                            }`}
                            style={getBackgroundStyle(channel)}
                          >
                            <span
                              className={
                                channel.artwork.path
                                  ? `NetworkIcon__network-name-invisible`
                                  : ''
                              }
                            >
                              {channel.network_name}
                            </span>
                          </span>
                        </div>
                        {channel.on_demand && (
                          <div className="NetworkIcon__badge">
                            <Badge
                              size="small"
                              variant="badge2"
                              css={{
                                padding: '0',
                                borderRadius: '4px',
                                backgroundColor: 'rgb(247, 247, 249)',
                                border: '1px solid rgb(221, 224, 230)',
                              }}
                            >
                              <span
                                className="HuluTooltip"
                                data-tip
                                data-for="sadFace"
                              >
                                {on_demand}
                              </span>
                            </Badge>
                            <ReactTooltip
                              id="sadFace"
                              place="right"
                              type="light"
                              effect="solid"
                            >
                              <div className="onDemandTooltip">
                                <div>{disclaimer_on_demand}</div>
                              </div>
                            </ReactTooltip>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

NetworksSearchModal.propTypes = {
  model: PropTypes.shape({
    promoted_channel: PropTypes.string,
  }),
  locale: PropTypes.string,
  sharedZipCode: PropTypes.string,
  filterChannel: PropTypes.string,
  showResultsOnOpen: PropTypes.bool,
  modalId: PropTypes.string,
  updateParent: PropTypes.func,
};

NetworksSearchModal.defaultProps = {
  locale: 'en-us',
};

export default NetworksSearchModal;
