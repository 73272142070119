import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import {
  MASTHEAD_VERTICAL_SIZES,
  MASTHEAD_HORIZONTAL_SIZES,
  BREAKPOINTS,
} from '!app/lib/constants';
import { useWindowResize } from '!app/lib/hooks/useWindowResize';
import { getImageUrls } from '!app/lib/imageUtils';
import '../stylesheets/MastheadTileBackground.scss';

const MastheadTileBackground = (props) => {
  const { artwork, className, mobileArtwork, title } = props;
  const {
    windowSize: { width },
  } = useWindowResize();

  const hsla = ([h, s, l], alpha = 1) => {
    return `hsla(${h}, ${s}%, ${l}%, ${alpha})`;
  };

  const getBackgroundArt = () => {
    const mobileArtworks = getImageUrls(
      get(mobileArtwork, 'path'),
      MASTHEAD_VERTICAL_SIZES,
      'webp'
    );
    const artworks = getImageUrls(
      get(artwork, 'path'),
      MASTHEAD_HORIZONTAL_SIZES,
      'webp'
    );
    const imageUrls = { ...mobileArtworks, ...artworks };

    return (
      <picture className={`${className}__art`}>
        <source
          media={`(min-width: ${BREAKPOINTS.XXLARGE}px)`}
          srcSet={`${imageUrls.xxlarge} 1x, ${imageUrls.xxlarge_retina} 2x`}
        />
        <source
          media={`(min-width: ${BREAKPOINTS.XLARGE}px)`}
          srcSet={`${imageUrls.xlarge} 1x, ${imageUrls.xlarge_retina} 2x`}
        />
        <source
          media={`(min-width: ${BREAKPOINTS.LARGE}px)`}
          srcSet={`${imageUrls.large} 1x, ${imageUrls.large_retina} 2x`}
        />
        <source
          media={`(min-width: ${BREAKPOINTS.MEDIUM}px)`}
          srcSet={`${imageUrls.medium} 1x, ${imageUrls.medium_retina} 2x`}
        />
        <source
          media={`(min-width: ${BREAKPOINTS.SMALL}px)`}
          srcSet={`${imageUrls.small} 1x, ${imageUrls.small_retina} 2x`}
        />
        <img
          src={imageUrls.xsmall}
          srcSet={`${imageUrls.xsmall} 1x, ${imageUrls.xsmall_retina} 2x`}
          alt={title}
          aria-label={title}
          role="presentation"
        />
      </picture>
    );
  };

  const getBackgroundGradientStyle = () => {
    const hue = get(props, 'artwork.hue', 0);
    const BASE_COLOR = {
      h: hue,
      s: 50,
      l: 40,
    };
    const ALPHA = 0.9;
    const baseColor = [BASE_COLOR.h, BASE_COLOR.s, BASE_COLOR.l];
    const colorFrom1 = hsla(baseColor, 0);
    const colorFrom2 = hsla(baseColor, ALPHA);
    const colorFrom3 = hsla(baseColor, 1);
    const mobileBackground = {
      background: `linear-gradient(180deg, ${colorFrom1} 30%, ${colorFrom2} 75%, ${colorFrom3} 100%)`,
    };
    const desktopBackground = {
      background: `linear-gradient(90deg, ${colorFrom3} 0%, ${colorFrom2} 35%, ${colorFrom1} 100%)`,
    };
    return width < BREAKPOINTS.MEDIUM ? mobileBackground : desktopBackground;
  };

  return (
    <div className={`${className}__wrapper`}>
      <div className={`${className}__base`} />
      {getBackgroundArt()}
      <div className={`${className}__scrim`} />
      <div
        className={`${className}__gradient`}
        style={getBackgroundGradientStyle()}
      />
    </div>
  );
};

MastheadTileBackground.propTypes = {
  className: PropTypes.string,
  artwork: PropTypes.shape({}),
  mobileArtwork: PropTypes.shape({}),
  title: PropTypes.string,
};

export default MastheadTileBackground;
