export const ACTION_TYPE_INIT_CTAFIELDS = 'ACTION_TYPE_INIT_CTAFIELDS';

export const init = (initPayload) => {
  return {
    type: ACTION_TYPE_INIT_CTAFIELDS,
    payload: {
      initPayload,
    },
  };
};
