import 'isomorphic-fetch';
import { getHost } from '!app/lib/config';
import { STATUS_CODES, USER_AGENTS_REGEX } from '!app/lib/constants';
import { eraseCookie, getCookie } from '!app/lib/cookies';
import { getDevice } from '!app/lib/deviceUtils';
import { mobileDetect } from '!app/lib/environment';
import { ServiceLogger } from '!app/lib/logger';
import { fetchWithTimeout } from '!app/lib/utils';

const FETCH_TIMEOUT = 15000; // in milliseconds
const ACTIVATION_AUTH_PATH = '/v1/web/code/authenticate';
const ACTIVATION_CODE_PATH = '/v1/web/code/register';

/**
 * Builds the code request form data object
 * @returns {FormData} Object containing form data
 */
const buildCodeRequestData = async () => {
  // The API expects _h_csrf_id & guid cookies to exist
  // csrf token and cookie must also exist
  const csrf = await generateToken(ACTIVATION_CODE_PATH);
  // Determine if the current device is Tesla, GM or other
  const deviceName = mobileDetect().match(`${USER_AGENTS_REGEX.TESLA}`)
    ? 'TESLA'
    : mobileDetect().match(`${USER_AGENTS_REGEX.GM}`)
    ? 'GM'
    : 'BROWSER';

  return (
    `csrf=${csrf}` +
    `&product_name=${deviceName}-PWA` +
    `&affiliate_name=hulu` +
    `&friendly_name=${deviceName}-PWA`
  );
};

/**
 * Fetches the activation code to be used for a connected auth.
 * @return {String} Activation code
 */
export const fetchActivationCode = async () => {
  const logMetaData = { logName: 'fetchActivationCode' };
  const anonymousToken = getCookie('_hulu_at');

  // If user is not anonymous they should not be doing a connected login
  if (!anonymousToken) {
    ServiceLogger.debug(
      'No anonymous token found! Skipping connected login.',
      {},
      logMetaData
    );

    // Do NOT call FetchActivationCode if not anonymous user
    return null;
  }

  const endpoint = getHost('hoth');
  const activationData = await buildCodeRequestData();
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: activationData,
  };

  try {
    // Request code from registration API
    const response = await fetchWithTimeout(
      `${endpoint}${ACTIVATION_CODE_PATH}`,
      options,
      FETCH_TIMEOUT
    );

    if (response.ok) {
      const responseData = await response.json();
      // Return the activation code from the api response
      return responseData?.data?.code;
    }

    // Assumes API error response
    ServiceLogger.error(
      `fetchActivationCode: Error received from ${endpoint}${ACTIVATION_CODE_PATH}`,
      response.status,
      logMetaData
    );
  } catch (error) {
    // Assumes network failure
    ServiceLogger.error(
      `fetchActivationCode: Fetch error from ${endpoint}${ACTIVATION_CODE_PATH}`,
      error,
      logMetaData
    );
  }

  return null;
};

/**
 * Builds the auth request form data object
 * @param {String} authCode code to lookup
 * @returns {FormData} Object containing form data
 */
const buildAuthRequestData = async (authCode) => {
  // The API expects _h_csrf_id, _hulu_at & guid cookies to exist
  // csrf token and cookie must also exist
  const csrf = await generateToken(ACTIVATION_AUTH_PATH);
  const HULU_ASSIGNMENTS = 'HULU_ASSIGNMENTS';
  // Determine if the current device is Tesla, GM or other
  const deviceName = mobileDetect().match(`${USER_AGENTS_REGEX.TESLA}`)
    ? 'Tesla'
    : mobileDetect().match(`${USER_AGENTS_REGEX.GM}`)
    ? 'GM'
    : 'Browser';

  const dataProperties = {
    device_capabilities: {
      device: {
        'hulu:app:web': __APP_VERSION__,
        [`hulu:runtime:browser:${getDevice()?.deviceType}`]: '',
        [`hulu:platform:browser:${getDevice()?.os}`]: getDevice()?.osVersion,
      },
    },
    app_version: __APP_VERSION__,
    device_platform: getDevice()?.os,
    device_type: getDevice()?.deviceType,
    device_os: `${getDevice()?.os} ${getDevice()?.osVersion}`,
    device_family: getDevice()?.os,
    device_manufacturer: deviceName,
    distro: deviceName,
    limit_ad_tracking: '',
  };

  const additionalPropertiesStr = encodeURIComponent(
    JSON.stringify(dataProperties)
  );

  return (
    `additional_properties=${additionalPropertiesStr}` +
    `&code=${authCode}` +
    `&csrf=${csrf}` +
    `&assignments=${HULU_ASSIGNMENTS}`
  );
};

/**
 * Polls the authentication service to determine if the code has been authenticated
 * @param {String} authCode code to lookup
 * @return {String} Object containing activation status code
 */
export const checkActivationAuth = async (authCode) => {
  if (!authCode) return null;

  const logMetaData = { logName: 'checkActivationAuth' };
  const endpoint = getHost('hoth');
  const authData = await buildAuthRequestData(authCode);
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: authData,
  };
  let returnStatus = null;

  try {
    // Test authentication at API endpoint
    const response = await fetchWithTimeout(
      `${endpoint}${ACTIVATION_AUTH_PATH}`,
      options,
      FETCH_TIMEOUT
    );

    returnStatus = response.status;
  } catch (error) {
    // Only log real errors
    // Normal response before authentication is 404
    if (returnStatus !== STATUS_CODES.NOT_FOUND) {
      // Terminating authentication error detected
      ServiceLogger.error(
        `useAuthenticateWithCode: Fetch error from ${endpoint}${ACTIVATION_AUTH_PATH}`,
        error,
        logMetaData
      );
    }
  }

  return returnStatus;
};

/**
 * Generates a token for auth
 * @param {String} path path to be used when generating the csrf token
 * @return {String} Token value
 */
const generateToken = async (path) => {
  const logMetaData = { logName: 'generateToken' };
  const endpoint = getHost('hudis');
  let csrf = '';
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  try {
    // Request code from registration API
    const response = await fetchWithTimeout(
      `${endpoint}/api/4.0/generate_csrf_value?for_hoth=true&path=${path}`,
      options,
      FETCH_TIMEOUT
    );

    if (response.ok) {
      if (!getCookie('_tcv')) {
        ServiceLogger.error(
          `generateToken: Fetch error from ${endpoint}/api/4.0/generate_csrf_value?for_hoth=true&path=${path}`,
          'Cookie not set',
          logMetaData
        );
      }
      csrf = getCookie('_tcv');
      eraseCookie('_tcv');
    } else {
      // Assumes API error response
      ServiceLogger.error(
        `generateToken: Error received from ${endpoint}/api/4.0/generate_csrf_value?for_hoth=true&path=${path}`,
        response.status,
        logMetaData
      );
    }
  } catch (error) {
    // Assumes network failure
    ServiceLogger.error(
      `generateToken: Fetch error from ${endpoint}/api/4.0/generate_csrf_value?for_hoth=true&path=${path}`,
      error,
      logMetaData
    );
  }
  return csrf;
};
