import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

const PlanComparisonChartContext = React.createContext();

const usePlanComparisonChartContext = () =>
  useContext(PlanComparisonChartContext);

const PlanComparisonChartProvider = ({ toggleDefaultValue, children }) => {
  const [isToggleOn, setIsToggleOn] = useState(toggleDefaultValue);
  const value = {
    isToggleOn,
    setIsToggleOn,
  };

  return (
    <PlanComparisonChartContext.Provider value={value}>
      {children}
    </PlanComparisonChartContext.Provider>
  );
};

PlanComparisonChartProvider.propTypes = {
  children: PropTypes.node,
};

export { PlanComparisonChartProvider, usePlanComparisonChartContext };
