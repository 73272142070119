import { string, shape, bool } from 'prop-types';

export const BillboardModelSchema = shape({
  eyebrow: string,
  headline: string,
  subheadline: string,
  disclaimer: string,
  modalLinkText: string,
  componentToScrollToId: string,
  hasBorder: bool,
});
