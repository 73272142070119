import classNames from 'classnames';
import { Field } from 'formik';
import React from 'react';

import { fireUserInteraction } from '!app/metrics/fireEvent';

/**
 * scrollToNextQuestion scrolls to the next question after a delay
 * @param {*} questionIndex index of the question
 * @param {*} getMap function to get the map of question refs
 * @param {*} scrollDelayTime ms to delay scroll
 */
const scrollToNextQuestion = (questionIndex, getMap, scrollDelayTime) => {
  const map = getMap();
  const node = map.get(`question${questionIndex + 1}`);
  setTimeout(() => {
    node.scrollIntoView();
  }, scrollDelayTime);
};

/**
 * onClickHandler handles the updates that should happen when a user clicks an answer
 * @param {*} answer answer selected and its data
 * @param {*} questionIndex index of current question
 * @param {*} scrollDelayTime ms to delay scroll
 * @param {*} getMap function to get the map of question refs
 * @param {*} field current field
 * @param {*} values form's current values (from Formik)
 * @param {*} setFieldValue function from Formik to set the value of a field
 * @param {*} formTouched object from Formik to indicate whether this field has been visited before
 */
const onClickHandler = (
  answer,
  questionIndex,
  scrollDelayTime,
  getMap,
  field,
  values,
  setFieldValue,
  formTouched
) => {
  const questionCount = values.questionSections.length;
  // clear out the values of questions that are after the currently answered question
  for (let index = questionIndex + 1; index < questionCount; index++) {
    setFieldValue(`question${index}`, null);
  }
  // set isFooterExpanded to true only after first question is answered for first time
  if (!values?.isFooterExpanded) {
    setFieldValue('isFooterExpanded', true);
  }
  // set hasAnsweredAllQuestions to true when user answers last question, else false
  setFieldValue('hasAnsweredAllQuestions', questionIndex === questionCount - 1);
  // set current question's value to the selected answer
  setFieldValue([field.name], answer.id);

  // for the last question, if the "keep plan" answer is selected, then pass through the previous answer's plan id
  // otherwise set the footerPlanId
  if (questionIndex + 1 === questionCount && answer.planId === null) {
    const previousQuestionAnswerId = values[`question${questionIndex - 1}`];
    setFieldValue(
      'footerPlanId',
      values.answerIdToPlanIdMap[previousQuestionAnswerId]
    );
  } else {
    setFieldValue('footerPlanId', answer.planId);
  }
  fireUserInteraction(
    `plan_builder:Q${questionIndex + 1}A${answer.engagementMetricId}`,
    'click',
    'click',
    true
  );

  // only scroll to next question if this is the first time user is answering the question and not the last question
  if (
    !Object.keys(formTouched).includes(`question${questionIndex}`) &&
    questionIndex < questionCount - 1
  ) {
    scrollToNextQuestion(questionIndex, getMap, scrollDelayTime);
  }
};

export const ExpPlanBuilderAnswer = ({
  answer,
  answerIndex,
  questionIndex,
  isDisabled,
  values,
  getMap,
  scrollDelayTime,
}) => {
  const questionId = `question${questionIndex}`;

  // isSingleColumn is used for styling the different answer options
  const isSingleColumn =
    answer?.middleColumn && !(answer?.leftColumn || answer?.rightColumn);
  const isSelectedAnswer = answer.id === values[questionId];
  // check if user has reselected their answer
  const hasReselected = values[`question${questionIndex}`] === answer.id;

  return (
    <Field name={questionId} value={answerIndex}>
      {({ field, form }) => {
        return (
          <button
            type="button"
            {...field}
            onClick={() =>
              hasReselected
                ? null
                : onClickHandler(
                    answer,
                    questionIndex,
                    scrollDelayTime,
                    getMap,
                    field,
                    values,
                    form.setFieldValue,
                    form.touched
                  )
            }
            key={`answer-${answerIndex}`}
            className={classNames('answer-button', {
              '--selected': isSelectedAnswer,
              '--disabled': isDisabled,
            })}
            disabled={isDisabled}
          >
            <span
              className={classNames('answer-container', {
                '--single-column': isSingleColumn,
              })}
            >
              {answer.leftColumn && (
                <div
                  className={classNames(
                    'left-column',
                    {
                      '--full-width': answer.id === 'keep-plan',
                    },
                    {
                      '--more-space': answer.id === 'trio-premium',
                    },
                    {
                      '--live-more-space':
                        answer.id ===
                        'hulu-no-ads-with-live-tv-ad-free-trio-premium',
                    },
                    {
                      '--live-more-space':
                        answer.id === 'hulu-no-ads-with-live-tv-hulu-no-ads',
                    }
                  )}
                  dangerouslySetInnerHTML={{ __html: answer.leftColumn }}
                />
              )}
              {answer.rightColumn && (
                <div
                  className={classNames('right-column')}
                  dangerouslySetInnerHTML={{ __html: answer.rightColumn }}
                />
              )}
            </span>
          </button>
        );
      }}
    </Field>
  );
};
