import PropTypes from 'prop-types';
import React from 'react';

import { trackEvent } from '../metricsTracker';

import { getDisplayName } from '!app/lib/hoc';

/**
 * A Higher-Order-Component (HOC) that wraps any targed component and returns a new Component that
 * will also fire off custom events to the metrics platform. This is needed because other Hits
 * event types do not support attaching custom data.
 *
 * @param {React.Component} Component The component that we're going to wrap for sending custom interaction.
 * @param {string} eventName The eventName which will be used in specifying the Hits track event.
 * @param {object} data Key value pairs of data that will be included with the Hits track event.
 * @returns {React.Component}
 */
export default function withCustomInteraction(Component, eventName, data = {}) {
  const render = (props) => {
    const onClick = (...args) => {
      fireHIT(eventName, data);

      // TODO (drew.hays November 15, 2017): trackEvent returns a promise,
      // so should we only call onClick after that? (Otherwise, if we can't
      // use navigator.sendBeacon, we may fail to send the event, because
      // the Document cancels the XHR before a page change.)
      //
      // Currently, if we only call `onClick` after the promise returns, unit
      // tests will fail because the test ends before the promise can be
      // resolved.  (Probably need to do some magic async stuff in jest.)
      if (props.onClick) {
        props.onClick.call(null, ...args);
      }
    };

    const newProps = { ...props, onClick };
    return <Component {...newProps} />;
  };

  render.displayName = getDisplayName('withCustomInteraction', Component);
  render.propTypes = {
    onClick: PropTypes.func,
  };

  return render;
}

/**
 * Fire a custom HIT that includes optional data.
 *
 * @param {string} eventName The eventName which will be used in specifying the Hits track event.
 * @param {object} data Key value pairs of data that will be included with the Hits track event.
 *
 * Note: assumes required data fields for the type of Hits tracking event are provided.
 */
function fireHIT(eventName, data = {}) {
  if (!eventName) {
    return;
  }

  // Set Hits version if not already specified
  const hitsData = {
    ...data,
    hit_version: data?.hit_version ? data.hit_version : '2.1.0',
  };

  trackEvent(eventName, hitsData);
}
