import shuffle from 'lodash/shuffle';
import { string } from 'prop-types';
import React, { Component } from 'react';

import { LogoIconModelSchema } from '../model/schema';
import '../stylesheet/LogoIconStyle.scss';

class LogoIcon extends Component {
  render() {
    const {
      model: { icon, name },
      className,
    } = this.props;

    return (
      <div className={`logo-wall--bug ${className || ''}`}>
        <img className="lazyload" data-src={icon} alt={name} />
      </div>
    );
  }
}

LogoIcon.propTypes = {
  model: LogoIconModelSchema,
  className: string,
};

export default LogoIcon;

/**
 * Renders rows of logos. Styles can generally be set with column classes.
 * @param {Object} model
 */
export const renderLogos = (model) => {
  const {
    parentClassName,
    wrapperClassName,
    logoClassName,
    shouldRandomize,
    logos,
  } = model;

  if (!logos || logos.length < 1) return null;

  const logosArray = shouldRandomize ? shuffle(logos) : logos;

  return (
    <div className={parentClassName}>
      <div className="row">
        {logosArray.map((logo, index) => (
          <div key={index} className={wrapperClassName}>
            <LogoIcon className={logoClassName} model={logo} />
          </div>
        ))}
      </div>
    </div>
  );
};
