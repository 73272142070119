import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { MWTModelSchema } from '../model/schema';

import LogoIcon from '!app/components/LogoWall/view/LogoIcon';
import { preventFocus } from '!app/lib/accessibilityUtils';
import { normalize } from '!app/lib/utils';
import {
  withUtagLink,
  withUserInteraction,
  WithEvents,
} from '!app/metrics/hoc';

import '../stylesheet/MediaWithTextStyle.scss';

const MediaWithText = (props) => {
  const {
    model: { image },
  } = props;
  const LinkButton = withUtagLink(
    {
      event_name: 'media_with_text_link',
      component_id: normalize(get(props, 'model.eyebrow')) || 'media_with_text',
    },
    withUserInteraction('a', 'media_with_text', 'link')
  );

  const ModalButton = withUtagLink(
    {
      event_name: 'media_with_text_modal',
      component_id: normalize(get(props, 'model.eyebrow')) || 'media_with_text',
    },
    withUserInteraction('button', 'media_with_text', 'modal')
  );

  /**
   * Renders a button by default but if a link is added, it renders an anchor
   * with that link.
   * @returns {Component|null}
   */
  const Button = () => {
    const {
      model: { buttonText, buttonUrl },
    } = props;

    if (!buttonText) return null;

    return (
      <div className="text-media__button-wrapper">
        {buttonUrl ? (
          <LinkButton
            className="text-media__link"
            href={buttonUrl}
            onMouseDown={preventFocus}
            dangerouslySetInnerHTML={{ __html: buttonText }}
          />
        ) : (
          <ModalButton
            className="text-media__button"
            type="button"
            name="view-channels"
            data-toggle="modal"
            data-target="#zip-modal"
            onMouseDown={preventFocus}
            dangerouslySetInnerHTML={{ __html: buttonText }}
          />
        )}
      </div>
    );
  };

  const Logos = () => {
    const {
      model: { logos },
    } = props;

    if (!logos || logos.length < 1) return null;

    return (
      <div className="text-media__logos">
        <div className="row">
          {logos.map((logo) => (
            <div
              key={`logoIcon_${logo.icon}`}
              className="text-media__logo-wrapper col-xs-3"
            >
              <LogoIcon className="text-media__logo" model={logo} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const TextSection = () => {
    const {
      model: { eyebrow, headline, body, disclaimer, id },
      enableBrand,
    } = props;

    const textMediaNoImageClassNames = classNames(
      'container-width',
      'text-media',
      'text-media--no-image',
      { 'text-media--brand-enabled': enableBrand }
    );

    return (
      <div className={textMediaNoImageClassNames} id={id}>
        <div className="text-media--text">
          {eyebrow && (
            <Text as="p" variant="eyebrow14" className="text-media--eyebrow">
              {eyebrow}
            </Text>
          )}
          {headline && (
            <Text as="h3" variant="title32" className="text-media--headline">
              {headline}
            </Text>
          )}
          {body && (
            <WithEvents.div
              className="text-media--paragraph"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          )}
          {disclaimer && (
            <WithEvents.div
              className="text-media--disclaimer-top text-media--disclaimer section-disclaimer section-disclaimer--dark"
              dangerouslySetInnerHTML={{ __html: disclaimer }}
            />
          )}
        </div>
      </div>
    );
  };

  const TextWithImage = () => {
    const {
      model: {
        alignment,
        image: { image_1x, image_2x },
        eyebrow,
        headline,
        body,
        disclaimer,
        id,
      },
      enableBrand,
    } = props;

    const Image = (
      <div
        className={`text-media--${alignment} text-media--image col-lg-7 col-md-6 col-xs-12`}
        key="image"
      >
        <img
          className="lazyload"
          data-src={image_1x.url}
          data-srcset={`${image_1x.url} 1x, ${image_2x.url} 2x`}
          role="presentation"
          alt={image_1x.alt}
        />
      </div>
    );

    const TextElement = (
      <div
        className={`text-media--${alignment} col-lg-5 col-md-6 col-xs-12`}
        key="text"
      >
        <div className="text-media--text">
          {eyebrow && (
            <Text as="p" variant="eyebrow14" className="text-media--eyebrow">
              {eyebrow}
            </Text>
          )}
          {headline && (
            <Text
              as="h3"
              variant="title32"
              id="regionMediaText"
              className="text-media--headline"
            >
              {headline}
            </Text>
          )}
          {body && (
            <WithEvents.div
              className="text-media--paragraph"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          )}
          {disclaimer && (
            <WithEvents.div
              className="text-media--disclaimer-top text-media--disclaimer section-disclaimer section-disclaimer--dark"
              dangerouslySetInnerHTML={{ __html: disclaimer }}
            />
          )}
        </div>
        <Logos />
        <Button />
      </div>
    );

    const textMediaClassNames = classNames(
      'container-width',
      'text-media',
      'cu-text-media',
      { 'text-media--brand-enabled': enableBrand }
    );

    const row =
      alignment === 'left' ? [Image, TextElement] : [TextElement, Image];

    return (
      <div
        className={textMediaClassNames}
        id={id}
        role="region"
        aria-labelledby="regionMediaText"
      >
        <div className="row">{row}</div>
      </div>
    );
  };

  return image ? <TextWithImage /> : <TextSection />;
};

MediaWithText.propTypes = {
  model: MWTModelSchema,
  enableBrand: PropTypes.bool,
};

export default MediaWithText;
