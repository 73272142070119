import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { handleTileOnClick } from '!app/lib/TealiumEventsUtils';
import { withTileInteraction } from '!app/metrics/hoc';
import TruncatedText from '!app/share/TruncatedText';

const TileContent = (props) => {
  const {
    disableTileLink,
    collectionId,
    index,
    collectionIndex,
    entityId,
    type,
    contextMenuButton,
    action,
    actionHover,
    title,
    detailsUrl,
    enableSignupModal,
    showSignupModal,
    description,
    metadata,
  } = props;
  const tileContentClass = classNames('Tile__content', {
    'Tile__content--with-menu': Boolean(contextMenuButton),
  });

  const TileLink = withTileInteraction('a', {
    element_specifier: 'tile_title',
    action_specifier: disableTileLink ? 'static' : 'link',
    conditional_properties: ['collection', 'entity'],
    collection_id: collectionId,
    collection_source: 'heimdall',
    collection_item_index: index,
    collection_index: collectionIndex,
    entity_id: entityId,
    entity_action_id: entityId,
    entity_type: type,
    entity_action_type: 'browse',
  });

  const ActionElement = () => {
    if (!action) return null;

    if (!actionHover) {
      return (
        <Text as="div" className="Tile__action" variant="eyebrow12">
          {action}
        </Text>
      );
    }
    return (
      <div className="Tile__action-container">
        <Text
          as="div"
          variant="eyebrow12"
          className="Tile__action Tile__action--base"
        >
          {action}
        </Text>
        <Text
          as="div"
          variant="eyebrow12"
          className="Tile__action Tile__action--hover"
        >
          {actionHover}
        </Text>
      </div>
    );
  };

  const TitleElement = () => {
    const hasLink = detailsUrl;
    const titleText = (
      <TruncatedText
        maxLines={2}
        breakpoints={{ xs: 'title14', md: 'title16' }}
      >
        {title}
      </TruncatedText>
    );

    // when enableSignupModal is not set on Hookup for non editorial collection's,
    // it would get defaulted to null which could lead to the modal not showing up
    let shouldEnableSignupModal = enableSignupModal;
    if (shouldEnableSignupModal === null) {
      shouldEnableSignupModal = true;
    }
    const titleClickDisabled = disableTileLink && !shouldEnableSignupModal;

    if (!hasLink || titleClickDisabled) {
      return (
        <TruncatedText
          maxLines={2}
          as="h3"
          data-automationid="tile_title"
          className="Tile__title"
          breakpoints={{ xs: 'title14', md: 'title16' }}
        >
          {title}
        </TruncatedText>
      );
    }

    if (disableTileLink) {
      return (
        <TileLink
          className="Tile__title-link Tile__title--clickable"
          data-automationid="tile_title"
          onClick={() => handleTileOnClick(true, title, null, showSignupModal)}
        >
          {titleText}
        </TileLink>
      );
    }

    return (
      <TileLink
        className="Tile__title-link Tile__title--clickable"
        href={detailsUrl}
        onClick={() => handleTileOnClick(true, title)}
      >
        {titleText}
      </TileLink>
    );
  };

  const DescriptionElement = () => {
    if (!description) return null;

    return (
      <TruncatedText
        lineHeightEm={1.429}
        maxLines={1}
        className="Tile__description"
        dataAutomationId="tile_description"
        breakpoints={{ xs: 'body12', ml: 'body14' }}
      >
        {description}
      </TruncatedText>
    );
  };

  const MetadataElement = () => {
    if (!metadata) return null;

    return (
      <ul className="Tile__metadata">
        {metadata.map((text, i) => (
          <li key={`${i}-${text}`} className="Tile__meta">
            <Text variant="body14">{text}</Text>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={tileContentClass}>
      <ActionElement />
      <TitleElement />
      <DescriptionElement />
      <MetadataElement />
      {contextMenuButton}
    </div>
  );
};

TileContent.defaultProps = {
  contextMenuButton: null,
  enableSignupModal: true,
};

TileContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  detailsUrl: PropTypes.string,
  action: PropTypes.string,
  actionHover: PropTypes.string,
  metadata: PropTypes.arrayOf(PropTypes.string),
  contextMenuButton: PropTypes.element,
  disableTileLink: PropTypes.bool,
  showSignupModal: PropTypes.func,
  collectionId: PropTypes.string,
  collectionIndex: PropTypes.number,
  index: PropTypes.number,
  entityId: PropTypes.string,
  type: PropTypes.string,
  enableSignupModal: PropTypes.bool,
};

export default TileContent;
