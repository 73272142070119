import PropTypes from 'prop-types';

import { MWTModelSchema } from '../../MediaWithText/model/schema';
import { ValuePropsModelSchema } from '../../ValueProps/model/schema';

export const ContentTabsModelSchema = PropTypes.shape({
  tabTitle: PropTypes.string,
  tabDescription: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      tab: MWTModelSchema,
      tabValueProps: ValuePropsModelSchema,
    })
  ),
});
