import { string, shape, arrayOf } from 'prop-types';

const SingleImagePropType = shape({
  url: string.isRequired,
  alt: string.isRequired,
});

export const NetworkIconsSchema = shape({
  icon: string.isRequired,
  name: string.isRequired,
});

export const MWTModelSchema = shape({
  style: string,
  image: shape({
    image_1x: SingleImagePropType,
    image_2x: SingleImagePropType,
  }),
  id: string,
  eyebrow: string,
  headline: string,
  body: string,
  disclaimer: string,
  logos: arrayOf(NetworkIconsSchema),
  buttonText: string,
  buttonUrl: string,
});
