import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { view as Slider } from '!app/components/Slider/Slider';
import { PortraitTile } from '!app/components/Tile';
import '../stylesheet/PortraitSlider.scss';

class PortraitSlider extends Component {
  mapItemToTile = (item, index) => {
    const { small, horizontal, enabledTileLinks } = this.props;
    return (
      <PortraitTile
        disableTileLink={!enabledTileLinks}
        asset={item}
        enableSponsorBranding={false}
        key={index}
        small={small}
        horizontal={horizontal}
      />
    );
  };

  render() {
    const {
      collection,
      collectionIndex,
      collectionType,
      small,
      horizontal,
    } = this.props;
    const { items } = collection;

    const sliderClasses = classnames({
      'Slider--portrait': !small && !horizontal,
      'Slider--small-portrait': small,
      'Slider--adaptive': horizontal,
    });

    return (
      <div className="PortraitSlider cu-portrait-slider">
        <Slider
          className={sliderClasses}
          items={items.map(this.mapItemToTile)}
          collection={collection}
          collectionIndex={collectionIndex}
          collectionType={collectionType}
          small={small}
          horizontal={horizontal}
          mobileModeTrigger={2}
          mobileMode="swipe"
          previousAltText="Previous"
          nextAltText="Next"
        />
      </div>
    );
  }
}

PortraitSlider.defaultProps = {
  collection: {},
  small: false,
  horizontal: false,
};

PortraitSlider.propTypes = {
  collectionIndex: PropTypes.number,
  collectionType: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  small: PropTypes.bool,
  horizontal: PropTypes.bool,
  enabledTileLinks: PropTypes.bool,
};

export default PortraitSlider;
