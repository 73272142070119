import Text from '@hulu/web-ui/Text';

import { usePlanComparisonChartContext } from './PlanComparisonChartContext';

import { REGISTER_MODALS_EVENT } from '!app/lib/constants';
import { withUtagLink, withUserInteraction } from '!app/metrics/hoc';
import ToggleSwitch from '!app/share/ToggleSwitch';
import '../stylesheet/PlanComparisonBundleHeader.scss';

const BUNDLE_CHANGE_DELAY_TIME = 100;

const PlanComparisonBundleHeader = (props) => {
  const {
    bundle: {
      toggleOnLabel,
      toggleOnImage,
      toggleOffLabel,
      toggleOffImage,
      toggleModalId,
    },
    isDark,
  } = props;
  const { isToggleOn, setIsToggleOn } = usePlanComparisonChartContext();
  const eventName = `bundle_toggle_${isToggleOn ? 'on' : 'off'}`;
  const Toggle = withUtagLink(
    {
      event_name: eventName,
      cta_placement: 'comparison_chart',
    },
    withUserInteraction(ToggleSwitch, eventName)
  );

  const handleBundleChange = () => {
    setIsToggleOn(!isToggleOn);
    const event = new CustomEvent(REGISTER_MODALS_EVENT);
    setTimeout(() => {
      document.dispatchEvent(event);
    }, BUNDLE_CHANGE_DELAY_TIME);
  };

  return (
    <div className="bundle-header-with-new-toggle">
      <div className="bundle-header-with-new-toggle__left-headline">
        <div className="bundle-header-with-new-toggle__left-top-headline">
          <Text variant="subtitle14">{toggleOffLabel}</Text>
        </div>
        {toggleOffImage?.image?.source && (
          <div className="bundle-header-with-new-toggle__left-bottom-headline">
            <img
              src={`https:${toggleOffImage.image.source}`}
              role="presentation"
              alt="hulu-logo"
            />
          </div>
        )}
      </div>

      <div className="bundle-header-with-new-toggle__toggle-container">
        <Toggle
          isOn={isToggleOn}
          onColor={isDark ? '#1CE783' : '#040405'}
          handleToggle={handleBundleChange}
        />
      </div>

      <div className="bundle-header-with-new-toggle__right-headline">
        <div className="bundle-header-with-new-toggle__right-top-headline">
          <Text variant="subtitle14">{toggleOnLabel}</Text>
          {toggleModalId && (
            <span>
              <a
                data-toggle="toggleModal"
                data-target={`#${toggleModalId}`}
                role="button"
              >
                <img
                  src={
                    isDark
                      ? '/static/hitch/static/icons/Info_Icon_White.svg'
                      : '/static/hitch/static/icons/Info_Icon_Dark_Grey.svg'
                  }
                  role="presentation"
                  alt={toggleOnLabel}
                />
              </a>
            </span>
          )}
        </div>
        {toggleOnImage?.image?.source && (
          <div className="bundle-header-with-new-toggle__right-bottom-headline">
            <img
              src={`https:${toggleOnImage.image.source}`}
              role="presentation"
              alt="hulu-logo"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanComparisonBundleHeader;
