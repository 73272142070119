import Text from '@hulu/web-ui/Text';
import { bool, number } from 'prop-types';
import React from 'react';

import ModelSchema from '../model/schema';

import { Tile } from '!app/components/Tile';
import '../stylesheets/GridCollection.scss';

const getItems = (
  collection,
  enabledTileLinks,
  collectionIndex,
  enableSignupModal,
  includeSEOAttr
) => {
  return collection.items.map((item, index) => {
    return (
      <div className="GridCollection__item" key={item.id}>
        <Tile
          asset={item}
          index={index}
          disableTileLink={!enabledTileLinks}
          collectionIndex={collectionIndex}
          collectionId={collection.id}
          enableSignupModal={enableSignupModal}
          includeSEOAttr={includeSEOAttr}
        />
      </div>
    );
  });
};

const GridCollection = (props) => {
  const {
    model: {
      collection,
      title,
      collection: { enabledTileLinks, enableSignupModal },
    },
    includeTitle,
    collectionIndex,
    includeSEOAttr,
  } = props;
  const items = getItems(
    collection,
    enabledTileLinks,
    collectionIndex,
    enableSignupModal,
    includeSEOAttr
  );

  return (
    <div className="GridCollection">
      {includeTitle && (
        <Text
          as="h1"
          breakpoints={{ xs: 'title32', lg: 'title48' }}
          className="GridCollection__title"
        >
          {title}
        </Text>
      )}
      <div className="GridCollection__items">{items}</div>
    </div>
  );
};

GridCollection.defaultProps = {
  includeTitle: false,
};

GridCollection.propTypes = {
  model: ModelSchema,
  includeTitle: bool,
  collectionIndex: number,
};

export default GridCollection;
