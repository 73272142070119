/**
 * The base path of the page(s) that consume the Edna Login component from the web-login-ui package.
 */
const LOGIN_BASE_PATH = '/app';

/**
 * A custom router that will be passed into the login feature components inside the web-login-ui package.
 *
 * The routing functions will be used by the package to interact with the hitch application
 * to navigate between dynamic routes inside the flow.
 *
 * @see {@link https://github.prod.hulu.com/WebTeam/web-login-ui/blob/main/src/features/unified-login/use-unified-login-machine.ts#L7-L39}
 */
export const LoginAppRouter = () => ({
  beforePopRoute: (callback) => window.addEventListener('popstate', callback),

  getLocalPathname: () => {
    // eslint-disable-next-line no-magic-numbers
    const localPathname = window.location.pathname.split('/').at(-1);

    // If the user is currently on a sub-path in the login flow: Ex. /login/enter-email
    // eslint-disable-next-line no-magic-numbers
    if (localPathname !== LOGIN_BASE_PATH.split('/').at(-1)) {
      return `/${localPathname}`;
    }
    return '/';
  },

  pushLocalRoute: (route) => {
    // Remove the trailing slash in the route argument if it exists
    const slug = route.startsWith('/') ? route.slice(1) : route;

    const targetRoute = `${LOGIN_BASE_PATH}/${slug}`;

    window.history.pushState(
      {
        url: targetRoute,
        slug,
      },
      '',
      targetRoute
    );
  },

  /**
   * Construct the app router used by the Edna Login flow from the web-login-ui package.
   * @returns a routing object whose format is predefined in the web-login-ui.
   */
  build() {
    return {
      beforePopRoute: this.beforePopRoute,

      getLocalPathname: this.getLocalPathname,

      pushLocalRoute: this.pushLocalRoute,
    };
  },
});
