import { WELCOME_CONTROL } from '!app/lib/constants';

export function getWelcomeVariation(path, variation) {
  if (path === 'welcome' && variation) {
    switch (variation) {
      default:
        return WELCOME_CONTROL;
    }
  }
  return null;
}

export function scrollToComponent(componentId) {
  const component = global.document.getElementById(componentId);
  if (!component) {
    return;
  }
  component.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
