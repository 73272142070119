import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

const ExpPlanComparisonChartContext = React.createContext();

const useExpPlanComparisonChartContext = () =>
  useContext(ExpPlanComparisonChartContext);

const ExpPlanComparisonChartProvider = ({ defaultTab, children }) => {
  const [tabIndex, setTabIndex] = useState(defaultTab);
  const value = {
    tabIndex,
    setTabIndex,
  };

  return (
    <ExpPlanComparisonChartContext.Provider value={value}>
      {children}
    </ExpPlanComparisonChartContext.Provider>
  );
};

ExpPlanComparisonChartProvider.propTypes = {
  children: PropTypes.node,
  defaultTab: PropTypes.string,
};

export { ExpPlanComparisonChartProvider, useExpPlanComparisonChartContext };
