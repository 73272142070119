import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import { assetPrefix } from '../../../config/settings';
import { AnchorModelSchema } from '../model/schema';

import { withUtagLink, WithEvents } from '!app/metrics/hoc';
import '../stylesheet/Anchor.scss';

class Anchor extends Component {
  constructor(props) {
    super(props);
  }

  mastheadScrollTo() {
    const { model } = this.props;
    scroller.scrollTo(model.anchorHash.replace('#', ''), {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -76,
      activeClass: 'active',
    });
  }

  render() {
    const {
      model: { anchorCopy },
    } = this.props;
    const transparent = Boolean(anchorCopy);
    const anchorAriaLabel = anchorCopy
      ? `Scroll to ${anchorCopy}`
      : 'Scroll down';
    const CircleButton = withUtagLink(
      { event_name: 'masthead_arrow' },
      'button'
    );
    return (
      <div className="Anchor">
        {Boolean(anchorCopy) && (
          <WithEvents.div
            className="Anchor__copy"
            dangerouslySetInnerHTML={{ __html: anchorCopy.toUpperCase() }}
          />
        )}
        <CircleButton
          onClick={() => this.mastheadScrollTo()}
          className={`button-link ${
            transparent ? 'Anchor__circle--transparent' : 'Anchor__circle'
          }`}
          aria-label={anchorAriaLabel}
          data-automationid="anchor_circle"
        >
          <img
            src={`${assetPrefix}static/icons/${
              !transparent ? 'masthead-scroll.svg' : 'arrow_down.svg'
            }`}
            alt={anchorAriaLabel}
          />
        </CircleButton>
      </div>
    );
  }
}

Anchor.propTypes = {
  model: AnchorModelSchema,
};

export default Anchor;
