import Entity from './Entity';

class Series extends Entity {
  constructor(entity) {
    super(entity);
  }

  getType() {
    return 'TV Series';
  }
}

export default Series;
