import React from 'react';

/* istanbul ignore next */
const CloseButtonSVG = () => (
  <svg width="22px" height="23px" viewBox="0 0 22 23">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        transform="translate(-1047.000000, -266.000000)"
        stroke="#6B7790"
        strokeWidth="2"
      >
        <g transform="translate(500.000000, 235.500000)">
          <g transform="translate(548.000000, 32.000000)">
            <path d="M0.625,19.375 L19.375,0.625" />
            <path d="M0.625,0.625 L19.375,19.375" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CloseButtonSVG;
