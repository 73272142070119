import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  DETAIL_MASTHEAD_BACKGROUND_VERTICAL_SIZES,
  DETAIL_MASTHEAD__WITH_RIBBON_BACKGROUND_VERTICAL_SIZES,
  MASTHEAD_HORIZONTAL_SIZES,
} from '!app/lib/constants';
import { getImageUrls } from '!app/lib/imageUtils';

import './DetailEntityBackground.scss';

const ALPHA = {
  from: 0.7,
  mid: 0.63,
  to: 0.00007,
};

const ALPHA_GRADIENT = {
  base: 1,
  from: 0.0001,
  mid: 0.5,
  to: 0.9,
};

class DetailEntityBackground extends Component {
  constructor(props) {
    super(props);
    this.state = { shouldDisplayGradient: false };
    this.removeGenericGradient = this.removeGenericGradient.bind(this);
    this.addGenericGradient = this.addGenericGradient.bind(this);
    this.imageRef = React.createRef();
  }

  hsla([h, s, l], alpha = 1) {
    return `hsla(${h}, ${s}%, ${l}%, ${alpha})`;
  }

  getBackgroundBaseStyle(baseColor, isMobileView) {
    if (isMobileView) {
      const colorFrom = this.hsla(baseColor, ALPHA.from);
      const colorMid = this.hsla(baseColor, ALPHA.mid);
      const colorTo = this.hsla(baseColor, ALPHA.to);
      return {
        background: `linear-gradient(0deg, ${colorFrom} 25.69%, ${colorMid} 36.24%, ${colorTo} 70.07%)`,
      };
    }
    const color = this.hsla(baseColor, 1);

    return {
      backgroundColor: `${color}`,
    };
  }

  removeGenericGradient() {
    this.setState({
      shouldDisplayGradient: false,
    });
  }

  addGenericGradient() {
    this.setState({
      shouldDisplayGradient: true,
    });
  }

  getBackgroundArtStyle(baseColor, artwork, editorial, blurAmount) {
    // Return if no artwork; Also prevents 500 error caused by missing artwork
    if (!artwork) return {};
    const colorTo = this.hsla(baseColor, 0);
    const colorFrom = this.hsla(baseColor, 1);

    const baseGradient = `linear-gradient(80deg, ${colorFrom} 10%, ${colorTo} 20%)`;
    const editorialGradient = `linear-gradient(0deg, ${colorFrom} 10%, ${colorTo} 40%)`;

    let styles = {};
    let gradients = [];
    let blurStyles;

    if (editorial) {
      gradients = [baseGradient, editorialGradient];

      styles = {
        opacity: 0.4,
      };
    } else {
      gradients = [baseGradient];
    }

    if (blurAmount) {
      blurStyles = {
        filter: `blur(${blurAmount}px)`,
      };
    }

    return {
      backgroundImage: gradients.join(', '),
      left: 'auto',
      ...styles,
      ...blurStyles,
    };
  }

  getBackgroundArt(
    baseColor,
    artwork,
    editorial,
    blurAmount,
    mobileArtwork,
    withRibbon
  ) {
    const { className } = this.props;
    const mastheadSizes = withRibbon
      ? DETAIL_MASTHEAD__WITH_RIBBON_BACKGROUND_VERTICAL_SIZES
      : DETAIL_MASTHEAD_BACKGROUND_VERTICAL_SIZES;
    const mobileArtworks = getImageUrls(
      get(mobileArtwork, 'path'),
      mastheadSizes,
      'webp'
    );

    const artworks = getImageUrls(
      get(artwork, 'path'),
      MASTHEAD_HORIZONTAL_SIZES,
      'webp'
    );
    const imageUrls = { ...mobileArtworks, ...artworks };
    return (
      <div
        className={`${className}__art`}
        style={this.getBackgroundArtStyle(
          baseColor,
          artwork,
          editorial,
          blurAmount
        )}
      >
        <picture className={`${className}__picture`}>
          <source
            media="(min-width: 1600px)"
            srcSet={`${imageUrls.xxlarge} 1x, ${imageUrls.xxlarge_retina} 2x`}
          />
          <source
            media="(min-width: 1280px)"
            srcSet={`${imageUrls.xlarge} 1x, ${imageUrls.xlarge_retina} 2x`}
          />
          <source
            media="(min-width: 1024px)"
            srcSet={`${imageUrls.large} 1x, ${imageUrls.large_retina} 2x`}
          />
          <source
            media="(min-width: 768px)"
            srcSet={`${imageUrls.medium} 1x, ${imageUrls.medium_retina} 2x`}
          />
          <img
            className={`${className}__image`}
            src={imageUrls.small}
            srcSet={`${imageUrls.small} 1x, ${imageUrls.small_retina} 2x`}
            role="presentation"
            onError={this.addGenericGradient}
            onLoad={this.removeGenericGradient}
            ref={this.imageRef}
          />
        </picture>
      </div>
    );
  }

  getBackgroundColorStyle() {
    return {};
  }

  getBackgroundOverlayStyle() {
    return {};
  }

  getBackgroundGradientStyle(baseColor, isMobileView) {
    let colorFrom = this.hsla(baseColor, 0);
    let colorTo = this.hsla(baseColor, 1);

    if (isMobileView) {
      const colorMid = this.hsla(baseColor, ALPHA_GRADIENT.mid);
      const base = this.hsla(baseColor, ALPHA_GRADIENT.base);
      colorFrom = this.hsla(baseColor, ALPHA_GRADIENT.from);
      colorTo = this.hsla(baseColor, ALPHA_GRADIENT.to);
      return {
        background: `linear-gradient(179.79deg, ${colorFrom} .18%, ${colorMid} 32.87%, ${colorTo} 64.43%, ${base} 99.82%)`,
      };
    }

    return {
      backgroundImage: `linear-gradient(245deg, ${colorFrom} 35%, ${colorTo} 70%)`,
    };
  }

  getBackgroundScrimStyle() {
    const scrims = [
      'linear-gradient(5deg, #000 0%, transparent 50%)',
      'radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 20%)',
    ];

    return {
      opacity: 0.35,
      backgroundImage: scrims.join(', '),
    };
  }

  componentDidMount() {
    const img = this.imageRef.current;
    if (!(img && img.complete && img.naturalWidth > 0)) {
      this.addGenericGradient();
    }
  }

  render() {
    const {
      artwork,
      editorial,
      className,
      blurAmount,
      isMobileView,
      mobileArtwork,
      withRibbon,
    } = this.props;
    const { shouldDisplayGradient } = this.state;
    const hue = isMobileView
      ? get(mobileArtwork, 'hue', 0)
      : get(artwork, 'hue', 0);
    const BASE_COLOR = {
      h: hue,
      s: 50,
      l: 40,
    };
    const baseColor = [BASE_COLOR.h, BASE_COLOR.s, BASE_COLOR.l];
    const errorGradient = {
      background:
        'linear-gradient(135deg, rgb(42, 65, 140) 0%, rgb(161, 116, 149) 50%, rgb(219, 159, 133) 100%)',
    };

    return (
      <div className={`${className}__wrapper`}>
        <div
          className={`${className}__base`}
          style={this.getBackgroundBaseStyle(baseColor, isMobileView)}
        />
        {this.getBackgroundArt(
          baseColor,
          artwork,
          editorial,
          blurAmount,
          mobileArtwork,
          withRibbon
        )}
        <div
          className={`${className}__color`}
          style={this.getBackgroundColorStyle()}
        />
        <div
          className={`${className}__overlay`}
          style={this.getBackgroundOverlayStyle()}
        />
        <div
          className={`${className}__gradient`}
          style={
            shouldDisplayGradient && isMobileView
              ? errorGradient
              : this.getBackgroundGradientStyle(baseColor, isMobileView)
          }
        />
        <div
          className={`${className}__scrim`}
          style={this.getBackgroundScrimStyle()}
        />
      </div>
    );
  }
}

DetailEntityBackground.propTypes = {
  artwork: PropTypes.shape({}).isRequired,
  editorial: PropTypes.bool,
  blurAmount: PropTypes.number,
  className: PropTypes.string,
  isMobileView: PropTypes.bool,
  mobileArtwork: PropTypes.shape({}).isRequired,
};

DetailEntityBackground.defaultProps = {
  imageWidth: 1280,
  editorial: false,
  className: 'DetailEntityBackground',
};

export default DetailEntityBackground;
