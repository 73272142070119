export const CollectionsModalQuery = `... on CollectionsModalPiece {
  backgroundImage
  collectionItems {
    headline
    description
    collection {
      ...collectionsModalCollectionFields
    }
  }
  copyTitle
  copyDescription
  landscapeVideo
  portraitVideo
  title
  __typename
}`;
