import PropTypes from 'prop-types';

import {
  getCtaText,
  getCtaPath,
  getDataTargetOverride,
} from '!app/lib/signupUtils';
import { getEventNameFromEntitlement } from '!app/lib/TealiumEventsUtils';
import { tokenizeCartAbandonmentCopy } from '!app/lib/utils/cartAbandonmentUtils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';

const CTA_LINK_TYPES = {
  ANCHOR: 'anchor',
  SUF: 'suf',
  PATH: 'path',
  MODAL: 'modal',
};

const hasCartAbandonmentFields = (cartAbandonment, text, href) => {
  return cartAbandonment && text && href;
};

/**
 * Returns relevant copy for the PrimaryCta
 *
 * @param {object} ctaOptions options used to get getCtaText
 * @param {boolean} shouldShowDownloadAppLink
 * @param {string} ctaDownloadAppText copy to use if shouldShowDownloadAppLink is true
 * @param {boolean} isHumanCodeOverride true if the CTA is used for a code submission
 * @returns {string} Copy to display in a Masthead PrimaryCta
 */
const getPrimaryCtaText = (
  ctaOptions,
  shouldShowDownloadAppLink,
  ctaDownloadAppText,
  isHumanCodeOverride
) => {
  const newCtaText = shouldShowDownloadAppLink
    ? ctaDownloadAppText
    : getCtaText(ctaOptions);
  return isHumanCodeOverride ? 'Apply' : newCtaText;
};

const configWithUtagLink = (options) => {
  const {
    user,
    shouldShowDownloadAppLink,
    isStartPage,
    element_specifier,
    DriverButton,
  } = options;
  return withUtagLink(
    {
      event_name: getEventNameFromEntitlement(user, shouldShowDownloadAppLink),
      cta_placement: 'masthead',
      page_type: isStartPage ? 'signup_start' : 'signup_lp',
      element_specifier,
    },
    DriverButton
  );
};

/**
 * Returns a primary cta DriverButton with attributes based on the type of CTA we're displaying.
 *
 * @returns {Component} PrimaryCta with the corresponding attributes
 */
const PrimaryCta = (props) => {
  const {
    user,
    ctaFields,
    locale,
    model: {
      ctaFormat,
      programType,
      ctaButtonStyle,
      anchorCtaText,
      cartAbandonmentCopy,
      ctaElementSpecifierOverride,
    },
    cartAbandonment,
    messages,
    ctaDownloadAppText,
    shouldShowDownloadAppLink,
    isHumanCodeOverride,
    isStartPage,
    isEsLang,
    onSubmit,
  } = props;

  const { cta_manage } = messages;
  const cartAbandonmentCta = cartAbandonmentCopy?.cta;
  const cartAbandonmentCtaLink = cartAbandonmentCta?.href;
  const cartAbandonmentCtaStyle = cartAbandonmentCta?.style ?? 'white';
  const cartAbandonmentCtaTokenized = tokenizeCartAbandonmentCopy(
    cartAbandonment,
    cartAbandonmentCta
  );
  const shouldShowCartAbandonmentCta = hasCartAbandonmentFields(
    cartAbandonment,
    cartAbandonmentCtaTokenized,
    cartAbandonmentCtaLink
  );

  const ctaOptions = {
    user,
    programType,
    componentNonSubCta: shouldShowCartAbandonmentCta
      ? cartAbandonmentCtaTokenized
      : ctaFormat,
    componentSubCta: isEsLang && cta_manage,
    locale,
    ctaFields,
  };

  const primaryCtaText = getPrimaryCtaText(
    ctaOptions,
    shouldShowDownloadAppLink,
    ctaDownloadAppText,
    isHumanCodeOverride
  );
  const overrideHref = shouldShowCartAbandonmentCta
    ? cartAbandonmentCtaLink
    : null;
  const overrideStyle = shouldShowCartAbandonmentCta
    ? cartAbandonmentCtaStyle
    : ctaButtonStyle;

  // Used for Student Discount to show SheerID modal
  const ctaDataTarget = getDataTargetOverride(getCtaPath(ctaOptions));

  const element_specifier = ctaElementSpecifierOverride;
  const utagLinkOptions = {
    user,
    shouldShowDownloadAppLink,
    isStartPage,
    element_specifier,
    DriverButton: withUserInteraction(
      CTAButton,
      element_specifier,
      'driver_click',
      'click',
      true,
      primaryCtaText
    ),
  };

  const DriverButton = configWithUtagLink(utagLinkOptions);
  return (
    !anchorCtaText &&
    primaryCtaText && (
      <DriverButton
        className="Masthead__input-cta"
        useStyle={overrideStyle}
        onClick={
          !ctaDataTarget
            ? () => {
                onSubmit(overrideHref);
              }
            : null
        }
        data-target={ctaDataTarget}
        data-automationid="masthead_input_cta"
      >
        {primaryCtaText}
      </DriverButton>
    )
  );
};

/**
 * Returns SecondaryDriverButton with attributes based on the type of CTA we're displaying.
 * Supports regular links, SUF, Anchor links, and Modal
 *
 * @returns {Component} SecondaryCta with the corresponding attributes
 */
const SecondaryCta = (props) => {
  const {
    model: {
      linkType,
      style,
      copy,
      href,
      cartAbandonmentCopy,
      elementSpecifierOverride,
    },
    user,
    isStartPage,
    scrollToComponent,
    onSecondarySubmit,
    cartAbandonment,
  } = props;

  const cartAbandonmentCta = cartAbandonmentCopy?.secondaryCta;
  const cartAbandonmentCtaLink = cartAbandonmentCta?.href;
  const cartAbandonmentCtaStyle = cartAbandonmentCta?.style ?? 'white_outline';
  const cartAbandonmentCtaTokenized = tokenizeCartAbandonmentCopy(
    cartAbandonment,
    cartAbandonmentCta
  );
  const shouldShowCartAbandonmentCta = hasCartAbandonmentFields(
    cartAbandonment,
    cartAbandonmentCtaTokenized,
    cartAbandonmentCtaLink
  );

  if ((!href || !copy) && !shouldShowCartAbandonmentCta) return null;

  const overrideHref = shouldShowCartAbandonmentCta
    ? cartAbandonmentCtaLink
    : href;
  const overrideLinkType = shouldShowCartAbandonmentCta
    ? CTA_LINK_TYPES.PATH
    : linkType;
  const overrideStyle = shouldShowCartAbandonmentCta
    ? cartAbandonmentCtaStyle
    : style;
  const secondaryCtaClasses = 'Masthead__input-cta Masthead__cta--secondary';
  const isAnchorLink = overrideLinkType === CTA_LINK_TYPES.ANCHOR;
  const isModalLink = overrideLinkType === CTA_LINK_TYPES.MODAL;
  const onClickMap = {
    anchor: scrollToComponent,
    path: onSecondarySubmit,
    suf: onSecondarySubmit,
    modal: null,
  };

  const element_specifier = elementSpecifierOverride;
  const utagLinkOptions = {
    user,
    shouldShowDownloadAppLink: false,
    isStartPage,
    element_specifier,
    DriverButton: withUserInteraction(
      CTAButton,
      element_specifier,
      'driver_click',
      'click',
      true,
      copy
    ),
  };

  const SecondaryDriverButton = configWithUtagLink(utagLinkOptions);

  return (
    <SecondaryDriverButton
      className={secondaryCtaClasses}
      useStyle={overrideStyle}
      href={overrideHref}
      data-href={isAnchorLink ? href : null}
      data-toggle={isModalLink ? CTA_LINK_TYPES.MODAL : null}
      data-target={isModalLink ? href : null}
      aria-label={copy}
      onClick={onClickMap[overrideLinkType]}
      data-automationid="masthead_input_cta_secondary"
    >
      {cartAbandonmentCtaTokenized || copy}
    </SecondaryDriverButton>
  );
};

PrimaryCta.propTypes = {
  cartAbandonment: PropTypes.shape({}),
  ctaDownloadAppText: PropTypes.shape({}),
  ctaFields: PropTypes.shape({}),
  isEsLang: PropTypes.bool,
  isHumanCodeOverride: PropTypes.bool,
  isStartPage: PropTypes.bool,
  locale: PropTypes.string,
  messages: PropTypes.string,
  model: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  shouldShowDownloadAppLink: PropTypes.bool,
  user: PropTypes.shape({}),
  ctaElementSpecifierOverride: PropTypes.string,
};

SecondaryCta.propTypes = {
  cartAbandonment: PropTypes.shape({}),
  isStartPage: PropTypes.bool,
  model: PropTypes.shape({}),
  onSecondarySubmit: PropTypes.func,
  shouldShowDownloadAppLink: PropTypes.bool,
  scrollToComponent: PropTypes.func,
  user: PropTypes.shape({}),
};

export { PrimaryCta, SecondaryCta };
