import PropTypes from 'prop-types';

import { getImageUrl } from '!app/lib/imageUtils';

const WATERMARK_WIDTH = 200;
const WATERMARK_HEIGHT = 200;

const BrandWatermark = (props) => {
  const { brandArtwork, isMobileDisplay } = props;
  if (!brandArtwork) {
    return null;
  }
  const brandArtworkUrl = getImageUrl(brandArtwork.path, {
    width: WATERMARK_WIDTH,
    height: WATERMARK_HEIGHT,
    format: 'webp',
  });
  return (
    <div
      className={`DetailEntityMasthead__brand${
        isMobileDisplay ? ' --mobile' : ' --desktop'
      }`}
    >
      <img
        className="DetailEntityMasthead__brand-img"
        src={brandArtworkUrl}
        role="presentation"
      />
    </div>
  );
};

BrandWatermark.propTypes = {
  brandArtwork: PropTypes.shape({}),
  isMobileDisplay: PropTypes.bool,
};

export default BrandWatermark;
