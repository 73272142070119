import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { MastheadModelSchema } from '../model/schema';

import BrandIcon from './BrandIcon';
import GradientBackground from './GradientBackground';
import ImageBackground from './ImageBackground';
import { PrimaryCta, SecondaryCta } from './MastheadCta';
import VideoBackground from './VideoBackground';

import { view as Ribbon } from '!app/components/Ribbon';
import { Translate } from '!app/i18n';
import { getHost } from '!app/lib/config';
import {
  ANON,
  PROGRAMS_WITH_CODE_EXCLUDE_DEVICE,
  PROGRAM_TYPE_HUMAN_CODE,
  PROGRAM_TYPE_DEVICE_CODE,
  CMS_PAGETYPE,
} from '!app/lib/constants';
import { isBrowser, mobileDetect } from '!app/lib/environment';
import { getPrefillCode } from '!app/lib/programUtils';
import {
  validateCodeFromSignup,
  isCodeRequired as isCodeRequiredUtil,
  getInputPlaceholder,
} from '!app/lib/signupUtils';
import { ctaRedirect, getDownloadAppLink, isLanguage } from '!app/lib/urlUtils';
import { tokenizeCartAbandonmentCopy } from '!app/lib/utils/cartAbandonmentUtils';
import { WithEvents } from '!app/metrics/hoc';
import '../stylesheet/Masthead.scss';
import { fetchCodeProgramDetails } from '!app/services/Ballyhoo';
import Banner from '!app/share/Banner';
import CTAButton from '!app/share/CTAButton';
import messages from '!app/share/messages';

const DEFAULT_ERROR_MESSAGE =
  'Code invalid. Please check your entry and try again.';
const DEVICE_CODE_TYPE = 'device';

const Masthead = (props) => {
  const {
    asPath,
    cartAbandonment,
    children,
    ctaFields,
    enableBrand,
    geodata,
    idx,
    locale,
    model: {
      anchorCtaText,
      anchorCtaUrl,
      backgroundImage,
      backgroundVideos,
      brandIcon,
      cartAbandonmentCopy,
      ctaButtonStyle,
      ctaDownloadAppText,
      ctaFormat,
      enableBrandBorder,
      headlineStates,
      isFullscreen,
      legalStates,
      mobileFirst,
      preheadlineStates,
      programPartner,
      programSubpartner,
      programType,
      requireDeviceCode,
      ribbon,
      secondaryCopyStates,
      secondaryCta,
      startPageHumanCodeOverride,
      style,
      subheadlineStates,
      supportingCopyStates,
      ctaElementSpecifierOverride,
    },
    pageType,
    query,
    topHatShown,
    user,
    user: { entitlementState, entitlementFlag, isSubscriber, isHuluUser },
  } = props;
  const bannerRef = useRef();
  const bannerDivRef = useRef();
  const codeInputRef = useRef();

  const [code, setCode] = useState(getPrefillCode(query));
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState(
    DEFAULT_ERROR_MESSAGE
  );

  const isCodeRequired = isCodeRequiredUtil(programType, requireDeviceCode);

  useEffect(() => {
    if (isCodeRequired) {
      setCode(codeInputRef.current?.value ?? null);
    }
  }, []);

  const isStartPage = pageType === CMS_PAGETYPE.start;

  const shouldShowDownloadAppLink =
    isHuluUser && mobileDetect().mobile() && ctaDownloadAppText;

  const isHumanCodeOverride =
    isStartPage &&
    startPageHumanCodeOverride &&
    programType === PROGRAM_TYPE_HUMAN_CODE;

  const translator = new Translate(locale);

  const getCodeType = () => {
    if (!programType) {
      return null;
    }
    if (PROGRAMS_WITH_CODE_EXCLUDE_DEVICE.includes(programType)) {
      return 'promotion';
    }
    if (programType === PROGRAM_TYPE_DEVICE_CODE) {
      return DEVICE_CODE_TYPE;
    }

    return undefined;
  };

  const setVoiceOverFocus = (element) => {
    const focusInterval = 10; // ms, time between function calls
    const focusTotalRepetitions = 10; // number of repetitions

    element.setAttribute('tabindex', '0');
    element.blur();

    let focusRepetitions = 0;
    const interval = window.setInterval(function () {
      element.focus();
      focusRepetitions++;
      if (focusRepetitions >= focusTotalRepetitions) {
        window.clearInterval(interval);
      }
    }, focusInterval);
  };

  const redirect = (params) => {
    const queryParams = params || {};
    if (getCodeType() === DEVICE_CODE_TYPE) {
      if (!isEmpty(programPartner)) queryParams.partner = programPartner;
      if (!isEmpty(programSubpartner))
        queryParams.subpartner = programSubpartner;
    }
    const ctaOptions = {
      params: queryParams,
      user,
      ctaFields,
    };
    // If logged-in and in mobile
    if (shouldShowDownloadAppLink) {
      window.location.assign(getDownloadAppLink());
    } else if (params?.promotion_code && isSubscriber) {
      // Send subscribers to HISwitch for VIP code flow
      // TODO: move to helper function for unit testing
      window.location.assign(
        `${getHost('hudis')}/account/addons/confirm?code=${
          params.promotion_code
        }`
      );
    } else {
      ctaRedirect(ctaOptions);
    }
  };

  const onSubmit = async (overrideHref) => {
    if (overrideHref) {
      const options = {
        params: {},
        user,
        componentUrl: overrideHref,
        ctaFields,
      };
      ctaRedirect(options);
      return;
    }

    if (isHumanCodeOverride) {
      if (isSubscriber) {
        window.location = `/start/redeem/${code}`;
        return;
      }
    }
    if (isCodeRequired) {
      onCodeSubmit();
    } else {
      redirect();
    }
  };

  const handleValidCodeFromSignup = async (res, type) => {
    /** @todo refactor to only support new json response */
    const contentType = res.headers.get('content-type');
    let validity;
    if (contentType && contentType.includes('application/json')) {
      validity = await res.json();
    }
    if (
      typeof get(validity, 'eligible') === 'undefined' ||
      get(validity, 'eligible')
    ) {
      redirect({ [`${type}_code`]: code });
    } else {
      setError(validity.reasonMessage);
    }
  };

  const onCodeSubmit = async () => {
    const type = getCodeType();

    if (asPath === '/start/vip') {
      const programDetails = await fetchCodeProgramDetails(code);
      const programId = get(programDetails, 'id', null);
      if (!programDetails) setError();
      if (programId) redirect({ [`${type}_code`]: code });
    } else {
      const res = await validateCodeFromSignup(type, code);
      if (res.ok) {
        handleValidCodeFromSignup(res, type);
      } else {
        setError();
      }
    }
  };

  const setError = (reasonMessage) => {
    if (bannerRef) bannerRef.current?.toggle();
    const message = reasonMessage || DEFAULT_ERROR_MESSAGE;

    setCodeError(true);
    setCodeErrorMessage(message);

    if (bannerDivRef?.current) {
      setVoiceOverFocus(bannerDivRef.current);
    }
  };

  const scrollToComponent = (event) => {
    event.preventDefault();
    const element = document.querySelector(event.target.dataset.href);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  /**
   * Returns the Component with the content that corresponds to the state of the user
   * with respect to entitlements. Defaults to anonymous state when there are no entitlement checks on that page.
   *
   * @param {Component} Component A React component that needs user's entitlement state to know which content to render
   * @param {string} className Class of Component
   * @param {Object} statefulContent Object with keys 0, 1, 2, 3, 4 whose values are the content that should be rendered for each case
   * @returns {Component} Component with the corresponding entitlement content
   */
  const renderComponent = ({
    Component: CustomComponent,
    className,
    statefulContent,
    id,
    dataAutomationId,
    cartAbandonmentElCopy,
  }) => {
    const entitledContent =
      entitlementFlag && entitlementState !== ANON
        ? statefulContent[entitlementState]
        : geodata?.geodataOverrides?.[className] || statefulContent[ANON];
    const cartAbandonmentContent = tokenizeCartAbandonmentCopy(
      cartAbandonment,
      cartAbandonmentElCopy
    );

    const content =
      cartAbandonment && cartAbandonmentContent
        ? cartAbandonmentContent
        : entitledContent;

    const componentProps = {
      className,
      'data-automationid': dataAutomationId,
    };
    if (id) componentProps.id = id;
    return (
      content && (
        <CustomComponent
          dangerouslySetInnerHTML={{ __html: content }}
          {...componentProps}
        />
      )
    );
  };
  return (
    <div>
      {isCodeRequired && (
        <Banner ref={bannerRef} id="masthead-banner">
          <div ref={bannerDivRef} className="Masthead__banner">
            <Text
              breakpoints={{ xs: 'body16', lg: 'body24' }}
              className="banner__span"
            >
              {codeErrorMessage}
            </Text>
          </div>
        </Banner>
      )}
      <div
        id="masthead"
        className={classNames(
          'Masthead',
          'cu-masthead',
          style,
          enableBrand
            ? { 'Masthead--brand': true }
            : {
                'Masthead--tall': children,
                'Masthead--short': !backgroundImage,
                'Masthead--fullscreen': isFullscreen,
                'Masthead--tophat-included': topHatShown,
                'Masthead--brand-border': enableBrandBorder,
                'gradient-skrim': !enableBrandBorder,
              }
        )}
        role="region"
        aria-labelledby="regionMasthead"
        data-automationid="masthead"
      >
        {!backgroundImage && <GradientBackground />}
        <ImageBackground
          image={backgroundImage}
          identifier={idx}
          mobileFirst={mobileFirst}
        />
        <VideoBackground videos={backgroundVideos} />
        <div className="Masthead__container">
          {renderComponent({
            Component: WithEvents.div,
            className: 'Masthead__preheadline',
            statefulContent: preheadlineStates,
            cartAbandonmentElCopy: cartAbandonmentCopy?.eyebrow,
          })}
          {renderComponent({
            Component: WithEvents.h1,
            className: 'Masthead__headline',
            statefulContent: headlineStates,
            id: 'regionMasthead',
            cartAbandonmentElCopy: cartAbandonmentCopy?.headline,
            dataAutomationId: 'masthead_headline',
          })}
          {renderComponent({
            Component: WithEvents.div,
            className: 'Masthead__subheadline',
            statefulContent: subheadlineStates,
            cartAbandonmentElCopy: cartAbandonmentCopy?.subheadline,
            dataAutomationId: 'masthead_subheadline',
          })}
          {renderComponent({
            Component: WithEvents.div,
            className: 'Masthead__primary-message',
            statefulContent: supportingCopyStates,
            cartAbandonmentElCopy: cartAbandonmentCopy?.primaryCopy,
          })}
          {renderComponent({
            Component: WithEvents.div,
            className: 'Masthead__secondary-message',
            statefulContent: secondaryCopyStates,
            cartAbandonmentElCopy: cartAbandonmentCopy?.secondaryCopy,
            dataAutomationId: 'masthead_secondary_message',
          })}
          <div className="Masthead__input">
            {isCodeRequired && (
              <div
                className={`Masthead__input-wrapper ${
                  codeError && 'Masthead__input--invalid'
                }`}
              >
                <label
                  className="Masthead__input-label"
                  htmlFor="Masthead-input-text"
                >
                  Enter your {getInputPlaceholder(programType)}
                </label>
                <input
                  type="text"
                  id="Masthead-input-text"
                  className="Masthead__input-box"
                  autoComplete="off"
                  placeholder={getInputPlaceholder(programType)}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setCodeError(false);
                  }}
                  value={code}
                  ref={codeInputRef}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      const cta = document.querySelector(
                        '.Masthead__input-cta'
                      );
                      // The second part of this check is purely to handle jest tests.
                      if (isBrowser() && Boolean(cta)) {
                        cta.click();
                      }
                    }
                  }}
                />
                <div className="Masthead__input-icon">!</div>
              </div>
            )}
            <PrimaryCta
              {...{
                model: {
                  ctaFormat,
                  programType,
                  ctaButtonStyle,
                  anchorCtaText,
                  cartAbandonmentCopy,
                  ctaElementSpecifierOverride,
                },
                user,
                ctaFields,
                locale,
                cartAbandonment,
                messages: translator.translateAll(messages),
                ctaDownloadAppText,
                isHumanCodeOverride,
                shouldShowDownloadAppLink,
                isStartPage,
                onSubmit,
                isEsLang: isLanguage('es-us'),
              }}
            />
            {anchorCtaText && (
              <CTAButton
                className="Masthead__anchor-cta"
                useStyle={ctaButtonStyle}
                data-href={anchorCtaUrl}
                onClick={scrollToComponent}
              >
                {anchorCtaText}
              </CTAButton>
            )}
            <SecondaryCta
              {...{
                model: { ...secondaryCta, cartAbandonmentCopy },
                scrollToComponent,
                onSecondarySubmit: () => {
                  ctaRedirect({
                    params: {},
                    user,
                    componentUrl: secondaryCta.href,
                    ctaFields,
                  });
                },
                user,
                isStartPage,
                cartAbandonment,
              }}
            />
          </div>
          {renderComponent({
            Component: WithEvents.div,
            className: 'Masthead__legal section-disclaimer',
            statefulContent: legalStates,
            cartAbandonmentElCopy: cartAbandonmentCopy?.legalCopy,
            dataAutomationId: 'masthead_legal',
          })}
          <BrandIcon brandIcon={brandIcon} />
        </div>
        {children && <div className="Masthead__children">{children}</div>}
        <Ribbon model={ribbon} asPath={asPath} />
      </div>
    </div>
  );
};

Masthead.defaultProps = {
  idx: 0,
  enableBrand: false,
};

Masthead.propTypes = {
  asPath: PropTypes.string,
  cartAbandonment: PropTypes.shape({}),
  model: MastheadModelSchema,
  user: PropTypes.shape({}),
  ctaFields: PropTypes.shape({}),
  idx: PropTypes.number,
  topHatShown: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  locale: PropTypes.string,
  enableBrand: PropTypes.bool,
  query: PropTypes.shape({}),
  propsOverride: PropTypes.shape({}),
  geodata: PropTypes.shape({}),
};

export default Masthead;
