import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import 'isomorphic-fetch';

import messages from '../messages';

import NetworkIcon from './NetworkIcon';

import { Modalv2 } from '!app/components/Modal';
import { Translate } from '!app/i18n';
import { getHost } from '!app/lib/config';
import { channelsMap } from '!app/lib/constants';
import '../stylesheet/NetworksSearchModalWithGeoStyle.scss';
import { isValidZipcode } from '!app/lib/zipcodeUtils';
import ZipCodeInput from '!app/share/ZipCodeInput';

const NetworksSearchModalWithGeo = (props) => {
  const {
    sharedZipCode,
    sharedArea,
    sharedResults,
    sharedInvalidState,
    locale,
    close,
    promotedChannelList,
    updateParent,
  } = props;
  const [zipcode, setZipcode] = useState(sharedZipCode || '');
  const [area, setArea] = useState(sharedArea || '');
  const [results, setResults] = useState(sharedResults || []);
  const [showDetailedInstructions, setShowDetailedInstructions] = useState(
    !sharedResults?.length
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidZipcode, setIsInvalidZipcode] = useState(
    sharedInvalidState || false
  );
  const [isServerError, setIsServerError] = useState(false);
  const translator = new Translate(locale);
  const translatedMessage = translator.translateAll(messages);

  const resultsRef = useRef(null);
  const zipCodeInputRef = useRef(null);

  const handleChangeZipCode = (e) => {
    setZipcode(e.target.value);
    setIsInvalidZipcode(false);
  };

  const setInvalidZipcodeError = () => {
    setIsInvalidZipcode(true);
    setIsLoading(false);
  };

  const setServerError = () => {
    setIsServerError(true);
    setIsLoading(false);
    zipCodeInputRef?.current?.blur();
  };

  const clearResults = () => {
    setResults([]);
    setIsServerError(false);
    setIsInvalidZipcode(false);
  };

  const setChannelResults = (resultsArray) => {
    setResults(resultsArray);
    setIsLoading(false);
    zipCodeInputRef?.current?.blur();
  };

  const submitSearch = (e) => {
    if (e) e.preventDefault();

    const { filterChannel } = props;

    if (!isValidZipcode(zipcode)) {
      setInvalidZipcodeError();
      return;
    }
    setIsLoading(true);
    setShowDetailedInstructions(false);

    const site = getHost('site').replace(
      /^http(s)?:/i,
      window.location.protocol
    );

    fetch(
      `${site}/api/3.0/channels/local/${zipcode}?group_by=category&be_localsonly=1`
    )
      .then((res) => res.json())
      .then(
        (data) => {
          if (data && data.length > 0) {
            const url = `/api/geolocation/getAreafromZip/${zipcode}`;
            fetch(url, { method: 'GET' })
              .then((response) => response.text())
              .then((areaName) => setArea(areaName || ''));
            clearResults();
            // We can pass along promoted channel types dynamically for the welcome experiments through the JSON
            if (filterChannel) {
              filterPromotedChannelResults(data, filterChannel);
            } else {
              filterPromotedChannelResults(data, promotedChannelList);
            }
          } else {
            setInvalidZipcodeError();
          }
        },
        () => {
          setServerError();
        }
      )
      .then(() => {
        resultsRef.current.focus();
      });
  };

  const filterPromotedChannelResults = (data, promoted_channel) => {
    const found = data.findIndex(
      (channel) => channel?.name === promoted_channel
    );
    const NOT_FOUND_INDEX = -1;
    const channels =
      found !== NOT_FOUND_INDEX ? [...data.splice(found, 1), ...data] : data;
    setChannelResults(channels);
  };

  const getChannelsMapping = (group) => {
    return channelsMap.get(group);
  };

  const onModalClose = () => {
    updateParent(zipcode, area, results, isInvalidZipcode);
    close();
  };

  const {
    channels_in_area_lower,
    disclaimer,
    disclaimer_detailed,
    error_try_later,
  } = translatedMessage;

  return (
    <div className="ZipModal">
      <Modalv2
        model={{ id: 'zip-modal' }}
        deepLinkAnchor="allchannels"
        title={channels_in_area_lower}
        close={onModalClose}
      >
        <div className="NetworksWithGeo">
          <ZipCodeInput
            zipcode={zipcode}
            area={area}
            isInvalidZipcode={isInvalidZipcode}
            isLoading={isLoading}
            submitSearch={submitSearch}
            handleChangeZipCode={handleChangeZipCode}
            zipCodeInputRef={zipCodeInputRef}
          />
          <Text
            as="p"
            variant="body12"
            className="NetworksWithGeo__legalAdvice"
          >
            {showDetailedInstructions ? disclaimer : disclaimer_detailed}
          </Text>
          <div ref={resultsRef}>
            {isServerError && (
              <label className="server-error heading4" htmlFor="zipcode-input">
                <Text variant="title-24">{error_try_later}</Text>
              </label>
            )}
            {results && results.length > 0 && (
              <div className="NetworksWithGeo__channelsContainer">
                {results.map((category, categoryIndex) => (
                  <div
                    key={categoryIndex}
                    className="NetworksWithGeo__channelCategory"
                  >
                    <Text
                      as="h3"
                      variant="subtitle-18"
                      className="NetworksWithGeo__channelCategoryTitle"
                    >
                      {translatedMessage[getChannelsMapping(category.name)]}
                    </Text>
                    <div
                      className="NetworksWithGeo__networksIconGroup"
                      role="list"
                      aria-label={`List of ${
                        translatedMessage[getChannelsMapping(category.name)]
                      }`}
                    >
                      {category.channels.map((channel, index) => (
                        <NetworkIcon
                          categoryIndex={categoryIndex}
                          channel={channel}
                          index={index}
                          locale={locale}
                          key={`${channel?.network_name} ${index}`}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modalv2>
    </div>
  );
};

NetworksSearchModalWithGeo.propTypes = {
  promotedChannelList: PropTypes.string,
  locale: PropTypes.string,
  sharedZipCode: PropTypes.string,
  sharedArea: PropTypes.string,
  sharedResults: PropTypes.arrayOf(PropTypes.shape({})),
  sharedInvalidState: PropTypes.bool,
  filterChannel: PropTypes.string,
  close: PropTypes.func,
  updateParent: PropTypes.func,
};

NetworksSearchModalWithGeo.defaultProps = {
  locale: 'en-us',
};

export default NetworksSearchModalWithGeo;
