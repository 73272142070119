import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { ExpPlanComparisonPlanMultiTabModelSchema } from '../model/schema';

import { view as Modal } from '!app/components/Modal';
import { getPremiumNetworkFromParameter } from '!app/lib/signupUtils';
import { ctaRedirect } from '!app/lib/urlUtils';
import { fireUtagLink, fireUserInteraction } from '!app/metrics';
import {
  WithEvents,
  withUtagLink,
  withUserInteraction,
} from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';

const ExpPlanComparisonPlan = (props) => {
  const {
    isBadgePresent,
    isBundle,
    plan: {
      headline,
      disclaimer: { richText, modalLinkText, modalContent, id },
      ctaAction,
      ctaText,
      mobileCtaText,
      ctaBtnStyle,
      eyebrow,
      badge,
      program,
      logo,
      description,
      headlineEmphasis,
      targetDisplayName,
    },
    index,
    numPlans,
    isDark,
  } = props;
  const headCardClass = classNames(
    'plan-head__card',
    `plan-head__card-${index}`,
    `col-xs-${12 / numPlans}`,
    {
      'plan-head__card--short': !isBadgePresent,
    }
  );

  const PlanButton = withUtagLink(
    {
      event_name: 'plan_select',
      cta_placement: 'comparison_chart',
      product_name: isBundle ? `${headline}-bundle` : headline,
    },
    withUserInteraction(
      CTAButton,
      'comparison_chart:plan_select',
      'driver_click',
      'click',
      true,
      targetDisplayName || ''
    )
  );

  const onDisclaimerClick = (event) => {
    const eventTarget = get(event, 'target');
    const dataId = eventTarget && eventTarget.getAttribute('data-id');
    if (!dataId) return;
    fireUtagLink({
      event_name: dataId,
      cta_placement: 'comparison_chart',
    });
    fireUserInteraction(dataId, 'click', 'click');
  };

  /**
   * Redirect user to signup or addons.
   *
   * @param {Event} e
   */
  const redirectToSignup = (e) => {
    e.stopPropagation();

    const { user, network, requirePremium } = props;
    let from = null;
    let componentUrl = ctaAction;
    if (program) {
      from = program;
      componentUrl = null;
    } else if (requirePremium && network.length) {
      from = getPremiumNetworkFromParameter(requirePremium, network);
    }

    const ctaOptions = {
      user,
      from,
      componentUrl,
      ctaFields: {},
    };
    ctaRedirect(ctaOptions);
  };

  const getCtaButton = (text) => {
    return (
      <PlanButton
        className={`plan-card__cta plan-card__${index}_cta `}
        onClick={redirectToSignup}
        aria-label={`Get ${headline} plan`}
        useStyle={isDark ? 'white' : ctaBtnStyle}
        fullWidth
      >
        {text}
      </PlanButton>
    );
  };

  return (
    <div className={headCardClass}>
      <div className="plan-head__card-content">
        {badge && (
          <div className="plan-head__card-badge">
            <Text variant="eyebrow10">{badge}</Text>
          </div>
        )}
        {eyebrow && (
          <div className="plan-head__card-eyebrow">
            <Text variant="eyebrow10">{eyebrow}</Text>
          </div>
        )}
        {headlineEmphasis && headline ? (
          <>
            <div className="plan-head__card-title">
              <Text variant="title16" className="plan-head__card-title-text">
                {headline}
              </Text>
            </div>
            {logo && (
              <div className="plan-head__card-eyebrow-image">
                <img src={logo} role="presentation" alt="logo" />
              </div>
            )}
          </>
        ) : (
          <>
            {logo && (
              <div className="plan-head__card-eyebrow-image">
                <img src={logo} role="presentation" alt="logo" />
              </div>
            )}
            <div className="plan-head__card-title">
              <Text variant="title16" className="plan-head__card-title-text">
                {headline}
              </Text>
            </div>
          </>
        )}
        {description && (
          <div className="plan-head__card-description">
            <Text variant="body12" className="plan-head__card-description-text">
              {description}
            </Text>
          </div>
        )}
        <div className="plan-card__button-wrapper">{getCtaButton(ctaText)}</div>
        <div className="plan-card__button-wrapper--mobile">
          {getCtaButton(mobileCtaText)}
        </div>
        {richText && (
          <WithEvents.span
            className="plan-head__card-disclaimer"
            dangerouslySetInnerHTML={{ __html: richText }}
          />
        )}
        {modalContent && (
          <>
            <span className="plan-head__card-disclaimer">
              <a
                data-toggle="modal"
                data-target={`#plan-disclaimer-modal__${id}`}
                data-id="plan-disclaimer-modal"
                title={richText}
                role="button"
                onClick={onDisclaimerClick}
              >
                {' '}
                <Text variant="body10">{modalLinkText}</Text>
              </a>
            </span>
            <Modal
              model={{ id: `plan-disclaimer-modal__${id}` }}
              className="plan-addon__info-modal"
            >
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: modalContent,
                  }}
                />
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

ExpPlanComparisonPlan.propTypes = {
  plan: ExpPlanComparisonPlanMultiTabModelSchema,
  isBadgePresent: PropTypes.bool,
  isBundle: PropTypes.bool,
  index: PropTypes.number,
  numPlans: PropTypes.number,
};

export default ExpPlanComparisonPlan;
