import get from 'lodash/get';

import { getBrowsePageType } from '!app/lib/browseUtils';
import {
  NETWORKS,
  ORIGINALS,
  SPORTS,
  TV,
  KIDS,
  NETWORK,
  ANON,
  PARTIALLY_ENTITLED,
  FULLY_ENTITLED,
  SASH_PRODUCT_INDEX,
  NOAH_PRODUCT_INDEX,
  LIVE_PRODUCT_INDEX,
  DISNEY_SASH_PRODUCT_INDEX,
  DISNEY_NOAH_PRODUCT_INDEX,
  DISNEY_LIVE_PRODUCT_INDEX,
  DISNEY_LIVE_NOAH_PRODUCT_INDEX,
  SASH,
  NOAH,
  LIVE,
  DISNEY_BUNDLE,
  DISNEY_NOAH_BUNDLE,
  DISNEY_LIVE_BUNDLE,
  DISNEY_NOAH_LIVE_BUNDLE,
  CMS_PAGETYPE,
} from '!app/lib/constants';
import { fireUtagLink } from '!app/metrics';

const hubPageTypes = [NETWORKS, ORIGINALS, SPORTS, TV, KIDS];

/**
 * Fire utag events for tile/link click
 * @param {boolean} isLink
 * @param {string} title
 */
export function handleTileOnClick(
  isLink,
  title,
  onClick,
  showSignupModal,
  event
) {
  if (event && showSignupModal) {
    event.preventDefault();
  }
  if (onClick) {
    onClick();
  }
  if (showSignupModal) {
    showSignupModal();
  }
  const tokens = window.location.pathname.toLowerCase().split('/');
  const hubPage = hubPageTypes.includes(tokens[1]) ? tokens[1] : null;
  let eventName;
  if (isLink) {
    eventName = hubPage
      ? `signup_${hubPage}_details_link`
      : 'signup_details_link';
  } else {
    eventName = hubPage
      ? `signup_${hubPage}_details_tile`
      : 'signup_details_tile';
  }
  fireUtagLink({
    event_name: eventName,
    tile_name: title,
  });
}

/**
 * Fire utag events on slider click
 * @param {number} moveToSlide
 * @param {string} direction
 * @param {string} contentSelect
 * @param {string} network
 */
export function fireUtagOnSliderClick(
  moveToSlide,
  direction,
  contentSelect,
  network
) {
  const tokens = window.location.pathname.toLowerCase().split('/');
  let pageName = hubPageTypes.includes(tokens[1]) ? tokens[1] : null;

  if (tokens[1] && tokens[1] === NETWORK) {
    pageName = NETWORKS;
  }

  let eventName = `signup_carousel_navigation_${direction}`;
  if (pageName) {
    eventName = `signup_${pageName}_carousel_navigation_${direction}`;
  }

  fireUtagLink({
    event_name: eventName,
    move_to_slide: moveToSlide,
    content_select: contentSelect,
    network_name: network,
  });
}

/**
 * return event_name based on users entitlement
 * @param {user} user
 */
export function getEventNameFromEntitlement(user, downloadAppOverride) {
  if (downloadAppOverride) {
    return 'download_app';
  }

  const entitlementState = get(user, 'entitlementState', ANON);
  switch (entitlementState) {
    case PARTIALLY_ENTITLED:
      return 'add_component';
    case FULLY_ENTITLED:
      return 'start_watching';
    case ANON:
    default:
      return 'free_trial';
  }
}

/**
 * return product name based on plan index
 * @param {planIndex} planIndex
 */
export function getProductName(planIndex) {
  const productIndexLookup = {
    [SASH_PRODUCT_INDEX]: SASH,
    [NOAH_PRODUCT_INDEX]: NOAH,
    [LIVE_PRODUCT_INDEX]: LIVE,
    [DISNEY_SASH_PRODUCT_INDEX]: DISNEY_BUNDLE,
    [DISNEY_NOAH_PRODUCT_INDEX]: DISNEY_NOAH_BUNDLE,
    [DISNEY_LIVE_PRODUCT_INDEX]: DISNEY_LIVE_BUNDLE,
    [DISNEY_LIVE_NOAH_PRODUCT_INDEX]: DISNEY_NOAH_LIVE_BUNDLE,
  };

  return get(productIndexLookup, [planIndex], 'UNKNOWN PLAN');
}

/**
 * return page_type based on path
 * @param {path} path
 */
export function getPageType(path, pageType) {
  // Checks if it is a browse page type
  const browsePageType = getBrowsePageType(path);
  if (browsePageType) {
    return `signup_${browsePageType}`;
  }
  // Check if start page if not default to landing page
  return `signup_${pageType === CMS_PAGETYPE.start ? 'start' : 'lp'}`;
}
