import PropTypes from 'prop-types';
import React from 'react';

import { getComponent } from '!app/components';

const BaseCollection = (props) => {
  const {
    model: { collections, metrics },
  } = props;
  return collections.map((collection) => {
    const { model } = collection;
    const CollectionComponent = getComponent(model.type);

    return (
      <CollectionComponent
        key={model.collection.id}
        model={{ ...model, collectionIndex: metrics.collectionIndex }}
      />
    );
  });
};

BaseCollection.propTypes = {
  model: PropTypes.shape({
    collections: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default BaseCollection;
