import { useEffect, useState } from 'react';

import { STATUS_CODES } from '!app/lib/constants';
import { checkActivationAuth } from '!app/services/Auth';

// Maximum polling tries is number of 10 second intervals in 30 minutes
const MAX_POLLING_RETRIES = 180;
const DEFAULT_TIMEOUT = 10000; // Value in ms
const PROFILES_PATH = '/app/profiles';
const CONNECTED_ERROR_PATH = '/connected-app-error';

const DEFAULT_AUTH_CONFIG = {
  retries: MAX_POLLING_RETRIES,
  timeout: DEFAULT_TIMEOUT,
};

const useAuthenticateWithCode = (
  authCode,
  authConfig = DEFAULT_AUTH_CONFIG
) => {
  const [authStatus, setAuthStatus] = useState(STATUS_CODES.NOT_FOUND);
  const [retries, setRetries] = useState(0);

  // Override defaults if config is set
  const thisConfig = { ...DEFAULT_AUTH_CONFIG, ...authConfig };

  useEffect(() => {
    if (!authCode || authStatus === STATUS_CODES.OK) return undefined;

    // If all retries have completed and there is still not a successful auth
    if (retries >= thisConfig.retries) {
      // Maximum retries exhausted
      // Redirect to Connected auth error page
      window.location.assign(CONNECTED_ERROR_PATH);
      return undefined;
    }

    // After a delay of time
    const timeDelay = setTimeout(() => {
      // Check for code activation
      // Check if authenticated yet
      const initCheckActivationAuth = async () => {
        const newAuthStatus = await checkActivationAuth(authCode);
        setAuthStatus(newAuthStatus);

        // If authenticated redirect to authenticated page
        if (newAuthStatus === STATUS_CODES.OK) {
          // Redirect to authenticated experience
          window.location.assign(PROFILES_PATH);
        } else if (newAuthStatus !== STATUS_CODES.NOT_FOUND) {
          // Auth error. Stop further retries
          // Redirect to Connected auth error page
          window.location.assign(CONNECTED_ERROR_PATH);
        }

        // Increase retry count to trigger next check
        setRetries(retries + 1);
      };
      initCheckActivationAuth();
    }, thisConfig.timeout);

    // this will clear Timeout
    return () => {
      clearTimeout(timeDelay);
    };
  }, [authCode, retries]);

  return {
    authStatus,
  };
};

export { useAuthenticateWithCode };
