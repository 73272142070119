import { getHost } from '!app/lib/config';
import { ServiceLogger } from '!app/lib/logger';
import { fetchWithTimeout } from '!app/lib/utils';

const EMAIL_STATUS = {
  EXISTING: 'existing',
  AVAILABLE: 'available',
};

/**
 * Calls Signup API to check if email exists on Hulu
 * @param {string} email
 * @returns {boolean} true if status is "existing", false if "available"
 */
export const isExistingHuluEmail = async (email) => {
  const url = `${getHost(
    'signup'
  )}/api/v2/accounts/status?email=${encodeURIComponent(email)}`;

  try {
    const response = await fetchWithTimeout(url);
    if (response?.ok) {
      const data = await response.json();
      return data?.status === EMAIL_STATUS.EXISTING;
    }
    ServiceLogger.error(
      `Failed to fetch status for email ${email} from sign up API.`,
      response.status,
      { logName: 'Signup' }
    );
    return {};
  } catch (error) {
    ServiceLogger.error(
      `Failed to fetch status for email ${email} from sign up API. Error: ${error}`,
      { logName: 'Signup' }
    );
    return {};
  }
};
