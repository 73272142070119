import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { ExpPlanComparisonPricingRowModelSchema } from '../model/schema';

import { useExpPlanComparisonChartContext } from './ExpPlanComparisonChartContext';

const ExpPlanComparisonPricingRow = (props) => {
  const { pricingRows, numPlans } = props;

  const { leftColumn, rightColumn } = useExpPlanComparisonChartContext();

  // Build prices array
  const prices = pricingRows[leftColumn].prices.concat(
    pricingRows[rightColumn].prices
  );

  return (
    <div className="plan-feature-item">
      <div className="col-xs-4 plan-feature__title--pricing-row">
        <Text variant="body14">{pricingRows.headline}</Text>
      </div>
      {prices.map((price, i) => {
        const { originalPriceText, discountedPriceText } = price;
        return (
          <Fragment key={i}>
            <div
              className={`col-xs-${
                12 / numPlans
              } plan-feature__check plan-feature__check-${i}`}
              key={`${pricingRows.slug}_${i}`}
            >
              {discountedPriceText ? (
                <div className="plan-feature__bullet">
                  <Text
                    variant="body12"
                    className="plan-feature__bullet_text--strikethrough"
                  >
                    {originalPriceText}
                  </Text>
                  <Text variant="body14" className="plan-feature__bullet_text">
                    {discountedPriceText}
                  </Text>
                </div>
              ) : (
                <div className="plan-feature__bullet">
                  <Text variant="body14" className="plan-feature__bullet_text">
                    {originalPriceText}
                  </Text>
                </div>
              )}
            </div>
            <div className="plan-feature__separator" />
          </Fragment>
        );
      })}
    </div>
  );
};

ExpPlanComparisonPricingRow.propTypes = {
  pricingRow: ExpPlanComparisonPricingRowModelSchema,
  numPlans: PropTypes.number,
};

export default ExpPlanComparisonPricingRow;
