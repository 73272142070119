import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ToasterModelSchema } from '../model/schemaToaster';

import {
  MOVIE,
  HULU_UPCOMING_SERIES_TITLE,
  HULU_UPCOMING_MOVIE_TITLE,
} from '!app/lib/constants';
import { mobileDetect } from '!app/lib/environment';
import {
  getCtaText,
  getPremiumNetworkFromParameter,
} from '!app/lib/signupUtils';
import { getEventNameFromEntitlement } from '!app/lib/TealiumEventsUtils';
import { ctaRedirect, getDownloadAppLink } from '!app/lib/urlUtils';
import { toPremiereDateFormat, isDateWithin90Days } from '!app/lib/utils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';
import { DetailEntityStoreSchema } from '!app/share/schema';
import '!app/components/Toaster/stylesheet/Toaster.scss';

class Toaster extends Component {
  constructor(props) {
    super(props);
    const {
      ctaDownloadAppText,
      isOriginalContent,
      disableInfo,
      contentType,
      premiereDate,
      user: { isHuluUser },
    } = this.props;

    this.redirect = this.redirect.bind(this);
    this.shouldShowDownloadAppLink =
      isHuluUser && mobileDetect().mobile() && ctaDownloadAppText;
    this.isHuluUpcoming = isDateWithin90Days(premiereDate) && isOriginalContent;
    this.disableMoreInfo = disableInfo || this.isHuluUpcoming;
    this.upcomingTitle =
      contentType === MOVIE
        ? HULU_UPCOMING_MOVIE_TITLE
        : HULU_UPCOMING_SERIES_TITLE;

    this.state = {
      positionBottom: false,
    };
  }

  redirect() {
    const { ctaUrl, user, ctaFields, network, requirePremium } = this.props;

    const from = getPremiumNetworkFromParameter(requirePremium, network);

    const ctaOptions = {
      user,
      componentUrl: ctaUrl,
      ctaFields,
      from,
    };

    // If logged-in and in mobile
    if (this.shouldShowDownloadAppLink) {
      window.location.assign(getDownloadAppLink());
    } else {
      ctaRedirect(ctaOptions);
    }
  }

  render() {
    const {
      ctaText,
      infoLine,
      ctaDownloadAppText,
      premiereDate,
      user,
      ctaFields,
      locale,
      isActive,
    } = this.props;

    const ctaOptions = {
      user,
      componentNonSubCta: ctaText,
      locale,
      ctaFields,
    };

    const newCtaText = this.shouldShowDownloadAppLink
      ? ctaDownloadAppText
      : getCtaText(ctaOptions);
    const DriverButton = withUtagLink(
      {
        event_name: getEventNameFromEntitlement(
          user,
          this.shouldShowDownloadAppLink
        ),
        cta_placement: 'toaster',
      },
      withUserInteraction(CTAButton, 'cta_toaster')
    );
    const toasterStyle = classNames('Toaster', {
      'Toaster--active': isActive,
    });

    return (
      <div className={toasterStyle}>
        <div className="Toaster__scrim">
          <DriverButton
            useStyle="black"
            className="Toaster__button"
            onClick={this.redirect}
          >
            {!this.disableMoreInfo && (
              <p className="Toaster__info">{infoLine}</p>
            )}
            {this.isHuluUpcoming && (
              <p className="Toaster__info Toaster__info--upcoming">
                {`${this.upcomingTitle} ${toPremiereDateFormat(premiereDate)}`}
              </p>
            )}
            <p className="Toaster__text">{newCtaText}</p>
          </DriverButton>
        </div>
      </div>
    );
  }
}

Toaster.propTypes = {
  model: ToasterModelSchema,
  user: PropTypes.shape({}),
  ctaFields: PropTypes.shape({}),
  locale: PropTypes.string,
  isActive: PropTypes.bool,
  ...DetailEntityStoreSchema,
};

export default Toaster;
