export const ACTION_TYPE_CLOSE_TOPHAT = 'ACTION_TOPHAT_CLOSE_TOPHAT';
export const ACTION_TYPE_OPEN_TOPHAT = 'ACTION_TOPHAT_OPEN_TOPHAT';

export const closeTopHat = (payload) => {
  return {
    type: ACTION_TYPE_CLOSE_TOPHAT,
    payload,
  };
};

export const openTopHat = (payload) => {
  return {
    type: ACTION_TYPE_OPEN_TOPHAT,
    payload,
  };
};
