import Text from '@hulu/web-ui/Text';
import classnames from 'classnames';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import Schema from '../model/schema';

import { MastheadTile } from '!app/components/Tile';
import { PREMIUM_NETWORKS } from '!app/lib/constants';
import { mobileDetect } from '!app/lib/environment';
import { isPremiumNetwork } from '!app/lib/heimdall';
import { getPremiumNetworkFromParameter } from '!app/lib/signupUtils';
import { getEventNameFromEntitlement } from '!app/lib/TealiumEventsUtils';
import { ctaRedirect, getDownloadAppLink } from '!app/lib/urlUtils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';

import '../stylesheets/HeroSliderMasthead.scss';

const NETWORK_LOGO_WIDTH = 500;
const NETWORK_LOGO_HEIGHT = 500;
const AUTOPLAY_INTERVAL = 4000; // 4 secs
const MAX_SLIDER_ITEMS = 5;

const HeroSliderMasthead = (props) => {
  const {
    model: { heroSliderCtaDownloadAppText },
    user,
    updateDetailEntity,
  } = props;

  const { isHuluUser } = user;

  const [mastheadIndex, setMastheadIndex] = useState(0);
  const sliderCountRef = useRef(0);
  const upsellName = get(props, 'model.upsell.name', '');
  const networkUpsell =
    typeof upsellName === 'string' ? upsellName.toLowerCase() : upsellName;
  updateDetailEntity({
    networkUpsell,
    requirePremium: PREMIUM_NETWORKS?.[networkUpsell] !== undefined,
  });

  const shouldShowDownloadAppLink =
    isHuluUser && mobileDetect().mobile() && heroSliderCtaDownloadAppText;

  const getBrandingUrl = (imageUrl) => {
    if (!imageUrl) {
      return null;
    }

    const operationsList = [
      { resize: `${NETWORK_LOGO_WIDTH}x${NETWORK_LOGO_HEIGHT}|max` },
      { format: 'webp' },
      { trim: true },
    ];
    const encodedOperations = encodeURI(
      `operations=${JSON.stringify(operationsList)}`
    );
    return `${imageUrl}&${encodedOperations}`;
  };

  const getMastheadContent = () => {
    const {
      model: {
        heroSliderCtaDesc,
        heroSliderCtaDisclaimer,
        heroSliderCtaLegalText,
        heroSliderCtaPremiumDesc,
        heroSliderCtas,
        network,
        networkLogo,
      },
      ctaFields,
      requirePremium,
      cartAbandonment,
    } = props;
    const networkImageUrl = getBrandingUrl(networkLogo);

    const description = isPremiumNetwork(network)
      ? heroSliderCtaPremiumDesc
      : heroSliderCtaDesc;
    const networkContainerEl = networkImageUrl ? (
      <img src={networkImageUrl} role="presentation" alt={network} />
    ) : (
      <Text
        breakpoints={{
          xs: 'title24',
          md: 'title32',
          lg: 'title40',
        }}
      >
        {network}
      </Text>
    );

    // if user is logged in and on mobile, should show download app version of CTA
    // else, should just show the regular CTA(s)
    const ctasToShow = shouldShowDownloadAppLink
      ? [
          {
            text: heroSliderCtaDownloadAppText,
            url: null,
            onClick: () => {
              window.location.assign(getDownloadAppLink());
            },
          },
        ]
      : heroSliderCtas;

    // HOC to add HITs (analytics) tracking to hero slider CTAs
    const CTAWithTracking = ({
      elementSpecifier,
      targetDisplayName,
      ...ctaProps
    }) => {
      return withUtagLink(
        {
          event_name: getEventNameFromEntitlement(
            user,
            shouldShowDownloadAppLink
          ),
          cta_placement: 'masthead_slider',
        },
        withUserInteraction(
          CTAButton,
          `${elementSpecifier}`,
          'driver_click',
          'click',
          true,
          `${targetDisplayName}`
        )
      )(ctaProps);
    };

    // renders CTAs for hero slider
    const renderCTA = (cta, index) => {
      const { text, url, hypeProgram } = cta;
      return (
        <CTAWithTracking
          key={`HeroSliderCTA-${index}`}
          className="HeroSliderMasthead__cta"
          useStyle="white"
          onClick={() =>
            cta.onClick
              ? cta.onClick()
              : ctaRedirect({
                  user,
                  componentUrl: url,
                  ctaFields,
                  // the "from" value will either come from CMS, fallback to premium network name if a premium title, or fallback to undefined if not premium
                  from:
                    hypeProgram ||
                    getPremiumNetworkFromParameter(requirePremium, network),
                  cartAbandonment,
                  // for hero slider CTAs, we do not want the value passed to the "from" parameter to fallback to the page URL
                  passUrlAsProgram: false,
                })
          }
          elementSpecifier={
            heroSliderCtas && heroSliderCtas.length > 1
              ? `masthead_cta:position_${index + 1}`
              : `cta_masthead_slider`
          }
          targetDisplayName={text}
          data-automationid={
            index === 0 ? 'masthead_input_cta' : 'masthead_input_cta_secondary'
          }
        >
          {text}
        </CTAWithTracking>
      );
    };

    return (
      <div className="HeroSliderMasthead__content">
        <div className="HeroSliderMasthead__networkContainer">
          {networkContainerEl}
        </div>
        {description && (
          <div
            className="HeroSliderMasthead__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {heroSliderCtaDisclaimer && (
          <div
            className="HeroSliderMasthead__disclaimer"
            dangerouslySetInnerHTML={{ __html: heroSliderCtaDisclaimer }}
          />
        )}
        <div className="HeroSliderMasthead__buttonSection">
          {heroSliderCtas && ctasToShow.map(renderCTA)}
        </div>
        {heroSliderCtaLegalText && (
          <div
            className="HeroSliderMasthead__legal section-disclaimer"
            dangerouslySetInnerHTML={{ __html: heroSliderCtaLegalText }}
          />
        )}
      </div>
    );
  };

  const getSliderItems = () => {
    const {
      model: { collection },
    } = props;
    const items = get(collection, 'items', []);
    const uniqueItems = uniqBy(items, (item) => item.id);

    return uniqueItems.slice(0, MAX_SLIDER_ITEMS).map((item, index) => {
      const itemClass = classnames('HeroSliderMasthead__item', {
        'HeroSliderMasthead__item--active': index === mastheadIndex,
      });
      const { id, name, artwork } = item;
      const accentHue =
        get(artwork, 'horizontalHero.hue', 0) ||
        get(artwork, 'horizontalProgramTile.hue', 0);
      const accentBarStyle = {
        borderBottomColor: `hsla(${accentHue}, 75%, 40%, 1)`,
      };

      return (
        <div key={id} className={itemClass}>
          <MastheadTile asset={item} />
          {name && (
            <Text
              as="div"
              breakpoints={{ xs: 'body10', lg: 'body12' }}
              className="HeroSliderMasthead__showName"
            >
              {name}
            </Text>
          )}
          <div
            className="HeroSliderMasthead__accent-bar"
            style={accentBarStyle}
          />
        </div>
      );
    });
  };

  useEffect(() => {
    const autoPlay = setInterval(() => {
      const sliderCount = Math.min(MAX_SLIDER_ITEMS, sliderCountRef.current);
      setMastheadIndex(
        (prevMastheadIndex) => (prevMastheadIndex + 1) % sliderCount
      );
    }, AUTOPLAY_INTERVAL);

    return () => clearInterval(autoPlay);
  }, []);
  useEffect(() => {
    setMastheadIndex(0);
    sliderCountRef.current = get(props, 'model.collection.items', []).length;
  }, [get(props, 'model.collection.id')]);

  return (
    <div className="HeroSliderMasthead__head">
      <div id="HeroSliderMasthead__hero" className="HeroSliderMasthead__hero">
        <div className="HeroSliderMasthead__heros">
          <div className="HeroSliderMasthead__items">{getSliderItems()}</div>
          {getMastheadContent()}
        </div>
      </div>
    </div>
  );
};

HeroSliderMasthead.propTypes = {
  cartAbandonment: PropTypes.shape({}),
  model: Schema,
  updateDetailEntity: PropTypes.func,
  user: PropTypes.shape({}),
};

export default HeroSliderMasthead;
