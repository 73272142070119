import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../stylesheet/ImageBackground.scss';

const BREAKPOINT_TABLET = 441;
const BREAKPOINT_TABLET_MOBILE_FIRST = 768;
const BREAKPOINT_DESKTOP = 769;
const BREAKPOINT_DESKTOP_MOBILE_FIRST = 1024;

class ImageBackground extends Component {
  render() {
    const { image } = this.props;
    if (!image) {
      return null;
    }
    const {
      image: { desktop, tablet, mobile },
      identifier,
      mobileFirst,
    } = this.props;
    const linearGradientFilter =
      'linear-gradient(0deg,transparent 60%,rgba(0,0,0,0.2) 90%,rgba(0,0,0,0.4) 100%)';
    const tabletMinWidth = mobileFirst
      ? BREAKPOINT_TABLET_MOBILE_FIRST
      : BREAKPOINT_TABLET;
    const dekstopMinWidth = mobileFirst
      ? BREAKPOINT_DESKTOP_MOBILE_FIRST
      : BREAKPOINT_DESKTOP;

    return (
      <div>
        <picture className="hidden">
          <source
            media={`(min-width: ${dekstopMinWidth}px)`}
            srcSet={desktop?.url}
          />
          <source
            media={`(min-width: ${tabletMinWidth}px)`}
            srcSet={tablet?.url}
          />
          <source media="(min-width: 0px)" srcSet={mobile?.url} />
          <img alt="preloading masthead" className="hidden" />
        </picture>
        <style jsx>
          {`
            @media only screen and (min-width: 0px) {
              .ImageBackground--image${identifier} {
                background-image: ${linearGradientFilter}, url(${mobile?.url});
              }
            }
            @media only screen and (min-width: ${tabletMinWidth}px) {
              .ImageBackground--image${identifier} {
                background-image: ${linearGradientFilter}, url(${tablet?.url});
              }
            }
            @media only screen and (min-width: ${dekstopMinWidth}px) {
              .ImageBackground--image${identifier} {
                background-image: ${linearGradientFilter}, url(${desktop?.url});
              }
            }
          `}
        </style>
        <div
          className={`ImageBackground ImageBackground--image${identifier}`}
        />
      </div>
    );
  }
}

ImageBackground.propTypes = {
  image: PropTypes.shape({
    desktop: PropTypes.shape({ url: PropTypes.string }),
    tablet: PropTypes.shape({ url: PropTypes.string }),
    mobile: PropTypes.shape({ url: PropTypes.string }),
  }),
  identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileFirst: PropTypes.bool,
};

export default ImageBackground;
