import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

import { HeadlineModelSchema } from '../model/schema';

import { PAGETYPE } from '!app/lib/constants';
import '../stylesheets/Headline.scss';

const Headline = (props) => {
  const {
    model: { id, title, description },
    pageType,
  } = props;

  const isSitemap = pageType === PAGETYPE.sitemapPage;
  const headlineWrapper = classNames('headline__wrapper', {
    'headline__wrapper--sitemap': isSitemap,
  });

  return (
    <div
      id={id}
      className={headlineWrapper}
      role="region"
      aria-labelledby="regionHeadline"
    >
      <div className={isSitemap ? 'container-width' : 'container-width--small'}>
        {title && (
          <h2
            className="section-headline"
            dangerouslySetInnerHTML={{ __html: title }}
            id="regionHeadline"
          />
        )}
        {description && (
          <div
            className="headline--description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  );
};

Headline.propTypes = {
  model: HeadlineModelSchema.isRequired,
  pageType: string.isRequired,
};

export default Headline;
