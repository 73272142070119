import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { find } from 'lodash';
import React, { useRef } from 'react';

import { handleSubmit } from './ExpPlanBuilder';

import CTAButton from '!app/share/CTAButton';
import '../stylesheet/ExpPlanBuilderFooter.scss';

export const ExpPlanBuilderFooter = ({ footer, values, user }) => {
  const { buttonText, continueText, plans } = footer;
  const { footerPlanId, isFooterExpanded, hasAnsweredAllQuestions } = values;
  const selectedPlan = find(plans, { id: footerPlanId });
  const {
    planName = '',
    planDetails = [],
    price = '',
    frequency = '',
    description = '',
    termsApplyRichText = '',
    signupUrl = '',
  } = selectedPlan;
  const priceWithFrequency = `$${price}/${frequency}.`;
  const footerRef = useRef();
  const hasPlanDetails = planDetails !== null;
  const textSizeBreakpoints = {
    xs: 'subtitle18',
    sm: 'subtitle24',
  };

  return (
    <div
      className={classNames(
        'cu-footer',
        'exp-plan-builder-escape-hatch-footer',
        {
          '--expanded': isFooterExpanded,
        }
      )}
      role="region"
      id="ExpPlanBuilderFooterId"
      ref={footerRef}
    >
      <div
        className={classNames('top-row', {
          '--expanded': isFooterExpanded,
        })}
      >
        <div
          className={classNames('title-with-price', {
            '--expanded': isFooterExpanded,
            '--hasAnsweredAllQuestions': hasAnsweredAllQuestions,
          })}
        >
          <Text
            as="div"
            variant="subtitle18"
            breakpoints={textSizeBreakpoints}
            className="exp-plan-builder-footer-title"
          >
            {planName}
          </Text>
          <Text
            as="div"
            variant="subtitle18"
            breakpoints={textSizeBreakpoints}
            className="exp-plan-builder-footer-pricing"
          >
            {priceWithFrequency}
          </Text>
        </div>
        {planDetails && (
          <Text
            as="div"
            variant="subtitle12"
            className="exp-plan-builder-footer-details"
          >
            {planDetails}
          </Text>
        )}
        <div
          className={classNames('exp-plan-builder-footer-button-container', {
            '--expanded': isFooterExpanded,
          })}
        >
          <CTAButton
            size="large"
            fullWidth
            className={classNames('exp-plan-builder-footer-button', {
              '--hasPlanDetails': hasPlanDetails,
            })}
            type="submit"
            href={signupUrl}
            onClick={(event) =>
              handleSubmit({
                event,
                signupUrl,
                user,
                hitTargetDisplayName: hasAnsweredAllQuestions
                  ? continueText
                  : buttonText,
              })
            }
            buttonThemeMode="light"
            disabled={!isFooterExpanded}
            emphasis={hasAnsweredAllQuestions ? 'high' : 'medium'}
          >
            {buttonText}
          </CTAButton>
        </div>
      </div>
      <hr />
      <div className="bottom-row">
        <Text
          as="div"
          variant="body12"
          className={classNames('exp-plan-builder-footer-description', {
            '--hasTermsApply': termsApplyRichText,
          })}
        >
          {description}
        </Text>
        {termsApplyRichText && (
          <div
            className={classNames('terms-apply', {
              '--hasDescription': description,
            })}
            dangerouslySetInnerHTML={{ __html: termsApplyRichText }}
          />
        )}
      </div>
    </div>
  );
};
