import { shape, string, arrayOf } from 'prop-types';

export const ListCardItemSchema = shape({
  name: string,
  href: string,
});

export const ListCardSchema = shape({
  title: string,
  viewAllUrl: string,
  urlFormat: string,
  items: arrayOf(ListCardItemSchema),
});
