import Text from '@hulu/web-ui/Text';

import { METADATA_FONT_SIZES } from './DetailEntityMetadata';

const Credits = ({ credits, shouldTruncate }) => {
  return credits?.length > 0 ? (
    <div className="DetailEntityMetadata__credits">
      {credits.map((credit, i) => {
        const { prefix, items, truncatedItems } = credit;
        const relevantItems = shouldTruncate ? truncatedItems : items;

        return (
          <Text as="p" key={`credit-${i}`} breakpoints={METADATA_FONT_SIZES}>
            <span className="prefix">{prefix} </span>
            {relevantItems.map((name) => {
              return (
                <span className="DetailEntityMetadata__credit">{name}</span>
              );
            })}
          </Text>
        );
      })}
    </div>
  ) : null;
};

export default Credits;
