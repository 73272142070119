import Text from '@hulu/web-ui/Text';
import React from 'react';

import PortraitCollectionModelSchema from '../model/schema';

import { view as PortraitSlider } from '!app/components/Slider/PortraitSlider';
import { preventFocus } from '!app/lib/accessibilityUtils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';

import '../stylesheets/PortraitCollection.scss';

const PortraitCollection = (props) => {
  const {
    model: { collection },
  } = props;

  if (!collection?.items?.length) return null;

  const TrayHeaderLink = withUtagLink(
    {
      event_name: 'portrait_collection_tray_header',
      component_id: 'portrait_collection',
    },
    withUserInteraction('a', 'tray_header', 'link')
  );

  const TitleElement = () => {
    const {
      model: {
        title,
        collection: { collectionHubPath },
      },
    } = props;
    const titleText = (
      <Text breakpoints={{ xs: 'label14', lg: 'label18' }}>{title}</Text>
    );
    return collectionHubPath ? (
      <TrayHeaderLink
        className="PortraitCollection__tray-header PortraitCollection__title"
        name={`${title}-collection`}
        onMouseDown={preventFocus}
        href={collectionHubPath}
        aria-label={`${title} Collection`}
      >
        {titleText}
      </TrayHeaderLink>
    ) : (
      <div className="PortraitCollection__title">{titleText}</div>
    );
  };

  return (
    <div className="PortraitCollection cu-non-sub-portrait-collection">
      <TitleElement />
      <div className="PortraitCollection__slider">
        <PortraitSlider
          collection={collection}
          enabledTileLinks={collection.enabledTileLinks}
        />
      </div>
    </div>
  );
};

PortraitCollection.propTypes = {
  model: PortraitCollectionModelSchema,
};

export default PortraitCollection;
