import { shape, string, arrayOf, boolean } from 'prop-types';

const PlanComparisonFeaturePlanModelSchema = shape({
  slug: string,
  isApplicable: boolean,
});

const PlanComparisonFeatureDescriptionModelSchema = shape({
  richText: string,
  modalId: string,
});

export const PlanComparisonFeatureDisclaimerModelSchema = shape({
  id: string,
  richText: string,
  modalLinkText: string,
  modalId: string,
});

export const PlanComparisonFeatureModelSchema = shape({
  slug: string,
  headline: string.isRequired,
  description: PlanComparisonFeatureDescriptionModelSchema,
  disclaimer: PlanComparisonFeatureDisclaimerModelSchema,
  plans: arrayOf(PlanComparisonFeaturePlanModelSchema),
});

export const PlanComparisonPricingRowModelSchema = shape({
  slug: string,
  headline: string.isRequired,
  displayStrikethroughPrices: boolean,
  prices: arrayOf(
    shape({ priceNote: string, price: string, originalPrice: string })
  ),
});

export const PlanComparisonPlanModelSchema = shape({
  slug: string,
  headline: string,
  eyebrow: string,
  badge: string,
  ctaText: string,
  mobileCtaText: string,
  ctaAction: string,
  ctaBtnStyle: string,
  name: string,
});

const FeatureDisclaimerSchema = shape({
  richText: string,
  modalLinkText: string,
  modalContent: string,
});

export const PlanComparisonChartModelSchema = shape({
  headline: string.isRequired,
  description: string,
  addonsHeadline: string,
  addonsDescription: string,
  image: string,
  plans: arrayOf(PlanComparisonPlanModelSchema).isRequired,
  pricingRows: arrayOf(PlanComparisonPricingRowModelSchema),
  features: arrayOf(PlanComparisonFeatureModelSchema),
  featureDisclaimers: arrayOf(FeatureDisclaimerSchema),
  addons: arrayOf(PlanComparisonFeatureModelSchema),
  addonDisclaimers: arrayOf(FeatureDisclaimerSchema),
});
