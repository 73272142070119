import { shape, string, bool } from 'prop-types';

import { ANON, PARTIALLY_ENTITLED, FULLY_ENTITLED } from '!app/lib/constants';

export const StatefulPropType = shape({
  [ANON]: string,
  [PARTIALLY_ENTITLED]: string,
  [FULLY_ENTITLED]: string,
});

export const DetailEntityStoreSchema = {
  network: string,
  requirePremium: bool,
};
