import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AnchorModelSchema } from '../model/schema';

import Anchor from './Anchor';

import { getComponent } from '!app/components';

class ContentWithAnchor extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      model: { isAnchorInside, content, anchor },
      locale,
      enableBrand,
      asPath,
      geodata,
    } = this.props;
    const { type } = content;

    const Content = getComponent(type);
    if (isAnchorInside) {
      return (
        <Content
          model={content}
          locale={locale}
          enableBrand={enableBrand}
          asPath={asPath}
          geodata={geodata}
        >
          <Anchor model={anchor} />
        </Content>
      );
    }
    return (
      <div className="Anchor__container">
        <Content
          model={content}
          locale={locale}
          enableBrand={enableBrand}
          asPath={asPath}
          geodata={geodata}
        />
        <Anchor model={anchor} />
      </div>
    );
  }
}

ContentWithAnchor.propTypes = {
  model: AnchorModelSchema,
  locale: PropTypes.string,
  enableBrand: PropTypes.bool,
  asPath: PropTypes.string,
};

export default ContentWithAnchor;
