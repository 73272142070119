/**
 * This component is a wrapper for the QRCode generator library
 * https://github.com/soldair/node-qrcode
 *
 * It is currently only being used to the extend of current needs but this component can be expanded to provide
 * a lot more functionality that is offered from the library.
 */
import QRCode from 'qrcode';
import React, { useState, useEffect } from 'react';

import { ComponentLogger } from '!app/lib/logger';

const logMetaData = { logName: 'QRCodeContainer' };

const QRCodeContainer = (props) => {
  const [state, setState] = useState({
    qrCodeSvgData: '',
  });

  useEffect(() => {
    const { urlPath } = props;

    // In testing there are times that it would return a partial load. The state setting is
    // done to avoid this issue.
    if (urlPath) {
      QRCode.toString(urlPath, function (err, data) {
        if (data) {
          setState({ ...state, qrCodeSvgData: data });
        } else {
          ComponentLogger.error(
            `Error generating QR code, Error: ${err}`,
            logMetaData
          );
        }
      });
    }
  }, []);

  const { qrCodeSvgData } = state;

  return (
    <div
      className="QRCodeContainer"
      dangerouslySetInnerHTML={{ __html: qrCodeSvgData ?? '' }}
    />
  );
};

export default QRCodeContainer;
