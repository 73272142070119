import { request, gql } from 'graphql-request';
import { useQuery } from 'react-query';

import { CollectionsModalCollectionFieldsFragment } from '!app/components/Collections/query/CollectionQuery';
import { CollectionsModalQuery } from '!app/components/CollectionsModal/query/CollectionsModalQuery';
import { CollectionsModalEntityFieldsFragment } from '!app/query/fragments';

const query = (collectionsModalId) => gql`
  query {
    piece(type: "collectionsModal", id: "${collectionsModalId}") {
      model {
        ${CollectionsModalQuery}
      }
    }
  }
  ${CollectionsModalCollectionFieldsFragment}
  ${CollectionsModalEntityFieldsFragment}
`;

const useCollectionsModal = (collectionsModalId) => {
  return useQuery(
    ['collectionsModal', collectionsModalId],
    async () => {
      const data = await request('/hookup', query(collectionsModalId));
      return data?.piece?.model;
    },
    {
      enabled: Boolean(collectionsModalId),
      cacheTime: 10 * 60 * 1000, // 10 minutes (defaults to 5: https://bit.ly/3z3Uqlt)
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useCollectionsModal;
