import { ThemeProvider } from '@emotion/react';
import Button from '@hulu/web-ui/Button';
import {
  oneHuluLightTheme,
  oneHuluDarkTheme,
} from '@hulu/web-ui/style/createTheme';
import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

import { preventFocus } from '!app/lib/accessibilityUtils';

const ONE_HULU_EMPHASIS_HIGH = 'high';
const ONE_HULU_EMPHASIS_MED = 'medium';
const ONE_HULU_SIZE = 'large';
const ONE_HULU_MODE_LIGHT = 'light';
const ONE_HULU_MODE_DARK = 'dark';

const ONE_HULU_STYLE_MAP = {
  primary: {
    emphasis: ONE_HULU_EMPHASIS_HIGH,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
  secondary: {
    emphasis: ONE_HULU_EMPHASIS_MED,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
  white: {
    emphasis: ONE_HULU_EMPHASIS_HIGH,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_DARK,
  },
  black: {
    emphasis: ONE_HULU_EMPHASIS_HIGH,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
  white_outline: {
    emphasis: ONE_HULU_EMPHASIS_MED,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_DARK,
  },
  black_outline: {
    emphasis: ONE_HULU_EMPHASIS_MED,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
  'grey-border': {
    emphasis: ONE_HULU_EMPHASIS_MED,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
  welcome: {
    emphasis: ONE_HULU_EMPHASIS_HIGH,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
  green_with_black_text: {
    // not a part of one hulu project
    emphasis: ONE_HULU_EMPHASIS_HIGH,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
  green_with_white_text: {
    // not a part of one hulu project
    emphasis: ONE_HULU_EMPHASIS_HIGH,
    size: ONE_HULU_SIZE,
    mode: ONE_HULU_MODE_LIGHT,
  },
};

/**
 * Renders a CTA Button.
 * web-ui docs found here: http://web-ui-storybook.staging.hulu.com/?path=/story/button--high-emphasis-buttons
 * @param {string} useStyle style set in CMS and mapped to OneHulu version.
 * @param {string} className
 * @param {string} emphasis Accepts 'high' 'medium' or 'text'. If not set, we'll use the value from ONE_HULU_STYLE_MAP
 * @param {string} size Accepts 'large' 'medium' or 'small'. If not set, we'll use the value from ONE_HULU_STYLE_MAP
 * @param {string} buttonThemeMode Accepts 'light' or 'dark. If not set, we'll use the value from ONE_HULU_STYLE_MAP
 */
const CTAButton = ({
  useStyle = 'white',
  className,
  emphasis,
  size,
  buttonThemeMode,
  href,
  children,
  ...otherProps
}) => {
  const mappedStyle = ONE_HULU_STYLE_MAP[useStyle];
  const mode = buttonThemeMode || mappedStyle?.mode;
  const theme = mode === 'dark' ? oneHuluDarkTheme : oneHuluLightTheme;
  return (
    // ThemeProvider passes our theme object to web-ui to determine which styles to use.
    // It will override any higher order ThemeProvider
    // and allows us to show dark-mode buttons even if the 'global theme' is light-mode
    <ThemeProvider theme={theme}>
      <Button
        className={classNames('button--cta', className, {
          '--green-with-black-text': useStyle === 'green_with_black_text',
          '--green-with-white-text': useStyle === 'green_with_white_text',
        })}
        emphasis={emphasis || mappedStyle?.emphasis}
        size={size || mappedStyle?.size}
        onMouseDown={preventFocus}
        as={href ? 'a' : 'button'}
        {...otherProps}
      >
        {children}
      </Button>
    </ThemeProvider>
  );
};

CTAButton.propTypes = {
  useStyle: string.isRequired,
  className: string,
};

CTAButton.defaultProps = {
  useStyle: 'primary',
};

export default CTAButton;
