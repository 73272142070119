import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';

import { METADATA_FONT_SIZES } from './DetailEntityMetadata';

import { SERIES, TV, MOVIES } from '!app/lib/constants';
import { getType } from '!app/lib/heimdall/index';
import { GenreLink } from '!app/share/GenreLink';

const EntityHub = ({ entityType, tags }) => {
  if (!entityType || !tags) return null;

  const entityName = getType(entityType);
  const hubName = entityType === SERIES ? TV : MOVIES;
  const aria = { 'aria-label': `View ${entityName} hub` };
  const classes = classNames('DetailEntityMetadata__hub', {
    'DetailEntityMetadata__hub-with-tags': tags,
  });
  const genreLink = (
    <GenreLink
      className="DetailEntityMetadata__type"
      name="view-type-hub"
      href={`/hub/${hubName}`}
      dangerouslySetInnerHTML={{ __html: entityName }}
      {...aria}
    />
  );

  return (
    <Text className={classes} breakpoints={METADATA_FONT_SIZES}>
      {genreLink}
    </Text>
  );
};

export default EntityHub;
