import { shape, string, bool, arrayOf } from 'prop-types';

export const NavigationModelSchema = shape({
  style: string,
  sticky_mode: bool,
  cta_always: bool,
  enable_cta_toaster: bool,
  enableMinimalNav: bool,
  items: arrayOf(
    shape({
      text: string,
      url: string,
    })
  ),
  cta: string,
  cta_button_style: string,
  disable_logo: bool,
  signup_flow_entry: string,
  ctaDownloadAppText: string,
  enableStickyModeAlways: bool,
});
