import PropTypes from 'prop-types';
import React from 'react';

import { getImageUrl } from '!app/lib/imageUtils';
import Background from '!app/share/Background';

const WIDTH_RATIO = 16;
const HORIZONTAL_HEIGHT = 435;
const VERTICAL_HEIGHT = 825;
const MOBILE_HORIZONTAL_HEIGHT = 250;
const MOBILE_VERTICAL_HEIGHT = 420;
class PortraitBackground extends Background {
  getBackgroundBaseStyle(baseColor) {
    // this is the color of the hover
    return {
      backgroundColor: this.hsla(baseColor),
    };
  }

  getBackgroundArt() {
    const {
      className,
      artwork,
      mobileArtwork,
      altText,
      horizontal,
    } = this.props;
    const ratio = horizontal ? WIDTH_RATIO / 9 : 2 / 3;
    const height = horizontal ? HORIZONTAL_HEIGHT : VERTICAL_HEIGHT;
    const width = Math.round(height * ratio);
    const imgSrc = getImageUrl(artwork.path, {
      width,
      height,
    });

    if (!mobileArtwork) {
      return (
        <img
          className={`${className}__art`}
          src={imgSrc}
          role="presentation"
          alt={altText}
        />
      );
    }

    const mobileHeight = horizontal
      ? MOBILE_HORIZONTAL_HEIGHT
      : MOBILE_VERTICAL_HEIGHT;
    const mobileWidth = Math.round(mobileHeight * ratio);
    const mobileImgSrc = getImageUrl(mobileArtwork.path, {
      width: mobileWidth,
      height: mobileHeight,
    });

    return (
      <picture className={`${className}__picture`}>
        <source media="(max-width: 1023px)" srcSet={mobileImgSrc} />
        <source media="(min-width: 1024px)" srcSet={imgSrc} />
        <img
          className={`${className}__art`}
          src={imgSrc}
          role="presentation"
          alt={altText}
        />
      </picture>
    );
  }

  getBackgroundGradientStyle() {
    return {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
    };
  }

  getBackgroundHighlightStyle(baseColor) {
    const colorFrom = this.hsla(baseColor, 1);

    return {
      opacity: 1.0,
      background: `linear-gradient(0deg, ${colorFrom}, transparent)`,
    };
  }

  getBackgroundScrimStyle() {
    return {
      height: '80px',
      width: '100%',
      transform: 'rotate(-180deg)',
    };
  }

  getBackgroundOverlayStyle() {
    return {
      opacity: 0.2,
      width: '100%',
      height: '100%',
    };
  }
}

PortraitBackground.propTypes = {
  artwork: PropTypes.shape({}).isRequired,
  mobileArtwork: PropTypes.shape({}),
  altText: PropTypes.string,
  horizontal: PropTypes.bool,
};

export default PortraitBackground;
