import { PropTypes } from 'prop-types';

export const ExpGridColumnModelSchema = PropTypes.shape({
  headline: PropTypes.string,
  description: PropTypes.string,
});

export const ExpGridRowModelSchema = PropTypes.shape({
  columns: PropTypes.arrayOf(ExpGridColumnModelSchema),
});

export const ExpGridModelSchema = PropTypes.shape({
  rows: PropTypes.arrayOf(ExpGridRowModelSchema),
  isSection: PropTypes.boolean,
  verticalAlign: PropTypes.oneOf([
    'normal',
    'start',
    'center',
    'end',
    'stretch',
  ]),
});
