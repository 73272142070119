import React from 'react';

const UpArrowSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91248 4.25415C7.68467 4.02634 7.31533 4.02634 7.08752 4.25415L2.42085 8.92081C2.19305 9.14862 2.19305 9.51797 2.42085 9.74577C2.64866 9.97358 3.01801 9.97358 3.24581 9.74577L7.5 5.49158L11.7542 9.74577C11.982 9.97358 12.3513 9.97358 12.5791 9.74577C12.807 9.51796 12.807 9.14862 12.5791 8.92081L7.91248 4.25415Z"
      fill="#040405"
    />
  </svg>
);

export default UpArrowSVG;
