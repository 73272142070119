import PropTypes from 'prop-types';

const SingleImagePropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
});

export const MediaContainerSchema = PropTypes.shape({
  image: PropTypes.shape({
    desktop: SingleImagePropType,
    tablet: SingleImagePropType,
    mobile: SingleImagePropType,
  }),
});
