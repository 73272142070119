import Text from '@hulu/web-ui/Text';
import { shape, string, arrayOf, boolean } from 'prop-types';

import { view as Modal } from '!app/components/Modal';
import '../stylesheet/ExpPlanComparisonBundleHeaderModal.scss';

const ExpPlanComparisonBundleHeaderModal = (props) => {
  const {
    modal: { id, header, disclaimer, sections = [] },
  } = props;

  const bullet = (
    <img
      src="/static/hitch/static/icons/Pricing_Checkmark_green-dark.svg"
      role="presentation"
    />
  );

  return (
    <Modal model={{ id }}>
      <div className="bundle-modal">
        <Text as="h2" className="bundle-modal__header" variant="title32">
          {header}
        </Text>

        {sections.map(({ title, items = [] }, i) => {
          return (
            <section className="bundle-modal__section" key={`section-${i}`}>
              <Text
                as="h3"
                variant="subtitle16"
                className="bundle-modal__section-header"
              >
                {title}
              </Text>

              <div>
                {items.map(({ text, displayBullet }, index) => {
                  return (
                    <div
                      className="bundle-modal__section-item"
                      key={`item-${index}`}
                    >
                      <span className="bundle-modal__item-bullet">
                        {displayBullet && bullet}
                      </span>

                      <Text
                        variant="body16"
                        className="bundle-modal__item-description"
                      >
                        {text}
                      </Text>
                    </div>
                  );
                })}
              </div>
            </section>
          );
        })}
        <div
          className="bundle-modal__disclaimer"
          dangerouslySetInnerHTML={{ __html: disclaimer }}
        />
      </div>
    </Modal>
  );
};

ExpPlanComparisonBundleHeaderModal.propTypes = {
  modal: shape({
    id: string,
    header: string,
    disclaimer: string,
    sections: arrayOf(
      shape({
        title: string,
        items: arrayOf(
          shape({
            text: string,
            displayBullet: boolean,
          })
        ),
      })
    ),
  }),
};

export default ExpPlanComparisonBundleHeaderModal;
