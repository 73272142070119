import Badge from '@hulu/web-ui/Badge';
import Text from '@hulu/web-ui/Text';
import React, { Fragment } from 'react';

import { ExpLiveTVComparisonChartSchema } from '../model/schema';

import { XIcon } from './XIcon';

import { goToUrl } from '!app/lib/urlUtils';
import { fireUserInteraction } from '!app/metrics';
import CTAButton from '!app/share/CTAButton';

import '../stylesheet/ExpLiveTVComparisonChart.scss';

export const ExpLiveTVComparisonChart = ({
  model: { chartHeader, chartRows, title, subtitle },
}) => {
  return (
    <section className="live-tv-comp-chart">
      <div className="headline">
        <Text
          as="div"
          className="title"
          variant="title48"
          breakpoints={{ xs: 'title32', sm: 'title40', lg: 'title48' }}
        >
          {title}
        </Text>
        <Text
          as="div"
          className="subtitle"
          variant="subtitle18"
          breakpoints={{ xs: 'subtitle18', sm: 'subtitle24' }}
        >
          {subtitle}
        </Text>
      </div>
      <table>
        <colgroup>
          <col />
          <col className="hulu-column" />
        </colgroup>
        <thead>
          <tr>
            <th className="empty-header-cell" />
            {chartHeader?.map(
              ({ copy, ctaCopy, ctaHref, badgeCopy }, index) => (
                <th
                  key={`${copy}-${index}`}
                  className="column_header_cell"
                  scope="col"
                >
                  <div className="content">
                    {badgeCopy && (
                      <div>
                        <Badge variant="av" className="badge">
                          <Text variant="label12">{badgeCopy}</Text>
                        </Badge>
                      </div>
                    )}
                    <Text
                      variant="subtitle16"
                      breakpoints={{
                        xs: 'subtitle16',
                        sm: 'subtitle18',
                        lg: 'subtitle24',
                      }}
                    >
                      {copy}
                    </Text>
                    {ctaCopy && (
                      <CTAButton
                        buttonThemeMode="dark"
                        className="cta"
                        onClick={() => {
                          fireUserInteraction(
                            'live_comp_chart:sign_up_cta',
                            'driver_click',
                            'click'
                          );
                          goToUrl(ctaHref);
                        }}
                      >
                        <Text
                          variant="label12"
                          breakpoints={{
                            xs: 'label12',
                            sm: 'label12',
                            lg: 'label14',
                          }}
                        >
                          {ctaCopy}
                        </Text>
                      </CTAButton>
                    )}
                  </div>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {chartRows?.map(({ content, logo, features }, rowIndex) => (
            <Fragment key={content}>
              <tr className="row-header-mobile">
                <td colSpan={3}>
                  <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: logo }} />
                    <Text
                      variant="subtitle14"
                      breakpoints={{
                        xs: 'subtitle14',
                        md: 'subtitle16',
                      }}
                    >
                      {content}
                    </Text>
                  </div>
                </td>
              </tr>
              <tr key={content}>
                <th className="row-header-desktop">
                  <div dangerouslySetInnerHTML={{ __html: logo }} />
                  <Text variant="subtitle16">{content}</Text>
                </th>
                {features?.map((feature, featureIndex) => (
                  <td className="cell" key={`${rowIndex}-${featureIndex}`}>
                    {feature ? (
                      <img
                        src="/static/hitch/static/icons/Pricing_Checkmark_green-light.svg"
                        role="presentation"
                        alt="checkmark"
                      />
                    ) : (
                      <XIcon />
                    )}
                  </td>
                ))}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </section>
  );
};

ExpLiveTVComparisonChart.propTypes = {
  model: ExpLiveTVComparisonChartSchema,
};
