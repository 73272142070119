import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';

import { ConnectedLoginContainerModelSchema } from '../model/schema';

import ConnectedLoginStep from './ConnectedLoginStep';

import { WithEvents } from '!app/metrics/hoc';

import '../stylesheet/ConnectedLoginContainerStyle.scss';

const renderImage = (props) => {
  const {
    model: {
      image: { image_1x, image_2x },
    },
  } = props;

  return (
    <div
      className="ConnectedLoginContainer__image col-lg-6 col-md-6 col-xs-12"
      key="image"
    >
      <img
        className="lazyload"
        data-src={image_1x.url}
        data-srcset={`${image_1x.url} 1x, ${image_2x.url} 2x`}
        role="presentation"
        alt={image_1x.alt}
      />
    </div>
  );
};

const ConnectedLoginContainer = (props) => {
  const {
    model: { headline, id, image, description, footNote, steps },
  } = props;

  const connectedLoginContainerClassNames = classNames(
    'container-width',
    'ConnectedLoginContainer'
  );

  return (
    <div className={connectedLoginContainerClassNames} id={id}>
      <div className="row">
        <div
          className="ConnectedLoginContainer__text col-lg-6 col-md-6 col-xs-12"
          key="text"
        >
          <Text as="h2" variant="title32" className="section-headline">
            {headline}
          </Text>

          {description && (
            <WithEvents.div
              className="ConnectedLoginContainer__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {steps && (
            <ul className="ConnectedLoginContainer__steps">
              {steps.map((item, index) => (
                <ConnectedLoginStep key={index} model={item} />
              ))}
            </ul>
          )}
          {footNote && (
            <WithEvents.div
              className="ConnectedLoginContainer__footnote"
              dangerouslySetInnerHTML={{ __html: footNote }}
            />
          )}
        </div>
        {image && renderImage(props)}
      </div>
    </div>
  );
};

ConnectedLoginContainer.propTypes = {
  model: ConnectedLoginContainerModelSchema,
};

export default ConnectedLoginContainer;
