/**
 * This functionality allows for HTML link from the CMS to fire HITS events.
 * The links are expected to be in the for of HTML component content.
 *
 * It wraps the entire node in a click event.
 * If we get a click on something, we need to check to see if there's any data
 * to fire events for.  We do this by traversing up the tree (until we get to
 * the current node), and looking for data to fire.
 *
 * Example markup:
 *  <a
 *    href="hulu.com/signup"
 *    data-events="user_interaction"
 *    data-action-specifier="driver_click"
 *    data-element-specifier="masthead_text_link"
 *    data-target-display-name="sign_up_for_hulu"
 *    >Signup</a>
 *
 * interaction_type will be set as a click.
 * data-event options are one to many of the following:
 *    "hits, utag, onlyOnce, user_interaction, driver_click"
 *
 * Further NSX specific documentation:
 *  https://wiki.disneystreaming.com/display/HHUWEB/HITS+Tracking
 * @module
 */

import PropTypes from 'prop-types';
import React from 'react';

import { trackEvent } from '../metricsTracker';
import { getMetricsPageName, getMetricsDeviceType } from '../utils';

import { getDeviceType } from '!app/lib/deviceUtils';
import { getDisplayName } from '!app/lib/hoc';
import { HocLogger } from '!app/lib/logger';

const logMetaData = { hoc: 'InnerHtmlEvents' };

/**
 * Mark whether or not we've already fired an event for this node.
 *
 * @see isMarked
 * @param {HTMLElement} node The DOM node to mark.
 */
function mark(node) {
  node.setAttribute('data-fired-event', 'true');
}

/**
 * Return whether or not we've already fired an event for this node.
 *
 * NOTE (drew.hays, November 14, 2017): This is gonna be finicky.  If React *ever* decides to re-render this node
 * tree (which isn't always up to us), then the node itself gets replaced with a similar one.  That means that
 * any data we try to store on this node won't work.
 *
 * @param {HTMLElement} node The DOM node to check.
 */
function isMarked(node) {
  return node.hasAttribute('data-fired-event');
}

/**
 * Given a templated string (see below for possible values), translate the templated
 * values into real values for metrics.
 *
 * - {$pageName} => A metrics-safe version of the path.
 *                  See app/metrics/utils.js:getMetricsPageName
 *
 * - {$device}   => A metrics-safe device type (either 'mw' for mobile web or 'web' otherwise).
 *                  See app/metrics/utils.js:getMetricsDeviceType
 *
 * @param {string} data A stringified version of the data to transform.
 */
function transform(data) {
  if (!data) {
    return data;
  }

  return data
    .replace(/\{\$pageName\}/g, getMetricsPageName())
    .replace(/\{\$device\}/g, getMetricsDeviceType());
}

/**
 * Parses a data string into an object for consumption.
 *
 * @param {string} dataString A string of the format "key:value,key2:value2".
 * @returns {Object}
 */
function parseStringToObject(dataString) {
  const dataObject = {};
  if (dataString && dataString.length > 0) {
    const tokens = dataString.split(',');
    tokens.forEach((token) => {
      const tuple = token.split(':');
      dataObject[tuple[0]] = tuple[1];
    });
  }
  return dataObject;
}

/**
 * Given a string, try to parse HIT data out of it.
 *
 * @param {string} string The string to parse for HIT data.
 */
function getHITData(string) {
  let data = {};

  if (string) {
    try {
      data = JSON.parse(transform(string)) || {};
    } catch (_unused) {
      HocLogger.warn(
        `Couldn't transform ${string} (${transform(string)}) into event.`,
        logMetaData
      );
    }
  }

  return data;
}

/**
 * Fire a HIT from an instrumentation-embedded HTML node.
 *
 * @param {HTMLElement} node The DOM node to get HIT properties from.
 */
function fireHIT(node) {
  if (!node.hasAttribute('data-event-properties')) {
    return;
  }

  const eventName = node.getAttribute('data-event-name') || 'user_interaction';
  trackEvent(eventName, getHITData(node.getAttribute('data-event-properties')));
}

/**
 * Fire a Driver Click HIT using the v1 embedded html node schema.
 *
 * @param {HTMLELement} node The DOM node to get HIT data from for a driver_click
 */
function fireDriverClick(node) {
  if (!node.hasAttribute('data-event-name')) {
    return;
  }

  const target = node.getAttribute('data-event-name');

  trackEvent('user_interaction', {
    hit_version: '2.1.0',
    // Force click event since it's inline.
    interaction_type: 'click',
    element_specifier: target, // A short description of the UI element on the current page that is the target of this user action.
    action_specifier: `driver_click:${getMetricsDeviceType()}`,
  });
}

/**
 * Fire a Driver Click HIT using the v2 embedded html node schema.
 *
 * @param {HTMLELement} node The DOM node to get HIT data from for a driver_click
 */
function fireUserInteraction(node) {
  if (
    !node.hasAttribute('data-element-specifier') ||
    !node.hasAttribute('data-action-specifier')
  ) {
    return;
  }

  const element = node.getAttribute('data-element-specifier');
  const action = node.getAttribute('data-action-specifier');
  const targetDisplayName = node.getAttribute('data-target-display-name') || '';

  trackEvent('user_interaction', {
    hit_version: '2.1.0',
    // Force click event since it's inline.
    interaction_type: 'click',
    element_specifier: element, // A short description of the UI element on the current page that is the target of this user action.
    action_specifier: `${action}:${getMetricsDeviceType()}`,
    target_display_name: targetDisplayName,
  });
}

/**
 * Fire a tealium utag link event. Requires attribute data-utag-object.
 *
 * @param {HTMLELement} node
 */
function fireUtagEvent(node) {
  if (!node.hasAttribute('data-utag-object')) {
    return;
  }

  const dataObject = parseStringToObject(node.getAttribute('data-utag-object'));
  const utagData = Object.assign(dataObject, {});
  utagData.event_name += `_${getMetricsPageName()}`;
  utagData.device_category = getDeviceType();
  if (global.window.utag) {
    global.window.utag.link(utagData);
  }
}

/**
 * @param {React.MouseEventHandler} event
 */
function onClick(event) {
  const div = event.currentTarget;

  // Traverse up the tree and find an event-like node.
  let node = event.target;
  while (node != null && node !== div && !node.hasAttribute('data-events')) {
    node = node.parentElement;
  }

  if (node.hasAttribute('data-events') && !isMarked(node)) {
    const events = node.getAttribute('data-events');
    // v2 schema
    if (events.includes('hits')) {
      fireHIT(node);
    }
    if (events.includes('utag')) {
      fireUtagEvent(node);
    }
    if (events.includes('onlyOnce')) {
      mark(node);
    }

    if (events.includes('user_interaction')) {
      fireUserInteraction(node);
    }

    // v1 schema
    if (events.includes('driver_click')) {
      fireDriverClick(node);
    }
  }
}

/**
 * A higher-order-component that will capture any click event that happens on this element or
 * any of its children and check to see if the clicked element (or any of that element's parents)
 * have instrumentation events attached to them.
 *
 * This event should really only be used in conjunction with elements that have the `dangerouslySetInnerHTML`
 * properties on them, because it's not very efficient to scan an entire tree for events.
 *
 * TODO (drew.hays, November 15, 2017): Once we've decided on an appropriate schema, document it here.
 *
 * @param {React.Component} Component The component to wrap.
 */
export default function withInnerHtmlEvents(Component) {
  const render = (props) => {
    /**
     * A wrapper handler for the event that will call metrics event and
     * then the original onClick event, if any.
     *
     * @param {React.MouseEventHandler} event The onClick event details.
     */
    const handler = (event, ...args) => {
      onClick(event);
      if (props.onClick) {
        props.onClick.call(null, event, ...args);
      }
    };

    const newProps = { ...props, onClick: handler };
    return <Component {...newProps} />;
  };

  render.displayName = getDisplayName('withInnerHtmlEvents', Component);
  render.propTypes = {
    onClick: PropTypes.func,

    // While `dangerouslySetInnerHTML` is *technically* a required property
    // for this HOC, we call it a required property because you should only
    // use this HOC on components that actually use the inner HTML feature.
    // Technically, it will work on *any* file, but the performance cost of
    // this HOC can be pretty high on large HTML structures, so we want to
    // limit the scope of it as much as possible.
    //
    // Documentation of dangerouslySetInnerHTML:
    // https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
    //
    // eslint-disable-next-line react/no-unused-prop-types
    dangerouslySetInnerHTML: PropTypes.shape({
      __html: PropTypes.string.isRequired,
    }).isRequired,
  };

  return render;
}
