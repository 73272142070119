import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { PlanComparisonPlanModelSchema } from '../model/schema';

import { view as Modal } from '!app/components/Modal';
import { getPremiumNetworkFromParameter } from '!app/lib/signupUtils';
import { ctaRedirect } from '!app/lib/urlUtils';
import { fireUtagLink, fireUserInteraction } from '!app/metrics';
import {
  WithEvents,
  withUtagLink,
  withUserInteraction,
} from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';

const PlanComparisonPlan = (props) => {
  const {
    isBadgePresent,
    plan: {
      headline,
      disclaimer: { richText, modalLinkText, modalContent, id },
      ctaAction,
      ctaText,
      mobileCtaText,
      ctaBtnStyle,
      eyebrow,
      badge,
      name,
    },
    index,
    numPlans,
  } = props;

  const headCardClass = classNames(
    'plan-head__card',
    `plan-head__card-${index}`,
    `col-xs-${12 / numPlans}`,
    {
      'plan-head__card--short': !isBadgePresent,
    }
  );

  const PlanButton = withUtagLink(
    {
      event_name: 'plan_select',
      cta_placement: 'comparison_chart',
      product_name: name,
    },
    withUserInteraction(CTAButton, 'plan_select')
  );

  const onDisclaimerClick = (event) => {
    const eventTarget = get(event, 'target');
    const dataId = eventTarget && eventTarget.getAttribute('data-id');
    if (!dataId) return;
    fireUtagLink({
      event_name: dataId,
      cta_placement: 'comparison_chart',
    });
    fireUserInteraction(dataId, 'click', 'click');
  };

  /**
   * Redirect user to signup or addons.
   *
   * @param {Event} e
   */
  const redirectToSignup = (e) => {
    e.stopPropagation();
    const { user, network, requirePremium, cartAbandonment } = props;
    let from = null;
    if (requirePremium && network.length) {
      from = getPremiumNetworkFromParameter(requirePremium, network);
    }
    const ctaOptions = {
      user,
      from,
      componentUrl: ctaAction,
      ctaFields: {},
      cartAbandonment,
    };
    ctaRedirect(ctaOptions);
  };

  const getCtaButton = (text) => {
    return (
      <PlanButton
        className={`plan-card__cta plan-card__${index}_cta`}
        onClick={(e) => {
          redirectToSignup(e);
        }}
        aria-label={`Get ${headline} plan`}
        useStyle={ctaBtnStyle}
        fullWidth
      >
        {text}
      </PlanButton>
    );
  };

  return (
    <div className={headCardClass}>
      <div className="plan-head__card-content">
        {isBadgePresent && (
          <div className="plan-head__card-badge">
            {badge && (
              <Text breakpoints={{ xs: 'eyebrow10', xl: 'eyebrow12' }}>
                {badge}
              </Text>
            )}
          </div>
        )}
        <div className="plan-head__card-title">
          <Text
            breakpoints={{
              xs: 'title14',
              md: 'title18',
              xl: 'title24',
            }}
          >
            {headline}
          </Text>
        </div>
        <div className="plan-head__card-eyebrow">
          <Text
            breakpoints={{
              xs: 'eyebrow10',
              md: 'eyebrow12',
              xl: 'eyebrow14',
            }}
          >
            {eyebrow}
          </Text>
        </div>
        <div className="plan-card__button-wrapper">{getCtaButton(ctaText)}</div>
        <div className="plan-card__button-wrapper--mobile">
          {getCtaButton(mobileCtaText)}
        </div>
        {richText && (
          <WithEvents.span
            className="plan-head__card-disclaimer"
            dangerouslySetInnerHTML={{ __html: richText }}
          />
        )}
        {modalContent && (
          <>
            <span className="plan-head__card-disclaimer">
              <a
                data-toggle="modal"
                data-target={`#plan-disclaimer-modal__${id}`}
                data-id="plan-disclaimer-modal"
                title={richText}
                role="button"
                onClick={onDisclaimerClick}
              >
                {' '}
                <Text variant="body10">{modalLinkText}</Text>
              </a>
            </span>
            <Modal
              model={{ id: `plan-disclaimer-modal__${id}` }}
              className="plan-addon__info-modal"
            >
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: modalContent,
                  }}
                />
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

PlanComparisonPlan.propTypes = {
  plan: PlanComparisonPlanModelSchema,
  isBadgePresent: PropTypes.bool,
  index: PropTypes.number,
  numPlans: PropTypes.number,
  cartAbandonment: PropTypes.shape({}),
};

export default PlanComparisonPlan;
