import { PropTypes } from 'prop-types';

export const ExpLiveTVComparisonChartSchema = PropTypes.shape({
  chartHeader: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      badgeCopy: PropTypes.string,
      ctaCopy: PropTypes.string,
      ctaHref: PropTypes.string,
    })
  ),
  chartRows: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      logo: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.bool),
    })
  ),
  title: PropTypes.string,
  subtitle: PropTypes.string,
});
