import get from 'lodash/get';

import Entity from './Entity';

class Network extends Entity {
  constructor(entity) {
    super(entity);
  }

  getData() {
    const common = super.getData();
    const data = {
      ...common,
      thumbnail: get(this._entity, 'artwork.networkTile.path', null),
      description: null,
      brandWatermark: null,
    };

    return data;
  }
}

export default Network;
