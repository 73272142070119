export default {
  disclaimer: {
    en: `Live TV is available for those live local, regional,
    and national channels available in your area, which are subject to change.
    Certain channels or content may not be available in all locations or on all devices.`,
    es:
      'Hulu con TV en vivo está disponible en los canales locales, regionales y nacionales en vivo disponibles en tu área, los cuales estan sujetos a cambios. Ciertos canales podrían ofrecen solo contenido on demand. Ciertos canales o contenidos podrían no estar disponibles en todas las áreas o en todos los dispositivos.',
  },
  error_try_later: {
    en: 'Something went wrong! Please try again later.',
    es: 'Algo no salió bien. Por favor intenta de nuevo más tarde.',
  },
  see_all: {
    en: 'See all available channels',
    es: 'Todos los canales disponibles',
  },
};
