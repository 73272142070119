import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React from 'react';

import '../stylesheets/CollectionDetails.scss';
import DetailEntityMetadata from '!app/share/DetailEntityMetadata';

const CollectionDetails = (props) => {
  const {
    model: {
      name,
      description,
      rating,
      genres,
      credits,
      avFeatures,
      tags,
      collection,
      entityType,
    },
    contentOverrides,
  } = props;

  const metadataProps = {
    rating,
    genres,
    credits,
    avFeatures,
    tags,
    entityType,
  };

  return (
    <div className="CollectionDetails__tab">
      <Text
        as="p"
        breakpoints={{ xs: 'subtitle14', lg: 'subtitle16' }}
        className="CollectionDetails__subtitle"
      >
        {collection.name}
      </Text>
      <Text
        as="p"
        breakpoints={{ xs: 'title18', md: 'title24', lg: 'title32' }}
        className="CollectionDetails__title"
      >
        {contentOverrides?.overrideName || name}
      </Text>
      <Text
        as="p"
        breakpoints={{ xs: 'body14', lg: 'body16' }}
        className="CollectionDetails__desc"
      >
        {contentOverrides?.overrideDescription || description}
      </Text>
      <DetailEntityMetadata {...metadataProps} />
    </div>
  );
};

CollectionDetails.propTypes = {
  model: PropTypes.shape({}),
};

export default CollectionDetails;
