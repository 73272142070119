import Badge from '@hulu/web-ui/Badge';
import classNames from 'classnames';

import DolbyAtmosLogo from './assets/DolbyAtmosLogo';

const AvFeatures = ({ items, isInline }) => {
  return items?.length > 0 ? (
    <ul
      className={classNames('DetailEntityMetadata__av-features', {
        'DetailEntityMetadata__av-features-inline': isInline,
      })}
    >
      {items.map((feature, i) => {
        const avFeature = feature === 'atmos' ? <DolbyAtmosLogo /> : feature;
        return (
          <li key={`feature-${i}`} className="av-feature">
            <Badge variant="badge2" size="small">
              {avFeature}
            </Badge>
          </li>
        );
      })}
    </ul>
  ) : null;
};

export default AvFeatures;
