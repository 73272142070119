import { connect } from 'react-redux';

import ExpPlanComparisonChart from './view/ExpPlanComparisonChart';

import { KEY as CTAFIELDS } from '!app/redux/ctaFields';
import ctaFieldsActionHandler from '!app/redux/ctaFields/action-handlers';
import { KEY as DETAIL_ENTITY } from '!app/redux/detailEntity';
import detailEntityActionHandler from '!app/redux/detailEntity/action-handlers';
import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';
import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

const view = connect((state) => ({
  user: state[USER],
  ctaFields: state[CTAFIELDS],
  network: state[DETAIL_ENTITY]?.network || state[DETAIL_ENTITY]?.networkUpsell,
  requirePremium: state[DETAIL_ENTITY].requirePremium,
}))(ExpPlanComparisonChart);

const reducerMap = {
  [CTAFIELDS]: ctaFieldsActionHandler,
  [DETAIL_ENTITY]: detailEntityActionHandler,
  [MODAL]: modalActionHandler,
  [USER]: userActionHandler,
};

export { view, reducerMap, ExpPlanComparisonChart };
