import { connect } from 'react-redux';

import ExpPlanBuilder from './view/ExpPlanBuilder';

import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

const view = connect((state) => ({
  user: state[USER],
}))(ExpPlanBuilder);

const reducerMap = {
  [USER]: userActionHandler,
};

export { view, reducerMap, ExpPlanBuilder };
