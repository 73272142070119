import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MicroSite extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const {
      model: { cssURL, jsURL },
    } = this.props;
    if (cssURL) {
      const cssLink = document.createElement('link');
      cssLink.rel = 'stylesheet';
      cssLink.href = cssURL;
      document.body.appendChild(cssLink);
    }
    if (jsURL) {
      const jsLink = document.createElement('script');
      jsLink.src = jsURL;
      document.body.appendChild(jsLink);
    }
  }

  render() {
    const {
      model: { htmlData },
    } = this.props;
    return <div dangerouslySetInnerHTML={{ __html: htmlData }} />;
  }
}

MicroSite.propTypes = {
  model: PropTypes.shape({}),
  htmlData: PropTypes.string,
  cssURL: PropTypes.string,
  jsURL: PropTypes.string,
};

export default MicroSite;
