import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { shape, number } from 'prop-types';
import React from 'react';

import LogoIcon from '!app/components/LogoWall/view/LogoIcon';

import '../stylesheet/TabContent.scss';

const TabContent = (props) => {
  const { items, activeTab } = props;

  return (
    <div className="TabContent">
      {items.map((item, index) => {
        const { headline, subheadline, logos, disclaimer } = item;
        const isActive = index === activeTab;
        return (
          <div
            key={`${headline}Content`}
            className={classNames('TabContent__container', {
              '--inactive': !isActive,
            })}
          >
            <div
              className={classNames('TabContent__headline', {
                '--raised': logos.length > 4,
              })}
            >
              <Text breakpoints={{ xs: 'title32', md: 'title48' }}>
                {headline}
              </Text>
            </div>
            <div className="TabContent__subheadline">
              <Text breakpoints={{ xs: 'body16', lg: 'body18' }}>
                {subheadline}
              </Text>
            </div>
            <div className="TabContent__logoRow">
              {logos.map((logo) => (
                <div
                  aria-label={`${logo.name} logo`}
                  key={logo.name}
                  role="listitem"
                  className="TabContent__logo"
                >
                  <LogoIcon model={logo} />
                </div>
              ))}
            </div>
            <Text as="div" variant="body12">
              <p
                className="TabContent__disclaimer section-disclaimer"
                dangerouslySetInnerHTML={{ __html: disclaimer }}
              />
            </Text>
          </div>
        );
      })}
    </div>
  );
};

TabContent.propTypes = {
  items: shape({}),
  activeTab: number,
};

export default TabContent;
