export default {
  log_in: {
    en: 'Log In',
    es: 'Iniciar sesión',
  },
  cta_manage: {
    en: 'MANAGE MY ACCOUNT',
    es: 'ADMINISTRAR MI CUENTA',
  },
};
