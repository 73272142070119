import React from 'react';

/* istanbul ignore next */
const ClockIconSVG = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 4.99988C9.5 7.48516 7.48528 9.49988 5 9.49988C2.51472 9.49988 0.5 7.48516 0.5 4.99988C0.5 2.5146 2.51472 0.499878 5 0.499878C7.48528 0.499878 9.5 2.5146 9.5 4.99988Z"
      stroke="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28564 2.85706H5.28564V5.7142H4.28564V2.85706Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.18823 4.75964V5.75964H4.33109V4.75964H7.18823Z"
      fill="white"
    />
  </svg>
);

export default ClockIconSVG;
