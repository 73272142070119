class Translate {
  constructor(locale) {
    this.language = (locale && locale.split('-')[0]) || 'en';
  }

  /**
   * Translates the supplied message or defaults back to the matchmaker field
   * @param {string} message translatable message
   * @param {string} field hangout field content
   */
  translate(message = [], field = null) {
    return message[this.language] || field;
  }

  /**
   * Translates an object of localisation strings
   * @param {Object} messages
   */
  translateAll(messages) {
    return Object.keys(messages).reduce((prev, curr) => {
      // eslint-disable-next-line
      prev[curr] = messages[curr][this.language] || messages[curr]['en'];
      return prev;
    }, {});
  }
}

export default Translate;
