import { AlertSolidIcon, SearchIcon } from '@hulu/web-ui-icons';
import Text from '@hulu/web-ui/Text';
import { string, func, bool, shape } from 'prop-types';
import React from 'react';

import messages from './messages';

import { Translate } from '!app/i18n';
import './ZipCodeInput.scss';
import { isValidZipcode } from '!app/lib/zipcodeUtils';
import { withCustomInteraction, withUtagLink } from '!app/metrics/hoc';

const ZipCodeInput = (props) => {
  const {
    zipcode,
    area,
    isInvalidZipcode,
    isLoading,
    submitSearch,
    handleChangeZipCode,
    zipCodeInputRef,
    locale,
  } = props;

  const translator = new Translate(locale);
  const translatedMessage = translator.translateAll(messages);
  const SearchButton = withUtagLink(
    {
      event_name: 'zip_module_search',
      zip_code: zipCodeInputRef
        ? isValidZipcode(zipCodeInputRef?.current?.value)
          ? zipCodeInputRef?.current?.value
          : null
        : null,
    },
    withCustomInteraction('button', 'home_zip_code_check', {
      zip_code: zipCodeInputRef
        ? isValidZipcode(zipCodeInputRef?.current?.value)
          ? zipCodeInputRef?.current?.value
          : null
        : null,
    })
  );

  return (
    <form onSubmit={(e) => submitSearch(e)}>
      <div className="ZipCodeInput">
        <span>
          <input
            type="tel"
            id="zipcode-input"
            className={`ZipCodeInput__input
            ${isInvalidZipcode ? 'error' : null}`}
            value={zipcode}
            maxLength="5"
            onChange={(e) => handleChangeZipCode(e)}
            aria-label={`Enter Zip Code${
              isInvalidZipcode ? `: ${translatedMessage.error_invalid}` : ''
            }`}
            aria-invalid={isInvalidZipcode ? 'true' : 'false'}
            ref={zipCodeInputRef}
          />
          {!isLoading && (
            <SearchButton className="ZipCodeInput__submitButton" type="submit">
              <SearchIcon className="ZipCodeInput__searchIcon" fill="white" />
            </SearchButton>
          )}

          {isLoading && <span className="ZipCodeInput__loadingIcon" />}
        </span>
        {isInvalidZipcode && (
          <label className="ZipCodeInput__invalidZip" htmlFor="zipcode-input">
            <AlertSolidIcon fill="red" />
            <Text variant="body12">{translatedMessage.error_invalid}</Text>
          </label>
        )}
        {area && (
          <Text variant="body16" className="ZipCodeInput__area">
            {area} Area
          </Text>
        )}
      </div>
    </form>
  );
};

ZipCodeInput.propTypes = {
  zipcode: string,
  area: string,
  isInvalidZipcode: bool,
  isLoading: bool,
  submitSearch: func,
  handleChangeZipCode: func,
  zipCodeInputRef: shape({}),
  locale: string,
};

export default ZipCodeInput;
