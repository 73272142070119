import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { fireUserInteraction } from '!app/metrics/fireEvent';

class Swipe extends Component {
  constructor(props) {
    super(props);
    this.handleStartTouch = ::this.handleStartTouch;
    this.handleEndTouch = ::this.handleEndTouch;
    this.startX = 0;
    this.startY = 0;
    this.distX = 0;
    this.distY = 0;
    this.threshold = 50; // required min distance traveled to be considered swipe
    this.restraint = 25; // maximum distance allowed at the same time in perpendicular direction
    this.allowedTime = 300; // maximum time allowed to travel that distance
    this.elapsedTime = 0;
    this.startTime = 0;
  }

  /**
   * Callback when the list of tabs begin to be touched to start tracking a swipe.
   *
   * @param {Event} e
   */
  handleStartTouch(e) {
    const touchobj = e.changedTouches[0];
    this.dist = 0;
    this.startX = touchobj.pageX;
    this.startY = touchobj.pageY;
    this.startTime = new Date().getTime();
  }

  /**
   * When the touch finally ends we will compare with when the touch was started.
   * This will determine if the move was prominant enough and if it left or right.
   *
   * @param {Event} e
   */
  handleEndTouch(e) {
    const { currentSelected, itemsLength, nextFunc, prevFunc } = this.props;
    const touchobj = e.changedTouches[0];
    this.distX = touchobj.pageX - this.startX;
    this.distY = touchobj.pageY - this.startY;
    this.elapsedTime = new Date().getTime() - this.startTime;
    if (this.elapsedTime <= this.allowedTime) {
      if (
        Math.abs(this.distX) >= this.threshold &&
        Math.abs(this.distY) <= this.restraint
      ) {
        if (this.distX < 0) {
          if (itemsLength - 1 !== currentSelected) {
            fireUserInteraction('change_tab', 'change_tab', 'swipe');
            if (nextFunc) {
              nextFunc(currentSelected + 1);
            }
          }
        } else if (currentSelected > 0) {
          fireUserInteraction('change_tab', 'change_tab', 'swipe');
          if (prevFunc) {
            prevFunc(currentSelected - 1);
          }
        }
      }
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div
        className="Swipe"
        onTouchStart={(e) => this.handleStartTouch(e)}
        onTouchEnd={(e) => this.handleEndTouch(e)}
      >
        {children}
      </div>
    );
  }
}

Swipe.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nextFunc: PropTypes.func.isRequired,
  prevFunc: PropTypes.func.isRequired,
  itemsLength: PropTypes.number.isRequired,
  currentSelected: PropTypes.number.isRequired,
};

export default Swipe;
