import 'isomorphic-fetch';
import { ServiceLogger } from '!app/lib/logger';

/**
 * Fetches the program details for a given code.
 * @param {String} code code to lookup
 * @return {Object{}} Object containing program details that a code is associated with
 */
async function fetchCodeProgramDetails(code) {
  const url = `/api/codes/${code}`;
  let data = null;
  try {
    const response = await fetch(url);

    if (response.ok) {
      data = await response.json();
    }
  } catch (error) {
    ServiceLogger.debug(
      `fetchCodeProgramDetails: Error received from ${url}`,
      error
    );
  }

  return data;
}

export { fetchCodeProgramDetails };
