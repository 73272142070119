import React, { Component } from 'react';

// Map to determine which window the given time falls into.
const gradients = Object.freeze([
  {
    startTime: 4,
    angle: 135,
    top: '#54abd6',
    middle: '#a56da6',
    bottom: '#d05b6a',
  },
  {
    startTime: 9,
    angle: 135,
    top: '#244bb3',
    middle: '#65b1bf',
    bottom: '#e3d09f',
  },
  {
    startTime: 15,
    angle: 135,
    top: '#2a418c',
    middle: '#a17495',
    bottom: '#db9f85',
  },
  {
    startTime: 19,
    angle: 135,
    top: '#2d0c3f',
    middle: '#944f6e',
    bottom: '#ef9276',
  },
  {
    startTime: 23,
    angle: 135,
    top: '#231437',
    middle: '#2c385e',
    bottom: '#336e6b',
  },
]);

/**
 * Get gradient for given time.
 * @param {*} time local time
 */
const getGradient = (time) => {
  return gradients.find((element, index, array) => {
    const next = array[index === array.length - 1 ? 0 : index + 1];
    return element.startTime > next.startTime
      ? time >= element.startTime || time < next.startTime
      : time >= element.startTime && time < next.startTime;
  });
};

class GradientBackground extends Component {
  constructor() {
    super();
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    setTimeout(() => {
      const gradient = getGradient(new Date().getHours());
      this.setState({
        gradient,
      });
    });
  }

  render() {
    const { gradient } = this.state;
    if (!gradient) {
      return null;
    }
    return (
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(${gradient.angle}deg, ${gradient.top} 0%, ${gradient.middle} 50%, ${gradient.bottom} 100%)`,
          animation: 'fade-in 1s',
        }}
      />
    );
  }
}

export default GradientBackground;
