import { connect } from 'react-redux';

import ExitIntentModal from './view/ExitIntentModal';

import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';
import { toggleModal } from '!app/redux/modal/actions';

const reducerMap = {
  [MODAL]: modalActionHandler,
};

const view = connect(
  (state, ownProps) => {
    const {
      model: { id },
    } = ownProps;

    const shown = state?.modal?.[id] && Boolean(state?.modal?.[id].shown);

    return {
      shown,
    };
  },
  (dispatch) => ({
    toggleModal: (id) => dispatch(toggleModal(id)),
  })
)(ExitIntentModal);

export { view, reducerMap, ExitIntentModal };
