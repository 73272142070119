import Text from '@hulu/web-ui/Text';
import React, { useState, useEffect } from 'react';

import 'isomorphic-fetch';
import { EmailUnsubscribeSchema } from '../model/schema';

import '../stylesheet/EmailUnsubscribe.scss';
import { CTA_BUTTON_STYLES, STATUS_CODES } from '!app/lib/constants';
import { getUserIDFromQuery } from '!app/lib/utils';
import CTAButton from '!app/share/CTAButton';

const EmailUnsubscribe = (props) => {
  const {
    model: {
      unsubHeadline,
      unsubDescription,
      resubHeadline,
      resubDescription,
      errorHeadline,
      errorDescription,
      resubscribeCtaText,
      backCtaText,
    },
    query,
  } = props;
  const unsubscribeImageSrc =
    '//assetshuluimcom-a.akamaihd.net/h3o/icons/Envelope_Unsubscribe.gif';
  const subscribeImageSrc =
    '//assetshuluimcom-a.akamaihd.net/h3o/icons/Envelope_Subscribe.gif';
  const userID = getUserIDFromQuery(query?.user_id);
  const [state, setState] = useState({
    headline: unsubHeadline,
    description: unsubDescription,
    displayResubscribeCta: true,
    backCtaStyle: CTA_BUTTON_STYLES.BLACK_OUTLINE,
    envelopeGIF: unsubscribeImageSrc,
    isLoading: false,
  });

  useEffect(() => {
    const unsubscribeImg = new Image();
    unsubscribeImg.src = unsubscribeImageSrc;
    const subscribeImg = new Image();
    subscribeImg.src = subscribeImageSrc;

    async function unsubscribe() {
      const url = `/api/email/updateNotifications/${userID}?notifyNews=false`;
      const res = await fetch(url, { method: 'PUT' });
      if (res.status !== STATUS_CODES.OK) {
        displayErrorMessage();
      }
    }
    unsubscribe();
  }, []);

  const resubscribe = async () => {
    setState({ ...state, isLoading: true });
    const url = `/api/email/updateNotifications/${userID}?notifyNews=true`;
    const res = await fetch(url, { method: 'PUT' });
    if (res.status === STATUS_CODES.OK) {
      completeResub();
    } else {
      displayErrorMessage();
    }
  };

  const displayErrorMessage = () => {
    setState({
      ...state,
      headline: errorHeadline,
      description: errorDescription,
      displayResubscribeCta: false,
      backCtaStyle: CTA_BUTTON_STYLES.BLACK,
      isLoading: false,
    });
  };

  const completeResub = () => {
    setState({
      ...state,
      headline: resubHeadline,
      description: resubDescription,
      displayResubscribeCta: false,
      backCtaStyle: CTA_BUTTON_STYLES.BLACK,
      envelopeGIF: subscribeImageSrc,
      isLoading: false,
    });
  };

  const redirectToWelcome = () => {
    window.location.assign('/welcome');
  };

  const {
    headline,
    description,
    displayResubscribeCta,
    backCtaStyle,
    envelopeGIF,
    isLoading,
  } = state;

  return (
    <div className="EmailUnsubscribe">
      <div className="EmailUnsubscribe__container">
        <img
          className="EmailUnsubscribe__image"
          src={envelopeGIF}
          alt="envelope GIF"
          role="presentation"
        />
        <div className="EmailUnsubscribe__inner-container">
          <Text
            as="p"
            breakpoints={{ xs: 'title24' }}
            className="EmailUnsubscribe__headline"
          >
            {headline}
          </Text>
          <Text
            as="p"
            breakpoints={{ xs: 'body16' }}
            className="EmailUnsubscribe__description"
          >
            {description}
          </Text>

          {displayResubscribeCta && (
            <CTAButton
              className="EmailUnsubscribe__resubscribe"
              useStyle={CTA_BUTTON_STYLES.BLACK}
              onClick={resubscribe}
            >
              {!isLoading ? (
                resubscribeCtaText
              ) : (
                <div
                  className="EmailUnsubscribe__loading"
                  aria-label="loading icon"
                  role="presentation"
                />
              )}
            </CTAButton>
          )}
          <CTAButton
            className="EmailUnsubscribe__back"
            useStyle={backCtaStyle}
            onClick={redirectToWelcome}
          >
            {backCtaText}
          </CTAButton>
        </div>
      </div>
    </div>
  );
};

EmailUnsubscribe.propTypes = {
  model: EmailUnsubscribeSchema,
};

export default EmailUnsubscribe;
