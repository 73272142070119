import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useLocalStorage } from '!app/lib/hooks/useLocalStorage';
import { toPremiereDateFormat, isFutureDate } from '!app/lib/utils';
import {
  WithEvents,
  withUtagLink,
  withUserInteraction,
} from '!app/metrics/hoc';
import '../stylesheet/TopHatStyle.scss';

const LP_TOPHAT = 'lp_tophat';

const TopHat = (props) => {
  const {
    model: { topHatId, body },
    shown,
    closeTopHat,
    openTopHat,
    latestSeason,
  } = props;

  const hasUpcomingSeason = latestSeason
    ? isFutureDate(latestSeason.firstEpPremiereDate)
    : false;

  const setTopHatId = () => {
    if (hasUpcomingSeason && !topHatId) return `${LP_TOPHAT}_upcoming`;
    return topHatId ? `${LP_TOPHAT}_${topHatId}` : LP_TOPHAT;
  };

  const localStorageKey = setTopHatId();

  const CloseButton = withUtagLink(
    { event_name: 'top_hat_close' },
    withUserInteraction('button', 'tophat', 'close')
  );

  /**
   * Sets an expiration date and time for our tophat.
   */
  const calculateExpiration = () => {
    const numMinutes = 24 * 60; // 24 hours in  minutes
    const in24Hours = new Date(new Date().getTime() + numMinutes * 60 * 1000);
    return in24Hours;
  };

  const {
    storedValue: localStorageEntry,
    setValue: setLocalStorageEntry,
    removeValue: removeLocalStorageEntry,
    isExpired,
  } = useLocalStorage(localStorageKey, null, calculateExpiration());

  useEffect(() => {
    if (isExpired || !localStorageEntry) {
      openTopHat();
      removeLocalStorageEntry(localStorageKey);
    }
  }, []);

  /**
   * Sets an expiration date for the tophat and closes it.
   */
  const setExpirationAndClose = () => {
    setLocalStorageEntry(calculateExpiration());
    closeTopHat();
  };

  const setTopHatContent = () => {
    if (!hasUpcomingSeason) return body;
    const { season, firstEpPremiereDate } = latestSeason;
    const seasonCopy =
      season > 0 ? `Season ${season} Coming` : 'Series Premiere';
    return `${seasonCopy} ${toPremiereDateFormat(firstEpPremiereDate)}`;
  };

  if (!shown) return null;
  return (
    <div className="tophat cu-tophat" role="banner">
      <WithEvents.div
        className="tophat__body"
        dangerouslySetInnerHTML={{ __html: setTopHatContent() }}
      />
      {!hasUpcomingSeason && (
        <CloseButton
          className="tophat__button"
          onClick={setExpirationAndClose}
          aria-label="Close Banner"
        >
          &times;
        </CloseButton>
      )}
    </div>
  );
};

TopHat.propTypes = {
  model: PropTypes.shape({
    body: PropTypes.string,
  }),
  shown: PropTypes.bool,
  closeTopHat: PropTypes.func.isRequired,
  openTopHat: PropTypes.func.isRequired,
  latestSeason: PropTypes.shape({
    season: PropTypes.string,
    previousSeason: PropTypes.string,
    hasUpcomingSeason: PropTypes.boolean,
    hasLastEpPremiered: PropTypes.boolean,
    firstEpPremiereDate: PropTypes.string,
    lastEpPremiereDate: PropTypes.string,
  }),
};

export default TopHat;
