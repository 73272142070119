import { connect } from 'react-redux';

import DualCtaMasthead from './view/DualCtaMasthead';

import { KEY as CTAFIELDS } from '!app/redux/ctaFields';
import ctaFieldsActionHandler from '!app/redux/ctaFields/action-handlers';
import { KEY as TOPHAT } from '!app/redux/tophat';
import topHatActionHandler from '!app/redux/tophat/action-handlers';
import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

const view = connect((state) => {
  return {
    user: state[USER],
    topHatShown: state[TOPHAT].shown,
    ctaFields: state[CTAFIELDS],
  };
})(DualCtaMasthead);

const reducerMap = {
  [USER]: userActionHandler,
  [TOPHAT]: topHatActionHandler,
  [CTAFIELDS]: ctaFieldsActionHandler,
};

export { view, reducerMap };
