import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { InView as Observer } from 'react-intersection-observer';

import { PlanComparisonChartModelSchema } from '../model/schema';

import PlanComparisonDisclaimer from './PlanComparisonDisclaimer';
import PlanComparisonFeature from './PlanComparisonFeature';
import PlanComparisonPlan from './PlanComparisonPlan';
import PlanComparisonPricingRow from './PlanComparisonPricingRow';

import { isBrowser } from '!app/lib/environment';
import { useRect } from '!app/lib/hooks/useRect';
import { fireUserInteraction } from '!app/metrics/fireEvent';
import { DetailEntityStoreSchema } from '!app/share/schema';
import '../stylesheet/PlanComparisonChart.scss';

const NAV_HEIGHT = 74;
const PlanComparisonChart = (props) => {
  const {
    model: {
      headline,
      description,
      addonsHeadline,
      addonsDescription,
      image,
      plans,
      pricingRows,
      features,
      featureDisclaimers,
      addons,
      addonDisclaimers,
    },
    user,
    requirePremium,
    network,
  } = props;
  const [isSticky, setIsSticky] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [topObserverRootMargin, setTopObserverRootMargin] = useState(0);
  const [bottomObserverRootMargin, setBottomObserverRootMargin] = useState(0);
  const [mainNavSticky, setMainNavSticky] = useState(false);
  const headContainerEl = useRef(null);
  const headColumnEl = useRef(null);
  const stickyHeadEl = useRef(null);
  const plansSectionEl = useRef(null);
  const isBadgePresent = plans.some(({ badge }) => badge);
  const numPlans = plans.length;

  const headContainerRect = useRect(headContainerEl);
  const headColumnRect = useRect(headColumnEl);
  const stickyHeadRect = useRect(stickyHeadEl);
  // Together, these let us know when to make the plans header sticky.
  const stickyClasses = {
    'plan-head-fixed': isSticky,
    'plan-head-fixed--flush': isSticky && !mainNavSticky,
  };
  const LARGE_WIDTH = 1024;

  // Group classes together to easier manage variables
  const cn = {
    headColumnClass: classNames(
      'col-xs-12',
      'col-lg-5',
      'plan-head-column',
      {
        'plan-head-column--short': !isBadgePresent,
      },
      stickyClasses
    ),
    headClass: classNames('col-xs-12', 'col-lg-7', 'plan-head', stickyClasses),
    headContainer: classNames('plan-head-container', stickyClasses),
    featureClass: classNames('plan-feature', stickyClasses),
    addonsClass: classNames('plan-addon', {
      'plan-addon_show': showAddons,
    }),
    plansContainerClass: classNames('plans-container'),
  };

  useEffect(() => {
    setObserverRootMargin();
    window.addEventListener('resize', setObserverRootMargin);

    return () => {
      window.removeEventListener('resize', setObserverRootMargin);
    };
  }, [headContainerRect, headColumnRect, stickyHeadRect]);

  /**
   * Expands/collapses Addon accordion state.
   */
  const toggleAddonsState = () => {
    fireUserInteraction(
      'default',
      `landing_plan_add_ons:${showAddons ? 'hide' : 'show'}`,
      'click',
      false
    );
    setShowAddons(!showAddons);
  };

  /**
   * Sets the rootmargin states for Observables according to screen size.
   */
  const setObserverRootMargin = () => {
    const mainNavEl = document.querySelector('header');
    const isMainNavSticky = mainNavEl
      ? mainNavEl.classList.contains('navigation--sticky')
      : false;
    setMainNavSticky(isMainNavSticky);
    const navigationHeights = {
      desktop: isMainNavSticky ? -mainNavEl.offsetHeight : 0,
      mobile: isMainNavSticky ? -mainNavEl.offsetHeight : 0,
    };
    const isSmallScreen = window.innerWidth < LARGE_WIDTH;
    const topRootMargin = isSmallScreen
      ? headColumnRect.height + navigationHeights.mobile
      : navigationHeights.desktop;
    const bottomRootMargin = isSmallScreen
      ? stickyHeadRect.height - navigationHeights.mobile - window.innerHeight
      : stickyHeadRect.height - navigationHeights.desktop - window.innerHeight;
    setBottomObserverRootMargin(bottomRootMargin);
    setTopObserverRootMargin(topRootMargin);
  };

  /**
   * Makes the plans header sticky if the chart header scrolls out of view.
   * @param {boolean} inView
   */
  const setStickyState = (inView) => {
    const plansSectionHeight = plansSectionEl.current.getBoundingClientRect()
      .top;
    const isWithinViewport = plansSectionHeight < window.innerHeight;
    const isStickyHeader = !inView && isWithinViewport;
    setIsSticky(isStickyHeader);
  };

  const handleBottomObserverChange = () => {
    setIsSticky(false);
  };

  const getPlanHeaderPosition = () => {
    const isSmallScreen = isBrowser() ? window.innerWidth < LARGE_WIDTH : false;

    if (isSmallScreen) {
      return mainNavSticky
        ? -(headColumnRect.height - NAV_HEIGHT)
        : -headColumnRect.height;
    }

    return mainNavSticky ? NAV_HEIGHT : 0;
  };

  return (
    <div
      className="plan-comparison-chart cu-plans"
      id="plans"
      role="region"
      aria-label="Plans"
      ref={plansSectionEl}
    >
      <div className={cn.plansContainerClass}>
        <Observer
          rootMargin={`${topObserverRootMargin}px 0px 0px 0px`}
          onChange={(inView) => setStickyState(inView)}
        >
          <div className="plans__observer" />
        </Observer>
        <div
          className={cn.headContainer}
          ref={headContainerEl}
          style={{
            top: getPlanHeaderPosition(),
          }}
        >
          <div className={cn.headColumnClass} ref={headColumnEl}>
            <Text
              as="h3"
              breakpoints={{ xs: 'title24', md: 'title32' }}
              className="plan-head__headline"
            >
              {headline}
            </Text>
            <div
              className="plan-head__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {image && <img className="plan-head__image" src={image} />}
          </div>
          <div className={cn.headClass} ref={stickyHeadEl}>
            {plans.map((plan, i) => (
              <PlanComparisonPlan
                numPlans={numPlans}
                plan={plan}
                key={`feature-plan-${i}`}
                user={user}
                network={network}
                requirePremium={requirePremium}
                isBadgePresent={isBadgePresent}
                index={i}
              />
            ))}
          </div>
        </div>
        <div
          className={cn.featureClass}
          style={{
            paddingTop: isSticky ? headContainerRect.height : null,
          }}
        >
          {pricingRows.map((pricingRow) => (
            <PlanComparisonPricingRow
              numPlans={numPlans}
              key={pricingRow.slug}
              pricingRow={pricingRow}
            />
          ))}
          {features.map((feature, i) => (
            <PlanComparisonFeature
              numPlans={numPlans}
              key={feature.slug}
              feature={feature}
              index={i}
              type="feature"
            />
          ))}
        </div>
        {featureDisclaimers.length > 0 && (
          <div className="plan-feature-disclaimers">
            {featureDisclaimers.map((disclaimer, i) => (
              <div
                key={`feature-disclaimer-${i}`}
                className="plans-disclaimer__container"
              >
                <PlanComparisonDisclaimer
                  disclaimer={disclaimer}
                  index={i}
                  type="feature"
                />
              </div>
            ))}
          </div>
        )}
        {addons.length > 0 && (
          <>
            <div className={cn.addonsClass}>
              <div className="plan-addon__head col-xs-12">
                <div className="col-lg-6 plan-addon__head-desktop">
                  <Text
                    as="h3"
                    breakpoints={{ xs: 'title24', md: 'title32' }}
                    className="plan-addon__head-title"
                  >
                    {addonsHeadline}
                  </Text>
                  <div
                    className="plan-addon__head-desc"
                    dangerouslySetInnerHTML={{ __html: addonsDescription }}
                  />
                </div>
              </div>
              <div className="plan-body__addon_container">
                {addons.map((feature, i) => (
                  <PlanComparisonFeature
                    key={feature.slug}
                    feature={feature}
                    numPlans={numPlans}
                    index={i}
                    type="addon"
                  />
                ))}
              </div>
              {addonDisclaimers.length > 0 && (
                <div className="plans-container__bottom-container">
                  {addonDisclaimers.map((disclaimer, i) => (
                    <PlanComparisonDisclaimer
                      key={`addon-disclaimer-${i}`}
                      disclaimer={disclaimer}
                      index={i}
                      type="addon"
                    />
                  ))}
                </div>
              )}
            </div>
            <div
              className="plan-addon_controller"
              onClick={toggleAddonsState}
              role="button"
              aria-label="See Addons"
              aria-pressed="false"
            >
              <Text variant="body16">
                {`${showAddons ? 'Hide' : 'Show'} Add-ons`}
              </Text>
              <img
                src={`/static/hitch/static/icons/Dropdown_${
                  showAddons ? 'Up' : 'Down'
                }_Arrow.svg`}
                role="presentation"
                alt="See Add-ons."
              />
            </div>
          </>
        )}
      </div>
      <Observer
        rootMargin={`100% 0px ${bottomObserverRootMargin}px 0px`}
        onChange={handleBottomObserverChange}
      >
        <div className="plans__bottom-observer" />
      </Observer>
    </div>
  );
};

PlanComparisonChart.propTypes = {
  model: PlanComparisonChartModelSchema.isRequired,
  ...DetailEntityStoreSchema,
};

export default PlanComparisonChart;
