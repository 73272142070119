import { shape, bool, number, string, arrayOf, oneOfType } from 'prop-types';

export const PlanCardModelSchema = shape({
  highlight: bool,
  id: oneOfType([number, string]).isRequired,
  price: shape({
    displayPrice: string.isRequired,
  }).isRequired,
  label: string,
  headline: string.isRequired,
  subheadline: string,
  program: string,

  features: oneOfType([arrayOf(string), string]),
  addon_headline: string,
  open_addons: string,
  close_addons: string,
  text_addons: arrayOf(string),
  cta_button_style: string,
  below_cta: string,
  mobile_button: string,
  mobile_close: string,

  ctaTextStates: shape({}),
  ctaActionStates: shape({}),
});

export const PlansContainerModelSchema = shape({
  headline: string.isRequired,
  description: string,
  disclaimer: string,
  plans: arrayOf(PlanCardModelSchema).isRequired,
});
