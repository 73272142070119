import { HuluLogin, LoginModal } from '@hulu/login-modal';
import { connect } from 'react-redux';

import Navigation from './view/Navigation';

import { KEY as CTAFIELDS } from '!app/redux/ctaFields';
import ctaFieldsActionHandler from '!app/redux/ctaFields/action-handlers';
import { KEY as DETAIL_ENTITY } from '!app/redux/detailEntity';
import detailEntityActionHandler from '!app/redux/detailEntity/action-handlers';
import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';
import { toggleModal } from '!app/redux/modal/actions';
import { KEY as TOPHAT } from '!app/redux/tophat';
import topHatActionHandler from '!app/redux/tophat/action-handlers';
import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

// TODO: Legacy login remove #HUWEB-30424
const {
  stateKey: HULU_LOGIN,
  actionHandlers: huluLoginActionHandlers,
} = HuluLogin;
const {
  stateKey: LOGIN_MODAL,
  actionHandlers: loginModalActionHandlers,
  actions: { showLogin },
} = LoginModal;

const view = connect(
  (state) => {
    return {
      user: state[USER],
      topHatShown: state[TOPHAT].shown,
      ctaFields: state[CTAFIELDS],
      network:
        state[DETAIL_ENTITY]?.network || state[DETAIL_ENTITY]?.networkUpsell,
      requirePremium: state[DETAIL_ENTITY].requirePremium,
    };
  },
  (dispatch) => ({
    toggleModal: (id) => {
      dispatch(toggleModal(id));
      dispatch(showLogin());
    },
  })
)(Navigation);

const reducerMap = {
  [USER]: userActionHandler,
  [TOPHAT]: topHatActionHandler,
  [CTAFIELDS]: ctaFieldsActionHandler,
  [HULU_LOGIN]: huluLoginActionHandlers,
  [LOGIN_MODAL]: loginModalActionHandlers,
  [MODAL]: modalActionHandler,
  [DETAIL_ENTITY]: detailEntityActionHandler,
};

export { view, reducerMap };
