import { string, shape, bool, arrayOf } from 'prop-types';

const NetworkIconsSchema = shape({
  icon: string.isRequired,
  name: string.isRequired,
});

const TierModalModelSchema = shape({
  name: string,
  id: string.isRequired,
  price: string,
  body: string,
  shouldRandomize: bool,
  logos: arrayOf(NetworkIconsSchema),
});

export default TierModalModelSchema;
