import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TileContent from './TileContent';
import TileContentEpisode from './TileContentEpisode';
import TileThumbnail from './TileThumbnail';

import {
  EPISODE_SCHEMA_ATTRIBUTES,
  MOVIE_SCHEMA_ATTRIBUTES,
  MOVIE,
  EPISODE,
} from '!app/lib/constants';

import '../stylesheets/Tile.scss';

class Tile extends Component {
  state = {};

  render() {
    const {
      darkTheme,
      contentType,
      index,
      premiereDate,
      includeSEOAttr,
      asset,
      title,
      detailsUrl,
    } = this.props;
    const tileClass = classNames(
      'Tile',
      {
        'Tile--dark': darkTheme,
      },
      'cu-tile'
    );

    const Content =
      contentType === 'episode' ? TileContentEpisode : TileContent;
    const episodeProps =
      contentType === EPISODE ? EPISODE_SCHEMA_ATTRIBUTES : {};
    const addMovieProps = asset?.type === MOVIE && includeSEOAttr;
    const movieProps = addMovieProps ? MOVIE_SCHEMA_ATTRIBUTES : {};

    return (
      <div
        className={tileClass}
        {...episodeProps}
        {...movieProps}
        data-automationid={`tile_${index}`}
      >
        {addMovieProps && (
          <div className="Tile__microdata">
            <meta itemProp="position" content={index} />
            <meta itemProp="dateCreated" content={premiereDate} />
            <meta itemProp="name" content={title} />
            <meta itemProp="url" content={detailsUrl} />
          </div>
        )}
        <TileThumbnail {...this.props} addMovieProps={addMovieProps} />
        <Content {...this.props} addMovieProps={addMovieProps} />
      </div>
    );
  }
}

Tile.propTypes = {
  contentType: PropTypes.oneOf(['classic', 'episode']),
  darkTheme: PropTypes.bool,
};

Tile.defaultProps = {
  contentType: 'classic',
};

export default Tile;
