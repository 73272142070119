import TierModal from './views/TierModal';

import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';

const reducerMap = {
  [MODAL]: modalActionHandler,
};

export { TierModal as view, reducerMap };
