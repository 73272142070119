import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import TruncatedText from '!app/share/TruncatedText';

const TileContentEpisode = (props) => {
  const {
    asset,
    description,
    descriptionLines,
    episode,
    partOfSeries,
    seasonNumber,
    title,
  } = props;

  const Prompt = () => {
    const action = asset?.number && `Episode ${asset.number}`;
    if (!action) return null;

    return (
      <Text as="div" variant="eyebrow12" className="Tile__action">
        {action}
      </Text>
    );
  };

  return (
    <>
      <div className="Tile__content">
        <Prompt />
        <TruncatedText
          breakpoints={{ xs: 'title14', md: 'title16' }}
          as="h3"
          maxLines={1}
          className="Tile__title"
          data-automationid="tile_title"
          itemProp="name"
        >
          {title}
        </TruncatedText>
        <>
          <meta itemProp="episodeNumber" content={episode} />
          <meta itemProp="partOfSeason" content={seasonNumber} />
          {partOfSeries && (
            <meta itemProp="partOfSeries" content={partOfSeries} />
          )}
        </>
      </div>
      {description ? (
        <TruncatedText
          as="p"
          variant="body14"
          lineHeightEm={1.429}
          maxLines={descriptionLines || 1}
          className="Tile__description"
          itemProp="description"
          dataAutomationId="tile_description"
        >
          {description}
        </TruncatedText>
      ) : null}
    </>
  );
};

TileContentEpisode.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionLines: PropTypes.number,
  description: PropTypes.string,
  action: PropTypes.string,
};

export default TileContentEpisode;
