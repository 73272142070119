import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useImageLoadStatus } from '!app/lib/hooks/useImageLoadStatus';
import TruncatedText from '!app/share/TruncatedText';

const PortraitTitleArt = (props) => {
  const { imageUrls, title } = props;
  const imageRef = React.createRef();
  const { isLoaded, hasError } = useImageLoadStatus(imageRef);

  const titleArtClasses = classnames('PortraitTile__title-art', {
    'PortraitTile__title-art--loaded': isLoaded,
  });
  const titleTextClasses = classnames('PortraitTile__title-art__text', {
    'PortraitTile__title-art__text--fade-in': hasError,
  });
  const titleBreakpoints = { xs: 'title24', md: 'title32' };
  return imageUrls ? (
    <div className={titleArtClasses}>
      <img
        className="PortraitTile__title-art__image"
        ref={imageRef}
        src={imageUrls.small}
        srcSet={`${imageUrls.small} 1x, ${imageUrls.small_retina} 2x`}
        alt={title}
        role="presentation"
        aria-label={title}
      />
      <TruncatedText className={titleTextClasses}>{title}</TruncatedText>
    </div>
  ) : (
    <TruncatedText
      maxLines={1}
      variant="title24"
      breakpoints={titleBreakpoints}
    >
      {title}
    </TruncatedText>
  );
};

PortraitTitleArt.propTypes = {
  imageUrls: PropTypes.shape({}),
  title: PropTypes.string,
};

export default PortraitTitleArt;
