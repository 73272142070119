import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ReactDOM from 'react-dom';
import './Banner.scss';

const TIMEOUT_TIME_MS = 5000;

class Banner extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      visible: false,
    };
    const { id } = this.props;
    this.id = id || 'banner';
  }

  toggle() {
    const { visible } = this.state;
    if (!visible) {
      this.setState({ visible: true });
      setTimeout(() => {
        this.setState({ visible: false });
      }, TIMEOUT_TIME_MS);
    }
  }

  render() {
    return null;
  }

  componentDidMount() {
    let p = this.id && document.getElementById(this.id);
    if (!p) {
      p = document.createElement('div');
      p.id = this.id;
      p.className = 'banner';
      document.body.insertBefore(p, document.body.firstChild);
    }
    this.element = p;
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    document.body.removeChild(this.element);
  }

  componentDidUpdate() {
    const { children } = this.props;
    const { visible } = this.state;

    ReactDOM.render(
      <ReactCSSTransitionGroup
        component="div"
        transitionName="banner"
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={1000}
      >
        {visible ? children : null}
      </ReactCSSTransitionGroup>,
      this.element
    );
  }
}

Banner.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default Banner;
