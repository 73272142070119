export default {
  enter_code: {
    en: 'ENTER YOUR ZIP CODE',
    es: 'Ingresa tu código postal',
  },
  channels_in_area_upper: {
    en: 'CHANNELS IN YOUR AREA',
    es: 'Canales en tu área',
  },
  channels_in_area_lower: {
    en: 'Channels in your area',
    es: 'Canales en tu área',
  },
  enter_your_home: {
    en: 'Enter your home ZIP code for channels available in your area.',
    es:
      'Ingresa tu código postal para conocer los canales disponibles en tu área.',
  },
  placeholder: {
    en: 'Enter Zip Code',
    es: 'Ingresa Tu Código Postal',
  },
  button: {
    en: 'SUBMIT',
    es: 'Enviar',
  },
  disclaimer: {
    en: `Live TV is available for those live local, regional,
    and national channels available in your area, which are subject to change.
    Certain channels or content may not be available in all locations or on all devices.`,
    es:
      'Hulu con TV en vivo está disponible en los canales locales, regionales y nacionales en vivo disponibles en tu área, los cuales estan sujetos a cambios. Ciertos canales podrían ofrecen solo contenido on demand. Ciertos canales o contenidos podrían no estar disponibles en todas las áreas o en todos los dispositivos.',
  },
  disclaimer_detailed: {
    en: `The channels below are available in your ZIP code for this plan.
    Live TV is available for those live local, regional, and national channels available in your area, which are subject to change.
    Certain channels or content may not be available in all locations or on all devices.`,
    es:
      'Los canales a continuación están disponibles en tu código postal para este plan. Hulu con TV en vivo está disponible en los canales locales, regionales y nacionales en vivo disponibles en tu área, los cuales estan sujetos a cambios. Ciertos canales podrían ofrecen solo contenido on demand. Ciertos canales o contenidos podrían no estar disponibles en todas las áreas o en todos los dispositivos.',
  },
  disclaimer_ie: {
    en:
      '* Channels labeled "On Demand only" are only available On Demand for your ZIP code. National programming, including most shows and movies, is typically available the day after air on broadcast TV.',
    es:
      '* Los canales clasificados como "Solo On Demand" están disponibles en tu código postal solo como programación on demand (no en vivo). La programación nacional, incluyendo la mayoría de los programas y películas, se encuentra disponible al día siguiente de su transmisión en vivo en TV.',
  },
  on_demand: {
    en: 'On Demand only',
    es: 'Solo On Demand',
  },
  disclaimer_on_demand: {
    en:
      'This channel is only available On Demand for your ZIP code. National programming, including most shows and movies, is typically available the day after air on broadcast TV.',
    es:
      'Este canal solo está disponible On Demand en tu código postal. La programación nacional, incluyendo la mayoría de los programas y películas, se encuentra disponible al día siguiente de su transmisión en vivo en TV.',
  },
  channels_sports: {
    en: 'Sports Channels',
    es: 'Canales de deportes',
  },
  channels_local: {
    en: 'Live Local Channels',
    es: 'Canales locales en vivo',
  },
  channels_entertainment: {
    en: 'Entertainment & Lifestyle Channels',
    es: 'Canales de entretenimiento y estilo de vida',
  },
  channels_family: {
    en: 'Family & Kids Channels',
    es: 'Canales familiares y para niños',
  },
  channels_movies: {
    en: 'Movies Channels',
    es: 'Canales de películas',
  },
  channels_news: {
    en: 'News Channels',
    es: 'Canales de noticias',
  },
  channels_premium: {
    en: 'Add-on Premium Channels',
    es: 'Add-ons de Canales Premium',
  },
  error_invalid: {
    en: 'This is an invalid ZIP code. Please try again.',
    es: 'Este código postal no es válido. Por favor intenta de nuevo.',
  },
  error_try_later: {
    en: 'Something went wrong! Please try again later.',
    es: 'Algo no salió bien. Por favor intenta de nuevo más tarde.',
  },
  entertainment_addon: {
    en: 'Entertainment Add-on',
    es: 'Entertainment Add-on',
  },
  espanol_addon: {
    en: 'Español Add-on',
    es: 'Español Add-on',
  },
  sports_addon: {
    en: 'Sports Add-on',
    es: 'Deportes Add-on',
  },
};
