import classNames from 'classnames';
import React, { useState } from 'react';

import { SupportedDevicesSchema } from '../model/schema';

import { useWindowResize } from '!app/lib/hooks/useWindowResize';

import '../stylesheet/SupportedDevices.scss';

const SupportedDevices = (props) => {
  const {
    model: {
      headline,
      description,
      disclaimerDesktop,
      disclaimerMobile,
      styleMode,
      deviceTabs,
    },
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [isViewingMore, setIsViewingMore] = useState(false);
  const {
    windowSize: { width },
  } = useWindowResize();

  const mobileViewMaxWidth = 768;
  const maxDeviceCountDesktop = 6;
  const maxDeviceCountMobile = 4;
  const devicesPerRow = 3;
  const viewMore = 'VIEW MORE ';
  const viewLess = 'VIEW LESS ';
  const disclaimer =
    width > mobileViewMaxWidth ? disclaimerDesktop : disclaimerMobile;
  const darkClass = { '--dark': styleMode === 'dark' };

  function tabClick(tabNum) {
    setActiveTab(tabNum);
    setIsViewingMore(false);
  }

  function viewMoreClick() {
    setIsViewingMore(!isViewingMore);
  }

  function renderViewMoreButton(numDevices) {
    if (
      (width > mobileViewMaxWidth && numDevices > maxDeviceCountDesktop) ||
      (width <= mobileViewMaxWidth && numDevices > maxDeviceCountMobile)
    ) {
      const viewButtonClasses = classNames(
        'SupportedDevices__view-button',
        {
          '--viewingMore': isViewingMore,
        },
        darkClass
      );
      const arrowClasses = classNames(
        {
          'SupportedDevices__arrow-down': !isViewingMore,
          'SupportedDevices__arrow-up': isViewingMore,
        },
        darkClass
      );
      return (
        <button className={viewButtonClasses} onClick={viewMoreClick}>
          {isViewingMore ? viewLess : viewMore}
          <div className={arrowClasses} />
        </button>
      );
    }

    return undefined;
  }

  function renderTabs() {
    return (
      <div className="SupportedDevices__tab-section">
        {deviceTabs.map((item, index) => {
          const tabClasses = classNames(
            'SupportedDevices__tab',
            {
              '--active': activeTab === index,
            },
            darkClass
          );
          return (
            <button
              className={tabClasses}
              aria-pressed={activeTab === index}
              aria-label={`${item.title} tab`}
              key={item.title}
              onClick={() => tabClick(index)}
            >
              {item.title} ({item.devices.length})
            </button>
          );
        })}
      </div>
    );
  }

  function renderDevices() {
    const numDevices = deviceTabs[activeTab].devices.length;
    const deviceSectionClasses = classNames(
      'SupportedDevices__device-section',
      {
        '--centered': numDevices <= devicesPerRow,
      }
    );
    return (
      <div>
        <div className={deviceSectionClasses}>
          {deviceTabs[activeTab].devices.map((item, index) => {
            // show at least 3 devices. If 'View More' was selected, show all. If deviceList is less than max to be shown for desktop or mobile, show all.
            if (
              index < devicesPerRow ||
              isViewingMore ||
              (width > mobileViewMaxWidth &&
                numDevices <= maxDeviceCountDesktop) ||
              (width <= mobileViewMaxWidth &&
                numDevices <= maxDeviceCountMobile)
            ) {
              return (
                <div
                  className={classNames('SupportedDevices__device', darkClass)}
                  key={`device--${index}`}
                >
                  <img
                    className="SupportedDevices__img"
                    aria-label={`${item.label} logo`}
                    src={item.icon}
                    role="presentation"
                  />
                  <div className="SupportedDevices__text-box">
                    <div className="SupportedDevices__text">{item.label}</div>
                  </div>
                </div>
              );
            }

            return null;
          })}
        </div>
        {renderViewMoreButton(numDevices)}
      </div>
    );
  }

  return (
    <div className={classNames('SupportedDevices', darkClass)}>
      <div
        className={classNames('SupportedDevices__inner-container', darkClass)}
      >
        <div className="SupportedDevices__top-bar" />
        <div className={classNames('SupportedDevices__text-fields', darkClass)}>
          <div className="SupportedDevices__headline">{headline}</div>
          <div
            className={classNames('SupportedDevices__description', darkClass)}
          >
            {description}
          </div>
        </div>
        {renderTabs()}
        <div className={classNames('SupportedDevices__gray-bar', darkClass)} />
        {renderDevices()}
      </div>
      <div
        className={classNames('SupportedDevices__disclaimer', darkClass)}
        dangerouslySetInnerHTML={{ __html: disclaimer }}
      />
    </div>
  );
};

SupportedDevices.propTypes = {
  model: SupportedDevicesSchema,
};

export default SupportedDevices;
