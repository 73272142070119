import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { string, shape, arrayOf, number, bool } from 'prop-types';
import React from 'react';

import Poster from './components/Poster';

import './Library.scss';

const Library = (props) => {
  const {
    model: {
      eyebrow,
      headline: title,
      subHeadline: description,
      hasHoverEffectRoundedCorners,
      posters,
    },
  } = props;

  const libraryPosterClasses = classNames('library__grid-container', {
    'poster--hover-rounded-corners': hasHoverEffectRoundedCorners,
  });

  const arePostersAutomated = posters.some(
    (poster) => poster.isLibraryPosterAutomated
  );

  return (
    <div id="library" className="library">
      <div className="library__header">
        <div className="library__content">
          <Text
            as="span"
            breakpoints={{ xs: 'eyebrow12', lg: 'eyebrow14' }}
            className="library__eyebrow"
            data-automationid="library_eyebrow"
          >
            {eyebrow}
          </Text>
          <Text
            as="h2"
            breakpoints={{ xs: 'title32', md: 'title48' }}
            className="library__title"
            data-automationid="library_title"
          >
            {title}
          </Text>
          <Text
            as="p"
            breakpoints={{ xs: 'body16', lg: 'body18' }}
            className="library__description"
            data-automationid="library_description"
          >
            {description}
          </Text>
        </div>
      </div>
      <div className={libraryPosterClasses}>
        <div className="library__grid">
          {posters.map((poster, index) => {
            return (
              <Poster
                key={`poster-${index}`}
                poster={poster}
                arePostersAutomated={arePostersAutomated}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

Library.propTypes = {
  model: shape({
    eyebrow: string,
    title: string,
    description: string,
    hasHoverEffectRoundedCorners: bool,
    posters: arrayOf(
      shape({
        modalId: string,
        eyebrow: string,
        headline: string,
        disclaimer: string,
        contentTitle: string,
        image: shape({
          alt: string,
          url: string,
          brandUrl: string,
          brand: string,
          hue: number,
        }),
      })
    ),
  }),
};

export { Library as view };
