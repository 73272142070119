import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { PlanComparisonFeatureModelSchema } from '../model/schema';

import { view as Modal } from '!app/components/Modal';
import { fireUtagLink, fireUserInteraction } from '!app/metrics';

const PlanComparisonFeature = (props) => {
  const {
    feature: {
      headline,
      plans,
      bullet,
      description: { richText, modalId },
    },
    index,
    type,
    numPlans,
    isToggleOn,
    isDark,
    highlightedColumnIndex,
    toggleHighlightedColumnIndex,
  } = props;

  const descriptionId = `${type}-description-tooltip`;

  const fireTooltipMetrics = (event) => {
    const toolTip = `${get(event, 'target.dataset.column', 'NO_COL')}-${get(
      event,
      'target.dataset.feature',
      'NO_FEATURE'
    )}`;
    fireUtagLink({
      event_name: 'tooltip_plans',
      cta_placement: 'comparison_chart',
      tool_tip: toolTip,
    });
    fireUserInteraction(descriptionId, 'click', 'click');
  };

  const featureTitleClasses = classNames(
    'col-xs-6',
    'col-lg-5',
    'plan-feature__title',
    { 'plan-feature__standard': !bullet }
  );

  const bulletDefault = (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2071 2.3138C14.5976 1.92327 14.5976 1.29011 14.2071 0.899583C13.8166 0.509058 13.1834 0.509058 12.7929 0.899583L7.5 6.19248L2.20711 0.899583C1.81658 0.509058 1.18342 0.509058 0.792893 0.899583C0.402369 1.29011 0.402369 1.92327 0.792893 2.3138L6.08579 7.60669L0.792893 12.8996C0.402369 13.2901 0.402369 13.9233 0.792894 14.3138C1.18342 14.7043 1.81658 14.7043 2.20711 14.3138L7.5 9.0209L12.7929 14.3138C13.1834 14.7043 13.8166 14.7043 14.2071 14.3138C14.5976 13.9233 14.5976 13.2901 14.2071 12.8996L8.91421 7.60669L14.2071 2.3138Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );

  const bulletApplicable = (
    <img
      src={
        isDark
          ? '/static/hitch/static/icons/Pricing_Checkmark_green-light.svg'
          : '/static/hitch/static/icons/Pricing_Checkmark_green-dark.svg'
      }
      role="presentation"
      alt={headline}
    />
  );

  const bulletText = (text) => (
    <Text variant="body16" className="plan-feature__bullet_text">
      {text}
    </Text>
  );
  return (
    <div className="plan-feature-item">
      <div className={featureTitleClasses}>
        <div>
          <Text variant="title14">{headline}</Text>
          {richText && (
            <span>
              <a
                data-toggle="modal"
                data-target={
                  modalId ? `#${modalId}` : `#${descriptionId}_${index}`
                }
                className="plan-addon__info"
                title={`${headline} info`}
                role="button"
                onClick={fireTooltipMetrics}
              >
                <img
                  src={
                    isDark
                      ? '/static/hitch/static/icons/Info_Icon_White.svg'
                      : '/static/hitch/static/icons/Info_Icon_Dark_Grey.svg'
                  }
                  role="presentation"
                  alt={headline}
                />
              </a>
              {richText && (
                <Modal
                  model={{
                    id: modalId ? `${modalId}` : `${descriptionId}_${index}`,
                  }}
                  className="plan-addon__info-modal"
                >
                  <div>
                    <Text
                      as="h3"
                      variant="title32"
                      className="plan-addon__header"
                    >
                      {headline}
                    </Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: richText,
                      }}
                    />
                  </div>
                </Modal>
              )}
            </span>
          )}
        </div>
      </div>

      {plans.map((plan, i) => {
        const { slug, isApplicable, text } = plan;
        const applicableDisplay = isApplicable
          ? bulletApplicable
          : bulletDefault;
        const featureDisplay = text ? bulletText(text) : applicableDisplay;
        const isHighlighted =
          (i === highlightedColumnIndex - 1 && !isToggleOn) ||
          (i === toggleHighlightedColumnIndex - 1 && isToggleOn);

        return (
          <Fragment key={i}>
            <div
              className={`col-xs-${
                12 / numPlans
              } plan-feature__check plan-feature__check-${
                isHighlighted ? 'highlighted' : 'not-highlighted'
              }`}
              key={`${slug}_${i}`}
            >
              <div className="plan-feature__bullet">{featureDisplay}</div>
            </div>
            <div className="plan-feature__separator" />
          </Fragment>
        );
      })}
    </div>
  );
};

PlanComparisonFeature.propTypes = {
  feature: PlanComparisonFeatureModelSchema,
  index: PropTypes.number,
  numPlans: PropTypes.number,
  type: PropTypes.string,
};

export default PlanComparisonFeature;
