import { shape, string, arrayOf, boolean } from 'prop-types';

const PlanComparisonFeaturePlanModelSchema = shape({
  slug: string,
  isApplicable: boolean,
});

const PlanComparisonFeatureDescriptionModelSchema = shape({
  richText: string,
  modalId: string,
});

export const PlanComparisonFeatureDisclaimerModelSchema = shape({
  id: string,
  richText: string,
  modalLinkText: string,
  modalId: string,
});

export const PlanComparisonFeatureModelSchema = shape({
  slug: string,
  headline: string.isRequired,
  description: PlanComparisonFeatureDescriptionModelSchema,
  disclaimer: PlanComparisonFeatureDisclaimerModelSchema,
  plans: arrayOf(PlanComparisonFeaturePlanModelSchema),
});

export const PlanComparisonPricingRowModelSchema = shape({
  slug: string,
  headline: string.isRequired,
  displayStrikethroughPrices: boolean,
  prices: arrayOf(
    shape({ priceNote: string, price: string, originalPrice: string })
  ),
});

export const PlanComparisonPlanModelSchema = shape({
  slug: string,
  headline: string,
  eyebrow: string,
  eyebrowImage: string,
  badge: string,
  ctaText: string,
  mobileCtaText: string,
  ctaAction: string,
  ctaBtnStyle: string,
  name: string,
});

const FeatureDisclaimerSchema = shape({
  richText: string,
  modalLinkText: string,
  modalContent: string,
});

export const PlanComparisonChartModelSchema = shape({
  toggleEnabled: boolean,
  toggleDefaultValue: boolean,
  toggleOnLabel: string,
  toggleOnImage: string,
  toggleOffLabel: string,
  toggleOffImage: string,
  toggleModalId: string,
  isDarkMode: boolean,
  headline: string.isRequired,
  description: string,
  addonsHeadline: string,
  addonsDescription: string,
  disclaimer: string,
  toggleOffPlans: arrayOf(PlanComparisonPlanModelSchema).isRequired,
  toggleOnPlans: arrayOf(PlanComparisonPlanModelSchema),
  pricingRows: arrayOf(PlanComparisonPricingRowModelSchema),
  toggleOffFeatures: arrayOf(PlanComparisonFeatureModelSchema),
  toggleOnFeatures: arrayOf(PlanComparisonFeatureModelSchema),
  toggleOffFeatureDisclaimers: arrayOf(FeatureDisclaimerSchema),
  toggleOnFeatureDisclaimers: arrayOf(FeatureDisclaimerSchema),
  toggleOffAddons: arrayOf(PlanComparisonFeatureModelSchema),
  toggleOnAddons: arrayOf(PlanComparisonFeatureModelSchema),
  toggleOffAddonDisclaimers: arrayOf(FeatureDisclaimerSchema),
  toggleOnAddonDisclaimers: arrayOf(FeatureDisclaimerSchema),
});
