// eslint-disable-next-line
import { connect } from 'react-redux';
import TopHat from './view/TopHat';

import { KEY as TOPHAT } from '!app/redux/tophat';
import topHatActionHandler from '!app/redux/tophat/action-handlers';
import { closeTopHat, openTopHat } from '!app/redux/tophat/actions';

const reducerMap = {
  [TOPHAT]: topHatActionHandler,
};

const view = connect(
  (state) => {
    return {
      shown: Boolean(state[TOPHAT].shown),
    };
  },
  (dispatch) => ({
    closeTopHat: () => dispatch(closeTopHat()),
    openTopHat: () => dispatch(openTopHat()),
  })
)(TopHat);

export { view, reducerMap };
