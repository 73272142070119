import PropTypes from 'prop-types';

const SingleImagePropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
});

export const ValuePropModelSchema = PropTypes.shape({
  style: PropTypes.string,
  icon: PropTypes.shape({
    icon: SingleImagePropType,
  }),
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  bodyCopy: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
});

export const ValuePropsModelSchema = PropTypes.shape({
  style: PropTypes.string,
  items: PropTypes.arrayOf(ValuePropModelSchema),
});
