import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { HubTabsSchema } from '../model/schema';

import EditorialCollection from '!app/components/Collections/EditorialCollection/views/EditorialCollection';
import { Tabs, Tab } from '!app/share/Tabs';
import '../stylesheets/HubTabs.scss';

class HubTabs extends Component {
  initialTab = 0;

  constructor(props) {
    super(props);

    const {
      query: { tab },
      model: { tabIds },
    } = this.props;
    const tabQueryParam = typeof tab === 'string' && tab.toLowerCase();

    if (tab && tabIds.includes(tabQueryParam)) {
      this.initialTab = parseInt(tabIds.indexOf(tabQueryParam), 10);
    }
  }

  render() {
    const {
      model: { tabIds, tabs },
      asPath,
    } = this.props;

    const handleSelected = (selected) => {
      const hrefPath = `${asPath}?tab=${tabIds[selected]}`;
      history.pushState({}, null, hrefPath);
    };

    return (
      <div className="HubTabs">
        <Tabs
          tabType="collection"
          shouldCenterTabTitles
          initialTab={this.initialTab}
          onSelected={handleSelected}
        >
          {tabs &&
            tabs.map((tab) => {
              const tabName = get(tab, 'tabName');
              const collectionItems = get(tab, 'collectionItems');
              const tabId = get(tab, 'tabId');
              const hrefPath = `${asPath}?tab=${tabId}`;

              return (
                <Tab key={tabId} title={tabName} hrefPath={hrefPath}>
                  <div className="HubTabs__collections">
                    {collectionItems &&
                      collectionItems.map((collectionItem, key) => (
                        <EditorialCollection model={collectionItem} key={key} />
                      ))}
                  </div>
                </Tab>
              );
            })}
        </Tabs>
      </div>
    );
  }
}

HubTabs.propTypes = {
  model: HubTabsSchema,
  asPath: PropTypes.string,
  query: PropTypes.shape({
    tab: PropTypes.string,
  }),
};

export default HubTabs;
