import get from 'lodash/get';

class Entity {
  constructor(entity) {
    this._entity = entity;
  }

  getArtworkPath() {
    const programTile = get(
      this._entity,
      'artwork.horizontalProgramTile.path',
      null
    );
    const hero = get(this._entity, 'artwork.horizontalHero.path', null);

    return programTile || hero;
  }

  getPortraitArtwork() {
    const videoHorizontal = get(this._entity, 'artwork.verticalHero', null);
    return videoHorizontal;
  }

  /**
   * Unused for now. Will be used for mobile portrait colleciton.
   */
  getProgramVerticalArtwork() {
    return get(this._entity, 'artwork.verticalProgramTile', null);
  }

  getTitleArtwork() {
    return get(this._entity, 'artwork.titleArtwork.path', null);
  }

  getBrandArtWork() {
    return get(this._entity, 'artwork.watermark.path', null);
  }

  getDuration() {
    return get(this._entity, 'duration');
  }

  getType() {
    const type = this?._entity?.type;
    return type ? type[0].toUpperCase() + type.slice(1) : 'Default';
  }

  getDescription() {
    return get(this._entity, 'description');
  }

  getRating() {
    return get(this._entity, 'rating');
  }

  getGenres() {
    return get(this._entity, 'genres', []);
  }

  getPremiereDate() {
    const dateStr = get(this._entity, 'premiereDate', null);
    return Boolean(dateStr) && new Date(dateStr);
  }

  getAssetUrl() {
    return this.getDetailsPageUrl();
  }

  getDetailsPageUrl() {
    return get(this._entity, 'href');
  }

  isPlayable() {
    return Boolean(this._entity.eabId);
  }

  getTimeBadge() {
    return this._entity?.timeBadge ? { text: this._entity?.timeBadge } : null;
  }

  getData() {
    return {
      title: get(this._entity, 'name'),
      description: this.getDescription(),
      rating: this.getRating(),
      genres: this.getGenres(),
      type: this.getType(),
      premiereDate: this.getPremiereDate(),
      entityId: get(this._entity, 'id'),
      eabId: get(this._entity, 'eabId'),
      thumbnail: this.getArtworkPath(),
      artwork: this.getPortraitArtwork(),
      titleArtwork: this.getTitleArtwork(),
      programArtwork: this.getProgramVerticalArtwork(),
      duration: this.getDuration(),
      assetUrl: this.getAssetUrl(),
      detailsUrl: this.getDetailsPageUrl(),
      brandWatermark: this.getBrandArtWork(),
      availability: {
        isPlayable: this.isPlayable(),
      },
      timeBadge: this.getTimeBadge(),
    };
  }
}

export default Entity;
