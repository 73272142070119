import { shape, PropTypes } from 'prop-types';

const SingleImagePropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
});

export const ConnectedLoginStepModelSchema = PropTypes.shape({
  stepType: PropTypes.string.isRequired,
  details: PropTypes.string,
  index: PropTypes.number,
  qrCode: PropTypes.string,
  enableConnectedLogin: PropTypes.boolean,
  pollingRetryDelay: PropTypes.number,
  maxPollingTime: PropTypes.number,
});

export const ConnectedLoginContainerModelSchema = PropTypes.shape({
  headline: PropTypes.string,
  image: shape({
    image_1x: SingleImagePropType,
    image_2x: SingleImagePropType,
  }),
  description: PropTypes.string,
  footNote: PropTypes.string,
  steps: PropTypes.arrayOf(ConnectedLoginStepModelSchema),
});
