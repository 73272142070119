import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { COLLECTION } from '!app/lib/constants';
import { normalize } from '!app/lib/utils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';
import { Nav, NavItem } from '!app/share/Nav';
import { Swipe } from '!app/share/Swipe';
import '../stylesheet/TabsStyle.scss';

const TRANSITION_TYPE_SLIDE = 'slide';
const TRANSITION_TYPE_FADE = 'fade';

export { TRANSITION_TYPE_FADE, TRANSITION_TYPE_SLIDE };

class Tabs extends Component {
  state = {
    selected: 0,
  };

  TabItems = this.renderTabItems();

  constructor(props) {
    super(props);
    this.onSelected = this.onSelected.bind(this);
    this.setInitialTab = this.setInitialTab.bind(this);

    // Mostly for SEO purposes when JS is turned off
    this.setInitialTab(false);
  }

  setInitialTab(useSetState = true) {
    const { initialTab } = this.props;
    const initialTabValue = parseInt(initialTab, 10);
    const selectedObj = {
      selected: initialTabValue,
    };

    if (initialTab && initialTabValue) {
      if (useSetState) {
        this.setState(selectedObj);
      } else {
        this.state = selectedObj;
      }
    }
  }

  componentDidMount() {
    this.setInitialTab();
  }

  onSelected(selected) {
    const { onSelected } = this.props;
    this.setState({ selected });
    if (onSelected) onSelected(selected);
  }

  renderTabItems() {
    const { children, tabType } = this.props;

    const TabItems = children.map((child, index) => {
      const TabWithUserInteraction = withUserInteraction(
        NavItem,
        'collection_tab',
        normalize(child.props.title)
      );
      const TabItem =
        tabType === COLLECTION
          ? withUtagLink(
              {
                event_name: 'collection_tab',
                content_select: get(child, 'props.title', '').toLowerCase(),
              },
              TabWithUserInteraction
            )
          : TabWithUserInteraction;

      const hrefPath = child.props.hrefPath;
      const additionalProps = {};
      if (hrefPath) {
        additionalProps.href = hrefPath;
      }

      return (
        <TabItem key={index} {...additionalProps}>
          {child.props.title}
        </TabItem>
      );
    });

    return TabItems;
  }

  render() {
    const {
      children,
      accentColor,
      transitionType,
      shouldCenterTabTitles,
    } = this.props;
    const { selected } = this.state;

    return (
      <div className="tabs">
        <Swipe
          nextFunc={(selectedTab) => this.setState({ selected: selectedTab })}
          prevFunc={(selectedTab) => this.setState({ selected: selectedTab })}
          itemsLength={children.length}
          currentSelected={selected}
        >
          <Nav
            selectedTab={selected}
            onSelected={this.onSelected}
            accentColor={accentColor && accentColor.toString()}
            shouldCenterItems={shouldCenterTabTitles}
            shouldPreventDefaultClick
          >
            {this.TabItems}
          </Nav>
        </Swipe>
        <div className="tabs__divider" />
        <div className="tabs__container">
          <div
            className={classNames('tabs__content', {
              'tabs__content--slider': transitionType === TRANSITION_TYPE_SLIDE,
            })}
            style={{
              transform:
                transitionType === TRANSITION_TYPE_SLIDE &&
                `translateX(-${selected}00%)`,
            }}
          >
            {React.Children.map(children, (child, index) =>
              React.cloneElement(child, {
                active: index === selected,
                transitionType,
              })
            )}
          </div>
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  transitionType: TRANSITION_TYPE_FADE,
  shouldCenterTabTitles: false,
};

Tabs.propTypes = {
  accentColor: PropTypes.string,
  onSelected: PropTypes.func,
  transitionType: PropTypes.oneOf([
    TRANSITION_TYPE_SLIDE,
    TRANSITION_TYPE_FADE,
  ]),
  shouldCenterTabTitles: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
  tabType: PropTypes.string,
  initialTab: PropTypes.number,
};

export default Tabs;
