import React from 'react';

import { useAuthenticateWithCode } from '!app/lib/hooks/useAuthenticateWithCode';
import { useFetchActivationCode } from '!app/lib/hooks/useFetchActivationCode';

import '../stylesheet/ConnectedLoginStyle.scss';

const ConnectedLogin = (props) => {
  const { pollingRetryDelay, maxPollingTime } = props;
  // pollingRetryDelay uses seconds interval
  // maxPollingTime uses minutes interval

  const AUTH_CONFIG =
    pollingRetryDelay && maxPollingTime
      ? {
          // Conversion to determine the maximum amount of retries given the times
          retries: parseInt((maxPollingTime * 60) / pollingRetryDelay),
          // Seconds converted to MS
          timeout: pollingRetryDelay * 1000,
        }
      : null;

  const { authCode } = useFetchActivationCode();
  useAuthenticateWithCode(authCode, AUTH_CONFIG);

  return <div className="ConnectedLogin">{authCode}</div>;
};

export default ConnectedLogin;
