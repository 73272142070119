import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';

import { ExpNoFeesMinimalistColumnModelSchema } from '../model/schema';

const ExpNoFeesMinimalistColumn = (props) => {
  const {
    model: { text },
    className,
    index,
    isLastRow,
  } = props;
  const isFirstColumn = index === 0;
  const isHuluColumn = index === 1;
  const isCableColumn = index === 2;
  const isHeader = className.includes('header');

  let content = isHeader ? (
    <Text
      breakpoints={{
        xs: 'body16',
        md: 'body18',
      }}
    >
      {text}
    </Text>
  ) : (
    <Text variant="body10">{text}</Text>
  );

  if (text === '$$$') {
    content = (
      <img
        className="dollar-icon"
        src="/static/hitch/static/icons/dollar.svg"
      />
    );
  }

  if (isHuluColumn && isHeader) {
    content = (
      <div>
        <img
          className="hulu-white-logo"
          src="/static/hitch/static/logos/hulu_white.svg"
        />
        <div>
          <Text variant="body10" className="live-tv-text">
            {text}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(className, {
        highlighted: isHuluColumn,
        'header-row': isHeader,
        'last-row': isLastRow,
        'first-column': isFirstColumn,
        'hulu-column': isHuluColumn,
        'cable-column': isCableColumn,
      })}
    >
      {content}
    </div>
  );
};

ExpNoFeesMinimalistColumn.propTypes = {
  model: ExpNoFeesMinimalistColumnModelSchema,
};

export default ExpNoFeesMinimalistColumn;
