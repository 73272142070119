import { request, gql } from 'graphql-request';
import { useQuery } from 'react-query';

const query = (season) => {
  const { seriesId, seasonNum } = season;
  return gql`
  query {
    hub(path: "/series/${seriesId}/season/${seasonNum}") {
      items {
        id
        season
        number
        description
        name
        timeBadge
        artwork {
          horizontalHero {
            path
          }
          watermark {
            path
          }
        }
      }
    }
  }
  `;
};

const useSeasonCollection = (season) => {
  const queryString = query(season);
  return useQuery(
    ['seasonCollection', season],
    async () => {
      const data = await request('/hookup', queryString);
      return data?.hub;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useSeasonCollection;
