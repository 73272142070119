import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { view as Slider } from '../../Slider';

import { Tile } from '!app/components/Tile';

import '../stylesheet/TileSlider.scss';

class TileSlider extends Component {
  mapItemToTile = (item, index) => {
    const {
      collectionIndex,
      collection: { enableSignupModal, enabledTileLinks },
    } = this.props;
    return (
      <Tile
        asset={item}
        key={index}
        index={index}
        disableTileLink={!enabledTileLinks}
        collectionIndex={collectionIndex}
        enableSignupModal={enableSignupModal}
      />
    );
  };

  render() {
    const { collection, collectionIndex, collectionType } = this.props;
    const { items } = collection;

    return (
      <div className="TileSlider cu-tile-slider">
        <Slider
          className="Slider--tile"
          items={items.map(this.mapItemToTile)}
          ref={this.setSliderRef}
          collection={collection}
          collectionIndex={collectionIndex}
          collectionType={collectionType}
          mobileModeTrigger={2}
          mobileMode="static"
        />
      </div>
    );
  }
}

TileSlider.defaultProps = {
  collection: {},
};

TileSlider.propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    enableSignupModal: PropTypes.bool,
    enabledTileLinks: PropTypes.bool,
  }),
  collectionIndex: PropTypes.number,
  collectionType: PropTypes.string,
};

export default TileSlider;
