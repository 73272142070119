import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';

import { useExpPlanComparisonChartContext } from '!app/experimental/components/ExpPlanComparisonChartMultiTab/view/ExpPlanComparisonChartContext';
import { fireUserInteraction } from '!app/metrics';

export const ExpPlanComparisonController = ({ controllerTabs }) => {
  const { tabIndex, setTabIndex } = useExpPlanComparisonChartContext();

  const tabOnClick = (key, value) => {
    fireUserInteraction(
      `${value.elementSpecifier}`,
      'driver_click',
      'click',
      true,
      `${value.name}`
    );
    setTabIndex(key);
  };

  return (
    <div className="ExpPlansContainerMultiTab-plan-controller">
      {Object.entries(controllerTabs).map(([key, value]) => {
        return (
          <button
            className={classNames('plan-controller-chip', {
              '--selected': tabIndex === key,
            })}
            onClick={() => tabOnClick(key, value)}
            key={`controller-${key}`}
            aria-label={`${value.name} Tab`}
          >
            <Text variant="subtitle14">{value.name}</Text>
          </button>
        );
      })}
    </div>
  );
};
export default ExpPlanComparisonController;
