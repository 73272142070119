import Text from '@hulu/web-ui/Text';
import get from 'lodash/get';
import { string } from 'prop-types';
import React from 'react';

import { ListCardItemSchema } from '../model/schema';

import '../stylesheets/ListCardItem.scss';

const ListCardItem = (props) => {
  const { items, pageType, urlFormat } = props;

  return (
    <div className="ListCardItem">
      {items.map((item) => {
        const name = get(item, 'name', '');
        let href = get(item, 'href', '');

        if (!name || !href) return null;

        if (urlFormat) {
          const pathSplit = href.split('/');
          // canonical URL is found at the index 1
          const canonicalUrl = get(pathSplit, '[2]') || get(pathSplit, '[0]');
          if (canonicalUrl) {
            href = urlFormat.replace(/{{canonicalUrl}}/g, canonicalUrl);
          }
        }

        const hrefWithReferrer = pageType
          ? `${href}?lp_referrer=${pageType.toLowerCase()}`
          : href;

        return (
          <div key={name} className="ListCardItem__item">
            <a href={hrefWithReferrer} title={name}>
              <Text variant="body16">{name}</Text>
            </a>
          </div>
        );
      })}
    </div>
  );
};

ListCardItem.propTypes = {
  items: ListCardItemSchema,
  pageType: string,
  urlFormat: string,
};

export default ListCardItem;
