import { ThemeProvider } from '@emotion/react';
import { oneHuluDarkTheme } from '@hulu/web-ui/style/createTheme';
import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { withFormik } from 'formik';
import React, { useEffect, useRef } from 'react';

import '../stylesheet/ExpPlanBuilder.scss';
import {
  PLAN_BUILDER_HIT_FLOW_NAME,
  PLAN_BUILDER_HIT_SCREEN_NAME,
} from '../constants';

import { ExpPlanBuilderFooter } from './ExpPlanBuilderFooter';
import { ExpPlanBuilderQuestionContainer } from './ExpPlanBuilderQuestionContainer';

import { mobileDetect } from '!app/lib/environment';
import { goToSignup } from '!app/lib/signupUtils';
import {
  fireSubscriptionStart,
  fireSubscriptionStepStart,
  fireUserInteraction,
} from '!app/metrics/fireEvent';

const ExpPlanBuilder = (props) => {
  const {
    model: {
      title,
      subtitle,
      startPriceText,
      questionSections,
      bottomSection,
      footer,
      scrollDelayTime,
    },
    values,
    setFieldValue,
    user,
  } = props;
  const lastQuestionIndex = questionSections.length - 1;
  useEffect(() => {
    fireSubscriptionStart(PLAN_BUILDER_HIT_FLOW_NAME);
    fireSubscriptionStepStart(
      PLAN_BUILDER_HIT_FLOW_NAME,
      PLAN_BUILDER_HIT_SCREEN_NAME
    );
    // scroll to top of page if user refreshes
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      // expand footer if at the bottom of the page and answered all questions
      const isAtPageBottom =
        global.window.pageYOffset + global.window.innerHeight >=
        global.document.body.scrollHeight;
      const hasAnsweredAllQuestions =
        values?.[`question${lastQuestionIndex}`] !== null;
      const isMobile = mobileDetect().mobile() && !mobileDetect().tablet();
      if (
        !values.isFooterExpanded &&
        isAtPageBottom &&
        hasAnsweredAllQuestions
      ) {
        setFieldValue('isFooterExpanded', true);
      } else if (values.isFooterExpanded && isMobile) {
        // collapse on scroll up if mobile
        setFieldValue('isFooterExpanded', false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [values.isFooterExpanded, values[`question${lastQuestionIndex}`]]);
  // used for scrolling to the next question
  const questionsRef = useRef(null);
  // creating a map of refs and accessing ref by id
  const getMap = () => {
    if (!questionsRef.current) {
      questionsRef.current = new Map();
    }
    return questionsRef.current;
  };

  return (
    <ThemeProvider theme={oneHuluDarkTheme}>
      <form
        onSubmit={(event) => handleSubmit(event, values, props)}
        className={classNames('exp-plan-builder-form', {
          '--expanded': values.isFooterExpanded,
        })}
      >
        <div className="exp-plan-builder">
          <div className="exp-plan-builder-container-width">
            <div className="title-section">
              <Text variant="title24" className="plan-builder-title">
                {title}
              </Text>
              <Text variant="body16" className="plan-builder-subtitle">
                {subtitle}
              </Text>
              <div
                className="start-price-text"
                dangerouslySetInnerHTML={{ __html: startPriceText }}
              />
            </div>
            <div className="question-section">
              {questionSections.map((sectionData, index) => (
                <ExpPlanBuilderQuestionContainer
                  key={`question-${index}`}
                  sectionData={sectionData}
                  index={index}
                  values={values}
                  getMap={getMap}
                  scrollDelayTime={scrollDelayTime}
                />
              ))}
            </div>
            <div
              className={classNames('bottom-section', {
                '--expanded': values.isFooterExpanded,
              })}
            >
              <div
                className="bottom-content"
                dangerouslySetInnerHTML={{ __html: bottomSection }}
              />
            </div>
          </div>
        </div>
        <ExpPlanBuilderFooter footer={footer} values={values} user={user} />
      </form>
    </ThemeProvider>
  );
};

export const handleSubmit = ({
  event,
  signupUrl,
  user,
  hitTargetDisplayName,
}) => {
  event.preventDefault();
  fireUserInteraction(
    'cta_primary',
    'driver_click',
    'click',
    true,
    hitTargetDisplayName
  );
  goToSignup({}, signupUrl, user);
};

// answerIdToPlanIdMap is map of answer ids to their associated plan id
const mapPropsToValues = (props) => {
  const initialValues = {};
  const answerIdToPlanIdMap = {};
  props.model.questionSections.forEach((question, index) => {
    initialValues[`question${index}`] = null;
    (question.answers || []).forEach((answer) => {
      answerIdToPlanIdMap[answer.id] = answer.planId;
    });
  });
  return {
    ...initialValues,
    isFooterExpanded: false,
    questionSections: props.model.questionSections || [],
    footerPlanId: props.model.footer.defaultPlanId,
    answerIdToPlanIdMap,
  };
};
export default withFormik({
  mapPropsToValues,
  handleSubmit,
  displayName: 'ExpPlanBuilder',
})(ExpPlanBuilder);
