import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { enterKeyHandler, preventFocus } from '!app/lib/accessibilityUtils';
import '../stylesheet/VideoBackground.scss';
import { BREAKPOINTS, DEVICES } from '!app/lib/constants';
import { useWindowResize } from '!app/lib/hooks/useWindowResize';

function VideoBackground({ videos }) {
  const videoEl = React.useRef();
  const [videosForScreenWidth, setVideosForScreenWidth] = React.useState(
    videos
  );
  const [playing, setPlaying] = React.useState(true);
  const {
    windowSize: { width },
  } = useWindowResize();

  const playVideo = () => {
    const playPromise = videoEl?.current?.play();
    // if valid promise, set playing (state) to true
    // if error or undefined, set playing to false
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          setPlaying(true);
        })
        .catch(() => {
          setPlaying(false);
        });
    } else {
      setPlaying(false);
    }
  };

  const toggleVideo = () => {
    const videoElement = videoEl?.current;

    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }

    setPlaying(!videoElement.paused);
  };

  useEffect(() => {
    let currentDeviceType = DEVICES.DESKTOP;
    if (width < BREAKPOINTS.LARGE) {
      currentDeviceType = DEVICES.TABLET;
    }
    if (width < BREAKPOINTS.MEDIUM) {
      currentDeviceType = DEVICES.MOBILE;
    }

    // Attempt to select the video that is intended for the device
    let videoForScreenWidth = videos?.find(
      (video) => video.device === currentDeviceType
    );

    // If no such video exists, attempt to use a video intended for all devices
    if (!videoForScreenWidth) {
      videoForScreenWidth = videos?.find((video) => video.device === 'any');
    }

    // No videos are available, so try to don't render any
    if (!videoForScreenWidth) {
      setVideosForScreenWidth(undefined);
      return;
    }

    setVideosForScreenWidth([videoForScreenWidth]);
  }, [width]);

  useEffect(() => {
    // When we change the videosForScreenWidth, we need to load and play the new video
    videoEl?.current?.load();
    playVideo();
  }, [videoEl, videosForScreenWidth]);

  const controlsClass = classnames('VideoBackground__controls', {
    'VideoBackground__controls--playing': playing,
    'VideoBackground__controls--paused': !playing,
  });
  const controlsLabel = playing ? 'Pause Video' : 'Play Video';

  if (!videosForScreenWidth || videosForScreenWidth.length === 0) {
    return null;
  }

  return (
    <div className="VideoBackground" data-automationid="video_background">
      <video
        preload="auto"
        loop="loop"
        muted
        playsInline
        className="VideoBackground__video"
        ref={videoEl}
      >
        {videosForScreenWidth &&
          videosForScreenWidth.map((video, idx) => (
            <source key={idx} src={video.url} type={video.type} />
          ))}
      </video>
      <div
        className={controlsClass}
        tabIndex={0}
        role="button"
        aria-label={controlsLabel}
        onClick={toggleVideo}
        onKeyDown={enterKeyHandler(toggleVideo)}
        onMouseDown={preventFocus}
      />
    </div>
  );
}

VideoBackground.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
      device: PropTypes.oneOf(Object.values(DEVICES)),
    })
  ),
};

export default VideoBackground;
