import Text from '@hulu/web-ui/Text';
import { string } from 'prop-types';
import React from 'react';

import { ListCardSchema } from '../model/schema';

import ListCardItem from './ListCardItem';

import '../stylesheets/ListCard.scss';

const VIEW_ALL_TEXT = 'View all';

const ListCard = (props) => {
  const {
    model: { title, viewAllUrl, urlFormat, items },
    pageType,
  } = props;

  return (
    <div className="ListCard">
      <div className="ListCard__container container-width">
        <div className="ListCard__content">
          <div className="ListCard__header">
            <Text className="ListCard__header__title" variant="title18">
              {title}
            </Text>
            {viewAllUrl && (
              <a href={viewAllUrl}>
                <span className="ListCard__header__viewAll">
                  <Text
                    variant="label12"
                    className="ListCard__header__viewAll__text"
                  >
                    {VIEW_ALL_TEXT}
                  </Text>
                  <span className="ListCard__header__viewAll__arrow" />
                </span>
              </a>
            )}
          </div>
          {items && (
            <ListCardItem
              items={items}
              pageType={pageType}
              urlFormat={urlFormat}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ListCard.propTypes = {
  model: ListCardSchema.isRequired,
  pageType: string,
};

export default ListCard;
