import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { find } from 'lodash';
import React from 'react';

import { ExpPlanBuilderAnswerSection } from './ExpPlanBuilderAnswerSection';

/**
 * Determines if the current question should be disabled if the previous question has been answered
 * @param index index of the current question
 * @param values form values
 * @returns true if previous question is null/not answered, false if it not null/is answered
 */
const determineIfDisabled = (index, values) => {
  if (index === 0) {
    return false;
  }
  const previousQuestionIndex = index - 1;
  return values[`question${previousQuestionIndex}`] === null;
};

/**
 * For question 2, the answers rendered are dependent on the answer of question 1
 * For question 3, the answers rendered are dependent on the answer of question 2
 * in question 1's answers, there is a field "nextAnswerIds"
 * @param index index of the current question
 * @param values form values
 * @param answers array of answers
 * @returns answers array that should be rendered for this question
 */
// TODO: consider using nextQuestionId instead of nextAnswerIds to determine what to render
const determineAnswersToRender = (index, values, answers) => {
  let renderedAnswers = answers;

  if (index === 1) {
    let answerIdsToRender = [];
    if (values.question0 === 'ad-free') {
      answerIdsToRender = values.questionSections[0].answers[0].nextAnswerIds;
    } else {
      answerIdsToRender = values.questionSections[0].answers[1].nextAnswerIds;
    }

    renderedAnswers =
      answerIdsToRender.map((id) => {
        return find(answers, { id });
      }) || [];
  }

  // Logic for results from second question. Displays different additive pricing
  else if (index === 2) {
    const liveTVAnswers = values.questionSections[1];
    let answerIdsToRender = [];

    // Default to LiveTV value when first question is not yet answered or if ad-free is selected
    if (!values.question0 || values.question0 === 'ad-free') {
      answerIdsToRender = liveTVAnswers.answers[1].nextAnswerIds;
    }
    // Disabled LiveTV value when first answer is ad-supported
    else if (values.question0 === 'ad-supported') {
      answerIdsToRender = liveTVAnswers.answers[4].nextAnswerIds;
    }

    // Finds next answers based on question 2 result
    if (values.question1) {
      const foundItems = liveTVAnswers.answers.find(
        (element) => element.id === values.question1
      );
      if (foundItems) {
        answerIdsToRender = foundItems.nextAnswerIds;
      }
    }

    renderedAnswers =
      answerIdsToRender.map((id) => {
        return find(answers, { id });
      }) || [];
  }

  return renderedAnswers;
};

export const ExpPlanBuilderQuestionContainer = ({
  sectionData,
  index,
  values,
  getMap,
  scrollDelayTime,
}) => {
  const { imagePath, question, description, linkText, answers } = sectionData;
  const renderedAnswers = determineAnswersToRender(index, values, answers);
  const isDisabled = determineIfDisabled(index, values);
  const questionId = `question${index}`;

  // ref callback that sets the ref as the DOM node or null
  const refCallback = (node) => {
    const map = getMap();
    if (node) {
      map.set(questionId, node);
    } else {
      map.delete(questionId);
    }
  };

  return (
    <div
      className={classNames('question-container', {
        '--disabled': isDisabled,
      })}
      key={questionId}
      ref={refCallback}
    >
      <div className="question">
        {imagePath && <img src={imagePath} alt="icon" role="presentation" />}
        <Text variant="subtitle18">{question}</Text>
        <div
          className="question-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {linkText && (
          <div
            className={classNames('question-linkText', {
              '--disabled': isDisabled,
            })}
            dangerouslySetInnerHTML={{ __html: linkText }}
          />
        )}
      </div>
      <ExpPlanBuilderAnswerSection
        answers={renderedAnswers}
        questionIndex={index}
        isDisabled={isDisabled}
        values={values}
        getMap={getMap}
        scrollDelayTime={scrollDelayTime}
      />
      <hr />
    </div>
  );
};
