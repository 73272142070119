import { string, shape, arrayOf } from 'prop-types';

import { SingleImagePropType } from '!app/components/Masthead/model/schema';
import { NetworkIconsSchema } from '!app/components/MediaWithText/model/schema';

const SpotlightTabModelSchema = shape({
  eyebrow: string,
  headline: string,
  subheadline: string,
  disclaimer: string,
  logos: arrayOf(NetworkIconsSchema),
  desktopBackgroundImage: SingleImagePropType,
  mobileBackgroundImage: SingleImagePropType,
});

export const SpotlightTabsModelSchema = shape({
  spotTabs: arrayOf(SpotlightTabModelSchema).isRequired,
});
