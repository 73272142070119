import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { bool } from 'prop-types';
import React from 'react';

import { FAQsModelSchema } from '../model/schema';

import FAQ from './FAQ';

import { WithEvents } from '!app/metrics/hoc';

import '../stylesheet/FAQsStyle.scss';

const FAQs = (props) => {
  const {
    model: { headline, description, faqs },
    enableBrand,
  } = props;
  const accordionHeadline = classNames({
    'accordion__headline--brand': enableBrand,
  });
  return (
    <div
      className="accordion cu-faq"
      id="faq"
      role="region"
      aria-labelledby="regionFAQ"
    >
      <div className="container-width">
        <div className="accordion__header">
          <Text
            className={accordionHeadline}
            as="h2"
            variant="title32"
            id="regionFAQ"
          >
            {headline}
          </Text>
          <WithEvents.div
            className="accordion__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="accordion__list">
            <ul>
              {faqs &&
                faqs.map((item, index) => <FAQ key={index} model={item} />)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

FAQs.propTypes = {
  model: FAQsModelSchema,
  enableBrand: bool,
};

export default FAQs;
