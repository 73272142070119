import React from 'react';

import { sanitize } from '!app/lib/checkUp';

/* istanbul ignore next */
const DetailButtonSVG = ({ height = '60px', width = '60px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    className={sanitize('button', 'detail')}
  >
    <circle
      fillOpacity="0.2"
      cx="30"
      cy="30"
      r="29"
      stroke="#FFFFFF"
      strokeWidth="1.5"
    />
    <g
      transform="translate(16.071429, 17.142857)"
      fillRule="nonzero"
      fill="#FFFFFF"
    >
      <path d="M21.9263541,11.4642855 L0,11.4642855 L0,13.6071427 L21.9420593,13.6071427 L13.0824461,22.1982827 L14.5976749,23.6675955 L26.069575,12.5433287 L14.5976749,1.41906191 L13.0824461,2.8883747 L21.9263541,11.4642855 Z" />
    </g>
  </svg>
);

export default DetailButtonSVG;
