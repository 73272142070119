import PropTypes from 'prop-types';
import React from 'react';

import { ExpPlanComparisonFeatureDisclaimerModelSchema } from '../model/schema';

import { view as Modal } from '!app/components/Modal';
import { fireUtagLink, fireUserInteraction } from '!app/metrics';

const ExpPlanComparisonDisclaimer = (props) => {
  const {
    disclaimer: { modalContent, richText, modalLinkText },
    index,
    type,
  } = props;
  const hasModal = Boolean(modalContent);
  const disclaimerId = `${type}-disclaimer-modal`;

  const onDisclaimerClick = () => {
    fireUtagLink({
      event_name: disclaimerId,
      cta_placement: 'comparison_chart',
    });
    fireUserInteraction(disclaimerId, 'click', 'click');
  };

  return (
    <div className="plans-container__features-container col-xs-12">
      <div className="plans-container__features-container__disclaimer">
        <span
          className="section-disclaimer"
          dangerouslySetInnerHTML={{ __html: richText }}
        />
        {hasModal && (
          <a
            data-toggle="modal"
            data-target={`#${disclaimerId}__${index}`}
            title={richText}
            role="button"
            onClick={onDisclaimerClick}
          >
            {modalLinkText}
          </a>
        )}
      </div>
      {hasModal && (
        <Modal
          model={{ id: `${disclaimerId}__${index}` }}
          className="plan-addon__info-modal"
        >
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: modalContent,
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

ExpPlanComparisonDisclaimer.propTypes = {
  disclaimer: ExpPlanComparisonFeatureDisclaimerModelSchema,
  index: PropTypes.number,
  type: PropTypes.string,
};

export default ExpPlanComparisonDisclaimer;
