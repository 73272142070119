import PropTypes, { shape, string, arrayOf, boolean, oneOf } from 'prop-types';

import { DEVICES } from '!app/lib/constants';

const MastheadVideo = PropTypes.shape({
  type: string.isRequired,
  url: string.isRequired,
  device: oneOf(Object.keys(DEVICES).map((key) => key.toLowerCase())),
});

export const SingleImagePropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
});

const MastheadCTA = PropTypes.shape({
  ctaButtonStyle: string,
  ctaCopy: string.isRequired,
  eyebrow: string,
  headline: string,
  subheadline: string,
  ctaHref: string.isRequired,
  elementSpecifier: string,
  targetDisplayName: string,
});

export const ExpMastheadDualCTAModelSchema = shape({
  style: string,
  backgroundImage: PropTypes.shape({
    desktop: SingleImagePropType,
    mobile: SingleImagePropType,
  }),
  backgroundVideos: arrayOf(MastheadVideo),
  eyebrow: string,
  headline: string,
  subheadline: string,
  primaryCopy: string,
  secondaryCopy: string,
  leftCta: MastheadCTA.isRequired,
  rightCta: MastheadCTA.isRequired,
  legal: string,
  leftAlign: boolean,
});
