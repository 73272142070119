import view from './view/MicroSite';

import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';

const reducerMap = {
  [MODAL]: modalActionHandler,
};

export { view, reducerMap };
