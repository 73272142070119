import { ConnectedLoginStepModelSchema } from '../model/schema';

import ConnectedLogin from './ConnectedLogin';

import { view as QRCodeContainer } from '!app/components/QRCodeContainer';
import { WithEvents } from '!app/metrics/hoc';

const STEPTYPE_WITH_QR = 'with_qr_code';
const STEPTYPE_WITH_ACTIVATION = 'with_activation_login';

const ConnectedLoginStep = (props) => {
  const {
    model: {
      stepType,
      details,
      qrCode: qrCodeUrl,
      enableConnectedLogin,
      pollingRetryDelay,
      maxPollingTime,
    },
  } = props;

  return (
    <li className="ConnectedLoginStep">
      {details && (
        <WithEvents.div
          className="step-description"
          dangerouslySetInnerHTML={{ __html: details }}
        />
      )}
      {stepType === STEPTYPE_WITH_QR && qrCodeUrl && (
        <QRCodeContainer urlPath={qrCodeUrl} />
      )}
      {stepType === STEPTYPE_WITH_ACTIVATION && enableConnectedLogin && (
        <ConnectedLogin
          pollingRetryDelay={pollingRetryDelay}
          maxPollingTime={maxPollingTime}
        />
      )}
    </li>
  );
};

ConnectedLoginStep.propTypes = {
  model: ConnectedLoginStepModelSchema,
};

export default ConnectedLoginStep;
