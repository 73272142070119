import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BrandWatermark from './BrandWatermark';

import { useImageLoadStatus } from '!app/lib/hooks/useImageLoadStatus';

const TitleArt = (props) => {
  const { imageUrls, title, brandArtwork } = props;
  const imageRef = React.createRef();
  const { isLoaded, hasError } = useImageLoadStatus(imageRef);

  const titleArtEl = () => {
    const titleArtClasses = classnames('DetailEntityMasthead__title-art', {
      'DetailEntityMasthead__title-art--loaded': isLoaded,
    });
    const titleTextClasses = classnames('DetailEntityMasthead__title__text', {
      'DetailEntityMasthead__title__text--fade-in': hasError,
    });

    return (
      <div className={titleArtClasses}>
        <picture className="DetailEntityMasthead__title-art__image">
          <source
            media="(min-width: 1280px)"
            srcSet={`${imageUrls.large} 1x, ${imageUrls.large_retina} 2x`}
          />
          <source
            media="(min-width: 1024px)"
            srcSet={`${imageUrls.medium} 1x, ${imageUrls.medium_retina} 2x`}
          />
          <img
            ref={imageRef}
            src={imageUrls.small}
            srcSet={`${imageUrls.small} 1x, ${imageUrls.small_retina} 2x`}
            alt={title}
            aria-label={title}
            role="presentation"
          />
        </picture>
        <span className={titleTextClasses}>{title}</span>
      </div>
    );
  };

  return (
    <h1 id="regionDetailMasthead" className="DetailEntityMasthead__title">
      {imageUrls ? titleArtEl() : <span>{title}</span>}
      <BrandWatermark brandArtwork={brandArtwork} isMobileDisplay />
    </h1>
  );
};

TitleArt.propTypes = {
  imageUrls: PropTypes.shape({}),
  title: PropTypes.string,
  brandArtwork: PropTypes.shape({}),
};

export default TitleArt;
