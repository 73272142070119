import { string, shape } from 'prop-types';

export const NetworksGeoModelSchema = shape({
  headline: string,
  description: string,
  defaultInputEyebrow: string,
  specificInputEyebrow: string,
  zipCodePrompt: string,
  promotedChannelList: string,
  defaultChannelHtml: string,
});
