import { useState } from 'react';

/**
 * Manages items in LocalStorage
 * @param {string} key the ID of the local storage item
 * @param {string} initialValue initial value of the item (can be null)
 * @param {string} expiration optional. if set, the item will be deleted from local storage after this date
 */
const useLocalStorage = (key, initialValue, expiration) => {
  /**
   * Sets an item to LocalStorage.
   * @param {string|function} value the value saved to Local Storage.
   */
  const setValue = (value) => {
    try {
      // Allows value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      global.window.localStorage.setItem(key, valueToStore);
    } catch (error) {}
  };

  /**
   * Removes an item from LocalStorage.
   * @param {string} itemKey
   */
  const removeValue = (itemKey) => {
    try {
      global.window.localStorage.removeItem(itemKey);
    } catch (error) {}
  };

  /**
   * Gets item from LocalStorage
   * @returns {string} item from localStorage or initialValue.
   */
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = global.window.localStorage.getItem(key);
      return item || initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  /**
   * Checks LocalStorage expiration date
   * @returns {boolean} true if the local storage item has an expiration date that has passed.
   */
  const [isExpired] = useState(() => {
    if (!expiration || !storedValue) return false;
    const expirationDate = new Date(storedValue).getTime();
    const currentTime = Date.now();
    return currentTime > expirationDate;
  });

  return {
    storedValue,
    setValue,
    removeValue,
    isExpired,
  };
};

export { useLocalStorage };
