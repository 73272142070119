import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

const ExpPlanComparisonChartContext = React.createContext();

const useExpPlanComparisonChartContext = () =>
  useContext(ExpPlanComparisonChartContext);

const ExpPlanComparisonChartProvider = ({ children }) => {
  const [isBundle, setIsBundle] = useState(false);
  const value = {
    isBundle,
    setIsBundle,
  };

  return (
    <ExpPlanComparisonChartContext.Provider value={value}>
      {children}
    </ExpPlanComparisonChartContext.Provider>
  );
};

ExpPlanComparisonChartProvider.propTypes = {
  children: PropTypes.node,
};

export { ExpPlanComparisonChartProvider, useExpPlanComparisonChartContext };
