import PropTypes from 'prop-types';

import EditorialCollectionSchema from '!app/components/Collections/EditorialCollection/model/schema';

const HubTabSchema = PropTypes.shape({
  tabName: PropTypes.string,
  sectionName: PropTypes.string,
  collectionItems: PropTypes.arrayOf(EditorialCollectionSchema),
});

export const HubTabsSchema = PropTypes.shape({
  tabs: PropTypes.arrayOf(HubTabSchema),
});
