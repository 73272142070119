import { connect } from 'react-redux';

import MastheadCarousel from './view/MastheadCarousel';

import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

const view = connect((state) => {
  return {
    user: state[USER],
  };
})(MastheadCarousel);

const reducerMap = {
  [USER]: userActionHandler,
};

export { view, reducerMap };
