import { connect } from 'react-redux';

import Masthead from './views/Masthead';

import { KEY as CTAFIELDS } from '!app/redux/ctaFields';
import ctaFieldsActionHandler from '!app/redux/ctaFields/action-handlers';
import { KEY as DETAIL_ENTITY } from '!app/redux/detailEntity';
import detailEntityActionHandler from '!app/redux/detailEntity/action-handlers';
import { updateDetailEntity } from '!app/redux/detailEntity/actions';
import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';
import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

const reducerMap = {
  [MODAL]: modalActionHandler,
  [DETAIL_ENTITY]: detailEntityActionHandler,
  [USER]: userActionHandler,
  [CTAFIELDS]: ctaFieldsActionHandler,
};

const view = connect(
  (state) => {
    return {
      user: state[USER],
      ctaFields: state[CTAFIELDS],
    };
  },
  (dispatch) => ({
    updateDetailEntity: (detailEntity) =>
      dispatch(updateDetailEntity(detailEntity)),
  })
)(Masthead);

export { view, reducerMap };
