import { connect } from 'react-redux';

import HeroSliderMasthead from './views/HeroSliderMasthead';

import { KEY as CTAFIELDS } from '!app/redux/ctaFields';
import ctaFieldsActionHandler from '!app/redux/ctaFields/action-handlers';
import { KEY as DETAIL_ENTITY } from '!app/redux/detailEntity';
import detailEntityActionHandler from '!app/redux/detailEntity/action-handlers';
import { updateDetailEntity } from '!app/redux/detailEntity/actions';
import { KEY as USER } from '!app/redux/user';
import userActionHandler from '!app/redux/user/action-handlers';

const view = connect(
  (state) => ({
    user: state[USER],
    ctaFields: state[CTAFIELDS],
    network:
      state[DETAIL_ENTITY]?.network || state[DETAIL_ENTITY]?.networkUpsell,
    requirePremium: state[DETAIL_ENTITY].requirePremium,
  }),
  (dispatch) => ({
    updateDetailEntity: (detailEntity) =>
      dispatch(updateDetailEntity(detailEntity)),
  })
)(HeroSliderMasthead);

const reducerMap = {
  [USER]: userActionHandler,
  [CTAFIELDS]: ctaFieldsActionHandler,
  [DETAIL_ENTITY]: detailEntityActionHandler,
};

export { view, reducerMap };
