import PropTypes from 'prop-types';

import './ToggleSwitch.scss';

const ToggleSwitch = ({ isOn, handleToggle, onClick }) => {
  return (
    <div className="toggle-switch">
      <input
        checked={isOn}
        onClick={onClick}
        onChange={handleToggle}
        className="toggle-switch__checkbox"
        id="toggle-switch"
        type="checkbox"
      />
      <label className="toggle-switch__label" htmlFor="toggle-switch">
        <span className="toggle-switch__button" />
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  isOn: PropTypes.bool,
  handleToggle: PropTypes.func,
  onClick: PropTypes.func,
  onColor: PropTypes.string,
};

export default ToggleSwitch;
