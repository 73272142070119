import get from 'lodash/get';

import {
  PREMIEREDATEFORMAT,
  PREMIEREDATEFORMAT_MMDDYY,
  CMS_PAGETYPE,
} from '!app/lib/constants';

/**
 * Turns a single string into an array if it's not already an array.
 * @param {string|Array} arg
 */
const forceToArray = (arg) => (!arg || Array.isArray(arg) ? arg : [arg]);

const plural = (num, w1, w2) => {
  if (num <= 1) return w1;
  if (w2) return w2;
  return `${w1}s`;
};

/**
 * Normalises strings to lowercase and replaces all non-alphanumeric characters
 * with underscores.
 * @param {string} string
 * @return {string}
 * @todo add options for special characters or preventing toLowerCase
 */
const normalize = (string) =>
  string ? string.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_') : null;

const toPremiereDateFormat = (date, locale = 'en-US') => {
  if (!date) {
    return undefined;
  }

  return new Date(date).toLocaleDateString(
    locale,
    get(PREMIEREDATEFORMAT, locale)
  );
};

const toPremiereDateFormatMMDDYY = (date, locale = 'en-US') => {
  if (!date) return undefined;
  return new Date(date).toLocaleDateString(
    locale,
    get(PREMIEREDATEFORMAT_MMDDYY, locale)
  );
};

const isFutureDate = (date) => {
  if (!date) {
    return undefined;
  }

  const parseDate = Date.parse(date);
  return parseDate ? parseDate - Date.now() > 0 : false;
};

/**
 * Checks if a target date is within a certain number of days from now.
 * @param {date} targetDate the date to check
 * @param {number} daysFromNow the number of days away to check
 * @returns {boolean} true if targetDate is within daysFromNow
 */
const isDateWithinXDays = (daysFromNow) => (targetDate) => {
  if (!targetDate || !isFutureDate(targetDate)) return false;
  const difference = new Date(targetDate).getTime() - new Date().getTime();
  const maxDate = daysFromNow * 1000 * 60 * 60 * 24;
  return difference < maxDate;
};
const upcomingContentDays = 90;
const isDateWithin90Days = isDateWithinXDays(upcomingContentDays);
const TIMEOUT_TIME_MS = 2000;
const getDateInXDays = (daysToAdd) => () => {
  const today = new Date();
  const inXDays = today.setDate(today.getDate() + daysToAdd);
  return new Date(inXDays).toISOString();
};
const WIDTHS = {
  xs: 375,
  sm: 480,
  md: 768,
  ml: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1600,
};
const getBreakpoint = () => {
  const winWidth = global.window.innerWidth;
  switch (true) {
    case winWidth < WIDTHS.xs:
      return {
        breakPoint: 'xs',
        mediaType: 'mobile',
      };
    case winWidth >= WIDTHS.xs && winWidth < WIDTHS.md:
      return {
        breakPoint: 'sm',
        mediaType: 'mobile',
      };
    case winWidth >= WIDTHS.md && winWidth < WIDTHS.ml:
      return {
        breakPoint: 'md',
        mediaType: 'tablet',
      };
    case winWidth >= WIDTHS.ml && winWidth < WIDTHS.lg:
      return {
        breakPoint: 'lg',
        mediaType: 'desktop',
      };
    case winWidth >= WIDTHS.lg && winWidth < WIDTHS.xxl:
      return {
        breakPoint: 'xl',
        mediaType: 'desktop',
      };
    case winWidth >= WIDTHS.xxl:
    default:
      return {
        breakPoint: 'xxl',
        mediaType: 'desktop',
      };
  }
};

/**
 * Smooth scrolls to a component.
 * @param {string} id of component to scroll to
 */
const scrollToComponent = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const getPageType = (cmsPageType, systemPageType) =>
  systemPageType === 'LandingPage' ? CMS_PAGETYPE[cmsPageType] : systemPageType;

const truncateByWordCount = (text, wordLimit) => {
  return `${text.split(' ').splice(0, wordLimit).join(' ')}...`;
};

const fetchWithTimeout = (url, options, timeout = TIMEOUT_TIME_MS) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error(`Timeout of ${timeout} ms exceeded`)),
        timeout
      )
    ),
  ]);
};

/**
 * Parses a JSON string to an object with error handling.
 * @param {String} string JSON string to parse
 * @param {Object} logConfig includes the logger, message, and logMetaData to log on error.
 *
 * @return {Object} parsed object
 */
const parseJSON = (jsonString, logConfig) => {
  if (!jsonString) return null;
  const { logger, message, logMetaData } = logConfig;
  let parsedValue = null;
  try {
    parsedValue = JSON.parse(jsonString);
  } catch (error) {
    logger.warn(message, error, logMetaData);
  }
  return parsedValue;
};

/**
 * Runs sha1 hash on string and matches it to expected
 * @param {String} email user email
 * @param {String} userId user id
 * @param {String} expectedNonce expected nonce
 *
 * @return {Boolean} nonce matches expected
 */
const isValidNonce = (email, userID, expectedNonce) => {
  const crypto = require('crypto');
  const shasum = crypto.createHash('sha1');
  const salt =
    '.P-F"L29FN;QL9QAOKg!&C671g)W55D%MyZ@mlY6.&CwS2\\Z)a/780J,>%$?\\dr';
  const finalStr = email + salt + userID;
  shasum.update(finalStr);
  const preNonce = shasum.digest('base64');
  const nonce = preNonce
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/[=\n\r]+$/, '');
  return nonce === expectedNonce;
};

/**
 * currently, two user IDs are passed into the query making it an array
 * checks if array and returns first ID, otherwise return ID
 * @param {String} userId user ID passed via query
 *
 * @return {String} final userID
 */
const getUserIDFromQuery = (userID) => {
  return Array.isArray(userID) ? userID[0] : userID;
};

const getAutomationIdFromString = (str) =>
  str ? str.toLowerCase().replace(/ /g, '_') : '';

/**
 * gets DMA from request object
 * @param {String} req request object
 *
 * @return {number} user DMA
 */
const getDMA = (req) => {
  return req?.query?.dma || req?.geodata?.geo?.dma;
};

/**
 * gets zipcode from request object
 * @param {String} req request object
 *
 * @return {number} user zipcode
 */
const getZip = (req) => {
  return req?.query?.zip || req?.geodata?.geo?.zip;
};

const cleanPath = (path) => {
  if (path === '/' || !path) {
    return path;
  }
  return path.toLowerCase().replace(/\/$/, '');
};

const validateEmail = (value) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

export {
  forceToArray,
  plural,
  normalize,
  toPremiereDateFormat,
  toPremiereDateFormatMMDDYY,
  isFutureDate,
  isDateWithin90Days,
  getDateInXDays,
  getBreakpoint,
  scrollToComponent,
  getPageType,
  truncateByWordCount,
  fetchWithTimeout,
  parseJSON,
  isValidNonce,
  getUserIDFromQuery,
  getAutomationIdFromString,
  getDMA,
  getZip,
  cleanPath,
  validateEmail,
};
