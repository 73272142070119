import React from 'react';

export function XIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2071 2.3138C14.5976 1.92327 14.5976 1.29011 14.2071 0.899583C13.8166 0.509058 13.1834 0.509058 12.7929 0.899583L7.5 6.19248L2.20711 0.899583C1.81658 0.509058 1.18342 0.509058 0.792893 0.899583C0.402369 1.29011 0.402369 1.92327 0.792893 2.3138L6.08579 7.60669L0.792893 12.8996C0.402369 13.2901 0.402369 13.9233 0.792894 14.3138C1.18342 14.7043 1.81658 14.7043 2.20711 14.3138L7.5 9.0209L12.7929 14.3138C13.1834 14.7043 13.8166 14.7043 14.2071 14.3138C14.5976 13.9233 14.5976 13.2901 14.2071 12.8996L8.91421 7.60669L14.2071 2.3138Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
