import Text from '@hulu/web-ui/Text';

const ExpNoFeesMinimalistDisclaimer = ({ children, className }) => {
  return (
    <div className={className}>
      <Text variant="body10">{children}</Text>
    </div>
  );
};

export default ExpNoFeesMinimalistDisclaimer;
