import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { preventFocus, enterKeyHandler } from '!app/lib/accessibilityUtils';

import '../stylesheet/NavItemStyle.scss';

const NavItem = ({ active, children, ...props }) => {
  return (
    <a
      className={classNames('nav-item', { 'nav-item--active': active })}
      {...props}
      /* eslint-disable react/destructuring-assignment */
      onKeyDown={enterKeyHandler(props.onClick)}
      onMouseDown={preventFocus}
      role="button"
      aria-label={`View ${children}`}
      tabIndex="0"
    >
      <Text variant="label14">{children}</Text>
    </a>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

NavItem.displayName = 'NavItem';

export default NavItem;
