import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import React, { useState } from 'react';

import DownArrowSVG from '!app/components/assets/DownArrowSVG';
import UpArrowSVG from '!app/components/assets/UpArrowSVG';
import { useExpPlanComparisonChartContext } from '!app/experimental/components/ExpPlanComparisonChartCategoryDropdown/view/ExpPlanComparisonChartContext';
import { fireUserInteraction } from '!app/metrics';

export const ExpPlanComparisonDropdownController = ({ controllerTabs }) => {
  const { tabIndex, setTabIndex } = useExpPlanComparisonChartContext();
  const [isExpanded, toggleIsExpanded] = useState(false);
  const currentPlan = controllerTabs[tabIndex].name;

  const tabOnClick = (key, value) => {
    fireUserInteraction(
      `${value.elementSpecifier}`,
      'driver_click',
      'click',
      true,
      `${value.name}`
    );

    setTabIndex(key);
    toggleIsExpanded(!isExpanded);
  };

  if (!controllerTabs || Object.keys(controllerTabs).length < 2) return null;

  return (
    <>
      <div
        className="ExpPlansContainerDropdown-plan-controller"
        onClick={() => toggleIsExpanded(!isExpanded)}
      >
        <Text>{currentPlan}</Text>
        {isExpanded ? (
          <UpArrowSVG title="UpArrow" />
        ) : (
          <DownArrowSVG title="DownArrow" />
        )}
      </div>
      {!isExpanded ? (
        ''
      ) : (
        <div className="expanded-list">
          {Object.entries(controllerTabs).map(([key, value]) => {
            return (
              <Text
                className={classNames('plan-controller-chip', {
                  '--selected': tabIndex === key,
                })}
                key={`controller-${key}`}
                label={value.name}
                value={key}
                aria-label={`${value.name} Option`}
                onClick={() => tabOnClick(key, value)}
              >
                {value.name}
              </Text>
            );
          })}
        </div>
      )}
    </>
  );
};
export default ExpPlanComparisonDropdownController;
