import classnames from 'classnames';
import { func, shape, string } from 'prop-types';
import React from 'react';

import { MastheadModelSchema } from '../model/schema';

import BrandWatermark from './BrandWatermark';
import DetailEntity from './DetailEntity';

import { useWindowResize } from '!app/lib/hooks/useWindowResize';
import DetailEntityBackground from '!app/share/DetailEntityBackground';

import '../stylesheets/Masthead.scss';

const BACKGROUND_WIDTH = 1400;

const Masthead = (props) => {
  const {
    model: {
      backgroundArtwork,
      brandArtwork,
      network,
      requirePremium,
      verticalTileArtwork,
      ribbon,
      ...rest
    },
    play,
    updateDetailEntity,
    user,
    ctaFields,
    locale,
    contentOverrides,
  } = props;

  const {
    windowSize: { width },
  } = useWindowResize();
  const maxMobileViewSize = 768;
  const isMobileView = width < maxMobileViewSize;
  const withRibbon = ribbon?.ribbonImage;

  updateDetailEntity({ network, requirePremium });
  const componentClass = classnames('DetailEntityMasthead', 'cu-masthead', {
    'DetailEntityMasthead--with-ribbon': withRibbon,
  });

  return (
    <div
      className={componentClass}
      role="region"
      aria-labelledby="regionDetailMasthead"
    >
      <DetailEntityBackground
        artwork={backgroundArtwork}
        imageWidth={BACKGROUND_WIDTH}
        isMobileView={isMobileView}
        mobileArtwork={verticalTileArtwork}
      />
      <BrandWatermark brandArtwork={brandArtwork} isMobileDisplay={false} />
      <DetailEntity
        {...rest}
        play={play}
        brandArtwork={brandArtwork}
        user={user}
        ctaFields={ctaFields}
        locale={locale}
        network={network}
        requirePremium={requirePremium}
        ribbon={ribbon}
        contentOverrides={contentOverrides}
      />
    </div>
  );
};

Masthead.propTypes = {
  model: MastheadModelSchema,
  play: func,
  updateDetailEntity: func,
  user: shape({}),
  ctaFields: shape({}),
  locale: string,
};

export default Masthead;
