import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';

import { FooterSocial, FooterLegal } from '!app/share/Footer';
import './FooterStyle.scss';

const Footer = (props) => {
  const { asPath, featureFlags, pageType } = props;

  return (
    <footer
      className="cu-footer"
      role="region"
      aria-label="Footer"
      id="FooterSm"
    >
      <div className="container-width">
        <div className="hulu-footer">
          <div className="footer--site-links">
            <a href="/press" className="footer--site-link" title="Press">
              <Text variant="body14">Press</Text>
            </a>
            <a href="/jobs" className="footer--site-link" title="Jobs">
              <Text variant="body14">Jobs</Text>
            </a>
            <a
              href="//help.hulu.com/s/article/how-to-contact-Hulu"
              className="footer--site-link"
              title="Contact Us"
            >
              <Text variant="body14">Contact</Text>
            </a>
          </div>
          <div className="footer--legal-links">
            <div className="footer--legal-links-left">
              <FooterSocial asPath={asPath} pageType={pageType} />
            </div>
            <div className="footer--legal-links-right">
              <FooterLegal
                asPath={asPath}
                featureFlags={featureFlags}
                pageType={pageType}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  asPath: PropTypes.string,
  featureFlags: PropTypes.shape({}),
  pageType: PropTypes.string,
};

export default Footer;
