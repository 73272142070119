import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { bool, shape } from 'prop-types';
import React, { useState } from 'react';

import { PlansContainerModelSchema } from '../model/schema';

import PlanCard from './PlanCard';

import { WithEvents } from '!app/metrics/hoc';
import { DetailEntityStoreSchema } from '!app/share/schema';

import '../stylesheet/PlansContainer.scss';

/**
 * Renders a container of plan cards and manages their animation states.
 *
 * @see PlanCard
 */
const PlansContainer = (props) => {
  const [cardIsHighlighted, setCardIsHighlighted] = useState(false);
  const {
    model: { headline, description, plans, disclaimer },
    user,
    ctaFields,
    enableBrand,
    network,
    requirePremium,
    cartAbandonment,
  } = props;

  const planContainer = classNames(
    'plan-container',
    'container-width',
    'cu-plans'
  );

  const planContainerRow = classNames('plan-container__row', {
    'plan-container__has-three': plans.length === 3,
  });

  const planContainerHeadline = classNames('plan-container__headline', {
    'plan-container__headline--brand': enableBrand,
  });

  /**
   *  Manages the animation state of a card.
   *
   * @param {boolean} isHighlighted
   */
  const toggleCardIsHighlighted = (isHighlighted) => {
    setCardIsHighlighted(isHighlighted);
  };

  return (
    <div className={planContainer} id="plans" role="region" aria-label="Plans">
      <div className="plan-container__header">
        {headline && (
          <Text
            as="h2"
            breakpoints={{ xs: 'title32', md: 'title40', lg: 'title48' }}
            className={planContainerHeadline}
          >
            {headline}
          </Text>
        )}
        {description && (
          <Text
            as="p"
            breakpoints={{ xs: 'body16', lg: 'body24' }}
            className="plan-container__description"
          >
            {description}
          </Text>
        )}
      </div>
      <div className={planContainerRow}>
        {plans &&
          plans.map((item, index) => {
            return (
              <PlanCard
                key={index}
                index={index}
                model={item}
                user={user}
                ctaFields={ctaFields}
                someCardIsHighlighted={cardIsHighlighted}
                onMouseEnteredOrLeft={toggleCardIsHighlighted}
                isOneUpPlan={plans.length === 1}
                network={network}
                requirePremium={requirePremium}
                cartAbandonment={cartAbandonment}
              />
            );
          })}
      </div>
      {disclaimer && (
        <div className="plan-container__disclaimer section-disclaimer section-disclaimer--dark">
          <WithEvents.div dangerouslySetInnerHTML={{ __html: disclaimer }} />
        </div>
      )}
    </div>
  );
};

PlansContainer.propTypes = {
  model: PlansContainerModelSchema.isRequired,
  user: shape({}),
  ctaFields: shape({}),
  enableBrand: bool,
  cartAbandonment: shape({}),
  ...DetailEntityStoreSchema,
};

export default PlansContainer;
