import PropTypes from 'prop-types';

import { CollectionModelSchema } from '../../common/model/schema';

export default PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  collection: CollectionModelSchema.isRequired,
  collectionIndex: PropTypes.number,
});
