import Text from '@hulu/web-ui/Text';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { getPageType } from '!app/lib/TealiumEventsUtils';
import { normalize } from '!app/lib/utils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';

const FooterLegal = ({ asPath, featureFlags, pageType }) => {
  const currentYear = new Date().getFullYear();
  const tealiumPageType = getPageType(asPath, pageType);
  const relTag = 'noopener noreferrer nofollow';
  const hasOneTrustFooterChangeActivated =
    featureFlags?.hasOneTrustFooterChangeActivated;
  const hasSubscriberAgreementUpdateEnabled =
    featureFlags?.hasSubscriberAgreementUpdateEnabled;
  const handleDoNotShareClick = (e) => {
    e.preventDefault();
    const activeGroups = window?.OnetrustActiveGroups || '';
    // Split by comma and filter out empty values
    const currentGroupCount = activeGroups.split(',').filter((group) => group)
      .length;

    window?.OneTrust?.OnConsentChanged((consentEvent) => {
      const newGroupCount = consentEvent?.detail?.length;
      // Only trigger a reload if active group count changed
      if (currentGroupCount !== newGroupCount) {
        window?.location?.reload();
      }
    });
    window?.OneTrust?.ToggleInfoDisplay();
  };

  const legalLinks = [
    {
      label: 'About Ads',
      link: '//info.evidon.com/pub_info/3920?v=1&nt=0&nw=false',
      icon: '//c.evidon.com/pub/icon1.png',
      rel: relTag,
      target: '_blank',
      alt: 'Evidon AdChoices',
    },
    {
      label: hasSubscriberAgreementUpdateEnabled
        ? 'Subscriber Agreement'
        : 'Terms of Use',
      link: hasSubscriberAgreementUpdateEnabled
        ? '/subscriber_agreement'
        : '/terms',
      rel: relTag,
      target: '_self',
    },
    {
      label: 'Privacy Policy',
      link:
        'https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/',
      rel: relTag,
      target: '_self',
    },
    {
      label: hasOneTrustFooterChangeActivated
        ? 'Do Not Sell or Share My Personal Information'
        : 'Do Not Sell My Personal Information',
      link: '/do-not-sell-my-info',
      rel: '',
      target: '_self',
      onClick: hasOneTrustFooterChangeActivated
        ? handleDoNotShareClick
        : undefined,
    },
    {
      label: hasOneTrustFooterChangeActivated
        ? 'Your US State Privacy Rights'
        : 'Your California Privacy Rights',
      link: '/ca-privacy-rights',
      rel: '',
      target: '_self',
    },
    {
      label: 'TV Parental Guidelines',
      link: 'http://www.tvguidelines.org',
      rel: relTag,
      target: '_blank',
    },
    {
      label: 'Sitemap',
      link: '/sitemap',
      rel: '',
      target: '_self',
    },
  ];

  const renderIcon = (section) => {
    return (
      <>
        <img src={section.icon} role="presentation" alt={section.alt} />
        <Text variant="body12">{section.label}</Text>
      </>
    );
  };

  return (
    <>
      <div className="footer--legal-links-right-col">
        <Text variant="body12" className="footer__copyright">
          &copy; {currentYear} Hulu, LLC
        </Text>
      </div>

      {legalLinks
        .filter((section) => get(featureFlags, section.featureFlag, true))
        .map((section, index) => {
          const LegalItem = withUtagLink(
            {
              event_name: 'footer_link',
              event_label: section.link,
              page_type: tealiumPageType,
            },
            withUserInteraction('a', 'footer', normalize(section.label))
          );

          const labelText = <Text variant="body12">{section.label}</Text>;

          return (
            <div className="footer--legal-links-right-col" key={index}>
              <LegalItem
                href={section.link}
                className="footer--legal-link"
                target={section.target}
                rel={section.rel}
                aria-label={section.label}
                title={section.label}
                onClick={section.onClick}
              >
                {section.icon ? renderIcon(section) : labelText}
              </LegalItem>
            </div>
          );
        })}

      <div className="footer--legal-links-right-col">
        <Text variant="body12" className="footer__copyright-mobile">
          &copy; {currentYear} Hulu, LLC
        </Text>
      </div>
    </>
  );
};

FooterLegal.propTypes = {
  asPath: PropTypes.string,
  featureFlags: PropTypes.shape({}),
  pageType: PropTypes.string,
};

export default FooterLegal;
