import get from 'lodash/get';

import { PREMIUM_NETWORKS, SERIES } from '!app/lib/constants';
import { plural } from '!app/lib/utils';

export const getType = (entity) => {
  if (entity.toLowerCase() === SERIES) {
    return 'TV Series';
  }

  return entity[0].toUpperCase() + entity.slice(1);
};

export const getCollectionByName = (name, components) => {
  return components.find((component) => component.name.toLowerCase() === name);
};

export const getTags = (entity) => {
  const premiereDate = get(entity, 'premiereDate');
  return premiereDate ? [new Date(entity.premiereDate).getFullYear()] : null;
};

export const getArtworkForMasthead = (entity) => {
  const { artwork } = entity;
  const backgroundArtwork =
    get(artwork, 'horizontalHero') || get(artwork, 'horizontalProgramTile');
  const verticalTileArtwork =
    get(artwork, 'verticalHero') || get(artwork, 'verticalProgramTile');
  const titleArtwork = get(artwork, 'titleArtwork');
  const brandArtwork = get(artwork, 'watermark');

  return {
    backgroundArtwork,
    verticalTileArtwork,
    titleArtwork,
    brandArtwork,
  };
};

export const getHeadlineText = (entity) => {
  const { isHuluOriginal, availableSeasons } = entity;

  const text = [];
  if (isHuluOriginal) {
    text.push('Original');
  }

  if (availableSeasons > 0) {
    text.push(
      `${availableSeasons} ${plural(availableSeasons, 'season')} available`
    );
  }

  return text;
};

/**
 * Returns entity id
 * @param {Object} entity
 */
export const getId = (entity) => entity.id || null;

/**
 * @param {string} network
 * @return {boolean}
 */
export const isPremiumNetwork = (network) =>
  typeof network === 'string' &&
  PREMIUM_NETWORKS?.[network?.toLowerCase()] !== undefined;
