import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React from 'react';

import SignupModalModelSchema from '../model/schema';

import 'isomorphic-fetch';
import { view as Modal } from '!app/components/Modal';
import {
  MOVIE,
  MODAL_NAME_SIGNUP,
  HULU_UPCOMING_SERIES_TITLE,
  HULU_UPCOMING_MOVIE_TITLE,
} from '!app/lib/constants';
import { mobileDetect } from '!app/lib/environment';
import {
  generateLogin,
  getPremiumNetworkFromParameter,
  getCtaText,
} from '!app/lib/signupUtils';
import { getEventNameFromEntitlement } from '!app/lib/TealiumEventsUtils';
import { ctaRedirect, getDownloadAppLink } from '!app/lib/urlUtils';
import {
  plural,
  toPremiereDateFormat,
  isDateWithin90Days,
} from '!app/lib/utils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';
import Logo from '!app/share/Logo';
import { DetailEntityStoreSchema } from '!app/share/schema';
import '../stylesheets/SignupModal.scss';

const LoginButton = withUtagLink(
  {
    event_name: 'login',
    cta_placement: 'signup_modal',
    page_type: 'signup_browse',
  },
  withUserInteraction(CTAButton, 'cta_modal', 'login')
);

const SignupModal = (props) => {
  const {
    model: {
      name,
      availableSeasons,
      episodeCount,
      ctaText,
      legalText,
      bodyText,
      premiereDate,
      contentType,
      ctaDownloadAppText,
      isOriginalContent,
      ctaUrl,
      ctaHypeProgram,
    },
    user: { isHuluUser },
    user,
    ctaFields,
    locale,
    cartAbandonment,
    contentOverrides,
    asPath,
    network,
    requirePremium,
    host,
  } = props;

  const checkIfUpcomingContent = () => {
    return isDateWithin90Days(premiereDate) && isOriginalContent;
  };

  const isHuluUpcoming = checkIfUpcomingContent();

  const loginLink = generateLogin({
    isHuluUser,
    asPath,
    url: host,
  });

  const shouldShowDownloadAppLink =
    isHuluUser && mobileDetect().mobile() && ctaDownloadAppText;

  const DriverButton = withUtagLink(
    {
      event_name: getEventNameFromEntitlement(user, shouldShowDownloadAppLink),
      cta_placement: 'signup_modal',
      page_type: 'signup_browse',
    },
    withUserInteraction(CTAButton, 'cta_modal')
  );

  const redirect = () => {
    const from =
      ctaHypeProgram || getPremiumNetworkFromParameter(requirePremium, network);

    const ctaOptions = {
      user,
      componentUrl: ctaUrl,
      ctaFields,
      from,
      cartAbandonment,
      passUrlAsProgram: false,
    };

    // If logged-in and in mobile
    if (shouldShowDownloadAppLink) {
      window.location.assign(getDownloadAppLink());
    } else {
      ctaRedirect(ctaOptions);
    }
  };

  const login = () => {
    window.location.assign(loginLink);
  };
  const upcomingTitle =
    contentType === MOVIE
      ? HULU_UPCOMING_MOVIE_TITLE
      : HULU_UPCOMING_SERIES_TITLE;
  const headline = isHuluUpcoming
    ? `${upcomingTitle} ${toPremiereDateFormat(premiereDate)}`
    : `Start watching <span>${contentOverrides?.overrideName || name}</span>`;
  const subheadline =
    availableSeasons && episodeCount && !isHuluUpcoming
      ? `${availableSeasons} ${plural(
          availableSeasons,
          'season'
        )} available (${episodeCount} ${plural(episodeCount, 'episode')})`
      : null;
  const ctaOptions = {
    user,
    componentNonSubCta: ctaText,
    locale,
    ctaFields,
    cartAbandonment,
  };
  const newCtaText = shouldShowDownloadAppLink
    ? ctaDownloadAppText
    : getCtaText(ctaOptions);
  const loginBtn = 'Log In';

  return (
    <Modal model={{ id: MODAL_NAME_SIGNUP }} className="signup-modal__parent">
      <div className="signup-modal">
        <span className="signup-modal__brand" href="/">
          <span>hulu</span>
          <div className="signup-modal__logo">
            <Logo />
          </div>
        </span>
        <Text as="div" className="signup-modal__headline" variant="body24">
          <div dangerouslySetInnerHTML={{ __html: headline }} />
        </Text>
        {subheadline && (
          <Text as="div" variant="body16" className="signup-modal__subheadline">
            <span>{subheadline}</span>
          </Text>
        )}
        <Text as="div" variant="body16" className="signup-modal__body">
          {bodyText}
        </Text>
        {!isHuluUser && (
          <LoginButton
            className="signup-modal__button signup-modal__button-white"
            useStyle="secondary"
            onClick={login}
          >
            {loginBtn}
          </LoginButton>
        )}
        <DriverButton
          className="signup-modal__button signup-modal__button-black"
          useStyle="black"
          onClick={redirect}
        >
          {newCtaText}
        </DriverButton>
        {legalText && (
          <Text as="div" variant="body12" className="signup-modal__legal">
            <span dangerouslySetInnerHTML={{ __html: legalText }} />
          </Text>
        )}
      </div>
    </Modal>
  );
};

SignupModal.propTypes = {
  model: SignupModalModelSchema,
  user: PropTypes.shape({}),
  ...DetailEntityStoreSchema,
};

export default SignupModal;
