import PropTypes from 'prop-types';

import { CollectionModelSchema } from '../../common/model/schema';

export default PropTypes.shape({
  title: PropTypes.string, // title of the collection
  editorialHeadline: PropTypes.string,
  editorialDescription: PropTypes.string,
  collection: CollectionModelSchema.isRequired,
});
