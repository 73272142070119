import Text from '@hulu/web-ui/Text';
import React from 'react';

// This component is used to display the modal copy and brand logo at the bottom of the
// Collection Modal Masthead when at least one library poster is automated

export const AutomationStateModalCopy = ({
  brandUrl,
  copyDescription,
  copyTitle,
}) => {
  return (
    <div className="modal__copy modal__copy--automation">
      <Text
        as="h3"
        breakpoints={{ xs: 'title32', md: 'title48' }}
        className="modal__copy--title"
      >
        {copyTitle}
      </Text>

      <div className="modal__copy--flex-box--automation">
        <div className="modal__copy--description--automation">
          <Text
            as="p"
            breakpoints={{ xs: 'body16', md: 'body18' }}
            className="modal__copy--description"
          >
            {copyDescription}
          </Text>
        </div>
        <div className="modal__copy--brand-logo--automation">
          {brandUrl && (
            <img
              src={brandUrl}
              role="presentation"
              className="modal__copy--brand-logo"
            />
          )}
        </div>
      </div>
    </div>
  );
};
