import Text from '@hulu/web-ui/Text';
import React, { useState, useEffect, useRef } from 'react';

import messages from '../messages';
import { NetworksGeoModelSchema } from '../model/schema';

import '!app/components/NetworksGeo/stylesheet/NetworksGeo.scss';
import NetworksSearchModal from '!app/components/NetworksSearchModal/view/NetworksSearchModal';
import StaticHtml from '!app/components/StaticHtml/view/StaticHtml';
import { Translate } from '!app/i18n';
import { enterKeyHandler, preventFocus } from '!app/lib/accessibilityUtils';
import { getHost } from '!app/lib/config';
import { fireUtagLink } from '!app/metrics/fireEvent';
import { WithEvents } from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';

const NetworksGeo = (props) => {
  const {
    model: {
      headline,
      description,
      defaultInputEyebrow,
      specificInputEyebrow,
      promotedChannelList,
      defaultChannelHtml,
    },
    geodata,
    locale,
  } = props;

  const [zipcode, setZipcode] = useState(geodata?.zip || '');
  const [area, setArea] = useState(geodata?.area || '');
  const [results, setResults] = useState([]);
  const [isServerError, setIsServerError] = useState(false);
  const isMountedRef = useRef(null);

  // Input Eyebrow is used as the primary action button text
  const inputEyebrow = area ? `${area} →` : defaultInputEyebrow;
  const translator = new Translate(locale);
  const translatedMessage = translator.translateAll(messages);
  const isDefault = results.length === 0;

  // This currently only returns the first channels group in the list for display
  const logos = results.slice(0, 1).map((category) => {
    const channelsList = category.channels.map((channel) => {
      return { name: channel.network_name, icon: channel.artwork.path };
    });
    return { name: category.name, logoList: channelsList };
  });

  useEffect(() => {
    isMountedRef.current = true;
    // Load the networks by detected zip code on component load if a zip code is present
    loadChannels(zipcode);
    // Cleanup on unmount
    return () => (isMountedRef.current = false);
  }, [zipcode]);

  // Order the collection of networks by the perferred networks group
  const filterPromotedChannels = (data) => {
    const found = data.findIndex(
      (channel) => channel?.name === promotedChannelList
    );
    const NOT_FOUND_INDEX = -1;
    const channels =
      found !== NOT_FOUND_INDEX ? [...data.splice(found, 1), ...data] : data;
    return channels;
  };

  const loadChannels = (zip) => {
    // Avoids undefined zip code calls to the api
    if (!zip) {
      return;
    }

    const site = getHost('site').replace(
      /^http(s)?:/i,
      window.location.protocol
    );

    fetch(
      `${site}/api/3.0/channels/local/${zip}?group_by=category&be_localsonly=1`
    )
      .then((res) => res.json())
      .then(
        (data) => {
          // Ensure the component is still mounted before we proceed with updating other states
          if (isMountedRef?.current) {
            const channels = filterPromotedChannels(data);
            if (!Array.isArray(channels) || channels?.length === 0) {
              return;
            }
            loadArea(zip);
            setResults(channels);
          }
        },
        () => {
          setServerError();
        }
      );
  };

  // Determine the geo area name using the detected zip code
  const loadArea = (zip) => {
    const url = `/api/geolocation/getAreafromZip/${zip}`;
    fetch(url, { method: 'GET' })
      .then((response) => response.text())
      .then(
        (areaName) => {
          setArea(areaName || '');
        },
        () => {
          setServerError();
        }
      );
  };

  const setServerError = () => {
    setIsServerError(true);
  };

  // In instances where the zip code changes at the NetworkSearchModal level this allows
  // the zip code to be updated at this level as well
  const updateParentState = (childZip, childIsInvalidZip) => {
    // If the last zipcode was invalid skip zipcode update
    if (!childIsInvalidZip && childZip) {
      setZipcode(childZip);
    }
  };

  const onEyebrowClicked = () => {
    fireUtagLink({ event_name: 'zip_module_v2' });
  };

  return (
    <div className="NetworksGeo-container">
      <div
        className="NetworksGeo container-width cu-channels"
        id="channels"
        role="region"
        aria-labelledby="regionNetwork"
      >
        <div className="NetworksGeo__header section-header">
          <Text
            as="h2"
            variant="title32"
            className="NetworksGeo__headline section-headline"
            id="regionNetwork"
          >
            {headline}
          </Text>
          {Boolean(description) && (
            <WithEvents.div
              className="NetworksGeo__description section-body"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        {inputEyebrow && (
          <div>
            {!isDefault && specificInputEyebrow && (
              <Text variant="label18">{specificInputEyebrow}</Text>
            )}
            <button
              className="arrow-button"
              type="button"
              name="view-channels"
              data-automationid="arrow_button"
              data-toggle="modal"
              data-target="#zip-modal-v2"
              onClick={onEyebrowClicked}
              onKeyDown={enterKeyHandler(() =>
                fireUtagLink({ event_name: 'zip_module_v2' })
              )}
              onMouseDown={preventFocus}
            >
              <Text variant="label18">{inputEyebrow}</Text>
            </button>
          </div>
        )}
        <div className="NetworksGeo__networkContainer">
          <div className="NetworksGeo__networkList">
            {isDefault || isServerError ? (
              <StaticHtml model={{ html_code: defaultChannelHtml }} />
            ) : (
              logos?.map((group) => (
                <div key={group.name} className="NetworksGeo__logoGroup">
                  {group?.logoList?.map((logo, i) => (
                    <img
                      className="NetworksGeo__networkIcon"
                      src={logo.icon}
                      key={`${logo.name}-${logo.icon}-${i}`}
                      alt={logo.name}
                    />
                  ))}
                </div>
              ))
            )}
          </div>
          {(isDefault || isServerError) && (
            <CTAButton
              className="NetworksGeo__seeAllCta"
              useStyle="black_outline"
              data-toggle="modal"
              data-target="#zip-modal-v2"
            >
              {translatedMessage.see_all}
            </CTAButton>
          )}
        </div>
      </div>
      <NetworksSearchModal
        sharedZipCode={zipcode}
        model={{ promoted_channel: promotedChannelList }}
        updateParent={updateParentState}
        showResultsOnOpen
        modalId="zip-modal-v2"
      />
    </div>
  );
};

NetworksGeo.propTypes = {
  model: NetworksGeoModelSchema.isRequired,
};

export default NetworksGeo;
