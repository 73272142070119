import get from 'lodash/get';
import { useState, useEffect } from 'react';

/**
 * Manages window resizing
 */
const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: get(window, 'innerWidth'),
        height: get(window, 'innerHeight'),
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { windowSize };
};

export { useWindowResize };
