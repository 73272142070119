import { useEffect, useState } from 'react';

import { fetchActivationCode } from '!app/services/Auth';

const CONNECTED_ERROR_PATH = '/connected-app-error';

const useFetchActivationCode = () => {
  const [authCode, setAuthCode] = useState(null);

  useEffect(() => {
    const initFetchActivationCode = async () => {
      const thisAuthCode = await fetchActivationCode();
      if (!thisAuthCode) {
        // Something went wrong getting an auth code
        // Redirect to Connected auth error page
        window.location.assign(CONNECTED_ERROR_PATH);
      } else {
        setAuthCode(thisAuthCode);
      }
    };
    initFetchActivationCode();
  }, []);

  return {
    authCode,
  };
};

export { useFetchActivationCode };
