import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

const ExpPlanComparisonChartContext = React.createContext();

const useExpPlanComparisonChartContext = () =>
  useContext(ExpPlanComparisonChartContext);

const ExpPlanComparisonChartProvider = ({
  defaultLeftColumn,
  defaultRightColumn,
  children,
}) => {
  const [leftColumn, setleftColumn] = useState(defaultLeftColumn);
  const [rightColumn, setrightColumn] = useState(defaultRightColumn);

  const value = {
    leftColumn,
    setleftColumn,
    rightColumn,
    setrightColumn,
  };

  return (
    <ExpPlanComparisonChartContext.Provider value={value}>
      {children}
    </ExpPlanComparisonChartContext.Provider>
  );
};

ExpPlanComparisonChartProvider.propTypes = {
  children: PropTypes.node,
  defaultLeftColumn: PropTypes.string,
  defaultRightColumn: PropTypes.string,
};

export { ExpPlanComparisonChartProvider, useExpPlanComparisonChartContext };
