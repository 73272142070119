import PropTypes from 'prop-types';

import { CollectionModelSchema } from '!app/components/Collections/common/model/schema';

export const CollectionTabsModelSchema = PropTypes.shape({
  accentColor: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      model: PropTypes.shape({
        type: PropTypes.string.isRequired,
        collection: CollectionModelSchema.isRequired,
      }),
    })
  ).isRequired,
});
