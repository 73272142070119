import classnames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CollectionTabsModelSchema } from '../model/schema';

import { getComponent } from '!app/components';
import { ITEM_LIST_SCHEMA_ATTRIBUTES, MOVIE } from '!app/lib/constants';
import { Tabs, Tab } from '!app/share/Tabs';
import '../stylesheets/CollectionTabs.scss';

class CollectionTabs extends Component {
  constructor(props) {
    super(props);
    const {
      query,
      model: { tabs = [] },
    } = this.props;
    const tab = query?.tab;
    const tabQueryParam = typeof tab === 'string' && tab.toLowerCase();
    const tabIds = tabs.map((tabEl) => (tabEl?.title || '').toLowerCase());

    // Set initial tab to the value of ?tab param
    if (tabIds.includes(tabQueryParam)) {
      this.initialTab = parseInt(tabIds.indexOf(tabQueryParam), 10);
    }
  }

  render() {
    const {
      model: { tabs = [], accentColor, shouldCenterTabTitles, metrics },
      extraClassName,
      onSelected,
      latestSeason,
      withSlider,
      query,
      asPath,
      contentOverrides,
    } = this.props;

    let tabIndex = get(metrics, 'collectionIndex', 0);
    const collectionTabsClass = classnames('CollectionTabs', extraClassName);
    const itemListAttributes =
      !withSlider && query?.entity === MOVIE ? ITEM_LIST_SCHEMA_ATTRIBUTES : {};

    return (
      <div className={collectionTabsClass} {...itemListAttributes}>
        <Tabs
          accentColor={accentColor}
          shouldCenterTabTitles={shouldCenterTabTitles}
          onSelected={onSelected}
          tabType="collection"
          initialTab={this.initialTab}
          shouldPreventDefaultClick
        >
          {tabs
            .filter((tab) => {
              const { model } = tab;
              const hasItems = get(model, 'collection.items', []).length > 0;
              const tabType = get(model, 'type', null);
              if (hasItems || tabType === 'collection_details') return tab;
              return undefined;
            })
            .map((tab, index) => {
              const { model, title = '' } = tab;
              const tabType = get(model, 'type', null);
              const CollectionComponent = getComponent(tabType);
              const hrefPath = asPath
                ? `${asPath}?tab=${title.toLowerCase()}`
                : undefined;

              if (!CollectionComponent) return null;

              return (
                <Tab key={index} title={title} hrefPath={hrefPath}>
                  <CollectionComponent
                    model={{ ...model }}
                    collectionIndex={tabIndex++}
                    latestSeason={
                      tabType === 'collection_details' ? null : latestSeason
                    }
                    contentOverrides={contentOverrides}
                    includeSEOAttr={!withSlider}
                    query={query}
                    asPath={asPath}
                  />
                </Tab>
              );
            })}
        </Tabs>
      </div>
    );
  }
}

CollectionTabs.propTypes = {
  model: CollectionTabsModelSchema,
  extraClassName: PropTypes.string,
  onSelected: PropTypes.func,
  latestSeason: PropTypes.shape({
    season: PropTypes.string,
    previousSeason: PropTypes.string,
    hasUpcomingSeason: PropTypes.boolean,
    hasLastEpPremiered: PropTypes.boolean,
    firstEpPremiereDate: PropTypes.string,
    lastEpPremiereDate: PropTypes.string,
  }),
};

export default CollectionTabs;
