import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { PlanComparisonFeatureModelSchema } from '../model/schema';

import { view as Modal } from '!app/components/Modal';
import { fireUtagLink, fireUserInteraction } from '!app/metrics';

const PlanComparisonFeature = (props) => {
  const {
    feature: {
      headline,
      plans,
      bullet,
      description: { richText, modalId },
    },
    index,
    type,
    numPlans,
  } = props;

  const hasMoreInfo = richText || modalId;
  const descriptionId = `${type}-description-tooltip`;

  const fireTooltipMetrics = (event) => {
    const toolTip = `${get(event, 'target.dataset.column', 'NO_COL')}-${get(
      event,
      'target.dataset.feature',
      'NO_FEATURE'
    )}`;
    fireUtagLink({
      event_name: 'tooltip_plans',
      cta_placement: 'comparison_chart',
      tool_tip: toolTip,
    });
    fireUserInteraction(descriptionId, 'click', 'click');
  };

  const featureTitleClasses = classNames(
    'col-xs-6',
    'col-lg-5',
    'plan-feature__title',
    { 'plan-feature__standard': !bullet }
  );

  const bulletDefault = (
    <Text variant="body16" className="plan-feature__bullet_default">
      &mdash;
    </Text>
  );

  const bulletApplicable = (
    <img
      src="/static/hitch/static/icons/Pricing_Checkmark_green-light.svg"
      role="presentation"
      alt={headline}
    />
  );

  const bulletText = (text) => (
    <Text variant="body16" className="plan-feature__bullet_text">
      {text}
    </Text>
  );
  const featureHref = richText ? `#${descriptionId}_${index}` : `#${modalId}`;

  return (
    <div className="plan-feature-item">
      <div className={featureTitleClasses}>
        <div>
          <Text variant="body16">{headline}</Text>
          {hasMoreInfo && (
            <span>
              <a
                data-toggle="modal"
                data-target={featureHref}
                className="plan-addon__info"
                title={`${headline} info`}
                role="button"
                onClick={fireTooltipMetrics}
                href={featureHref}
              >
                <img
                  src="/static/hitch/static/icons/Info_Icon_Dark_Grey.svg"
                  role="presentation"
                  alt={headline}
                />
              </a>
              {richText && (
                <Modal
                  model={{ id: `${descriptionId}_${index}` }}
                  className="plan-addon__info-modal"
                >
                  <div>
                    <Text
                      as="h3"
                      variant="title32"
                      className="plan-addon__header"
                    >
                      {headline}
                    </Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: richText,
                      }}
                    />
                  </div>
                </Modal>
              )}
            </span>
          )}
        </div>
      </div>

      {plans.map((plan, i) => {
        const { slug, isApplicable, text } = plan;
        const applicableDisplay = isApplicable
          ? bulletApplicable
          : bulletDefault;
        const featureDisplay = text ? bulletText(text) : applicableDisplay;

        return (
          <Fragment key={i}>
            <div
              className={`col-xs-${
                12 / numPlans
              } plan-feature__check plan-feature__check-${i}`}
              key={`${slug}_${i}`}
            >
              <div className="plan-feature__bullet">{featureDisplay}</div>
            </div>
            <div className="plan-feature__separator" />
          </Fragment>
        );
      })}
    </div>
  );
};

PlanComparisonFeature.propTypes = {
  feature: PlanComparisonFeatureModelSchema,
  index: PropTypes.number,
  numPlans: PropTypes.number,
  type: PropTypes.string,
};

export default PlanComparisonFeature;
