import React from 'react';

const DownArrowSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91248 9.74573C7.68467 9.97354 7.31533 9.97354 7.08752 9.74573L2.42085 5.07906C2.19305 4.85126 2.19305 4.48191 2.42085 4.25411C2.64866 4.0263 3.01801 4.0263 3.24581 4.25411L7.5 8.50829L11.7542 4.25411C11.982 4.0263 12.3513 4.0263 12.5791 4.25411C12.807 4.48191 12.807 4.85126 12.5791 5.07907L7.91248 9.74573Z"
      fill="#040405"
    />
  </svg>
);

export default DownArrowSVG;
