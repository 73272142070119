import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React from 'react';

import { preventFocus } from '!app/lib/accessibilityUtils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';

import '../stylesheets/SimpleCollectionShared.scss';

const TrayHeaderLink = withUtagLink(
  {
    event_name: 'portrait_collection_tray_header',
    component_id: 'portrait_collection',
  },
  withUserInteraction('a', 'tray_header', 'link')
);

const SimpleCollectionShared = ({
  name,
  description,
  viewAllLink,
  slider,
  collectionHubPath,
}) => {
  const TitleEl = () => {
    const titleText = (
      <Text breakpoints={{ xs: 'label14', lg: 'label18' }}>{name}</Text>
    );

    return collectionHubPath ? (
      <TrayHeaderLink
        className="SimpleCollection__tray-header SimpleCollection__title"
        name={`${name}-collection`}
        onMouseDown={preventFocus}
        href={collectionHubPath}
        aria-label={`${name} Collection`}
        data-automationid="simple_collection_title"
      >
        {titleText}
      </TrayHeaderLink>
    ) : (
      <h2
        className="SimpleCollection__title"
        data-automationid="simple_collection_title"
      >
        {titleText}
      </h2>
    );
  };

  return (
    <div className="SimpleCollection">
      <TitleEl />
      {description && (
        <Text
          as="div"
          variant="body16"
          className="SimpleCollection__description"
        >
          {description}
        </Text>
      )}
      {viewAllLink}
      {slider && <div className="SimpleCollection__slider">{slider}</div>}
    </div>
  );
};

SimpleCollectionShared.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  slider: PropTypes.shape({}),
  viewAllLink: PropTypes.shape({}),
  collectionHubPath: PropTypes.string,
};

export default SimpleCollectionShared;
