import { connect } from 'react-redux';

import Modal from './view/Modal';
import Modalv2 from './view/Modalv2';

import { KEY as MODAL } from '!app/redux/modal';
import modalActionHandler from '!app/redux/modal/action-handlers';
import { toggleModal } from '!app/redux/modal/actions';

const reducerMap = {
  [MODAL]: modalActionHandler,
};

const view = connect(
  (state, ownProps) => {
    const {
      model: { id },
    } = ownProps;
    const shown = state?.modal?.[id] && Boolean(state?.modal?.[id].shown);
    return {
      shown,
    };
  },
  (dispatch) => ({
    toggleModal: (id) => dispatch(toggleModal(id)),
  })
)(Modal);

export { view, reducerMap, Modalv2 };
