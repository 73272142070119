import get from 'lodash/get';
import React from 'react';

import SimpleCollectionModelSchema from '../model/schema';

import SimpleCollectionShared from './SimpleCollectionShared';

import { view as TileSlider } from '!app/components/Slider/TileSlider';
import '../stylesheets/SimpleCollection.scss';
import { SIMPLE_COLLECTION } from '!app/lib/constants';
import { getAutomationIdFromString } from '!app/lib/utils';

const SimpleCollection = (props) => {
  const {
    model: { collection, title, description, collectionIndex },
  } = props;

  if (!collection.items.length) return null;

  const slider = (
    <TileSlider
      collection={collection}
      collectionIndex={collectionIndex}
      collectionType={SIMPLE_COLLECTION}
    />
  );

  return (
    <div
      className="NonSubSimpleCollection cu-non-sub-simple-collection"
      data-automationid={`${getAutomationIdFromString(
        title
      )}_simple_collection`}
    >
      <SimpleCollectionShared
        name={title}
        description={description}
        slider={slider}
        collectionHubPath={get(collection, 'collectionHubPath')}
      />
    </div>
  );
};

SimpleCollection.propTypes = {
  model: SimpleCollectionModelSchema,
};

export default SimpleCollection;
