import { PropTypes } from 'prop-types';

export const ExpNoFeesMinimalistColumnModelSchema = PropTypes.shape({
  text: PropTypes.string,
});

export const ExpNoFeesMinimalistModelSchema = PropTypes.shape({
  rows: PropTypes.arrayOf(ExpNoFeesMinimalistColumnModelSchema),
  title: PropTypes.string,
  disclaimer: PropTypes.string,
});
