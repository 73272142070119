import classNames from 'classnames';
import React from 'react';

import { DualMastheadProduct } from './DualMastheadProduct';

import BrandIcon from '!app/components/Masthead/view/BrandIcon';
import GradientBackground from '!app/components/Masthead/view/GradientBackground';
import ImageBackground from '!app/components/Masthead/view/ImageBackground';
import VideoBackground from '!app/components/Masthead/view/VideoBackground';
import { view as Ribbon } from '!app/components/Ribbon';
import { WithEvents } from '!app/metrics/hoc';
import '../stylesheet/DualCtaMasthead.scss';

const DualCtaMasthead = (props) => {
  const {
    asPath,
    children,
    enableBrand,
    idx,
    model: {
      belowCtaContainer,
      dualMastheadProducts,
      alignment,
      eyebrow,
      headline,
      subheadline,
      primaryCopy,
      secondaryCopy,
      backgroundImage,
      backgroundVideos,
      brandIcon,
      enableBrandBorder,
      isFullscreen,
      mobileFirst,
      ribbon,
      style,
    },
    topHatShown,
    user,
  } = props;

  const ctaEntitlements = user?.dualCtaMasthead || [];
  return (
    <div
      id="masthead"
      className={classNames(
        'DualCtaMasthead',
        'cu-masthead',
        style,
        `DualCtaMasthead--${alignment}`,
        enableBrand
          ? { 'DualCtaMasthead--brand': true }
          : {
              'DualCtaMasthead--tall': children,
              'DualCtaMasthead--short': !backgroundImage,
              'DualCtaMasthead--fullscreen': isFullscreen,
              'DualCtaMasthead--tophat-included': topHatShown,
              'DualCtaMasthead--brand-border': enableBrandBorder,
              'gradient-skrim': !enableBrandBorder,
            }
      )}
      role="region"
      aria-labelledby="regionMasthead"
      data-automationid="masthead"
    >
      {!backgroundImage && <GradientBackground />}
      <ImageBackground
        image={backgroundImage}
        identifier={idx}
        mobileFirst={mobileFirst}
      />
      <VideoBackground videos={backgroundVideos} />
      <div className="DualCtaMasthead__container">
        {eyebrow && (
          <WithEvents.div
            className="DualCtaMasthead__preheadline"
            data-automationid="masthead_preheadline"
            dangerouslySetInnerHTML={{ __html: eyebrow }}
          />
        )}
        {headline && (
          <WithEvents.h1
            className="DualCtaMasthead__headline"
            data-automationid="masthead_headline"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        )}
        {subheadline && (
          <WithEvents.div
            className="DualCtaMasthead__subheadline"
            data-automationid="masthead_subheadline"
            dangerouslySetInnerHTML={{ __html: subheadline }}
          />
        )}
        {primaryCopy && (
          <WithEvents.div
            className="DualCtaMasthead__primary-message"
            data-automationid="masthead_message"
            dangerouslySetInnerHTML={{ __html: primaryCopy }}
          />
        )}
        {secondaryCopy && (
          <WithEvents.div
            className="DualCtaMasthead__secondary-message"
            data-automationid="masthead_secondary_message"
            dangerouslySetInnerHTML={{ __html: secondaryCopy }}
          />
        )}
        <div className="DualCtaMasthead__cta-container">
          {dualMastheadProducts.map((cta, index) => {
            return (
              <DualMastheadProduct
                cta={cta}
                index={index}
                key={`cta-${index}`}
                entitlement={ctaEntitlements[index]}
              />
            );
          })}
        </div>
        <div
          className="DualCtaMasthead__below-cta-container"
          dangerouslySetInnerHTML={{ __html: belowCtaContainer }}
        />
        <BrandIcon brandIcon={brandIcon} />
      </div>
      {children && <div className="DualCtaMasthead__children">{children}</div>}
      <Ribbon model={ribbon} asPath={asPath} />
    </div>
  );
};

export default DualCtaMasthead;
