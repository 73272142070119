import { ExpNoFeesMinimalistModelSchema } from '../model/schema';

import ExpNoFeesMinimalistDisclaimer from './ExpNoFeesMinimalistDisclaimer';
import ExpNoFeesMinimalistRow from './ExpNoFeesMinimalistRow';
import ExpNoFeesMinimalistTitle from './ExpNoFeesMinimalistTitle';

import '../stylesheet/ExpNoFeesMinimalistStyle.scss';

const ExpNoFeesMinimalist = (props) => {
  const {
    model: { title, rows, disclaimer },
  } = props;

  return (
    <div className="exp-no-fees-minimalist">
      <ExpNoFeesMinimalistTitle
        className="exp-no-fees-minimalist__title"
        title={title}
      />

      <div className="exp-no-fees-minimalist__table-container">
        <div className="exp-no-fees-minimalist__table">
          <ExpNoFeesMinimalistRow
            model={rows[0]}
            className="exp-no-fees-minimalist__header"
          />

          {rows.slice(1).map((row, index) => (
            <ExpNoFeesMinimalistRow
              key={index}
              model={row}
              isLastRow={rows.length === index + 2}
              className="exp-no-fees-minimalist__row"
            />
          ))}
        </div>
        <ExpNoFeesMinimalistDisclaimer className="exp-no-fees-minimalist__disclaimer">
          {disclaimer}
        </ExpNoFeesMinimalistDisclaimer>
      </div>
    </div>
  );
};

ExpNoFeesMinimalist.propTypes = {
  model: ExpNoFeesMinimalistModelSchema,
};

export default ExpNoFeesMinimalist;
