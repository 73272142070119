import PropTypes from 'prop-types';

export const DeviceTypeModelSchema = PropTypes.shape({
  description: PropTypes.string,
  icon: PropTypes.shape({
    url: PropTypes.string,
  }),
  name: PropTypes.string,
});

export const DevicesModelSchema = PropTypes.shape({
  description: PropTypes.string,
  headline: PropTypes.string,
  disclaimer: PropTypes.string,
  devices: PropTypes.arrayOf(DeviceTypeModelSchema),
});
