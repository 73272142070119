import { string, bool, shape } from 'prop-types';

import { StatefulPropType } from '!app/share/schema';

const SingleImagePropType = shape({
  url: string.isRequired,
  alt: string.isRequired,
});

export const CTAModelSchema = shape({
  eyebrow: string,
  bodyStates: StatefulPropType,
  headlineStates: StatefulPropType,
  primaryCta: shape({}),
  primaryAction: shape({}),
  secondaryCta: string,
  secondaryAction: string,
  primaryProgram: string,
  secondaryProgram: string,
  disclaimer: string,
  emailCapture: bool,
  style: string,
  image: shape({
    desktop: SingleImagePropType,
    mobile: SingleImagePropType,
    tablet: SingleImagePropType,
  }),
  primaryCtaStyle: string,
});
