// TODO: Legacy login remove #HUWEB-30424
import { LoginModal as SharedLoginModal } from '@hulu/login-modal';
import WebLogin from '@hulu/web-login-ui';
import className from 'classnames';
import getConfig from 'next/config';
import PropTypes from 'prop-types';

import '../stylesheet/LoginModal.scss';
import { view as Modal } from '!app/components/Modal';
import { getHost } from '!app/lib/config';
import * as metricsTracker from '!app/metrics';

const { view: LoginModalView } = SharedLoginModal;

const LoginModal = (props) => {
  const signupHref = `${getHost('signup')}/go/one-hulu`;
  const publicRuntimeConfig = getConfig().publicRuntimeConfig;
  const {
    closeLoginModal,
    hasUnifiedLoginEnabled,
    hasUpdatedGenderOptions,
    shown,
  } = props;

  const onLoggedIn = ({ hasAuthenticationChanged }) => {
    if (hasAuthenticationChanged) {
      window.location.reload();
    }
  };

  return (
    <Modal
      className={className({ 'modal-unified-login': hasUnifiedLoginEnabled })}
      model={{ id: 'login-modal' }}
      onModalClosed={closeLoginModal}
      shown
    >
      {hasUnifiedLoginEnabled ? (
        shown ? (
          <WebLogin
            env={publicRuntimeConfig?.webLoginEnv}
            recaptchaMode="auto"
            startTrialHref={signupHref}
            trackEvent={metricsTracker?.trackEvent}
            onLoggedIn={onLoggedIn}
            useUpdatedGenderOptions={hasUpdatedGenderOptions}
          />
        ) : null
      ) : (
        <LoginModalView
          config={publicRuntimeConfig}
          startTrialHref={signupHref}
          metricsTracker={metricsTracker}
        />
      )}
    </Modal>
  );
};

LoginModal.propTypes = {
  closeLoginModal: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
  hasUnifiedLoginEnabled: PropTypes.bool,
  hasUpdatedGenderOptions: PropTypes.bool,
};

export default LoginModal;
