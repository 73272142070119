import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';

import Entity from './Entity';
import * as EntityType from './EntityType';
import Episode from './Episode';
import Extra from './Extra';
import Movie from './Movie';
import Network from './Network';
import Series from './Series';

const getEntityMetadata = (asset) => {
  let entity;

  switch (asset.type) {
    case EntityType.EPISODE:
      entity = new Episode(asset);
      return entity.getData();
    case EntityType.EXTRA:
      entity = new Extra(asset);
      return entity.getData();
    case EntityType.MOVIE:
      entity = new Movie(asset);
      return entity.getData();
    case EntityType.SERIES:
      entity = new Series(asset);
      return entity.getData();
    case EntityType.NETWORK:
      entity = new Network(asset);
      return entity.getData();
    default:
      entity = new Entity(asset);
      return entity.getData();
  }
};

const getTags = (data = {}) => {
  const { rating, genres, type, premiereDate } = data;
  const slots = [];
  if (rating) {
    slots.push(rating);
  }
  if (genres?.length > 0) {
    slots.push(genres.slice(0, 2).join(', '));
  }
  if (type) {
    const premiereDateSlot = premiereDate
      ? `${type} (${premiereDate.getFullYear()})`
      : type;
    slots.push(premiereDateSlot);
  }

  return slots.join(' • ');
};

const getDescriptionOverwrite = (data) => {
  // we don't want description for extras or network
  if (
    data.type.toLowerCase() === EntityType.EXTRA ||
    data.type.toLowerCase() === EntityType.NETWORK
  ) {
    return {
      description: null,
    };
  }

  return {
    description: getTags(data),
  };
};

const getEpisodeOverwrites = (data) => {
  const action = data.episode ? `Episode ${data.episode}` : null;
  return {
    action,
    contentType: 'episode',
    descriptionLines: 5,
    disableTileLink: true,
  };
};

const getTagsOverwrite = (data) => {
  return {
    joinedTags: getTags(data),
  };
};

const getArtworkOverwrites = (data, asset) => {
  const horizontalProgramTile = get(
    asset,
    'artwork.horizontalProgramTile',
    null
  );
  const horizontalHero = get(asset, 'artwork.horizontalHero', null);
  const verticalProgramTile = get(asset, 'artwork.verticalProgramTile', null);
  const verticalHero = get(asset, 'artwork.verticalHero', null);

  return {
    artwork: horizontalHero || horizontalProgramTile || {},
    mobileArtwork: verticalHero || verticalProgramTile || {},
  };
};

const withTileProps = (Component, overwriteProps) => {
  const WithTileProps = (props) => {
    const { asset } = props;
    const tileProps = getEntityMetadata(asset);
    let customProps;

    if (isFunction(overwriteProps)) {
      customProps = overwriteProps(tileProps, asset);
    }

    const finalProps = {
      ...props,
      ...tileProps,
      ...customProps,
    };

    // always block click-through for episode and extra asset regardless of what configured in hangout
    if ([EntityType.EPISODE, EntityType.EXTRA].includes(asset.type)) {
      finalProps.disableTileLink = true;
      finalProps.hideControlIcon = true;
    }

    return <Component {...finalProps} />;
  };

  WithTileProps.propTypes = {
    asset: PropTypes.shape({}).isRequired,
  };

  return WithTileProps;
};

export {
  getEntityMetadata,
  getDescriptionOverwrite,
  getEpisodeOverwrites,
  getTagsOverwrite,
  getArtworkOverwrites,
  withTileProps,
};
