import Text from '@hulu/web-ui/Text';
import { isEmpty } from 'lodash';
import React from 'react';

import ImageBackground from '../../Masthead/view/ImageBackground';
import useCollectionsModal from '../useCollectionsModal';

import { AutomationStateModalCopy } from './AutomationStateModalCopy';

import EditorialCollection from '!app/components/Collections/EditorialCollection/views/EditorialCollection';
import MastheadVideo from '!app/components/MastheadVideo';
import { Modalv2 } from '!app/components/Modal';

import '../stylesheets/CollectionsModal.scss';
import '../../Modal/stylesheet/ModalStyle.scss';

const CollectionsModal = (props) => {
  const {
    collectionsModalId,
    close,
    collectionModalUrlObject,
    arePostersAutomated,
    brandUrl,
  } = props;
  const { data, isFetching } = useCollectionsModal(collectionsModalId);
  let collectionsWithTitles = [];
  const {
    collectionItems,
    backgroundImage,
    portraitVideo,
    landscapeVideo,
    title,
    copyTitle,
    copyDescription,
  } = data ?? {};

  // because we're making this query client side we need to reshape the data to mimic the behavior of the EditorialCollection adapter
  if (!isFetching) {
    collectionsWithTitles = collectionItems?.map((collectionItem) => ({
      title: collectionItem?.collection?.name,
      ...collectionItem,
    }));
  }

  return (
    <Modalv2
      className="CollectionsModal"
      title={title}
      close={close}
      arePostersAutomated={arePostersAutomated}
    >
      {!isFetching && (
        <>
          <div className="modal__content">
            <ImageBackground
              image={
                !isEmpty(collectionModalUrlObject)
                  ? collectionModalUrlObject
                  : backgroundImage
              }
              brandUrl={brandUrl}
              identifier="0"
              mobileFirst
            />
            <MastheadVideo
              landscapeVideo={landscapeVideo}
              portraitVideo={portraitVideo}
              inModal
            />
            {arePostersAutomated ? (
              <AutomationStateModalCopy
                brandUrl={brandUrl}
                copyDescription={copyDescription}
                copyTitle={copyTitle}
              />
            ) : (
              <div className="modal__copy">
                <Text
                  as="h3"
                  breakpoints={{ xs: 'title32', md: 'title48' }}
                  className="modal__copy--title"
                >
                  {copyTitle}
                </Text>

                <Text
                  as="p"
                  breakpoints={{ xs: 'body16', md: 'body18' }}
                  className="modal__copy--description"
                >
                  {copyDescription}
                </Text>
              </div>
            )}
            <div className="modal__scrim" />
          </div>
          <div className="modal__collections">
            {collectionsWithTitles?.map(
              (collectionItem, key) =>
                collectionItem?.collection && (
                  <EditorialCollection model={collectionItem} key={key} />
                )
            )}
          </div>
        </>
      )}
    </Modalv2>
  );
};

export default CollectionsModal;
