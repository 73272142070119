import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../stylesheet/NavStyle.scss';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      nav_offset: 0,
    };
    this.onItemClick = this.onItemClick.bind(this);
  }

  componentDidMount() {
    const { selected } = this.state;
    this.onItemClick(selected);
  }

  componentDidUpdate() {
    const { selectedTab } = this.props;
    const { selected } = this.state;
    if (selectedTab !== selected) {
      this.onItemClick(selectedTab);
    }
  }

  /**
   * Function to change the nav item and indicator
   * @param {*} selected
   * @param Event object
   */
  onItemClick(selected, event) {
    const { onSelected, shouldPreventDefaultClick } = this.props;
    this.setState({ selected });
    if (onSelected) {
      onSelected(selected);
    }
    if (this.root) {
      const a = this.root.getElementsByTagName('a')[selected];
      if (!a) return;
      const navItems = this.root.getElementsByClassName('nav__items')[0];
      const indicatorOffset = a.offsetLeft;
      let navOffset = 0;
      if (window.innerWidth) {
        const windowInnerWidth = window.innerWidth;
        // Check if the nav items are wider than the window
        if (windowInnerWidth < navItems.offsetWidth) {
          navOffset = a.offsetLeft;
          // Check if the selected tab would hit the end of the window so keep it from moving
          if (navItems.offsetWidth - navOffset < windowInnerWidth) {
            navOffset = navItems.offsetWidth - windowInnerWidth;
          }
        }
      }
      this.setState({
        nav_offset: navOffset,
        indicator_offset: indicatorOffset,
        indicator_width: a.offsetWidth,
      });
    }

    if (shouldPreventDefaultClick && event) {
      event.preventDefault();
    }
  }

  render() {
    const { children, accentColor, shouldCenterItems } = this.props;
    const {
      selected,
      indicator_offset,
      indicator_width,
      nav_offset,
    } = this.state;
    const NEGATIVE_INDICATOR_OFFSET = -1;
    const indicatorStyle = classNames('nav__accent-bar', {
      hidden: indicator_offset < 0,
    });

    return (
      <div
        className={classNames('nav', { 'nav--centered': shouldCenterItems })}
        ref={(ele) => (this.root = ele)}
      >
        <div
          className="nav__items"
          style={{ transform: `translateX(-${nav_offset}px)` }}
        >
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              active: index === selected,
              onClick: (event) => this.onItemClick(index, event),
            })
          )}
          {indicator_offset > NEGATIVE_INDICATOR_OFFSET && (
            <span
              className={indicatorStyle}
              style={{
                backgroundColor: accentColor || '#262930',
                transform: `translateX(${indicator_offset}px)`,
                width: indicator_width,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

Nav.defaultProps = {
  shouldCenterItems: false,
};

Nav.propTypes = {
  onSelected: PropTypes.func,
  accentColor: PropTypes.string,
  shouldCenterItems: PropTypes.bool,
  shouldPreventDefaultClick: PropTypes.bool,
  selectedTab: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Nav;
