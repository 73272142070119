import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../stylesheet/BrandIcon.scss';

class BrandIcon extends Component {
  render() {
    const { brandIcon } = this.props;
    const { description, url } = brandIcon || {};
    if (!url) {
      return null;
    }

    return (
      <div className="BrandIcon" role="img">
        <img
          className="BrandIcon__brand-img"
          src={url}
          alt={description}
          role="presentation"
          data-automationid="masthead_brand_image"
        />
      </div>
    );
  }
}

BrandIcon.propTypes = {
  brandIcon: PropTypes.shape({
    description: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default BrandIcon;
