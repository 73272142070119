export const ACTION_TYPE_TOGGLE_MODAL = 'STATE_MODAL_ACTION_TOGGLE_MODAL';

export const toggleModal = (modal_id) => {
  return {
    type: ACTION_TYPE_TOGGLE_MODAL,
    payload: {
      modal_id,
    },
  };
};
