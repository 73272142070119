import Text from '@hulu/web-ui/Text';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { ExpPlanComparisonPricingRowModelSchema } from '../model/schema';

const ExpPlanComparisonPricingRow = (props) => {
  const {
    isBundle,
    pricingRow: { slug, bundleHeadline, headline, prices: originalPrices },
    numPlans,
  } = props;

  const headlineText = <Text variant="body16">{headline}</Text>;
  const prices = isBundle
    ? originalPrices
    : originalPrices.filter((price) => price.text);

  return (
    <div className="plan-feature-item">
      <div className="col-xs-6 col-lg-5 plan-feature__title--pricing-row">
        {isBundle ? bundleHeadline : headlineText}
      </div>

      {prices.map((price, i) => {
        const {
          text,
          bundle: { originalPriceText, discountedPriceText },
        } = price;

        return (
          <Fragment key={i}>
            <div
              className={`col-xs-${
                12 / numPlans
              } plan-feature__check plan-feature__check-${i}`}
              key={`${slug}_${i}`}
            >
              {isBundle ? (
                <div className="plan-feature__bullet">
                  <Text
                    variant="body12"
                    className="plan-feature__bullet_text--strikethrough"
                  >
                    {originalPriceText}
                  </Text>
                  <Text variant="body16" className="plan-feature__bullet_text">
                    {discountedPriceText}
                  </Text>
                </div>
              ) : (
                <div className="plan-feature__bullet">
                  <Text variant="body16" className="plan-feature__bullet_text">
                    {text}
                  </Text>
                </div>
              )}
            </div>
            <div className="plan-feature__separator" />
          </Fragment>
        );
      })}
    </div>
  );
};

ExpPlanComparisonPricingRow.propTypes = {
  isBundle: PropTypes.bool,
  pricingRow: ExpPlanComparisonPricingRowModelSchema,
  numPlans: PropTypes.number,
};

export default ExpPlanComparisonPricingRow;
