import { ACTION_TYPE_TOGGLE_MODAL } from '../actions';

export default {
  [ACTION_TYPE_TOGGLE_MODAL]: (state, payload) => {
    const { modal_id } = payload;
    // if (!state[modal_id]) {
    //     state[modal_id] = {}
    // }
    // state[modal_id].shown = !!state[modal_id] && !state[modal_id].shown
    return {
      ...state,
      [modal_id]: {
        shown: state[modal_id] ? !state[modal_id].shown : true,
      },
    };
  },
};
