import Text from '@hulu/web-ui/Text';
import React from 'react';

import { DevicesModelSchema } from '../model/schema';

import DevicesType from './DevicesType';

import { mobileDetect } from '!app/lib/environment';
import { WithEvents } from '!app/metrics/hoc';
import '../stylesheet/DevicesStyle.scss';

const Devices = (props) => {
  const {
    model: { description, devices, disclaimer, headline },
  } = props;

  const isMobile = mobileDetect().mobile();
  const devicesLength = devices.length;

  return (
    <div
      className={`supported-devices ${isMobile ? 'mobile' : ''} cu-devices`}
      id="devices"
      role="region"
      aria-labelledby="regionDevice"
      data-automationid="supported_devices"
    >
      <div className="supported-devices--header section-header white">
        <Text
          as="h2"
          variant="title32"
          className="section-headline supported-devices--headline white"
          id="regionDevice"
          data-automationid="supported_devices_headline"
        >
          {headline}
        </Text>
        <div
          className="supported-devices--description"
          data-automationid="supported_devices_description"
        >
          {description && (
            <WithEvents.p dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>
      </div>
      <div
        className="supported-devices--list container-width"
        data-automationid="supported_devices_list"
      >
        <section
          role="list"
          aria-label="Supported Devices"
          className="row justify-content-center"
        >
          {devices &&
            devices.map((item, index) => (
              <div
                key={index}
                role="listitem"
                className={`supported-devices--list-item col-xs-6 col-md-3 col-lg-2
                    ${
                      index % 5 === 0 && devicesLength - index > 5
                        ? 'offset-lg-1'
                        : ''
                    }`}
                data-automationid="supported_devices_list_item"
              >
                <DevicesType model={item} />
              </div>
            ))}
        </section>
      </div>
      <div className="supported-devices--disclaimer section-disclaimer">
        {disclaimer && (
          <WithEvents.p dangerouslySetInnerHTML={{ __html: disclaimer }} />
        )}
      </div>
    </div>
  );
};

Devices.propTypes = {
  model: DevicesModelSchema,
};

export default Devices;
