import Text from '@hulu/web-ui/Text';
import get from 'lodash/get';
import React, { useState, useEffect } from 'react';

import TierModalModelSchema from '../model/schema';

import { renderLogos } from '!app/components/LogoWall/view/LogoIcon';
import { view as Modal } from '!app/components/Modal';
import { fireUtagLink } from '!app/metrics/fireEvent';
import { trackEvent } from '!app/metrics/metricsTracker';
import '../stylesheets/TierModal.scss';

const TierModal = (props) => {
  const {
    model: { name, id, price, body },
  } = props;

  const [logos, setLogos] = useState(null);

  const shouldRandomize = get(props, 'model.shouldRandomize', false);
  const logosModel = {
    parentClassName: 'tier-modal__logos',
    wrapperClassName: 'tier-modal__logo-wrapper col-md-2',
    logoClassName: 'tier-modal__logo',
    shouldRandomize,
    logos: get(props, 'model.logos'),
  };

  useEffect(() => {
    // We set state in didMount for randomised logos so that react doesn't get
    // mismatched props between server and client (didMount only runs
    // client-side). If logos are not randomised then we just render them
    // server-side.
    if (shouldRandomize) {
      // eslint-disable-next-line react/no-did-mount-set-state
      setLogos({ logos: renderLogos(logosModel) });
    }
  }, []);

  const onTierModalOpen = (tierId, tierPrice) => {
    if (tierId === null || tierPrice === null) {
      return;
    }

    fireUtagLink({
      event_name: 'add-on_tier_modal_open',
      product_name: tierId,
      product_price: tierPrice,
    });

    trackEvent('user_interaction', {
      action_specifier: `tier_modal_click_productName:${tierId}_price:${tierPrice}`,
      element_specifier: 'tier_modal_open',
      hit_version: '2.4.0',
      interaction_type: 'click',
    });
  };

  return (
    <Modal
      model={{ id }}
      className="tier-modal__parent"
      onModalOpen={() => onTierModalOpen(id, price)}
    >
      <div className="tier-modal">
        {name && (
          <Text as="h3" variant="title32">
            <span
              className="tier-modal__name"
              dangerouslySetInnerHTML={{ __html: name }}
            />
          </Text>
        )}
        {price && (
          <Text className="tier-modal__pricing" as="div" variant="title32">
            {price}
          </Text>
        )}
        {body && (
          <Text as="div" variant="body16">
            <span
              className="tier-modal__body"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </Text>
        )}
        {shouldRandomize ? logos : renderLogos(logosModel)}
      </div>
    </Modal>
  );
};

TierModal.propTypes = {
  model: TierModalModelSchema,
};

export default TierModal;
