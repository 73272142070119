import Text from '@hulu/web-ui/Text';

import { ExpGridColumnModelSchema } from '../model/schema';

import { WithEvents } from '!app/metrics/hoc';

const ExpGridColumn = (props) => {
  const {
    model: { headline, description, columnsPerRow },
  } = props;
  const maxWidthPercent = 100;
  // TODO: Allow for custom styles
  const columnStyles = {
    flex: `${maxWidthPercent / columnsPerRow}%`,
  };

  return (
    <div className="exp-grid-column" style={columnStyles}>
      {headline && (
        <Text
          as="h2"
          variant="title32"
          className="section-headline"
          dangerouslySetInnerHTML={{ __html: headline }}
        />
      )}
      {description && (
        <WithEvents.div
          className="column-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
};

ExpGridColumn.propTypes = {
  model: ExpGridColumnModelSchema,
};

export default ExpGridColumn;
