import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import React from 'react';

import { LogoWallModelSchema } from '../model/schema';

import LogoIcon from './LogoIcon';

import { enterKeyHandler, preventFocus } from '!app/lib/accessibilityUtils';
import { fireUtagLink } from '!app/metrics/fireEvent';
import { WithEvents } from '!app/metrics/hoc';

import '../stylesheet/LogoWallStyle.scss';

const LogoWall = (props) => {
  const {
    model: { headline, description, zipCodeModalText, logos },
  } = props;

  const iconsLength = logos && logos.length;

  return (
    <div
      className="logo-wall container-width cu-channels"
      id="channels"
      role="region"
      aria-labelledby="regionNetwork"
      data-automationid="logo_wall"
    >
      <div className="logo-wall__header section-header">
        <Text
          as="h2"
          variant="title32"
          className="logo-wall__headline"
          id="regionNetwork"
          data-automationid="logo_wall_headline"
        >
          {headline}
        </Text>
        {Boolean(description) && (
          <WithEvents.div
            className="logo-wall__description section-body"
            dangerouslySetInnerHTML={{ __html: description }}
            data-automationid="logo-wall__description"
          />
        )}
      </div>
      <button
        className="arrow-button"
        type="button"
        name="view-channels"
        data-automationid="arrow_button"
        data-toggle="modal"
        data-target="#zip-modal"
        onClick={() => fireUtagLink({ event_name: 'zip_module' })}
        onKeyDown={enterKeyHandler(() =>
          fireUtagLink({ event_name: 'zip_module' })
        )}
        onMouseDown={preventFocus}
      >
        <Text variant="label14">{zipCodeModalText}</Text>
      </button>
      <div className="logo-wall__logo-list">
        <section
          role="list"
          aria-label="Featured Networks"
          className="gutter-8 row justify-content-center"
        >
          {logos &&
            logos.map((logo, index) => {
              const numLogosMd = 7;
              const numLogosLg = 8;
              const logoClasses = classNames(
                'col-xs-4 col-sm-3 network-col-md col-md-2 col-lg-1 logo-wall__list-item',
                {
                  'network-offset-md-left':
                    index % numLogosMd === 0 &&
                    iconsLength - index >= numLogosMd,
                  'network-offset-lg-left':
                    index % numLogosLg === 0 &&
                    iconsLength - index >= numLogosLg,
                }
              );
              return (
                <div key={index} role="listitem" className={logoClasses}>
                  <LogoIcon model={logo} />
                </div>
              );
            })}
        </section>
      </div>
    </div>
  );
};

LogoWall.propTypes = {
  model: LogoWallModelSchema,
};

export default LogoWall;
