import get from 'lodash/get';
import React, { Component } from 'react';

import CollectionTabs from './CollectionTabs';

import { CollectionTabsModelSchema } from '!app/components/CollectionTabs/model/schema';
import * as HeroSliderMasthead from '!app/components/HeroSliderMasthead';

class CollectionTabsWithHeroSlider extends Component {
  constructor(props) {
    super(props);
    this.onSelected = this.onSelected.bind(this);
    this.onMastheadIndexChange = this.onMastheadIndexChange.bind(this);
    this.state = {
      selected: 0,
      mastheadIndex: 0,
    };
  }

  onMastheadIndexChange(mastheadIndex) {
    this.setState({ mastheadIndex });
  }

  onSelected(selected) {
    this.setState({ selected });
  }

  render() {
    const { selected } = this.state;
    const {
      model,
      model: { tabs },
    } = this.props;
    const currentTabModel = get(tabs, `${selected}.model`);
    const sliderMastheadModel = { ...model, ...currentTabModel };

    return (
      <>
        <HeroSliderMasthead.view model={sliderMastheadModel} />
        <CollectionTabs
          model={model}
          onSelected={this.onSelected}
          extraClassName="CollectionTabs--withHeroSlider"
          withSlider
        />
      </>
    );
  }
}

CollectionTabsWithHeroSlider.propTypes = {
  model: CollectionTabsModelSchema,
};

export default CollectionTabsWithHeroSlider;
