export const CaretDownSVG = () => {
  return (
    <svg
      className="caret-down"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 30"
      x="0px"
      y="0px"
    >
      <g>
        <path d="M12,16a.99676.99676,0,0,1-.707-.293l-6-6A.99989.99989,0,0,1,6.707,8.293L12,13.58594l5.293-5.293A.99989.99989,0,0,1,18.707,9.707l-6,6A.99676.99676,0,0,1,12,16Z" />
      </g>
    </svg>
  );
};
