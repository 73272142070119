import { shape, string, arrayOf, boolean } from 'prop-types';

const ExpPlanComparisonFeaturePlanModelSchema = shape({
  slug: string,
  isApplicable: boolean,
});

const ExpPlanComparisonFeatureDescriptionModelSchema = shape({
  richText: string,
  modalId: string,
});

export const ExpPlanComparisonFeatureDisclaimerModelSchema = shape({
  id: string,
  richText: string,
  modalLinkText: string,
  modalId: string,
});

export const ExpPlanComparisonFeatureModelSchema = shape({
  slug: string,
  headline: string.isRequired,
  description: ExpPlanComparisonFeatureDescriptionModelSchema,
  disclaimer: ExpPlanComparisonFeatureDisclaimerModelSchema,
  plans: arrayOf(ExpPlanComparisonFeaturePlanModelSchema),
});

export const ExpPlanComparisonPricingRowModelSchema = shape({
  slug: string.isRequired,
  headline: string.isRequired,
  prices: arrayOf(
    shape({ originalPriceText: string, discountedPriceText: string })
  ),
});

export const ExpPlanComparisonPlanModelSchema = shape({
  slug: string,
  headline: string,
  eyebrow: string,
  badge: string,
  ctaText: string,
  mobileCtaText: string,
  ctaAction: string,
  ctaBtnStyle: string,
  program: string,
  logo: string,
  description: string,
});

const FeatureDisclaimerSchema = shape({
  richText: string,
  modalLinkText: string,
  modalContent: string,
  isBundle: boolean,
});

export const ExpPlanComparisonChartMultiTabModelSchema = shape({
  headline: string.isRequired,
  description: string,
  addonsHeadline: string,
  addonsDescription: string,
  defaultTab: string.isRequired,
  controllerTabs: shape({
    [string]: shape({
      name: string,
      elementSpecifier: string,
    }),
  }),
  plans: shape({
    [string]: arrayOf(ExpPlanComparisonPlanModelSchema).isRequired,
  }),
  features: shape({
    [string]: arrayOf(ExpPlanComparisonFeatureModelSchema).isRequired,
  }),
  featureDisclaimers: shape({
    [string]: arrayOf(FeatureDisclaimerSchema),
  }),
  addons: shape({
    [string]: arrayOf(ExpPlanComparisonFeatureModelSchema),
  }),
  addonDisclaimers: shape({
    [string]: arrayOf(FeatureDisclaimerSchema),
  }),
  pricingRows: shape({
    [string]: arrayOf(ExpPlanComparisonPricingRowModelSchema),
  }),
});
