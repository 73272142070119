import { connect } from 'react-redux';

import {
  withTileProps,
  getDescriptionOverwrite,
  getEpisodeOverwrites,
  getTagsOverwrite,
  getArtworkOverwrites,
} from './model/adapter';
import MastheadTileView from './views/MastheadTile';
import PortraitTileView from './views/PortraitTile/PortraitTile';
import TileView from './views/Tile';

import { MODAL_NAME_SIGNUP } from '!app/lib/constants';
import { toggleModal } from '!app/redux/modal/actions';

const withSignupModal = connect(null, (dispatch) => ({
  showSignupModal: () => dispatch(toggleModal(MODAL_NAME_SIGNUP)),
}));

const PortraitTile = withSignupModal(
  withTileProps(PortraitTileView, getTagsOverwrite)
);
const Tile = withSignupModal(withTileProps(TileView, getDescriptionOverwrite));
const EpisodeTile = withSignupModal(
  withTileProps(TileView, getEpisodeOverwrites)
);
const MastheadTile = withSignupModal(
  withTileProps(MastheadTileView, getArtworkOverwrites)
);

export { Tile, EpisodeTile, PortraitTile, MastheadTile };
