import { ACTION_TYPE_CLOSE_TOPHAT, ACTION_TYPE_OPEN_TOPHAT } from '../actions';

export default {
  [ACTION_TYPE_CLOSE_TOPHAT]: (state) => {
    return {
      ...state,
      shown: false,
    };
  },
  [ACTION_TYPE_OPEN_TOPHAT]: (state) => {
    return {
      ...state,
      shown: true,
    };
  },
};
