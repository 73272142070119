import { ThemeProvider } from '@emotion/react';
import {
  oneHuluLightTheme,
  oneHuluDarkTheme,
} from '@hulu/web-ui/style/createTheme';
import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { InView as Observer } from 'react-intersection-observer';

import { ExpPlanComparisonChartModelSchema } from '../model/schema';

import ExpPlanComparisonBundleHeader from './ExpPlanComparisonBundleHeader';
import ExpPlanComparisonDisclaimer from './ExpPlanComparisonDisclaimer';
import ExpPlanComparisonFeature from './ExpPlanComparisonFeature';
import ExpPlanComparisonHeader from './ExpPlanComparisonHeader';
import ExpPlanComparisonPlan from './ExpPlanComparisonPlan';
import ExpPlanComparisonPricingRow from './ExpPlanComparisonPricingRow';

import {
  ExpPlanComparisonChartProvider,
  useExpPlanComparisonChartContext,
} from '!app/experimental/components/ExpPlanComparisonChart/view/ExpPlanComparisonChartContext';
import { isBrowser } from '!app/lib/environment';
import { fireUserInteraction } from '!app/metrics/fireEvent';
import { DetailEntityStoreSchema } from '!app/share/schema';
import '../stylesheet/ExpPlanComparisonChart.scss';

const NAV_HEIGHT = 74;
const LARGE_WIDTH = 1024;
const ExpPlanComparisonChart = (props) => {
  const {
    model: {
      headline,
      description,
      addonsHeadline,
      addonsDescription,
      isDark,
      bundle,
      bundlePlans,
      plans: nonBundlePlans,
      pricingRows,
      bundleFeatures,
      features: nonBundleFeatures,
      bundleFeatureDisclaimers,
      featureDisclaimers: nonBundleFeatureDisclaimers,
      bundleAddons,
      addons: nonBundleAddons,
      bundleAddonDisclaimers,
      addonDisclaimers: nonBundleAddonDisclaimers,
    },
    user,
    requirePremium,
    network,
  } = props;
  const isDarkClass = { 'is-dark': isDark };
  const { isBundle } = useExpPlanComparisonChartContext();
  const [isSticky, setIsSticky] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [topObserverRootMargin, setTopObserverRootMargin] = useState(0);
  const [bottomObserverRootMargin, setBottomObserverRootMargin] = useState(0);
  const [headerContainerHeight, setHeaderContainerHeight] = useState(0);
  const [headerColumnHeight, setHeadColumnHeight] = useState(0);
  const [mainNavSticky, setMainNavSticky] = useState(false);
  const headContainerEl = useRef(null);
  const headColumnEl = useRef(null);
  const stickyHeadEl = useRef(null);
  const plansSectionEl = useRef(null);
  const planFeaturesEl = useRef(null);
  const plans = isBundle ? bundlePlans : nonBundlePlans;
  const features = isBundle ? bundleFeatures : nonBundleFeatures;
  const featureDisclaimers = isBundle
    ? bundleFeatureDisclaimers
    : nonBundleFeatureDisclaimers;
  const addons = isBundle ? bundleAddons : nonBundleAddons;
  const addonDisclaimers = isBundle
    ? bundleAddonDisclaimers
    : nonBundleAddonDisclaimers;
  const isBadgePresent = plans.some(({ badge }) => badge);
  const numPlans = plans.length;
  // Together, these let us know when to make the plans header sticky.
  const stickyClasses = {
    'plan-head-fixed': isSticky,
  };

  // Group classes together to easier manage variables
  const cn = {
    headColumnClass: classNames(
      'col-xs-12',
      'col-lg-5',
      'plan-head-column',
      {
        'plan-head-column--short': !isBadgePresent,
      },
      stickyClasses
    ),
    headClass: classNames('col-xs-12', 'col-lg-7', 'plan-head', stickyClasses),
    headContainer: classNames('plan-head-container', stickyClasses),
    featureClass: classNames('plan-feature', stickyClasses),
    addonsClass: classNames('plan-addon', {
      'plan-addon_show': showAddons,
    }),
    plansContainerClass: classNames('exp-plans-container'),
  };

  useEffect(() => {
    setObserverRootMargin();
    window.addEventListener('resize', setObserverRootMargin);

    return () => {
      window.removeEventListener('resize', setObserverRootMargin);
    };
  }, []);

  /**
   * Expands/collapses Addon accordion state.
   */
  const toggleAddonsState = () => {
    fireUserInteraction(
      'default',
      `landing_plan_add_ons:${showAddons ? 'hide' : 'show'}`,
      'click',
      false
    );
    setShowAddons(!showAddons);
  };

  /**
   * Sets the rootmargin states for Observables according to screen size.
   */
  const setObserverRootMargin = () => {
    const mainNavEl = document.querySelector('header');
    const isMainNavSticky = mainNavEl
      ? mainNavEl.classList.contains('navigation--sticky')
      : false;
    setMainNavSticky(isMainNavSticky);
    const navigationHeights = {
      desktop: isMainNavSticky ? -mainNavEl.offsetHeight : 0,
      mobile: isMainNavSticky ? -mainNavEl.offsetHeight : 0,
    };
    const isSmallScreen = window.innerWidth < LARGE_WIDTH;
    const headContainerHeight = headContainerEl.current.getBoundingClientRect()
      .height;
    const headColumnHeight = headColumnEl.current.getBoundingClientRect()
      .height;
    const stickyHeadHeight = stickyHeadEl.current.getBoundingClientRect()
      .height;
    const topRootMargin = isSmallScreen
      ? headColumnHeight + navigationHeights.mobile
      : navigationHeights.desktop;
    const bottomRootMargin = isSmallScreen
      ? stickyHeadHeight - navigationHeights.mobile - window.innerHeight
      : stickyHeadHeight - navigationHeights.desktop - window.innerHeight;
    setBottomObserverRootMargin(bottomRootMargin);
    setTopObserverRootMargin(topRootMargin);
    setHeaderContainerHeight(headContainerHeight);
    setHeadColumnHeight(headColumnHeight);
  };

  /**
   * Makes the plans header sticky if the chart header scrolls out of view.
   * @param {boolean} inView
   */
  const setStickyState = (inView) => {
    const plansSectionDistanceFromTop = plansSectionEl.current.getBoundingClientRect()
      .top;
    const featuresElementDistanceFromTop = planFeaturesEl.current.getBoundingClientRect()
      .top;
    const isWithinViewport = plansSectionDistanceFromTop < window.innerHeight;
    const isFeaturesInView =
      featuresElementDistanceFromTop < window.innerHeight;
    const isStickyHeader = !inView && isWithinViewport && isFeaturesInView;
    setIsSticky(isStickyHeader);
  };

  const handleBottomObserverChange = () => {
    setIsSticky(false);
  };

  const getPlanHeaderPosition = () => {
    const isSmallScreen = isBrowser() ? window.innerWidth < LARGE_WIDTH : false;

    if (isSmallScreen) {
      return mainNavSticky
        ? -(headerColumnHeight - NAV_HEIGHT)
        : -headerColumnHeight;
    }

    return mainNavSticky ? NAV_HEIGHT : 0;
  };
  return (
    <ThemeProvider theme={isDark ? oneHuluDarkTheme : oneHuluLightTheme}>
      <div
        className={classNames(
          'exp-plan-comparison-chart',
          'cu-plans',
          isDarkClass
        )}
        id="plans"
        role="region"
        aria-label="Plans"
        ref={plansSectionEl}
      >
        <div className={cn.plansContainerClass}>
          <ExpPlanComparisonHeader
            headline={headline}
            description={description}
          />
          <Observer
            rootMargin={`${topObserverRootMargin}px 0px 0px 0px`}
            onChange={(inView) => setStickyState(inView)}
          >
            <div className="plans__observer" />
          </Observer>
          <div
            className={cn.headContainer}
            ref={headContainerEl}
            style={{
              top: getPlanHeaderPosition(),
            }}
          >
            <div className={cn.headColumnClass} ref={headColumnEl}>
              <ExpPlanComparisonBundleHeader bundle={bundle} isDark={isDark} />
            </div>
            <div className={cn.headClass} ref={stickyHeadEl}>
              {plans.map((plan, i) => (
                <ExpPlanComparisonPlan
                  numPlans={numPlans}
                  plan={plan}
                  key={`feature-plan-${i}`}
                  user={user}
                  network={network}
                  requirePremium={requirePremium}
                  isBadgePresent={isBadgePresent}
                  isBundle={isBundle}
                  index={i}
                  isDark={isDark}
                />
              ))}
            </div>
          </div>
          <div
            className={cn.featureClass}
            style={{
              paddingTop: isSticky ? headerContainerHeight : null,
            }}
            ref={planFeaturesEl}
          >
            {pricingRows.map((pricingRow) => (
              <ExpPlanComparisonPricingRow
                isBundle={isBundle}
                numPlans={numPlans}
                key={pricingRow.slug}
                pricingRow={pricingRow}
              />
            ))}
            {features.map((feature, i) => (
              <ExpPlanComparisonFeature
                numPlans={numPlans}
                key={feature.slug}
                feature={feature}
                index={i}
                type="feature"
                isDark={isDark}
              />
            ))}
          </div>
          {featureDisclaimers.length > 0 && (
            <div className="plan-feature-disclaimers">
              {featureDisclaimers.map((disclaimer, i) => (
                <div
                  key={`feature-disclaimer-${i}`}
                  className="plans-disclaimer__container"
                >
                  <ExpPlanComparisonDisclaimer
                    disclaimer={disclaimer}
                    index={i}
                    type="feature"
                  />
                </div>
              ))}
            </div>
          )}
          {addons.length > 0 && (
            <>
              <div className={cn.addonsClass}>
                <div className="plan-addon__head col-xs-12">
                  <div className="col-lg-6 plan-addon__head-desktop">
                    <Text
                      as="h3"
                      breakpoints={{ xs: 'title24', md: 'title32' }}
                      className="plan-addon__head-title"
                    >
                      {addonsHeadline}
                    </Text>

                    <div
                      className="plan-addon__head-desc"
                      dangerouslySetInnerHTML={{ __html: addonsDescription }}
                    />
                  </div>
                </div>
                <div className="plan-body__addon_container">
                  {addons.map((feature, i) => (
                    <ExpPlanComparisonFeature
                      key={feature.slug}
                      feature={feature}
                      numPlans={numPlans}
                      index={i}
                      type="addon"
                      isDark={isDark}
                    />
                  ))}
                </div>
                {addonDisclaimers.length > 0 && (
                  <div className="plans-container__bottom-container">
                    {addonDisclaimers.map((disclaimer, i) => (
                      <ExpPlanComparisonDisclaimer
                        key={`addon-disclaimer-${i}`}
                        disclaimer={disclaimer}
                        index={i}
                        type="addon"
                      />
                    ))}
                  </div>
                )}
              </div>
              <div
                className="plan-addon_controller"
                onClick={toggleAddonsState}
                role="button"
                aria-label="See Addons"
                aria-pressed="false"
                style={showAddons ? { 'border-top': 'none' } : {}}
              >
                {`${showAddons ? 'Hide' : 'Show'} Add-ons`}
                <img
                  src={`/static/hitch/static/icons/Dropdown_${
                    showAddons ? 'Up' : 'Down'
                  }_Arrow.svg`}
                  role="presentation"
                  alt="See Add-ons."
                />
              </div>
            </>
          )}
        </div>
        <Observer
          rootMargin={`100% 0px ${bottomObserverRootMargin}px 0px`}
          onChange={handleBottomObserverChange}
        >
          <div className="plans__bottom-observer" />
        </Observer>
      </div>
    </ThemeProvider>
  );
};

ExpPlanComparisonChart.propTypes = {
  model: ExpPlanComparisonChartModelSchema.isRequired,
  ...DetailEntityStoreSchema,
};

const ExpPlanComparisonChartWrapper = (props) => {
  return (
    <ExpPlanComparisonChartProvider>
      <ExpPlanComparisonChart {...props} />
    </ExpPlanComparisonChartProvider>
  );
};

export default ExpPlanComparisonChartWrapper;
